import {Injectable} from '@angular/core';
import {SecurityService} from './security.service';

@Injectable({
    providedIn: 'root',
})
export class AuthorizationService {
    constructor() {}

    public hasPermissions(roles: []): boolean {
        return roles.some(rol => this.hasPermission(rol));
    }

    public hasPermission(rol): boolean {
        return SecurityService.getUser().authorities.some(a => a === rol);
    }
}
