import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(private _matSnackBar: MatSnackBar, private translateService: TranslateService) {
    }

    showMessage(i18KeyMessage?: string, textMessage?: string): void {
        const message = i18KeyMessage ? this.translateService.instant(i18KeyMessage) : textMessage;
        this._matSnackBar.open(message, 'OK', {
            verticalPosition: 'top',
            duration: 2000
        });
    }

    showMessageDontShowOK(i18KeyMessage?: string, textMessage?: string): void {
        const message = i18KeyMessage ? this.translateService.instant(i18KeyMessage) : textMessage;
        this._matSnackBar.open(message, null, {
            verticalPosition: 'top',
            duration: 2000
        });
    }
}


