import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClientService} from '../../../core/services/rest-client.service';
import {SocialSearchCriteria} from '../../models/social-search-criteria';
import {Pageable} from '../../models/pageable';
import {PagedResult} from '../../models/paged-result';

@Injectable({
    providedIn: 'root'
})
export class SocialRemoteService {
    private URL = '/api/social';

    constructor(private restClientService: RestClientService) {
    }

    getFollowedInUsers(userIds: string[]): Observable<any[]> {
        return this.restClientService.post(`${this.URL}/all-followed-in-users`, userIds);
    }

    getTopFollowingAndFollowers(id: string, criteria?: Pageable): Observable<any> {
        return this.restClientService.get(`${this.URL}/${id}`, criteria);
    }

    searchFollowed(id: string, criteria?: SocialSearchCriteria): Observable<PagedResult<any>> {
        return this.restClientService.get(`${this.URL}/${id}/followed`, criteria);
    }

    searchFollowers(id: string, criteria?: SocialSearchCriteria): Observable<PagedResult<any>> {
        return this.restClientService.get(`${this.URL}/${id}/followers`, criteria);
    }

    create(userId: string): Observable<any> {
        return this.restClientService.post(`${this.URL}/${userId}`);
    }

    delete(userId: string): Observable<any> {
        return this.restClientService.delete(`${this.URL}/${userId}`);
    }
}
