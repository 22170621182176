import {Directive, OnDestroy, Input, EventEmitter, HostListener, Renderer2, ElementRef, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';

@Directive({
    selector: '[BtnDisableAfterClickDirective]'
})
export class BtnDisableAfterClickDirective {
    // README --> READ AT THE END
    @Input('BtnDisableAfterClickDirective')
    reenableButton: EventEmitter<boolean>;
    subscription: Subscription;

    constructor(
        private renderer: Renderer2,
        private el: ElementRef
    ) {
    }

    @HostListener('click')
    onClick(): void {
        this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
    }

    ngOnInit(): void {
        this.subscription = this.reenableButton.subscribe(value => {
            // debugger;
            // console.log('Get value ', value);
            if (!value) {
                this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
            }
        });
    }

    ngOnDestroy(): void {
        // tslint:disable-next-line:no-unused-expression
        this.subscription && this.subscription.unsubscribe();
    }

}

/* >>README<<
[--JUST-DISABLE--]
>>_____HTML_____
<button [BtnDisableAfterClickDirective]="reenableButton" (click)="submit()">My button</button>
>>_____TS_____
public reenableButton = new EventEmitter<boolean>(false);

[--RE-ENABLE--]
>>_____HTML_____
<button [BtnDisableAfterClickDirective]="reenableButton" (click)="submit()">My button</button>
>>_____TS_____
public reenableButton = new EventEmitter<boolean>(false);
submit(){
    this.reenableButton.emit(false);
}
*/
