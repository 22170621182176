import {ContentBlock} from './content-block';

export class ContentBlockLabel extends ContentBlock{
    title: string;
    subtitle: string;
    bgColor: string;

    constructor(block) {
        super(block);
        this.title = block.title || '';
        this.subtitle = block.subtitle || '';
        this.bgColor = block.bgColor || '';
    }

    updateData(data): void {
        super.updateData(data);
        this.title = data.title;
        this.subtitle = data.subtitle;
        this.bgColor = data.bgColor;
    }

    getTitle(): string {
        return 'course.labelBlock';
    }

    getTypeAsString(): string {
        return 'label';
    }

}
