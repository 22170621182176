import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';

import videojs from 'video.js';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';
import {Observable} from 'rxjs';
import {FuseUtils} from '../../../../../@fuse/utils';
import {CookieService} from 'ngx-cookie-service';
import {Cookie} from '@quiox/global/models/cookie';
import {VideoAccessRemoteService} from "../../../course/services/remote-services/video-access.remote.service";

@Component({
    selector: 'app-vjs-player',
    templateUrl: 'f-video-js-player.component.html',
    styleUrls: ['./f-video-js-player.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VjsPlayerComponent implements OnInit, OnDestroy {
    private readonly videoType: 'application/dash+xml' | 'application/x-mpegURL';
    player: videojs.Player;
    ready: boolean;
    elementId: string;
    cookieItems: Cookie[];
    @Input() src: string;
    @Input() poster: string;
    @Input() readyHandler: Observable<any>;
    @Input() readyCookie: Observable<any>;
    @Output() videoPaused: EventEmitter<void>;
    @Output() videoPlaying: EventEmitter<void>;


    constructor(private _cookieService: CookieService, private videoAccessRemoteService: VideoAccessRemoteService) {
        this.player = null;
        this.videoType = 'application/x-mpegURL';
        this.elementId = FuseUtils.generateGUID();
        this.videoPaused = new EventEmitter();
        this.videoPlaying = new EventEmitter();


    }

    ngOnInit(): void {
        if (!this.readyHandler) {
            this.ready = true;
            setTimeout(() => this.initPlayer());
        }
    }

    startPlayback(): void {
        this.readyHandler.subscribe(result => {
            console.log('Resultado en el video js', result);
            if (result != null) {

                this.videoAccessRemoteService.getCookie().subscribe(cookie => {
                    this.cookieItems = [];
                    this.cookieItems = cookie;

                    if (this._cookieService.check('CloudFront-Key-Pair-Id')) {
                        this._cookieService.delete('CloudFront-Key-Pair-Id');
                        console.log(`Cookie CloudFront-Key-Pair-Id  eliminada.`);
                    } else {
                        console.log(`La cookie CloudFront-Key-Pair-Id no existe.`);
                    }
                    if (this._cookieService.check('CloudFront-Policy')) {
                        this._cookieService.delete('CloudFront-Policy');
                        console.log(`Cookie CloudFront-Policy  eliminada.`);
                    } else {
                        console.log(`La cookie CloudFront-Policy  no existe.`);
                    }
                    if (this._cookieService.check('CloudFront-Signature')) {
                        this._cookieService.delete('CloudFront-Signature');
                        console.log(`Cookie CloudFront-Signature eliminada.`);
                    } else {
                        console.log(`La cookie CloudFront-Signature no existe.`);
                    }

                    // Calcular la fecha de expiración en 3 horas
                    const expiracion = new Date();
                    expiracion.setTime(expiracion.getTime() + 3 * 60 * 60 * 1000); // 3 horas en milisegundos

                    this.cookieItems.forEach(cookieResult => {
                        this._cookieService.set(cookieResult.name, cookieResult.value, expiracion, cookieResult?.path, cookieResult?.domain, cookieResult?.secure, cookieResult?.sameSite);
                    });

                    this.src = result.data;
                    this.ready = true;
                    setTimeout(() => {
                        this.initPlayer();
                        // this.player.play();
                    });
                });
            }
        });
    }

    initPlayer(): void {
        // instantiate Video.js
        const options = {
            // fluid: true,
            autoplay: true,
            controls: true,
            sources: [{
                src: this.src,
                type: this.videoType,
                withCredentials: true
            }],
            html5: {
                vhs: {
                    overrideNative: true
                }
            }
        };

        const target = document.getElementById(this.elementId);
        this.player = videojs(target, options, () => {
            this.player.hlsQualitySelector({
                displayCurrentQuality: true
            });
        });
        this.player.on('pause', args => {
            this.videoPaused.emit();
        });
        this.player.on('play', args => {
            this.videoPlaying.emit();
        });
    }

    ngOnDestroy(): void {
        // destroy player
        if (this.player) {
            this.player.dispose();
        }
    }

}
