import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslationUtils} from '../../../core/services/translation.utils';
import {Observable} from 'rxjs';
import {Deleteable, Retrieveable, Saveable, Searchable} from '../../../core/services/remote-services-interfaces';
import {RestClientService} from '../../../core/services/rest-client.service';
import {PagedResult} from '../../models/paged-result';

@Injectable({
    providedIn: 'root'
})
export class WebinarRemoteService implements Searchable<any>,
    Retrieveable<any, string>,
    Saveable<any>,
    Deleteable<any, string> {
    private URL = '/api/webinar';

    constructor(private restClientService: RestClientService, private translateService: TranslateService) {
    }

    search(criteria?: any): Observable<PagedResult<any>> {
        return this.restClientService.get(this.URL, criteria);
    }

    get(id: string): Observable<any> {
        return this.restClientService.get(`${this.URL}/${id}`);
    }

    save(webinar): Observable<any> {
        TranslationUtils.processTranslation(webinar, this.getTranslatableFields(), this.translateService);
        if (webinar.id) {
            return this.restClientService.put(`${this.URL}/${webinar.id}`, webinar);
        }
        return this.restClientService.post(this.URL, webinar, {courseAnnouncementId: webinar.courseAnnouncementId});
    }

    delete(id: string): Observable<any> {
        return this.restClientService.delete(`${this.URL}/${id}`);
    }

    allMine(criteria?: any): Observable<PagedResult<any>> {
        return this.restClientService.get(this.URL + '/all/mine', criteria);
    }

    count(): Observable<any> {
        return this.restClientService.get(this.URL + '/all/count');
    }

    getTranslatableFields(): string[] {
        return ['name', 'summary'];
    }

}
