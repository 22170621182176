import {CoursePermissions} from '../course/config/course-permissions';


export const coffeeShopMenu = {
    id: 'coffeeshop',
    name: 'coffeeshop',
    fieldI18Key: 'NAV.coffeeshop',
    menu: [
        {
            name: 'coffeeshop',
            fieldI18Key: 'NAV.coffeeshop',
            url: '/general-forum/',
            icon: 'people_outline',
            roles: [CoursePermissions.CATEGORY_VIEW]
        }
    ]
};

export const coffeeShopManagerMenu = {
    projectName: '',
    projectLogo: 'assets/img/neuuni_logotipo_blanco.png',
    modules: [
        coffeeShopMenu
    ]
};
