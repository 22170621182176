<div mat-dialog-content class="p-0 m-0">

    <div fxLayout="column">
        <!--            <div *ngIf="isTeacher || isBothRoles || isStudent" class="search-wrapper" fxFlex fxLayout="row" fxLayoutAlign="start center">-->
        <!--                <div class="search" [ngClass]="{disabled: loading}" fxFlex fxLayout="row" fxLayoutAlign="start center">-->
        <!--                    <mat-icon class="s-16 secondary-text">search</mat-icon>-->
        <!--                    <input [formControl]="filter" type="text" placeholder="Buscar Miembros" fxFlex>-->
        <!--                </div>-->
        <!--            </div>-->


        <div  class="py-8" fxLayout="row" fxLayoutAlign="space-between center" style="cursor: pointer;">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="friend">
                    <img *ngIf="profile.image" [src]="profile.image.url" alt="{{profile.firstName}}" (click)="goProfile()" style="cursor: pointer;">
                    <img *ngIf="!profile.image" src="assets/images/avatars/profile.jpg"
                         alt="{{ profile.firstName }}" (click)="goProfile()" style="cursor: pointer;">
                </div>
                <div (click)="goProfile()" style="cursor: pointer;">{{ profile.firstName ? profile.firstName + ' ' + profile.lastName : '@' + profile.nick.split('@')[0] }}</div>

            </div>
            <div>
                <div [fxHide]="ownProfile" class="actions" fxLayout="row" fxLayoutAlign="end center"
                     [@animate]="{value:'*', params:{delay:'200ms'}}">

                    <button mat-raised-button color="accent" aria-label="Seguir" *ngIf="canFollow" [disabled]="disableFollowBtn"
                            (click)="saveFollowing()">
                        {{ isFollowing ? 'Dejar de seguir' : 'Seguir' }}
                    </button>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
    </div>

    <!--            <div *ngIf="(total - (size * (page+1)) ) >= users.length && users.length!=0" class="my-16"-->
    <!--                 fxLayout="column">-->
    <!--                <button mat-raised-button color="accent" (click)="viewMore()">Ver más</button>-->
    <!--            </div>-->
</div>


