import {CoursePermissions} from '../course/config/course-permissions';

export const generalConfigMenu = {
    id: 'rrhh',
    name: 'humanR esources',
    fieldI18Key: 'NAV.GENERALCONFIG',
    menu: [
        {
            name: 'General',
            fieldI18Key: 'NAV.DEAN',
            url: '/general-config/dean',
            roles: [CoursePermissions.DEAN_UPDATE]
        }
    ]
};

export const generalConfigManagerMenu = {
    projectName: '',
    projectLogo: 'assets/img/neuuni_logotipo_blanco.png',
    modules: [
        generalConfigMenu
    ]
};



