import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-expandable-text',
    templateUrl: './expandable-text..component.html',
    styleUrls: ['./expandable-text..component.scss']
})
export class ExpandableTextComponent {
    @Input() text: string;
    @Input() numberValue: number;
    expanded = false;
    truncatedText: string;
    fullTextContent: string;

    ngOnInit() {
        this.updateText();
    }

    // getTruncatedText(): string {
    //     if (this.text) {
    //         const lines = this.text.split('\n').map((line) => line.trim());
    //         return lines.slice(0, 3).join('<br>');
    //     }
    //     return '';
    // }

    toggleExpand() {
        this.expanded = !this.expanded;
        this.updateText();
    }

    private updateText() {
        if (this.expanded) {
            this.truncatedText = '';
            this.fullTextContent = this.text;
        } else {
            this.truncatedText = this.getTruncatedText(this.text, this.numberValue);
            this.fullTextContent = '';
        }
    }

    private getTruncatedText(text: string, numberValue: number): string {
        // Implement your logic here to truncate the text with three dots
        // For simplicity, let's assume we cut it at the first 100 characters
        if (text.length > numberValue) {
            const truncated = text.substring(0, numberValue);
            return truncated + '...';
        } else {
            return text;
        }
    }

    seeMoreText(): boolean {
      return  this.text.length > this.numberValue;

    }


}
