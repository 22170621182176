import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FImageCropperComponent} from '../f-image-cropper/f-image-cropper.component';
import { DialogService } from '../../services/dialog.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-f-image-preview-field',
    templateUrl: './f-image-preview-field.component.html',
    styleUrls: ['./f-image-preview-field.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FImagePreviewFieldComponent implements OnInit {
    private cropperDialogRef: MatDialogRef<FImageCropperComponent>;
    imagePreview: any;

    @Input() maintainAspectRatio = false;
    @Input() initialData: any;
    @Input() ratioImage: any =  16 / 9;
    @Input() maxSize: number;

    @Output() fileChange: EventEmitter<File>;

    constructor(private _matDialog: MatDialog, private dialogService: DialogService) {
        this.fileChange = new EventEmitter();
        this.maxSize = this.maxSize || 0;
    }

    ngOnInit(): void {
    }

    onFileChange(files: FileList, event): void {
        this.cropperDialogRef = this._matDialog.open(FImageCropperComponent, {
            panelClass: 'contact-form-dialog',
            data: {
                fileEvent: event,
                ratio: this.ratioImage,
                roundCropper: false,
                maintainAspectRatio: this.maintainAspectRatio,
            },
            restoreFocus: false,
            autoFocus: false
        });
        this.cropperDialogRef.afterClosed().subscribe(cropperRes => {
            if (cropperRes) {
                if (cropperRes.file.size > (this.maxSize * 1024 * 1024) && this.maxSize > 0) {
                    if (this.maxSize < 1) {
                        this.dialogService.showErrorDialog(`No se pueden subir recursos de más de ${this.maxSize * 1000} KB`, 'Tamaño excedido');
                    } else {
                        this.dialogService.showErrorDialog(`No se pueden subir recursos de más de ${this.maxSize} MB`, 'Tamaño excedido');
                    }                    
                } else {
                    this.fileChange.emit(cropperRes.file);
                    this.imagePreview = cropperRes.base64;
                }
                // this.fileChange.emit(cropperRes.file);
                // this.imagePreview = cropperRes.base64;
            }
        });
    }

    removeImage(): void {
        this.imagePreview = null;
        this.fileChange.emit(null);
    }

}
