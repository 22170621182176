import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {sha256} from 'js-sha256';
import {UserAccessRemoteService} from '../../../course/services/remote-services/user-access.remote.service';
import {AppConfigService} from '../../services/app-config.service';
import {RestClientService} from '../../services/rest-client.service';
import {SecurityService} from '../../services/security.service';
import {CodeVerifierGeneratorService} from './code-verifier-generator.service';
import {CareerMenuService} from '../../services/career-menu.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private readonly authServerUrl: string;
    private readonly redirectUri: string;
    private readonly clientId = 'angularApp';
    private codeVerifier: string;
    private codeChallenge: string;
    private _logoutHandlers: (() => Promise<void>)[] = [];

    constructor(private _http: HttpClient, private restClientService: RestClientService, private careerMenuService: CareerMenuService,
                private securityService: SecurityService, private codeVerifierGeneratorService: CodeVerifierGeneratorService,
                private userAccessRemoteService: UserAccessRemoteService, appConfigService: AppConfigService) {

        this.authServerUrl = appConfigService.getAppConfig().authUrl + '/';
        this.redirectUri = appConfigService.getAppConfig().redirectUri;
        // this.setTestCodeVerifierAndChallenge();

        const user = SecurityService.getUser();

        // setTimeout(() => {
        //     if (user && localStorage.tokenExpireAt) {
        //         let diff = Math.max(localStorage.tokenExpireAt - new Date().getTime(), 0);
        //         diff = Math.min(diff, 0x7FFFFFFF); // setTimeout limit is MAX_INT32=(2^31-1)
        //         localStorage.tokenRefreshAt = diff;
        //         this.startRefreshToken();
        //     }
        // }, 100);

        if (user) {
            // console.log('Datos user-acces', user);
            // this.userAccessRemoteService.updateLastAccess().subscribe();
        }

    }

    async retrieveTokenProcess(code=null): Promise<boolean> {

        try {
            console.log('Entro a verificar external');
            const externalUidUser: any = await this._http.get('api/users/externalUid').toPromise();
        } catch (error) {
            console.error('Usuario no puede ser procesado, error de autenticación.', error);
            // Puedes manejar el error de manera específica según tus necesidades
            return false;
        }

        try {
            const res: any = await this._http.get('api/users/me').toPromise();
            if (res != null) {
                console.log('Usuario', res);
                if (res != null) {
                    const userAccess = this.userAccessRemoteService.updateLastAccess().subscribe();
                }

                console.log('Estes es el api user me que ejecura', res);
                this.securityService.setUser(res);
                await this.careerMenuService.getCareers();
                await this.careerMenuService.getPreEnrollments();
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error("Error al cargar la sesión:", error);
            // Puedes manejar el error de manera específica según tus necesidades
            return false;
        }
    }



    // async loadSession() {
    //     return  await this._http.get('api/users/me');
    // }

    async loadSession(): Promise<boolean> {
        try {
            const res: any = await this._http.get('api/users/me').toPromise();
            console.log('Valor de loadSession res', res);
            if (res != null) {
                return true;
            } else {

                return false;
            }
        } catch (error) {
            console.error("Error al cargar la sesión:", error);
            // Puedes manejar el error de manera específica según tus necesidades
            return false;
        }
    }

    // retrieveToken(code): Promise<void> {
    //     return new Promise<void>((resolve, reject) => {
    //         const params = new URLSearchParams();
    //         params.append('grant_type', 'authorization_code');
    //         params.append('client_id', this.clientId);
    //         params.append('redirect_uri', this.redirectUri);
    //         params.append('code', code);
    //         params.append('code_verifier', this.securityService.codeVerifier);
    //
    //         const headers = new HttpHeaders({
    //             'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
    //             // 'Authorization': 'Basic ' + btoa(this.clientId + ':secret')
    //         });
    //
    //         this._http.post(`${this.authServerUrl}oauth/token`, params.toString(), {headers: headers})
    //             .subscribe(data => {
    //                     this.securityService.setSecurityToken(data);
    //                     this.startRefreshToken();
    //                     resolve();
    //                 },
    //                 err => {
    //                     throw new Error('Invalid Credentials');
    //                 }
    //             );
    //     });
    // }

    // private startRefreshToken(): void {
    //     setTimeout(() => {
    //         this.refreshToken();
    //     }, localStorage.tokenRefreshAt);
    // }

    // refreshToken(): Promise<void> {
    //     // console.log('refrescando token. Tiempo(ms): ' + localStorage.tokenRefreshAt);
    //     return new Promise<void>((resolve, reject) => {
    //         const params = new URLSearchParams();
    //         params.append('grant_type', 'refresh_token');
    //         params.append('client_id', this.clientId);
    //         params.append('refresh_token', localStorage.refreshToken);
    //
    //         const headers = new HttpHeaders({
    //             'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
    //             // 'Authorization': 'Basic ' + btoa(this.clientId + ':secret')
    //         });
    //         this._http.post(`${this.authServerUrl}oauth/token`, params.toString(), {headers: headers})
    //             .subscribe((data: any) => {
    //                 this.securityService.setSecurityToken(data);
    //                 this.startRefreshToken();
    //                 resolve();
    //             }, err => {
    //                 throw new Error('Invalid Credentials');
    //             });
    //     });
    // }

    // use only in AuthGuard
    async logoutAndInitAuthFlow(): Promise<void> {
        this.securityService.deleteFromStorage();
        window.location.href = this.getAuthorizeUrl();
    }

    async logoutCompletely(): Promise<void> {
        const securityToken = this.securityService.getSecurityToken();
        // let httpHeaders = new HttpHeaders();
        // httpHeaders = httpHeaders.set('Authorization', `Bearer ${securityToken}`);

        await this.logout();
        window.location.href = this.authServerUrl + 'token/revoke?v=' + encodeURIComponent(securityToken);

        // return this._http.post(this.authServerUrl + 'token/revoke', {}, {headers: httpHeaders}).pipe(
        //     tap(res => {
        //         this.logout();
        //         this.setupCodeVerifier();
        //     })
        // );
    }

    getAuthorizeUrl(): string {
        this.setupCodeVerifier();
        return this.authServerUrl + 'oauth/authorize?' + this.getAuthorizeQueryParamsString();
    }

    getRegisterUrl(): string {
        return this.authServerUrl + 'register';
    }

    private setupCodeVerifier(): void {
        const codeVerifier = this.codeVerifierGeneratorService.getCode();
        this.codeChallenge = this.getCodeChallenge(codeVerifier);
        this.securityService.codeVerifier = codeVerifier;
    }

    private getAuthorizeQueryParamsString(): string {
        const responseType = 'code';
        const scope = 'read';
        const codeChallengeMethod = 's256';
        return `response_type=${responseType}` +
            `&client_id=${this.clientId}` +
            `&redirect_uri=${this.redirectUri}` +
            `&scope=${scope}` +
            `&code_challenge=${this.codeChallenge}` +
            `&code_challenge_method=${codeChallengeMethod}`;
    }

    getCodeChallenge(value: string): any {
        return btoa(sha256(value));
    }

    private setTestCodeVerifierAndChallenge(): void {
        this.codeVerifier = '4cc9b165-1230-4607-873b-3a78afcf60c5';
        this.codeChallenge = 'YmRmMTkyODk4YjJhYmM4MWQyOGNlZWYxMWJmODExMTYyMWZjY2ZhMGNjMGJjZTZlMjAwMGZlMzdmODc0MjcwZQ==';
    }

    registerLogoutHandler(handler: () => Promise<void>): void {
        this._logoutHandlers.push(handler);
    }

    private async logout(): Promise<void> {
        try {
            this.securityService.deleteFromStorage();
            await this.executeLogoutHandlers();
        } catch (e) {
            console.warn('Ocurrió un error ejecutando el proceso de logout local');
        }
    }

    private async executeLogoutHandlers(): Promise<void> {
        return Promise.all(
            this._logoutHandlers
                .map(h => h().catch(e => { console.warn(e); }))
        )
        .then(() => {})
        .catch((e) => {
            console.log(e);
        });
    }

}
