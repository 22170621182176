import {ContentBlock} from './content-block';
import {CompoundResource} from '../../../course/models/compound-resource';
import {Observable} from 'rxjs';

export class ContentBlockResource extends ContentBlock {
    static TYPE = 'resource';

    resources?: CompoundResource[];
    fileUrl?: string;
    downloadHandler: any;

    constructor(block) {
        super(block);
        this.resources = block.resources;
        this.fileUrl = block.fileUrl;
    }

    updateData(data): void {
        super.updateData(data);
        this.resources = data.resources;
    }

    getTitle(): string {
        return 'course.resourceBlock';
    }

    getTypeAsString(): string {
        return ContentBlockResource.TYPE;
    }

}
