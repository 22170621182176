import {CoursePermissions} from '../course/config/course-permissions';

export const humanResoursesMenu = {
    id: 'rrhh',
    name: 'humanResources',
    fieldI18Key: 'NAV.RRHH',
    menu: [
        {
            name: 'General',
            fieldI18Key: 'NAV.TEACHERSMANAGEMENT',
            url: '/human-resources/teachers',
            roles: [CoursePermissions.MANAGE_SIGN_TEACHER_PROFILE]
        },
        {
            name: 'General',
            fieldI18Key: 'NAV.COORDINATORMANAGER',
            url: '/human-resources/coordinator',
            roles: [CoursePermissions.COURSE_CREATE_COORDINATOR]
        }
    ]
};

export const humanResoursesManagerMenu = {
    projectName: '',
    projectLogo: 'assets/img/neuuni_logotipo_blanco.png',
    modules: [
        humanResoursesMenu
    ]
};



