import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import {Observable, Subject} from 'rxjs';
import {SecurityService} from '../../core/services/security.service';
import {AppConfigService} from '../../core/services/app-config.service';
import {takeUntil} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {APP_CONFIG} from "../../../app.config";

@Injectable({
    providedIn: 'root'
})
export class WebSocketService {
    private readonly RECONNECTION_TIMEOUT = 1 * 30 * 1000;
    // private readonly RECONNECTION_TIMEOUT = 1000;
    private INCREMENT_RECONNECTION_TIMEOUT = 0;

    private subscriptions: {address: string, subject: Subject<any>, subscription: {id: any, unsubscribe: () => void}}[] = [];
    private _unsubscribeAll: Subject<any>;
    webSocketEndPoint = '/chat';
    // topic: string = '/topic/greetings';
    stompClient: any;

    constructor(private securityService: SecurityService, private appConfigService: AppConfigService) {
        const config = APP_CONFIG[APP_CONFIG.active_profile];
        this.webSocketEndPoint = config.chatUrl + this.webSocketEndPoint;
        this._unsubscribeAll = new Subject();        
    }

    private token = null;

    connect(): Promise<void> {        
        return new Promise((resolve, reject) => {
            SecurityService.token$.pipe(
                takeUntil(this._unsubscribeAll)
            ).subscribe(token => {
                if (token) {
                    if (this.token !== token) {
                        this.token = token;
                        this.makeConnect(resolve, token);
                    }                    
                }
            });
        });
    }

    private makeConnect(resolve: any, token: any): void {
        const webSocketEndPoint = this.webSocketEndPoint + '?access_token=' + encodeURIComponent(token);
        const ws = new SockJS(webSocketEndPoint);
        this.stompClient = Stomp.over(ws);
        const _this = this;
        _this.stompClient.connect({}, frame => {
            resolve();
            // _this.stompClient.reconnect_delay = 2000;
        }, error => {
            this.errorCallBack(error, resolve);
        });
    }

    disconnect(): void {
        this.subscriptions.forEach(sub => {
            sub.subscription.unsubscribe();
            sub.subject.complete();
        });
        if (this.stompClient) {
            this.stompClient.disconnect();
        }
        this.subscriptions = [];

        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    subscribe(address: string): Observable<any> {
        const subject = new Subject();
        const subscription = this.stompClient.subscribe(address, event => {
            subject.next(JSON.parse(event.body));
        });
        this.subscriptions.push({address: address, subject: subject, subscription: subscription});
        return subject.asObservable();
    }

    unsubscribe(address: string): void {
        const pos = this.subscriptions.findIndex(s => s.address === address);
        if (pos >= 0) {
            this.subscriptions[pos].subscription.unsubscribe();
            this.subscriptions.splice(pos, 1);
        }
    }

    send(address: string, data?: any): void {
        // this.stompClient.send(address, {}, JSON.stringify(message));
        this.stompClient.send(address, {}, data ? JSON.stringify(data) : '');
    }

    errorCallBack(error, resolve): void {
        console.log('errorCallBack -> ' + error);        
        setTimeout(() => {
            // this.connect();
            this.makeConnect(resolve, this.token);
            this.INCREMENT_RECONNECTION_TIMEOUT += (this.INCREMENT_RECONNECTION_TIMEOUT < 4) ? 1 : 0;
        }, (this.RECONNECTION_TIMEOUT * this.INCREMENT_RECONNECTION_TIMEOUT));
    }

}
