
import {MigrarPermissions} from "../migrar/config/migrar-permissions";


export const migrarMenu = {
    id: 'migrar',
    name: 'migrar',
    fieldI18Key: 'NAV.migrar',
    menu: [
        {
            name: 'migrarUser',
            fieldI18Key: 'NAV.migrarUsuario',
            url: '/migrar',
            roles: [MigrarPermissions.MIGRAR_USER_COGNITO]
        }
    ]
};

export const migrarManagerMenu = {
    projectName: '',
    projectLogo: 'assets/img/neuuni_logotipo_blanco.png',
    modules: [
        migrarMenu
    ]
};
