export class ApiUrl {
    private static prefixPath: string[] = [
        '/api/welcome/',
        '/api/course-content/all/',
    ];

    // /^\/api\/courses\/.+\/teachers$/i,
    private static regexPath: RegExp[] = [

    ];

    public static isWhiteListed(url: string): boolean {
        return ApiUrl.prefixPath.some(u => url.startsWith(u))
            || ApiUrl.regexPath.some(p => p.test(url));
    }
}
