import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

export interface FileHandle {
    file: File;
    url: SafeUrl;
}

@Directive({
  selector: '[appDragDropFile]'
})
export class DragDropFileDirective {

    @Output() files: EventEmitter<FileHandle[]> = new EventEmitter();

    @HostBinding('style.background') private background = '#ffffff';

    constructor(private sanitizer: DomSanitizer) { }

    @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#039be5';
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#ffffff';
    }

    @HostListener('drop', ['$event']) public onDrop(evt: DragEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#ffffff';

        const files: FileHandle[] = [];
        for (let i = 0; i < evt.dataTransfer.files.length; i++) {
            const file = evt.dataTransfer.files[i];
            const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
            files.push({ file, url });
        }
        if (files.length > 0) {
            this.files.emit(files);
        }
    }

}
