import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MaterialModule} from './material.module';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {QxFilterComponent} from './components/qx-filter/qx-filter.component';
import {DialogFilterDate} from './components/qx-filter/dialog-filter-date/dialog-filter-date.component';
import {DialogFilterListService} from './components/qx-filter/dialog-filter-list/dialog-filter-list.service';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {DialogFilterInput} from './components/qx-filter/dialog-filter-input/dialog-filter-input.component';
import {DialogFilterListComponent} from '@quiox/global/components/qx-filter/dialog-filter-list/dialog-filter-list.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {QxSelectListComponent} from "@quiox/global/components/qx-dual-list/qx-select-list/qx-select-list.component";
import {QxDualListComponent} from "@quiox/global/components/qx-dual-list/qx-dual-list.component";

export const defaultDateFormat = 'DD/MM/YYYY';

export const MY_FORMATS = {
    parse: {
        dateInput: defaultDateFormat,
    },
    display: {
        dateInput: defaultDateFormat,
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: defaultDateFormat,
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export class PaginatorI18n extends MatPaginatorIntl {
    itemsPerPageLabel = 'Elementos por Página';
    firstPageLabel = 'Primera Página';
    lastPageLabel = 'Última Página';
    nextPageLabel = 'Próxima Página';
    previousPageLabel = 'Página Anterior';
    getRangeLabel = (page: number, pageSize: number, length: number): string => {
        return `Página ${page + 1} de ${length} Elementos encontrados`;
    }
}

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        TranslateModule,
    ],
    declarations: [
        QxFilterComponent,
        DialogFilterListComponent,
        DialogFilterInput,
        DialogFilterDate,
        QxDualListComponent,
        QxSelectListComponent
    ],
    exports: [
        MaterialModule,
        QxFilterComponent,
        TranslateModule,
        QxDualListComponent,
        QxSelectListComponent
    ],
    entryComponents: [
        DialogFilterListComponent,
        DialogFilterInput,
        DialogFilterDate,

    ],
    providers: [
        DialogFilterListService,
        {provide: MAT_DATE_LOCALE, useValue: 'es'},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
        // {provide: MatPaginatorIntl, useValue: new PaginatorI18n()}
    ]
})
export class GlobalModule {
}
