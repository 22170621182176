import {Course} from './course';
import {CourseRole} from './course-role';
import {UnitStructure} from './unit-structure';

export class CourseInfo {
    course?: Course;
    courseRole?: CourseRole;
    unitsInfo: UnitStructure[];
    teachers?: any[];
    dean: any;
    courseAnnouncementId: string;
    examTitleShow?: boolean;

    constructor() {
    }

    isStudent(): boolean {
        return this.courseRole && this.courseRole === CourseRole.STUDENT;
    }

    isTeacher(): boolean {
        return this.courseRole && this.courseRole === CourseRole.TEACHER;
    }

    isCoordinator(): boolean {
        return this.courseRole && this.courseRole === CourseRole.COORDINATOR;
    }

}
