import {Injectable} from '@angular/core';
import {RestClientService} from './rest-client.service';
import {MyCareerService} from './my-career.service';
import {SecurityService} from './security.service';

@Injectable({
    providedIn: 'root'
})
export class CareerMenuService {
    loaded = false;

    constructor(private restClientService: RestClientService, private myCareerService: MyCareerService) {
    }

    async getCareers(force = false): Promise<boolean> {
        if (force) {
            this.loaded = false;
        }
        const user = SecurityService.getUser();
        if (!this.loaded && user && user.roles.includes(SecurityService.ROLE_STUDENT)) {
            const myCareers: any = await this.restClientService.get('api/career/my-careers').toPromise();
            console.log('Carreras del estudiante: ', myCareers);
            this.myCareerService.setCareer(myCareers);
            this.loaded = true;
            return true;
        }
        return false;
    }

    async getPreEnrollments(): Promise<void> {
        const user = SecurityService.getUser();
        if (user.roles.includes(SecurityService.ROLE_STUDENT)) {
            const myPreEnrollments: any = await this.restClientService.get('api/pre-enrollment/owned').toPromise();
            this.myCareerService.setPreEnrollments(myPreEnrollments.elements);
        }
    }
}
