<ng-container *ngIf="images">


    <ng-container *ngIf="images.length > 0">
        <div class="contain-image" (click)="open()" style="cursor: pointer;">
            <img class="image-item" [src]="images[0].url"/>
            <div class="options-contain" *ngIf="images.length > 1">

                <!--<a mat-fab class="btn-circle m-16" style="margin-left: 0px !important;">+{{images.length - 1}}</a>
                <a mat-fab class="btn-circle m-16">
                    <mat-icon aria-hidden="false" aria-label="Example home icon">
                        photo_library
                    </mat-icon>
                </a>-->
                <div class="div-ngx-gallery-options">
                    <a>+{{images.length - 1}}</a>
                </div>
                <div class="div-ngx-gallery-options">
                    <mat-icon aria-hidden="false" aria-label="Example home icon">
                        photo_library
                    </mat-icon>
                </div>

                <!--<ng-template #onePicture>
                    <a mat-fab class="btn-circle m-16 btn-blue-circle" (click)="openDialog()">
                        <mat-icon aria-hidden="false" aria-label="Example home icon">
                            1
                        </mat-icon>
                    </a>
                </ng-template>-->
            </div>
        </div>

        <ngx-gallery
            (previewOpen)="previewOpen()"
            (previewClose)="previewClose()"
            [options]="galleryOptions"
            [images]="galleryImages">
        </ngx-gallery>


    </ng-container>


</ng-container>