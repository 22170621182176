<div class="mt-16" fxLayout="column">
    <app-content-block-edit *ngFor="let block of blocks; let index = index"
                            [model]="block.model"
                            [form]="block.form"
                            [someBlockActivedFlag]="someBlockActivedFlag"
                            (deleteAction)="onDelete($event)"
                            (moveUpAction)="onMoveUpOrder($event)"
                            (moveDownAction)="onMoveDownOrder($event)"
                            (someBlockActived)="someBlockActived($event)"
                            (saveAction)="onSave($event)">
    </app-content-block-edit>
</div>

<div *ngIf="blocks" fxLayoutAlign="space-between">

    <div *ngIf="blockVideoAppOnlyOne == false">
    <button mat-raised-button [matMenuTriggerFor]="menu" >{{ 'course.addBlock' | translate }}</button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item *ngIf="ableText" (click)="addTextBlockAction()">{{ 'course.text' | translate }}</button>
        <button mat-menu-item *ngIf="ableImage" (click)="addImageBlockAction()">{{ 'course.image' | translate }}</button>
        <button mat-menu-item *ngIf="ableVideo" (click)="addVideoBlockAction()">{{ 'course.video' | translate }}</button>
        <button mat-menu-item *ngIf="ableAppVideo" (click)="addAppVideoBlockAction()">App Video</button>
        <button mat-menu-item *ngIf="ableAppAudio" (click)="addAppAudioBlockAction()">App Audio</button>
        <button mat-menu-item *ngIf="ableResource" (click)="addResourceBlockAction()">Recursos</button>
        <button mat-menu-item *ngIf="ableLabel" (click)="addLabelBlockAction()">{{ 'course.label' | translate }}</button>
        <button mat-menu-item *ngIf="ableH5P" (click)="addH5PBlockAction()">{{ 'course.h5p' | translate }}</button>
    </mat-menu>
    </div>

    <div *ngIf="blockVideoAppOnlyOne == true">
    <button  id="button-video-aws" mat-raised-button [matMenuTriggerFor]="menu1" *ngIf="blockVideoAppOnlyOne == true">{{ 'course.addBlock' | translate }}</button>
    <mat-menu #menu1="matMenu">
        <button mat-menu-item *ngIf="ableAppVideo" (click)="addAppVideoBlockAction()">App Video</button>
        <button mat-menu-item *ngIf="ableH5P" (click)="addH5PBlockAction()">{{ 'course.h5p' | translate }}</button>
    </mat-menu>
    </div>


    <button mat-raised-button (click)="closeAction.emit()">
        <span>Finalizar</span>
    </button>

</div>