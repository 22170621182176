import {AfterViewInit, Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
// import {EmbedVideoService} from 'ngx-embed-video/dist';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-video-app-player-dialog',
    templateUrl: './f-video-player-dialog.component.html',
    styleUrls: ['./f-video-player-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FVideoPlayerDialogComponent implements OnInit, AfterViewInit {
    vimeoUrl = 'https://vimeo.com/197933516';
    youtubeUrl = 'https://www.youtube.com/watch?v=iHhcHTlGtRs';
    dailymotionUrl = 'https://www.dailymotion.com/video/x20qnej_red-bull-presents-wild-ride-bmx-mtb-dirt_sport';

    vimeoId = '197933516';
    youtubeId = 'iHhcHTlGtRs';
    dailymotionId = 'x20qnej';

    iframeHtml: any;
    headerText: string;

    // @ViewChild('divContainer', {static: true})
    // private divContainer: ElementRef;

    constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) private _data: any,
                /*private embedService: EmbedVideoService*/) {
        this.headerText = _data.headerText;
        // this.iframeHtml = this.embedService.embed(_data.url, {
        //     attr: { width: '100%', height: 393},
        //     query: {rel: 0, autoplay: 1}
        // });
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        // console.dir(this.divContainer.nativeElement.offsetHeight);
    }

}
