<div class="dialog-content-wrapper">

    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">

            <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                <span class="title dialog-title">{{ headerText }}</span>
            </div>

            <button mat-icon-button (click)="dialogRef.close()" aria-label="Close">
                <mat-icon>close</mat-icon>
            </button>

        </div>
    </mat-toolbar>

    <div #divContainer [innerHtml]="iframeHtml"></div>
<!--    <div mat-dialog-content class="p-0 m-0" fusePerfectScrollbar>-->
<!--        -->
<!--    </div>-->

<!--    <div mat-dialog-actions class="m-0 px-8" fxLayout="row" fxLayoutAlign="end center">-->
<!--        <button mat-button class="save-button" (click)="dialogRef.close()" aria-label="CERRAR">-->
<!--            {{ 'core.close' | translate }}-->
<!--        </button>-->
<!--    </div>-->
</div>
