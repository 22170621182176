<div fxLayout="column" fxLayoutAlign="start">

    <div class="h2">Categorias</div>
    <div fxLayout="row" fxLayoutAlign="start" class="mt-12" fxFlex="100">

        <div fxLayout="column" fxFlex="50">
            <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
                <mat-form-field appearance="outline" floatLabel="always" fxFlex="100" class="pr-4 ">
                    <mat-label>{{search}}</mat-label>
                    <input matInput placeholder="{{search}}" [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="searchTerm" (input)="filterCategoryByTerm()">
                </mat-form-field>
            </div> -->

            <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="100" class="mb-12">
                <mat-chip-list>
                    <mat-chip class="cursor-pointer" *ngFor="let category of categoriesFilter let i = index"
                              id="{{category.id}}" (click)="addCategory(category.id)">
                        {{ category.name }}
                        <mat-icon style="margin-left: 5px !important;">check_circle</mat-icon>
                    </mat-chip>
                </mat-chip-list>
                <!--<div fxLayout="column" fxFlex="25" *ngFor="let category of categoriesFilter let i = index">
                                <span id="{{category.id}}" (click)="addCategory(category.id)"
                                      class="toCategory">{{category.name }}</span>
                    </div>-->
            </div>

            <span class="span mt-16">Categorías seleccionada</span>
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="100" class="mb-16 mt-16">
                <mat-chip-list>
                    <mat-chip class="cursor-pointer" *ngFor="let cat of categoriesSelect let i = index"
                              id="{{cat.id}}" color="accent" selected (click)="removeCategory(cat.id)">
                        {{ cat.name }}
                        <mat-icon style="margin-left: 5px !important;">cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
                <!--<div fxLayout="column" fxFlex="25" *ngFor="let cat of categoriesSelect let i = index">
                    <span id="{{cat.id}}" (click)="removeCategory(cat.id)" class="toCategory">{{ cat.name }}</span>
                </div>-->
            </div>
        </div>

        <div fxLayout="column" fxFlex="50" class="ml-16">
            <!-- <div fxLayout="row " fxLayoutAlign="start" fxFlex="100">
                <mat-form-field appearance="outline" floatLabel="always" fxFlex="100" class="pr-4 ">
                    <mat-label>{{searchSubcategory}}</mat-label>
                    <input matInput placeholder="{{searchSubcategory}}" [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="searchTermSubCategory" (input)="filterSubCategoryByTerm()">
                </mat-form-field>
            </div> -->

            <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="100" class="mb-12">
                <mat-chip-list>
                    <mat-chip class="cursor-pointer" *ngFor="let subcategory of subCategoriesFilter let i = index"
                              id="{{subcategory.id}}" (click)="addSubCategory(subcategory.id)">
                        {{ subcategory.name }}
                        <mat-icon style="margin-left: 5px !important;">check_circle</mat-icon>
                    </mat-chip>
                </mat-chip-list>
                <!--<div fxLayout="column" fxFlex="25" *ngFor="let subcategory of subCategoriesFilter let i = index">
                            <span id="{{subcategory.id}}" (click)="addSubCategory(subcategory.id)"
                                  class="toCategory">{{subcategory.name }}</span>
                </div>-->
            </div>

            <span class="span mt-16">SubCategorías seleccionada</span>
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="100" class="mb-16 mt-16">
                <mat-chip-list>
                    <mat-chip class="cursor-pointer" *ngFor="let subCat of subCategoriesSelect let i = index"
                              id="{{subCat.id}}" color="accent" selected (click)="removeSubCategory(subCat.id)">
                        {{ subCat.name }}
                        <mat-icon style="margin-left: 5px !important;">cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
                <!--<div fxLayout="column" fxFlex="25" *ngFor="let subCat of subCategoriesSelect let i = index">
                    <span id="{{subCat.id}}" (click)="removeSubCategory(subCat.id)" class="toCategory">{{subCat.name }}</span>
                </div>-->
            </div>
        </div>

    </div>

</div>