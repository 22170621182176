import {ModuleConfig} from '@quiox/global/config/config';
import {CoursePermissions} from './course-permissions';

export const courseSupervisorMenu: ModuleConfig = {
    id: 'courses',
    name: 'Course',
    fieldI18Key: 'NAV.COURSE',
    menu: [
        {
            name: 'Courses',
            fieldI18Key: 'NAV.COURSES',
            url: '/courses/course-supervisor',
            exactMatch: true,
            roles: [CoursePermissions.COURSE_VIEW_SUPERVISOR],
        }
    ]

};
export const supervisoMenu = {
    projectName: '',
    projectLogo: 'assets/img/neuuni_logotipo_blanco.png',
    modules: [
        courseSupervisorMenu
    ]
};
