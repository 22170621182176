
<div *ngIf="!ready" class="poster-img audio-block">

    <div class="button-container">
        <img [src]="poster" class="video-image"/>
        <button *ngIf="poster" mat-fab (click)="startPlayback()">
            <mat-icon>play_circle_filled_white</mat-icon>
        </button>
    </div>
</div>
<div class=" audio-container" *ngIf="ready" fxFlexAlign="end">
    <img [src]="poster"/>
    <audio [id]="elementId"
           class="video-js"
           controls preload="auto"
           data-setup='{}'>
    </audio>
</div>