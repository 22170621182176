import {Injectable} from '@angular/core';
import {AppConfigService} from '../services/app-config.service';
import {SecurityService} from '../services/security.service';
import {UnitCount} from './model/UnitCount';
import {BehaviorSubject} from 'rxjs';
import * as _ from 'lodash';
import {NotificationTypes} from './user-notification-handler-init.service';
import {ActivityCount} from './model/ActivityCount';
import {UserNotificationRemoteService} from './user-notification.remote.service';
import {filter, take} from 'rxjs/operators';
import {ResolveEnd, Router} from '@angular/router';
import {AppUtils} from '@quiox/global/helpers/app.utils';

@Injectable({
    providedIn: 'root',
})
export class UserNotificationCountService {
    private readonly TIMEOUT = 30 * 60 * 1000; // 30 minutos en milisegundos
    private _timeOutHandler: any;
    private _courseId: any;
    private _courseAnnouncementId: string;
    $courseCount: BehaviorSubject<{count: number, course: UnitCount[]}>;

    constructor(private userNotificationRemoteService: UserNotificationRemoteService, private appConfigService: AppConfigService,
                private router: Router) {
        this.$courseCount = new BehaviorSubject(undefined);

        this.router.events
            .pipe(
                filter(event => event instanceof ResolveEnd),
                take(1)
            )
            .subscribe((event: ResolveEnd) => {
            // /courses/course/d8c54ad9-91a0-41f8-8aab-8510a0a18a94/preview/student/0/detail
            if (!event.urlAfterRedirects.startsWith('/courses/course/')) {
                this.initialize();
            }
        });
    }

    initialize(): void {
        SecurityService.userInfo$().subscribe(user => {
            if (user) {
                this._timeOutHandler = this.start();
            }
        });
    }

    start(): void {
        if (this.appConfigService.getAppConfig().disableNotifications) {
            return;
        }

        const profile = SecurityService.getCurrentContextFromRole();
        const group5 = (profile === 'student' || profile === 'teacher') ? AppUtils.capitalizeString(profile) : null;

        const params = this._courseId
            ? {group1: this._courseId, groupSummary: true, group5: group5, group6: this._courseAnnouncementId}
            : {group5: group5};

        this.userNotificationRemoteService.count(params)
            .subscribe(result => {
                const units = NotificationData.transform(result, this._courseAnnouncementId);
                // this.checkChangeCourseByCourseAnnouncement();
                this.$courseCount.next({count: result.totalCount, course: units});
                this._timeOutHandler = setTimeout(() => this.start(), this.TIMEOUT);
            },
            e => console.log(JSON.stringify(e)));
    }

    private checkChangeCourseByCourseAnnouncement(): void {
        if (this.$courseCount.getValue()) {
            const courseAnnouncementId = (this._courseAnnouncementId) ? this._courseAnnouncementId : null;
            if (courseAnnouncementId) {
                const $courseAnnouncementId: string = (this.$courseCount.getValue().course.length > 0)
                    ? this.$courseCount.getValue().course[0].activities[0].courseAnnouncementId
                    : null;
                if (($courseAnnouncementId !== courseAnnouncementId)) {
                    this.resetCountNotification();
                }
            }

        }
    }

    private async resetCountNotification(): Promise<void> {
        await this.$courseCount.next({count: this.$courseCount.getValue().count, course: []});
    }

    getCourseCount(courseId, courseAnnouncementId): void {
        clearTimeout(this._timeOutHandler);
        this._courseId = courseId;
        this._courseAnnouncementId = courseAnnouncementId;
        this.start();
    }

    clearCourseId(): void {
        this._courseId = null;
        this._courseAnnouncementId = null;
    }


}

class NotificationData {

    static transform(countInfoInput, courseAnnouncementId): UnitCount[] {
        const result = [];
        const countInfo: any = _.groupBy(countInfoInput.groupCount, item => item.group2);

        Object.keys(countInfo).forEach(unitId => {
            let unitInfo = countInfo[unitId];
            unitInfo = _.groupBy(unitInfo, item => item.group3);

            let forumCount = 0;
            const activities = [];
            Object.keys(unitInfo).forEach(activityId => {
                const currentItem: any[] = unitInfo[activityId];
                let currActivity = null;
                for (const c of currentItem) {
                    if (activityId === 'undefined' && c.type === NotificationTypes.UNIT_FORUM_NEW_MESSAGE) {
                        forumCount += c.count;
                    } else {
                        if (!currActivity) {
                            currActivity = this.getActivity(c, new ActivityCount(activityId, c.group6));
                            activities.push(currActivity);
                        } else {
                            this.getActivity(c, currActivity);
                        }
                    }
                }
                if (currActivity) {
                    currActivity.updateTotalCount();
                }
            });

            const uc = new UnitCount(unitId, courseAnnouncementId);
            uc.forumCount = forumCount;
            uc.activities = activities;
            uc.updateTotalCount();
            result.push(uc);
        });
        return result;
    }

    private static getActivity(activityItem, activity: ActivityCount): ActivityCount {
        if (activityItem.type === NotificationTypes.UNIT_PROJECT_FORUM_NEW_MESSAGE) {
            activity.feedbackCount += activityItem.count;
        } else if (activityItem.type === NotificationTypes.UNIT_PROJECT_CREATE || activityItem.type === NotificationTypes.UNIT_PROJECT_EVALUATION) {
            activity.projectCount += activityItem.count;
        }
        return activity;
    }

}
