export class ActivityCount {
    id: string;
    projectCount = 0;
    feedbackCount = 0;
    totalCount = 0;
    courseAnnouncementId: string;

    constructor(id, courseAnnouncementId = null) {
        this.id = id;
        this.courseAnnouncementId = courseAnnouncementId;
    }

    updateTotalCount(): void {
        this.totalCount = this.projectCount + this.feedbackCount;
    }

}
