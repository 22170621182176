import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-video-app-player-dialog',
    templateUrl: './f-video-app-player-dialog.component.html',
    styleUrls: ['./f-video-app-player-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FVideoAppPlayerDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
    }

}
