import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {merge, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FNavigationService} from '../../../app/main/core/services/f-navigation.service';
import {MessageService} from '../../../app/main/chat/services/message.service';

@Component({
    selector       : 'fuse-navigation',
    templateUrl    : './navigation.component.html',
    styleUrls      : ['./navigation.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit, OnDestroy
{
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private messageService: MessageService,
        private navigationService: FNavigationService,
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {

             // Mark for check
             this._changeDetectorRef.markForCheck();
         });

        this.setUserMessages();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    private setUserMessages(): void {
        const generalMessagesKey = 'myGeneralMessages';
        // this.navigationService.menuReady$.pipe(
        //     takeUntil(this._unsubscribeAll)
        // ).subscribe(() => {
        //     const navigationItem = this._fuseNavigationService.getNavigationItem(generalMessagesKey);
        //     if (navigationItem) {
        //         this.messageService.init();
        //     }
        // });

        this.messageService.messageCount$.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe(messageCount => {
            const navItem = this._fuseNavigationService.getNavigationItem(generalMessagesKey);
            if (navItem) {
                navItem.badge = this.getBadgeProperties(messageCount);
                this._fuseNavigationService.updateNavigationItem(generalMessagesKey, {});
            }
        });
    }

    private getBadgeProperties(messageCount: number): any {
        return messageCount > 0 ?
            {
                title: messageCount,
                bg: 'rgb(82, 94, 138)',
                fg: 'rgb(255, 255, 255)'
            } : undefined;
    }

}
