<mat-toolbar color="primary">
    <mat-toolbar-row>
        <span>Seleccione un rango de fecha</span>
        <span class="expand"></span>
        <mat-icon class="dialog-icon" svgIcon="close" (click)="dialogRef.close()"></mat-icon>
    </mat-toolbar-row>
</mat-toolbar>

<div class="content-center">
    <div class="container-details col-xs-12 scrollable">
        <form [formGroup]="form" class="padding-top-10">
            <mat-form-field class="col-sm-6">
                <mat-label>Fecha de inicio</mat-label>
                <input matInput [matDatepicker]="minDatePicker"
                       formControlName="minDate">
                <mat-datepicker-toggle matSuffix [for]="minDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #minDatePicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="col-sm-6">
                <mat-label>Fecha de fin</mat-label>
                <input matInput [matDatepicker]="maxDatePicker" formControlName="maxDate">
                <mat-datepicker-toggle matSuffix [for]="maxDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #maxDatePicker></mat-datepicker>
            </mat-form-field>
        </form>
    </div>
</div>

<div class="bottom-context">

    <div class="pull-right">
        <button mat-raised-button color="primary" [disabled]="!form.valid" class="text-uppercase margin-right-10"
                (click)="acepted()">
            Aceptar
        </button>
        <button mat-raised-button color="warn" class="text-uppercase" (click)="dialogRef.close()">
            Cancelar
        </button>
    </div>
</div>

