<ngx-stars *ngIf="!defaultIcons" readonly="true"
           [size]=size
           [initialStars]="eval"
           [customStarIcons]="heartIcons"
           [color]=color>
</ngx-stars>

<ngx-stars *ngIf="defaultIcons" readonly="true"
           [size]=size
           [initialStars]="eval"
            color="#f44336">
</ngx-stars>