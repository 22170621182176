export class FileType {

    static readonly types = {
        other: {text: 'Otro', icon: 'insert_drive_file'},
        image: {text: 'Imagen', icon: 'image'},
        pdf: {text: 'Pdf', icon: 'picture_as_pdf'},
        archive: {text: 'Archivo', icon: 'archive'},
        video: {text: 'Video', icon: 'videocam'}
    };

}
