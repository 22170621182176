import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppConfigService} from '../../services/app-config.service';

@Injectable({
    providedIn: 'root'
  })
export class AccountService {
    private readonly authServerUrl;

    constructor(private _http: HttpClient, appConfigService: AppConfigService) {
        this.authServerUrl = appConfigService.getAppConfig().authUrl;
    }

    public activation(token): Observable<any> {
        return this._http.post(`${this.authServerUrl}/account/activation/${token}`, {});
    }

    public resetPassword(token, data): Observable<any> {
        return this._http.post(`${this.authServerUrl}/account/reset-password/${token}`, data);
    }

    resendActivationToken(token): Observable<any> {
        return this._http.put(`${this.authServerUrl}/account/resend-activation/${token}`, {});
    }

    resendMail(token): Observable<any> {
        return this._http.post(`${this.authServerUrl}/account/resend-mail`, {token});
    }

    resendMailVerification(userId: any): Observable<any> {
        return this._http.get(`${this.authServerUrl}/account/resend-mail/${userId}`);
    }

    resendMailResetPassword(mail: any): Observable<any> {
        return this._http.get(`${this.authServerUrl}/account/resend-password/${mail}`);
    }
}
