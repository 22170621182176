import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {configCustom} from '../../menuConfig/config';
import {AppUtils} from '@quiox/global/helpers/app.utils';
import {config, MenuItem, ModuleConfig} from '@quiox/global/config/config';
import {FuseNavigationService} from '../../../../@fuse/components/navigation/navigation.service';
import {TranslateService} from '@ngx-translate/core';
import {SecurityService} from './security.service';
import {menuStudent} from '../../menuConfig/menu-student';
import {menuTeacher} from '../../menuConfig/menu-teacher';
import {Subject} from 'rxjs';
import {contactsMangerMenu} from 'app/main/menuConfig/menu-contacts-manager';
import {supervisoMenu} from '../../course/config/course-supervisor.menu';
import {salesMenu} from '../../sales/config/sales.menu';
import {MyCareerService} from './my-career.service';
import {coffeeShopManagerMenu} from '../../menuConfig/menu-coffee-shop';
import {CoursePermissions} from '../../course/config/course-permissions';
import {menuSupervisorTeacher} from '../../menuConfig/menu-supervisor-teacher';
import {neuuniWatchManagerMenu} from '../../menuConfig/menu-neuuni-watch';
import {UserAccessRemoteService} from "../../course/services/remote-services/user-access.remote.service";
import {menuCoordinator} from '../../menuConfig/menu-coordinator';

@Injectable({
    providedIn: 'root'
})
export class FNavigationService {
    modules = configCustom.modules.concat(config.modules);
    activeTab = 0;
    menuReady$: Subject<any>;
    private menuConstructed = false;

    constructor(private router: Router, private _fuseNavigationService: FuseNavigationService, private myCareerService: MyCareerService,
                private _translateService: TranslateService, private securityService: SecurityService) {
        this.menuReady$ = new Subject();
        // setTimeout(() => {
        //     this.constructMenu();
        // });
    }

    public casAuth(): void {
        console.log("entro casAuth");
      //  this.router.navigateByUrl('unauthorized');
      //    AppUtils.logout();
    }

    constructMenu(): void {
        console.log('Entro al constructMenu');
          const user = SecurityService.getUser();
        console.log('Valor de user dentro de constructMenu', user);
        console.log('Valor de menuConstructed', this.menuConstructed);
        if (!this.menuConstructed && user) {
            this.registerModules(user);
            // descomentar aqui START
            if (SecurityService.getCurrentContextFromRole() === 'student') {
                this.myCareerService.buildCareerMenu('start');
            }
            // END
            this.registerNavigationMenu(this.modules);
            this.menuConstructed = true;
        }
    }

    reBuildMenu(): void {
        // descomentar aqui START
        const user = SecurityService.getUser();

        this.myCareerService.buildCareerMenu('now');
        this.registerModules(user);
        this.registerNavigationMenu(this.modules);
        this.securityService.updateUserHome(this.firstOption);
        // END
    }

    private registerNavigationMenu(modules: ModuleConfig[]): void {
        const menus = modules.map(module => this.getModule(module));
        this._fuseNavigationService.unregister('main');
        this._fuseNavigationService.register('main', menus);
        this._fuseNavigationService.setCurrentNavigation('main');
        // ngxTranslate Fix Start
        setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('es');
            this.menuReady$.next();
        });
    }

    // public setCourseModuleVisibility(hide): void {
    //     this._fuseNavigationService.updateNavigationItem('course', {
    //         hidden: hide
    //     });
    //     setTimeout(() => {
    //         this._translateService.setDefaultLang('en');
    //         this._translateService.setDefaultLang('es');
    //     });
    // }

    private getModule(module: ModuleConfig): any {
        return {
            id: module.id,
            title: module.name,
            translate: module.fieldI18Key,
            isEnrolled: module.isEnrolled,
            type: 'group',
            hidden: module.hidden,
            children: module.menu.map(m => this.getMenu(module, m))
        };
    }

    private getMenu(module: ModuleConfig, m: MenuItem): any {
        return {
            id: m.id ? m.id : `${module.id}-${m.name}`,
            title: m.name,
            translate: m.fieldI18Key,
            type: 'item',
            url: m.url,
            icon: m.icon,
            isEnrolled: m.isEnrolled,
            exactMatch: m.exactMatch,
            classes: m.classes,
        };
    }

    registerModules(user: any): void {
        const profile = user.profile;
        console.log('Profile del user: ', profile);
        let modules: any = config.modules.concat(configCustom.modules);
        modules = AppUtils.clone(modules);

        // console.log('Roles del usuario: ', user.roles);
        if (
            (user.roles.includes(SecurityService.ROLE_ADMIN)
                || user.roles.includes(SecurityService.ROLE_MASTER)
                || user.roles.includes(SecurityService.ROLE_MANAGER)
                || user.roles.includes(SecurityService.ROLE_SEO_MANAGER)
                || user.roles.includes(SecurityService.ROLE_HUMAN_RESOURCES)
                || user.roles.includes(SecurityService.ROLE_MARKETING)
                || user.roles.includes(SecurityService.ROLE_SCHOOL_CONTROL)
                || user.roles.includes(SecurityService.ROLE_MARKETING)
                || user.roles.includes(SecurityService.ROLE_FINANCE)
                || user.roles.includes(SecurityService.ROLE_SYSTEM)
                || user.roles.includes(SecurityService.ROLE_MEDIA_CREATOR)
                || (user.roles.includes(SecurityService.ROLE_MODERATOR)
                && !user.roles.includes(SecurityService.ROLE_TEACHER))
            )
            && !(user.roles.includes(SecurityService.ROLE_SUPERVISOR))
            && !(user.roles.includes(SecurityService.ROLE_COORDINATOR))
        ) {
            this.modules = this.getDefaultModules(modules); // ADMIN LAYOUT

        } else if (user.roles
            && ((user.roles.includes(SecurityService.ROLE_SUPERVISOR)))
            && !(user.roles.includes(SecurityService.ROLE_MANAGER))) {
            // this.modules = supervisoMenu.modules; // SUPERVISOR MANAGER LAYOUT
            this.modules = this.getDefaultModules(modules);
        } else if (user.roles && (user.roles.includes(SecurityService.ROLE_SALES_ASSISTANT) ||
            user.roles.includes(SecurityService.ROLE_SALES_MANAGER))) {
            this.modules = salesMenu.modules; // SALES LAYOUT
        }
        // else if (user.roles && (user.roles.includes(SecurityService.ROLE_MODERATOR))) {
        //     this.modules = coffeeShopManagerMenu.modules;
        // }

        else if (user.roles && (user.roles.includes(SecurityService.ROLE_CONTACTS_MANAGER))) {
            this.modules = contactsMangerMenu.modules; // CONTACTS MANAGER LAYOUT
        // }
        // else if (user.roles && (user.roles.includes(SecurityService.ROLE_MEDIA_CREATOR))) {
        //     console.log('Entro al menu de media creador');
        //     this.modules = mediaCreatorMenu.modules; // MEDIA CREATOR LAYOUT

        }else if (profile === 'teacher') {
            this.modules = menuTeacher.modules; // TEACHER LAYOUT
        }else if (profile === 'coordinator') {
            this.modules = menuCoordinator.modules; // COORDINATOR LAYOUT
        } else if (profile === 'student') {
            this.modules = menuStudent.modules; // STUDENT LAYOUT
        } else if (user.roles && (user.roles.includes(SecurityService.ROLE_TEACHER))) {
            this.modules = menuTeacher.modules; // TEACHER LAYOUT
            this.modules.push(coffeeShopManagerMenu.modules[0]);
            if (user.userUniversity ) {
                // this.modules.push(coffeeShopManagerMenu.modules[0]);
                this.modules.push(neuuniWatchManagerMenu.modules[0]);
            }
        } else if (user.roles && (user.roles.includes(SecurityService.ROLE_SUPERVISOR_TEACHER))) {
            this.modules = menuSupervisorTeacher.modules; // SUPERVISOR TEACHER LAYOUT
        } else if (user.roles && (user.roles.includes(SecurityService.ROLE_COORDINATOR))) {
            this.modules = menuCoordinator.modules; // Coordinador
        }else if (user.roles && (user.roles.includes(SecurityService.ROLE_STUDENT) || user.roles.includes(SecurityService.ROLE_GUEST))) {
            this.modules = menuStudent.modules; // STUDENT LAYOUT
            this.modules.push(coffeeShopManagerMenu.modules[0]);
            if (user.userUniversity ) {
                this.modules.push(coffeeShopManagerMenu.modules[0]);
                this.modules.push(neuuniWatchManagerMenu.modules[0]);

            }

        }
       // else if (user.userUniversity) {
       //      // this.modules.length = this.modules.length + 1;
       //      this.modules = coffeeShopManagerMenu.modules;
       //  }
        console.log('Modulo correspondiente: ', this.modules);
    }

    determineUserHome(): string {
        console.log('Entro al determineUserHome');
        this.constructMenu();
        this.securityService.updateUserHome(this.firstOption);

        const route = localStorage.preEnrolmentApplication;
        if (route) {
            localStorage.removeItem('preEnrolmentApplication');
            return route// route.split('webapp')[1];
        }
        const user = SecurityService.getUser();

        console.log('Datos del usuario en f-navigation', user);
        // if (user != null){
        const isStudent = user.roles.includes(SecurityService.ROLE_STUDENT);
        if (isStudent && localStorage.course) {
            return '/payment/cart';
        }

        if (!user.lastAccess) {
            return '/courses/profile/' + user.id + '/wizard';
        }
        // }
        return this.firstOption;
    }

    verifyAnyPerm(roles: []): boolean {
        let verify = false;
        roles.forEach(rol => {
            if (this.verifyPerm(rol)) {
                verify = true;
            }
        });
        return verify;
    }

    verifyPerm(rol): boolean {
        return SecurityService.getUser().authorities.filter(a => a === rol).length > 0;
    }

    get firstOption(): any {
        let item: any;
        console.log('Valor del activeModule', this.activeModule.menu);
        if (this.activeModule.menu) {
            item = this.activeModule.menu[0];
        } else {
            item = this.activeModule.children[0];
        }
        console.log('Prinera opcion del menu firstOption valor del item', item);
        if (item?.childrens) {
            item = item?.childrens[0];
        }
        return item.url;

    }

    get activeModule(): any {
        return this.modules[this.activeTab];
    }

    private getDefaultModules(modules: any): any {
        return modules.filter((module: any) => {
            module.menu = module.menu.filter((item: any) => {
                if (item.childrens) {
                    item.childrens = item.childrens.filter((children: any) => {
                        return this.verifyAnyPerm(children.roles as []);
                    });
                    return item.childrens.length > 0;
                }

                if (item.roles) {
                    return this.verifyAnyPerm(item.roles as []);
                }
            });

            return module.menu.length > 0;
        });
    }

}
