import {
    AfterViewInit,
    Component,
    forwardRef,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    ViewEncapsulation
} from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/es.js';
import {ControlContainer, FormControl, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import {BaseControlValueAccessor} from '../base/base-control-value-accessor';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-f-text-editor-field',
    templateUrl: './f-text-editor-field.component.html',
    styleUrls: ['./f-text-editor-field.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FTextEditorFieldComponent),
        multi: true,
    }]
})
export class FTextEditorFieldComponent extends BaseControlValueAccessor<string> implements OnInit, OnDestroy, AfterViewInit {
    private unsubscribeAll: Subject<any>;
    control: FormControl;
    editorConfig: any;
    classicEditor = ClassicEditor;
    invalidValue = false;
    touched = false;
    @Input() label: string;
    @Input() minLength: number;
    @Input() maxLength: number;
    @Input() required: boolean;
    @Input() place: string;
    @Input() isDisabled: boolean = false;

    // @ViewChild('editorCk', {static: true})
    // ckEditor: any;

    constructor(@Optional() private controlContainer: ControlContainer, private injector: Injector) {
        super();
        this.unsubscribeAll = new Subject();
        // this.control = new FormControl();
        // this.control.statusChanges.subscribe(v => this.invalidValue = v);
        // console.log('cxcx' , this.place);

        this._value = '';

    }

    ngOnInit(): void {
        this.editorConfig = {
            // placeholder: 'escriba aquí ...', // Error in production mode if included. Reported Bug
            toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'indent', 'outdent', '|', 'undo', 'redo', '|', 'Link'],
            language: 'es',
            // placeholder: this.place ? this.place : 'Escribe aqui',
            link: {addTargetToExternalLinks: true}
        };
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    ngAfterViewInit(): void {
        const ngControl: NgControl = this.injector.get(NgControl, null);
        if (ngControl) {
            this.control = ngControl.control as FormControl;
            this.control.statusChanges
                .pipe(takeUntil(this.unsubscribeAll))
                .subscribe(value => {
                    this.invalidValue = value === 'INVALID';
            });
        } else {
            // Component is missing form control binding
        }

        // List Ckeditor available plugins
        // console.dir(ClassicEditor.builtinPlugins.map( plugin => plugin.pluginName ));
        // console.dir(this.ckEditor);
    }

    public writeValue(value: string): void {
        this._value = value || '';
    }


}
