
export const currentCourseMenu = {
    id: 'currentCourse',
    title: 'Curso',
    translate: 'NAV.COURSE',
    type: 'group',
    children: [
        {
            id: 'currCourseIntro',
            title: 'Introducción',
            type : 'item',
            url: '',
        },
        {
            id: 'currCourseMembers',
            title: 'Estudiantes',
            type: 'item',
            url: '',
        },
        {
            id: 'currCourseBibliography',
            title: 'Bibliografía',
            type: 'item',
            url: '/courses/course/${course.id}/bibliography',
        },
        {
            id: 'currCourseEvaluation',
            title: 'Evaluación',
            type: 'item',
            url: '/courses/course/${course.id}/evaluate',
        },
        {
            id: 'currCourseCertificate',
            title: 'Certificado',
            type: 'item',
            url: '/courses/course/${course.id}/certificate',
        },
        {
            id: 'currCourseAd',
            title: 'NAV.ADS',
            type: 'item',
            url: '/courses/course/${course.id}/ads',
        },
        {
            id: 'currCourseActivitiesSummary',
            title: 'NAV.GRADES',
            type: 'item',
            url: '/courses/course/${course.id}/grades',
        },
    ]
};


// agregar a los modules de menuStudent cuando ya este en prod universidad

export const menuStudent = {
    projectName: '',
    projectLogo: 'assets/img/neuuni_logotipo_blanco.png',
    modules: [

        {
            id: 'home',
            name: 'Inicio',
            fieldI18Key: 'NAV.HOME',
            menu: [
                // {
                //     name: 'Start',
                //     fieldI18Key: 'NAV.HOME',
                //     url: '/courses/student/home/recommended',
                //     icon: 'home',
                //     classes: 'home-item'
                // },
                {
                    name: 'MyCourses',
                    fieldI18Key: 'NAV.MYCOURSES',
                    url: '/courses/student/home/my-courses',
                    icon: 'school',
                },
                {
                    name: 'MyCompletedCourses',
                    fieldI18Key: 'NAV.MYCERTIFICATES',
                    url: '/courses/student/certificates',
                    icon: 'school',
                },
                {
                    id: 'myGeneralMessages',
                    name: 'Messages',
                    fieldI18Key: 'NAV.MESSAGES',
                    url: '/courses/message',
                    icon: 'chat',
                },
            ]
        }
    ]
};

