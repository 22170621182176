import {ProcessNotification} from '../user-notification.service';

export class NotificationCourseAnnouncementHandlers implements ProcessNotification {

    processNotification(notification, data, roleContext): void {
        notification.text = `Hay una nueva apertura para el curso: ${data.subjectName}`;
        // notification.url = `/university/my-career/enrollment-announcement/${data.studyPlanId}/${data.generationId}`;
        notification.icon = 'school';
        notification.avatar = '/assets/images/logos/neuuni_isotipo_250px.png';
    }

}
