import {GeneralPermissions} from '../general/config/general-permissions';

export const generalMenu = {
    id: 'general',
    name: 'General',
    fieldI18Key: 'NAV.GENERAL',
    menu: [
        {
            name: 'Contactos',
            fieldI18Key: 'NAV.CONTACTS',
            url: '/general/contact',
            roles: [GeneralPermissions.CONTACT_US_VIEW]
        },
    ]
};

export const contactsMangerMenu = {
    projectName: '',
    projectLogo: 'assets/img/neuuni_logotipo_blanco.png',
    modules: [
        generalMenu
    ]
};


