import {ContentBlock} from './content-block';
import {Image} from '../../../core/models/image';
import {Observable} from 'rxjs';

export class ContentBlockVideoApp extends ContentBlock{
    videoUrl: string;
    expiration: number;
    image: Image;
    readyHandler: Observable<any>;
    readyCookie: Observable<any>;
    forumActionHandler: any;
    msgs: any;
    fileUrl: string;
    msgsCount: number;

    constructor(block) {
        super(block);
        this.videoUrl = block.videoUrl || '';
        this.expiration = block.expiration || 0;
        this.image = block.image;
        this.msgs = block.msgs;
        this.fileUrl = block.fileUrl;
        this.msgsCount = block.msgsCount;
    }

    updateData(data): void {
        super.updateData(data);
        this.videoUrl = data.videoUrl;
        this.expiration = data.expiration;
        this.image = data.image;
    }

    getTitle(): string {
        return 'course.appVideoBlock';
    }

    getTypeAsString(): string {
        return 'videoApp';
    }

}
