import {ContentBlock} from './content-block';

export class ContentBlockVideo extends ContentBlock {
    videoUrl: string;

    constructor(lessonBlock) {
        super(lessonBlock);
        this.videoUrl = lessonBlock.videoUrl || '';
    }

    updateData(data): void {
        super.updateData(data);
        this.videoUrl = data.videoUrl;
    }

    getTitle(): string {
        return 'course.videoBlock';
    }

    getTypeAsString(): string {
        return 'video';
    }

}
