<div fxLayout="column" [formGroup]="form">

    <mat-form-field appearance="outline" fxFlex="100" [hintLabel]="'core.maxCharacters' | translate:{count: 255}">
        <mat-label>{{ 'course.title' | translate }}</mat-label>
        <input #titleInput matInput formControlName="title" required maxlength="255">
        <mat-hint align="end">{{titleInput.value?.length || 0}} / {{titleInput.maxLength}}</mat-hint>
        <mat-error>{{ 'course.title' | translate }} {{ 'core.required' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="100" [hintLabel]="'core.maxCharacters' | translate:{count: 255}">
        <mat-label>{{ 'course.subtitle' | translate }}</mat-label>
        <input #subtitleInput matInput formControlName="subtitle" maxlength="255">
        <mat-hint align="end">{{subtitleInput.value?.length || 0}} / {{subtitleInput.maxLength}}</mat-hint>
        <mat-error>{{ 'course.subtitleRequired' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
        <mat-label>Color</mat-label>
        <mat-select formControlName="bgColor" required>

            <ng-container *ngFor="let c of colors">
                <div>
                    <mat-option [value]="c.color"
                                [style.background-color]="c.color"
                                style="width: 99%; height: 40px; color: white; font-weight: bold;">
                        {{c.name}}
                    </mat-option>
                </div>

            </ng-container>
        </mat-select>
    </mat-form-field>

</div>