import {ModuleConfig} from '@quiox/global/config/config';
import {CoursePermissions} from './course-permissions';

export const seoMenu: ModuleConfig = {
    id: 'courses',
    name: 'SEO',
    fieldI18Key: 'SEO',
    menu: [
        {
            name: 'CoursesSEO',
            fieldI18Key: 'NAV.COURSES',
            url: '/courses/seo',
            exactMatch: true,
            roles: [CoursePermissions.URL_SEO_MANAGE],
        },
        {
            name: 'Categories',
            fieldI18Key: 'NAV.CATEGORIES',
            url: '/courses/seo-course-category',
            roles: [CoursePermissions.URL_SEO_MANAGE],
            // childrens: [
            //     {name: 'Categorías', url: '/categories', roles: ['ROLE_OWNER']}
            // ]
        },

    ]

};
