<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/neuuni_isotipo_azul.png">
                </div>
            </div>

            <div class="px-8 px-md-16">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div>

        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <!--HELP/START-->

<!--            Botón de pago, incluir link-->
           <a href="https://app.gedux.mx" target="_blank" style="text-decoration: none; color: #061926;margin-right: 1rem;place-content: center;
    align-items: center; display: flex;">
            <mat-icon  style="color: #262626; margin-right: 1rem;"
                       matTooltip="Pagos">monetization_on
            </mat-icon>
         </a>

            <div class="toolbar-separator"></div>
            <app-help-change></app-help-change>
            <!--HELP/END-->
            <div class="toolbar-separator"></div>
            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="avatar mr-0 mr-sm-16"
                         [src]="currentUser?.logo?.url || 'assets/images/avatars/profile.jpg'">
                    <span class="username mr-12" fxHide
                          fxShow.gt-sm>{{ currentUser?.email || currentUser?.username }}</span>
                    <mat-icon [fxHide]="visitedProfile" style="color: #fa3434; margin-right: 0;"
                              matTooltip="Debe completar el wizard de perfil">info
                    </mat-icon>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item (click)="goProfile()">
                    <mat-icon>account_circle</mat-icon>
                    <span>Mi perfil</span>
                    <mat-icon class="ml-16" style="color: #fa3434; margin-right: 0;"
                              *ngIf="!currentUser?.profileCompleted"
                              matTooltip="Faltan por llenar secciones del perfil">info
                    </mat-icon>
                </button>
                <button mat-menu-item [routerLink]="['/payment/my-orders']">
                    <mat-icon>assignment</mat-icon>
                    <span>Mis pedidos</span>
                </button>

                <button mat-menu-item class="" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>{{ 'core.logout' | translate }}</span>
                </button>

            </mat-menu>

            <!--            <div class="toolbar-separator"></div>-->

            <!--            <fuse-search-bar (input)="search($event)"></fuse-search-bar>-->


            <!--// (DONT SHOW) SET MXN DEFAULT-->

<!--            <app-country-change style="order: -1"></app-country-change>-->


            <!--            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>-->

            <!--            <button mat-icon-button-->
            <!--                    class="quick-panel-toggle-button"-->
            <!--                    (click)="toggleSidebarOpen('quickPanel')"-->
            <!--                    aria-label="Toggle quick panel">-->
            <!--                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>-->
            <!--            </button>-->

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>
            <!--            <button mat-icon-button class="navbar-toggle-button"-->
            <!--                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>-->
            <!--                <mat-icon class="secondary-text">menu</mat-icon>-->
            <!--            </button>-->
        </div>

    </div>

</mat-toolbar>
<div class="verificate-mail" *ngIf="verificate == true" fxLayout="column">
    <div fxLayoutAlign="space-between">
        <div>
           <span style="font-size: 16px; display: flex; justify-content: center; align-items: center;">
           Por favor verifica tu correo para activar tu cuenta
        </span>
        </div>
        <div>
            <button style="margin-top: -8px;" mat-icon-button (click)="verificate=false">
                <mat-icon>
                    close
                </mat-icon>
            </button>

        </div>

    </div>
    <div fxLayout="column" style="align-items: center" class="mt-16 mb-16">
        <mat-icon style="color: #0d47a1">
            email
        </mat-icon>
        <span style="color: #0d47a1">
            {{currentUser.email}}
        </span>
    </div>

    <span>
        Revisa tu bandeja de entrada o de spam. Hemos enviado un enlace de activación.
        Si no activa la cuenta no podrá acceder posteriormente a los cursos.
        <!--        , si no lo haces podrás tener problemas-->
        <!--        más adelante para acceder a tu cursos.-->
    </span>
    <span style="margin-top: 8px">
      Si no has recibido el correo presiona
    </span>
    <a style="margin-top: 8px; cursor: pointer; " (click)="resendMail()">Reenviar</a>
    <span style="margin-top: 8px;color: #0d47a1;" *ngIf="mailtext!==''">{{mailtext}}</span>
    <button class="mt-16" mat-raised-button color="accent" (click)="verificateAcount()">Ya validé mi cuenta</button>

</div>
