import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {MediaObserver} from '@angular/flex-layout';
import {CookieService} from 'ngx-cookie-service';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';

import {FuseMatchMediaService} from '@fuse/services/match-media.service';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {MessageService} from '../../../app/main/chat/services/message.service';
import {UserNotificationService} from 'app/main/core/user-notification/user-notification.service';
import {ShoppingCartService} from 'app/main/payment/services/shopping-cart.service';
import {Router} from '@angular/router';
import {UserNotificationCountService} from '../../../app/main/core/user-notification/user-notification-count.service';
import {WebinarGlobalService} from '../../../app/main/core/webinar/webinar-global.service';
import {SecurityService} from '../../../app/main/core/services/security.service';
import {LikeListDialogComponent} from "../../../app/main/general-forum/components/like-list-modal/like-list-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CourseTeacherMemberRemoteService} from "../../../app/main/course/services/remote-services/course-teacher-member.remote.service";
import {CourseStudentMemberRemoteService} from "../../../app/main/course/services/remote-services/course-student-member.remote.service";
import {CourseMemberRemoteService} from "../../../app/main/course/services/remote-services/course-member.remote.service";
import {LikeSearchCriteria} from "../../../app/main/general-forum/components/models/like-search-criteria";
import {ChatSearchCriteria} from "../../../app/main/chat/models/chat-search-criteria";
import {ChatListDialogComponent} from "../../../app/main/chat/components/chat-list-modal/chat-list-dialog.component";
import {SearchUserListDialogComponent} from "../../../app/main/general-forum/components/search-user-list-modal/search-user-list-dialog.component";
import {RestClientService} from "../../../app/main/core/services/rest-client.service";
import {FNavigationService} from "../../../app/main/core/services/f-navigation.service";
import {DialogService} from "../../../app/main/core/services/dialog.service";

@Component({
    selector: 'fuse-shortcuts',
    templateUrl: './shortcuts.component.html',
    styleUrls: ['./shortcuts.component.scss']
})
export class FuseShortcutsComponent implements OnInit, AfterViewInit, OnDestroy {
    shortcutItems: any[];
    navigationItems: any[];
    filteredNavigationItems: any[];
    searching: boolean;
    mobileShortcutsPanelActive: boolean;


    @Input()
    navigation: any;

    @ViewChild('searchInput')
    searchInputField;

    @ViewChild('shortcuts')
    shortcutsEl: ElementRef;

    // Private
    private _unsubscribeAll: Subject<any>;
    private likeListDialog: MatDialogRef<SearchUserListDialogComponent>;
    private readonly criteria: LikeSearchCriteria;

    // Mios de la app
    notificationCount: any;
    showWebinars: boolean;
    webinarCount = 0;
    showLayoutBtn = false;

    isTeacher: boolean;
    isBothRoles: boolean;
    isStudent: boolean;
    isCoordinator: boolean;
    loading: boolean;

    userList: any[];
    total: number;
    size: number;
    page: number;
    totalPages: number;

    /**
     * Constructor
     *
     * @param {CookieService} _cookieService
     * @param {FuseMatchMediaService} _fuseMatchMediaService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {MediaObserver} _mediaObserver
     * @param {Renderer2} _renderer
     */
    constructor(
        private _cookieService: CookieService,
        private _fuseMatchMediaService: FuseMatchMediaService,
        private _fuseNavigationService: FuseNavigationService,
        private _mediaObserver: MediaObserver,
        private _renderer: Renderer2,
        private messageService: MessageService,
        private userNotificationService: UserNotificationService,
        private userNotificationCountService: UserNotificationCountService,
        public shoppinCart: ShoppingCartService,
        private webinarGlobalService: WebinarGlobalService,
        private router: Router,
        private _matDialog: MatDialog,
        private teacherMemberRemoteService: CourseTeacherMemberRemoteService,
        private studentMemberRemoteService: CourseStudentMemberRemoteService,
        private courseMemberRemoteService: CourseMemberRemoteService,
        private restClientService: RestClientService,
        private dialogService: DialogService,
    ) {
        // Set the defaults
        this.shortcutItems = [];
        this.searching = false;
        this.mobileShortcutsPanelActive = false;

        // Set the private defaults
        this._unsubscribeAll = new Subject();


        this.criteria = new LikeSearchCriteria(1000);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the navigation items and flatten them
        this.filteredNavigationItems = this.navigationItems = this._fuseNavigationService.getFlatNavigation(this.navigation);

        if (this._cookieService.check('FUSE2.shortcuts')) {
            this.shortcutItems = JSON.parse(this._cookieService.get('FUSE2.shortcuts'));
        } else {
            // User's shortcut items
            this.shortcutItems = [
                // {
                //     title: 'Calendar',
                //     type: 'item',
                //     icon: 'today',
                //     url: '/apps/calendar'
                // },
                {
                    id: 'chatMessages',
                    title: 'Mensajes',
                    type: 'item',
                    icon: 'chat',
                    url: '/courses/message'
                },
                // {
                //     title: 'Perfil',
                //     type: 'item',
                //     icon: 'account_box',
                //     url: '/apps/contacts'
                // },
                // {
                //     title: 'To-Do',
                //     type: 'item',
                //     icon: 'check_box',
                //     url: '/apps/todo'
                // }
            ];
        }

        this.messageService.messageCount$.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe(messageCount => {
            const mailShortcut = this.shortcutItems.find(s => s.id === 'chatMessages');
            if (mailShortcut) {
                mailShortcut.badge = messageCount;
            }
        });

        this.userNotificationCountService.$courseCount.pipe(
            takeUntil(this._unsubscribeAll),
            filter(c => !!c)
        ).subscribe(count => {
            this.notificationCount = count.count;
        });

        SecurityService.userInfo$().pipe(
            takeUntil(this._unsubscribeAll),
            filter(u => !!u)
        ).subscribe((u) => {

            if (SecurityService.getUser() != null) {
                console.log('Usuario en webinar ', SecurityService.getUser());
                const role = SecurityService.getCurrentContextFromRole();
                console.log('Resultado de webinar', this.showWebinars = role === SecurityService.ROLE_STUDENT.toLowerCase() || role === SecurityService.ROLE_TEACHER.toLowerCase());
                this.showWebinars = role === SecurityService.ROLE_STUDENT.toLowerCase() || role === SecurityService.ROLE_TEACHER.toLowerCase() || role === SecurityService.ROLE_COORDINATOR.toLowerCase();
                //Revisar
                if (this.showWebinars) {
                    this.webinarGlobalService.getCount().then(count => this.webinarCount = count);
                }
            }

            if (SecurityService.getUser() != null) {
                if ((SecurityService.getUser()!.roles.includes('Student') && SecurityService.getUser()!.roles.includes('Teacher')) || (SecurityService.getUser()!.roles.includes('Student') && SecurityService.getUser()!.roles.includes('Coordinator')) || (SecurityService.getUser()!.roles.includes('Coordinator') && SecurityService.getUser()!.roles.includes('Teacher'))) {
                    this.showLayoutBtn = true;
                }
            }

        });

        if (SecurityService.getUser() != null) {
            this.isBothRoles = SecurityService.getUser()!.roles.includes('Student') && SecurityService.getUser()!.roles.includes('Teacher');
            const contextRole = SecurityService.getCurrentContextFromRole();
            this.isTeacher = contextRole === 'teacher';
            this.isStudent = contextRole === 'student';
            this.isCoordinator = contextRole === 'coordinator';
            // this.loadInitData();
        }

    }

    ngAfterViewInit(): void {
        // Subscribe to media changes
        this._fuseMatchMediaService.onMediaChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                if (this._mediaObserver.isActive('gt-sm')) {
                    this.hideMobileShortcutsPanel();
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Search
     *
     * @param event
     */
    search(event): void {
        const value = event.target.value.toLowerCase();

        if (value === '') {
            this.searching = false;
            this.filteredNavigationItems = this.navigationItems;

            return;
        }

        this.searching = true;

        this.filteredNavigationItems = this.navigationItems.filter((navigationItem) => {
            return navigationItem.title.toLowerCase().includes(value);
        });
    }

    /**
     * Toggle shortcut
     *
     * @param event
     * @param itemToToggle
     */
    toggleShortcut(event, itemToToggle): void {
        event.stopPropagation();

        for (let i = 0; i < this.shortcutItems.length; i++) {
            if (this.shortcutItems[i].url === itemToToggle.url) {
                this.shortcutItems.splice(i, 1);

                // Save to the cookies
                this._cookieService.set('FUSE2.shortcuts', JSON.stringify(this.shortcutItems));

                return;
            }
        }

        this.shortcutItems.push(itemToToggle);

        // Save to the cookies
        this._cookieService.set('FUSE2.shortcuts', JSON.stringify(this.shortcutItems));
    }

    /**
     * Is in shortcuts?
     *
     * @param navigationItem
     * @returns {any}
     */
    isInShortcuts(navigationItem): any {
        return this.shortcutItems.find(item => {
            return item.url === navigationItem.url;
        });
    }

    /**
     * On menu open
     */
    onMenuOpen(): void {
        setTimeout(() => {
            this.searchInputField.nativeElement.focus();
        });
    }

    onMenuOpen2(): void {

    }

    /**
     * Show mobile shortcuts
     */
    showMobileShortcutsPanel(): void {
        this.mobileShortcutsPanelActive = true;
        this._renderer.addClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }

    /**
     * Hide mobile shortcuts
     */
    hideMobileShortcutsPanel(): void {
        this.mobileShortcutsPanelActive = false;
        this._renderer.removeClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }

    get notifications(): UserNotificationService {
        return this.userNotificationService;
    }

    get webinars(): WebinarGlobalService {
        return this.webinarGlobalService;
    }

    // get notificationCount(): number {
    //     return this.userNotificationCountService.count;
    // }

    loadNotifications(): void {
        this.notifications.reset();
    }

    onNotificationScroll(): void {
        this.notifications.loadMore();
    }

    notificationClick(notification): void {
        this.notifications.click(notification);
    }

    showShoppingCart(): void {
        if (this.shoppinCart.count || this.shoppinCart.universityCount) {
            const orderStatus = this.shoppinCart.status;
            if (orderStatus === 'PROCESSING') {
                this.router.navigateByUrl('/payment/invoice/current');
            } else {
                this.router.navigateByUrl('/payment/cart');
            }
        }
    }

    showShoppingCartUniversity(): void {
        if (this.shoppinCart.coursesUniversity) {
            const orderStatus = this.shoppinCart.status;
            if (orderStatus === 'PROCESSING') {
                this.router.navigateByUrl('/payment/invoice/current');
            } else {
                const queryParams = '?university=true';
                this.router.navigateByUrl('payment/cart');
            }
        }
    }

    loadWebinars(): void {
        this.webinarGlobalService.reset();
    }

    onWebinarsScroll(): void {
        this.webinarGlobalService.loadMore();
    }

    webinarClick(item): void {
        this.webinarGlobalService.click(item);
    }

    changeProfile(): void {
        this.dialogService.showConfirmDialogPerfilUsuario('Está seguro que desea cambiar de perfil de usuario', (selectedValue) => {
            // const selectedOption = this.form.get('selectedValue').value; // Obtener el valor del select
            console.log('valor del select ' + selectedValue);


            // const toggleProfile = SecurityService.getUser().profile === 'coordinator' ? 'coordinator' : 'student';
            window.location.href = '/auth/success?code=0&profile=' + selectedValue;
        });
    }

    loadSearchUser(): void {

        this.likeListDialog = this._matDialog.open(SearchUserListDialogComponent, {
            panelClass: 'follow-list-dialog',
            data: {
                // post: user,
                // user: this.userList
            },
            restoreFocus: false,
            autoFocus: false
        });

        this.likeListDialog.afterClosed().subscribe(res => {
            const userSelectedId = this.likeListDialog.componentInstance.userSelectedId;
            // this.getChat(userSelectedId);
        });
    }

}
