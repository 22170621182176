import {Component, DoCheck, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {QxSelectListComponent} from './qx-select-list/qx-select-list.component';
import {QxDualListOp} from './qx-dual-list-op';
import { iif } from 'rxjs';

@Component({
    selector: 'qx-dual-list',
    templateUrl: './qx-dual-list.component.html',
    styleUrls: ['./qx-dual-list.component.scss']
})
export class QxDualListComponent implements OnInit{
    _selectedItems: any[] = [];
    _availableItems: any[] = [];

    @Input('title-selected') titleSelected: any ="Asignados";
    @Input('title-aviable') titleAviable: any="Disponibles";
    @Input('show-titles') showTitles: boolean=true;

    @Input('atr-name-selected') atrNameSelected: any;
    @Input('atr-value-selected') atrValueSelected: any;

    @Input('atr-name-aviable') atrNameAviable: any;
	@Input('atr-value-aviable') atrValueAviable: any;

    @Input() set selectedItems(value) {        
        if(value!=null){
            this._selectedItems = value;  
        } else{
            this._selectedItems = [];
        }          
        setTimeout(() => {          
          this.selectList1Comp.setItems(this._selectedItems);
          this.verifyItems();          
        });
    }

    @Input() set availableItems(value) {
        if(value!=null){
            this._availableItems = value;  
        } else{
            this._availableItems = [];
        }       
        setTimeout(() => {          
            this.selectList2Comp.setItems(this._availableItems);
            this.verifyItems();
        });     
    }


    verifyItems(){
    if(this._availableItems && this._availableItems.length>0 && this._selectedItems && this._selectedItems.length>0  ){
      
       
        var _availableItemsAux=[];

        for (const aviable of this._availableItems) {
            var exists=false;
            for (const select of this._selectedItems) {               
                if(aviable.id==select.id){
                    exists=true;
                }           
            }
            if(!exists){
                _availableItemsAux.push(aviable)
            }          
        }

        this._availableItems=_availableItemsAux;


       /* for( var i =this._availableItems.length ; i>=0; i--){
            for( var j=0; j<this._selectedItems.length; j++){
              if(this._availableItems[i].id == this._selectedItems[j].id){
                this._availableItems.splice(i, 1);
              }
            }
          }
       
       
        for( var i =this._availableItems.length - 1; i>=0; i--){
            for( var j=0; j<this._selectedItems.length; j++){
              if(this._availableItems[i].id == this._selectedItems[j].id){
                this._availableItems.splice(i, 1);
              }
            }
          }*/

          
       if(this.atrNameSelected!=null){
            this.selectList1Comp.setAtrName(this.atrNameSelected);
        }

        if(this.atrNameAviable!=null){
            this.selectList2Comp.setAtrName(this.atrNameAviable);
        }

        this.selectList1Comp.setItems(this._selectedItems);
        this.selectList2Comp.setItems( this._availableItems);

    }
    }

    @Output('selected') OnSelectedChange: EventEmitter<any>;

    private validator: (op: QxDualListOp) => Promise<void>;

    @ViewChild('select1', {static: true})
    selectList1Comp: QxSelectListComponent;

    @ViewChild('select2', {static: true})
    selectList2Comp: QxSelectListComponent;

    constructor() {
        this.OnSelectedChange = new EventEmitter();
    }

    ngOnInit(): void {
        this.selectList1Comp.setItems(this._selectedItems);
        this.selectList2Comp.setItems(this._availableItems);

        if(this.atrNameSelected!=null){
            this.selectList1Comp.setAtrName(this.atrNameSelected);
        }

        if(this.atrNameAviable!=null){
            this.selectList2Comp.setAtrName(this.atrNameAviable);
        }
       
    }

    addItem(): void {
        this.exchangeItems(this.selectList2Comp, this.selectList1Comp, 'add');
        this.updateSelectedList();
        
    }

    addAllItem(): void {
        this.exchangeItems(this.selectList2Comp, this.selectList1Comp, 'addAll');
        this.updateSelectedList();
    }

    removeItem(): void {
        this.exchangeItems(this.selectList1Comp, this.selectList2Comp, 'del');
        this.updateSelectedList();
    }

    removeAllItem(): void {
        this.exchangeItems(this.selectList1Comp, this.selectList2Comp, 'delAll');
        this.updateSelectedList();
    }

    getSelection(): any[] {
        return this.selectList1Comp.items;
    }

    registerOpValidator(validator: (op: QxDualListOp) => Promise<void>): void {
        this.validator = validator;
    }

    private updateSelectedList(){
        this._selectedItems=this.selectList1Comp.items;
        this.OnSelectedChange.emit(this._selectedItems);
    }
º
    private exchangeItems(source: QxSelectListComponent, target: QxSelectListComponent, op: 'add' | 'addAll' | 'del' | 'delAll'): void {
        if(op == 'addAll' || op=='delAll')
            source.selectionAll()
        
        const items = source.getSelection();
        console.log("source.getSelection()",items)
        if (items.length > 0) {
            if (this.validator) {
                this.validator({op: op, users: items}).then(() => {
                    this.makeExchange(source, target, items);
                });
            } else {
                this.makeExchange(source, target, items);
            }
        }
    }

    private makeExchange(source: QxSelectListComponent, target: QxSelectListComponent, items: any[]): void {
        target.clearFilter();
        target.addItems(items);
        source.removeItems(items);
    }
}
