<div class="navbar-header" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="logo"> <!-- (click)="goHome()" style="cursor: pointer;" // Esto va aqui en el logo, se quitó pq el welcome no iba a estar por el momento -->
        <img class="logo-icon" src="assets/images/logos/neuuni_isotipo_azul.png">
<!--        <mat-icon class="logo-icon1">menu</mat-icon>-->
        <span class="logo-text" >NEUUNI</span>
    </div>

    <button mat-icon-button class="toggle-sidebar-folded"
            (click)="toggleSidebarFolded()" fxHide.lt-lg>
        <mat-icon>{{ icon }}</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-opened"
            (click)="toggleSidebarOpened()" fxHide.gt-md>
        <mat-icon>arrow_back</mat-icon>
    </button>

</div>

<div class="navbar-content" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}"
     [ngClass]="fuseConfig.layout.navbar.primaryBackground">
    <fuse-navigation layout="vertical"></fuse-navigation>
</div>