import {APP_INITIALIZER, Injectable, NgModule} from '@angular/core';
import {CommonModule, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GlobalModule} from '@quiox/global/global.module';
import {RouterModule} from '@angular/router';
import {QxFTableComponent} from './components/qx-f-table/qx-f-table.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AppFormLayoutComponent} from './components/app-f-layout/app-form-layout.component';
import {FDialogNomenclatorComponent} from './crud/nomenclator/f-dialog-nomenclator.component';
import {FuseDirectivesModule} from '../../../@fuse/directives/directives';
import {FuseConfirmDialogComponent} from '../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {FuseTranslationLoaderService} from '../../../@fuse/services/translation-loader.service';
import {locale as navigationEnglish} from './i18n/en';
import {locale as navigationSpanish} from './i18n/es';
import {FValidationErrorDialogComponent} from './components/f-validation-error-dialog/f-validation-error-dialog.component';
import {FErrorDialogComponent} from './components/f-error-dialog/f-error-dialog.component';
import {FuseConfirmDialogModule} from '../../../@fuse/components';

import {UploadDataFileComponent} from './components/upload-data-file/upload-data-file.component';
import {FImagePreviewFieldComponent} from './components/f-image-preview-field/f-image-preview-field.component';
import {FTextEditorFieldComponent} from './components/f-text-editor-field/f-text-editor-field.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {FileUploadModule} from 'ng2-file-upload';
import {ImageCropperModule} from 'ngx-image-cropper';
import {FImageCropperComponent} from './components/f-image-cropper/f-image-cropper.component';
import {FVideoPlayerDialogComponent} from './components/f-video-player-dialog/f-video-player-dialog.component';
import {AppConfigLoaderService} from './services/app-config-loader.service';
import {ImageComponent} from './components/image/image.component';
import {FDropZoneImageFieldComponent} from './components/f-drop-zone-image/f-drop-zone-image-field.component';
import {DragDropFileDirective} from './directives/drag-drop-file.directive';
import {FAwsVideoPlayerComponent} from './components/f-aws-video-player/f-aws-video-player.component';
import {FAwsVideoEditorComponent} from './components/f-aws-video-editor/f-aws-video-editor.component';
import {FVideoAppPlayerDialogComponent} from './components/f-video-app-player-dialog/f-video-app-player-dialog.component';
import {UserNotificationModule} from './user-notification/user-notification.module';
import {FNgxGalleryComponent} from './components/f-ngx-gallery/f-ngx-gallery.component';
import {CoreCommonModule} from '../core-common/core-common.module';
import {PinchZoomModule} from 'ngx-pinch-zoom';
import {FVideoAppEditComponent} from './components/f-video-app/f-video-app-edit.component';
import {AuthTypePipe} from './pipe/auth-type.pipe';
import {BtnDisableAfterClickDirective} from './directives/btn-disable-after-click.directive';
import {FDropZoneImageFieldConcurrencyComponent} from './components/f-drop-zone-image-concurrency/f-drop-zone-image-field-concurrency.component';
import {MatSortModule} from '@angular/material/sort';
import {NgxGalleryModule} from 'ngx-gallery-9';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatBadgeModule} from '@angular/material/badge';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from '@angular/platform-browser';
import {TeacherBoxComponent} from '../course/components/common/course-preview/teacher-box/teacher-box.component';
import {CourseDetailBoxComponent} from '../course/components/common/course-recommended/detail-box/course-detail-box.component';
import {CategoriesFieldEditComponent} from '../course/components/common/course-edit/categories-field-edit/categories-field-edit.component';
import {VjsPlayerComponent} from './components/f-videojs-player/f-video-js-player.component';
import {FAwsAudioPlayerComponent} from './components/f-aws-audio-player/f-aws-audio-player.component';
import {ContentBlockResourceEditComponent} from '../content-blocks/edit-container/blocks/resource/content-block-resource-edit.component';
import {ContentBlockEditComponent} from '../content-blocks/edit-container/blocks/content-block-edit.component';
import {ContentBlockTextEditComponent} from '../content-blocks/edit-container/blocks/text/content-block-text-edit.component';
import {ContentBlockLabelEditComponent} from '../content-blocks/edit-container/blocks/label/content-block-label-edit.component';
import {ContentBlockImageEditComponent} from '../content-blocks/edit-container/blocks/image/content-block-image-edit.component';
import {ContentBlockVideoEditComponent} from '../content-blocks/edit-container/blocks/video/content-block-video-edit.component';
import {ContentBlockVideoAppEditComponent} from '../content-blocks/edit-container/blocks/video-app/content-block-video-app-edit.component';
import {ContentBlockEditContainerComponent} from '../content-blocks/edit-container/content-block-edit-container.component';
import {ProjectResourceComponent} from '../course/components/common/course-preview/unit-project/components/project-resource/project-resource.component';
import {ContentBlockAudioAppEditComponent} from '../content-blocks/edit-container/blocks/audio-app/content-block-audio-app-edit.component';
import {VjsPlayerTestComponent} from './components/f-videojs-test-player/f-video-js-player-test.component';
import {DurationTimePipe} from './pipe/duration-time-pipe';
import {ContentBlockH5pEditComponent} from '../content-blocks/edit-container/blocks/h5p/content-block-h5p-edit.component';


// custom configuration Hammerjs
@Injectable()
export class HammerConfig extends HammerGestureConfig {
    overrides = {
        swipe: {enable: true},
        pan: {enable: true},
        pinch: {enable: false},
        rotate: {enable: false},
    };
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        GlobalModule,
        MatSortModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FuseConfirmDialogModule,
        CKEditorModule,
        FileUploadModule,
        ImageCropperModule,
        UserNotificationModule,
        NgxGalleryModule,
        CoreCommonModule,
        PinchZoomModule,
        HammerModule,
    ],
    declarations: [
        QxFTableComponent,
        UploadDataFileComponent,
        AppFormLayoutComponent,
        FDialogNomenclatorComponent,
        FValidationErrorDialogComponent,
        FErrorDialogComponent,
        FImagePreviewFieldComponent,
        FTextEditorFieldComponent,
        FImageCropperComponent,
        FVideoPlayerDialogComponent,
        FVideoAppPlayerDialogComponent,
        ImageComponent,
        FDropZoneImageFieldComponent,
        DragDropFileDirective,
        FAwsVideoPlayerComponent,
        FAwsVideoEditorComponent,
        FNgxGalleryComponent,
        FVideoAppEditComponent,
        AuthTypePipe,
        FDropZoneImageFieldConcurrencyComponent,
        TeacherBoxComponent,
        CourseDetailBoxComponent,
        CategoriesFieldEditComponent,
        VjsPlayerTestComponent,
        VjsPlayerComponent,
        FAwsAudioPlayerComponent,
        ContentBlockResourceEditComponent,
        ContentBlockEditComponent,
        ContentBlockTextEditComponent,
        ContentBlockLabelEditComponent,
        ContentBlockImageEditComponent,
        ContentBlockEditContainerComponent,
        ContentBlockVideoEditComponent,
        ContentBlockVideoAppEditComponent,
        ProjectResourceComponent,
        ContentBlockAudioAppEditComponent,
        ContentBlockH5pEditComponent,
        DurationTimePipe
    ],
    exports: [
        CommonModule,
        GlobalModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        QxFTableComponent,
        FlexLayoutModule,
        UploadDataFileComponent,
        AppFormLayoutComponent,
        FImagePreviewFieldComponent,
        MatExpansionModule,
        FTextEditorFieldComponent,
        CKEditorModule,
        // FileUploadModule,
        MatBadgeModule,
        ImageCropperModule,
        ImageComponent,
        FDropZoneImageFieldComponent,
        FAwsVideoPlayerComponent,
        FAwsVideoEditorComponent,
        MatProgressSpinnerModule,
        FNgxGalleryComponent,
        CoreCommonModule,
        BtnDisableAfterClickDirective,
        FVideoAppEditComponent,
        AuthTypePipe,
        FDropZoneImageFieldConcurrencyComponent,
        HammerModule,
        TeacherBoxComponent,
        CourseDetailBoxComponent,
        CategoriesFieldEditComponent,
        VjsPlayerTestComponent,
        VjsPlayerComponent,
        FAwsAudioPlayerComponent,
        ContentBlockEditContainerComponent,
        ProjectResourceComponent,
        ContentBlockAudioAppEditComponent,
        ContentBlockH5pEditComponent,
        DurationTimePipe
    ],
    providers: [
        AppConfigLoaderService,
        {
            provide: APP_INITIALIZER,
            deps: [AppConfigLoaderService],
            useFactory: apiConfigProvider,
            multi: true,
        },
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        {provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig}
    ],
    entryComponents: [
        FDialogNomenclatorComponent,
        FuseConfirmDialogComponent,
        FValidationErrorDialogComponent,
        FErrorDialogComponent,
        FImageCropperComponent,
        FVideoPlayerDialogComponent,
        FVideoAppPlayerDialogComponent
    ]
})
export class CoreModule {
    constructor(fuseTranslationLoaderService: FuseTranslationLoaderService) {
        fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationSpanish);
    }
}

export function apiConfigProvider(appConfigLoaderService: AppConfigLoaderService): any {
    return () => appConfigLoaderService.loadConfiguration();
}
