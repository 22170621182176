import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import * as _ from 'lodash';
import {AppConfigService} from './app-config.service';
import {DateService} from './date.service';
import {catchError} from 'rxjs/operators';
import {RestClientErrorHandlerService} from './rest-client-error-handler.service';
import {SecurityService} from './security.service';
import {TranslateService} from '@ngx-translate/core';
import {ApiUrl} from './api-url';
import {APP_CONFIG} from "../../../app.config";

@Injectable({
    providedIn: 'root',
})
export class RestClientService {
    static readonly MAIN_SERVER_URL_PREFIX = '';
    static readonly CHAT_SERVER_URL_PREFIX = '/chat/';
    static readonly NOTIFICATION_SERVER_URL_PREFIX = '/notification/';

    constructor(private httpClient: HttpClient, private appConfigService: AppConfigService,
                private restClientErrorHandler: RestClientErrorHandlerService,
                private securityService: SecurityService,
                private translateService: TranslateService) {
    }

    public get<T>(url: string, params?: any, excludeFromLoader?: boolean): Observable<T> {
        const completeUrl = this.getCompleteUrl(url);
        // this.appendUrlToExclusionLoader(completeUrl, excludeFromLoader);
        return this.httpClient.get<T>(completeUrl, this.getRequestOptions(params, url))
            .pipe(catchError((error) => this.restClientErrorHandler.handleError(error)));
    }

    public post<T>(url: string, data?: any, params?: any, extraData: any = {handleErr: false}): Observable<T> {
        const completeUrl = this.getCompleteUrl(url);
        return this.httpClient.post<T>(completeUrl, data, this.getRequestOptions(params, url))
            .pipe(catchError((error) => this.restClientErrorHandler.handleError(error, extraData.handleErr)));
    }

    public put<T>(url: string, data?: any, extraData: any = {handleErr: false}): Observable<T> {
        const completeUrl = this.getCompleteUrl(url);
        return this.httpClient.put<T>(completeUrl, data, this.getRequestOptions({}, url))
            .pipe(catchError((error) => this.restClientErrorHandler.handleError(error, extraData.handleErr)));
    }

    public delete<T>(url: string, params?: any, extraData: any = {handleErr: false}): Observable<T> {
        const completeUrl = this.getCompleteUrl(url);
        // this.appendUrlToExclusionLoader(completeUrl, excludeFromLoader);
        return this.httpClient.delete<T>(completeUrl, this.getRequestOptions(params, url))
            .pipe(catchError((error) => this.restClientErrorHandler.handleError(error, extraData.handleErr)));
    }

    // public getBlob<T>(url: string, params?: any): Observable<Blob> {
    //     return this.httpClient.get(this.getCompleteUrl(url), {
    //         responseType: 'blob',
    //         headers: this.getHttpHeaders(null),
    //         params: this.getHttpParams(params)
    //     })
    //         .pipe(catchError((error) => this.restClientErrorHandler.handleBlobError(error)));
    // }
    public getBlob<T>(url: string, params?: any): Observable<Blob> {
        const completeUrl = this.getCompleteUrl(url);
        return this.httpClient.get<Blob>(completeUrl, this.getRequestOptionsBlob(params, url))
            .pipe(catchError((error) => this.restClientErrorHandler.handleError(error)));
    }

    /////////////////////////////////////////////////////////////////////////////////////////////

    private getCompleteUrl(url: string): string {
        if (url.startsWith(RestClientService.CHAT_SERVER_URL_PREFIX)) {
            url = url.slice(RestClientService.CHAT_SERVER_URL_PREFIX.length);
            const config = APP_CONFIG[APP_CONFIG.active_profile];
            const chatServerUrl = config.chatUrl;
            return this.getCompleteUrlFromSegments(chatServerUrl, url);
        } else {
            return this.getCompleteUrlFromSegments(this.appConfigService.getAppConfig().apiUrl, url);
        }
    }

    private getCompleteUrlFromSegments(prefixUrl: string, url: string): string {
        return url.length > 0 && url[0] === '/'
            ? `${prefixUrl}${url}`
            : `${prefixUrl}/${url}`;
    }

    private getRequestOptions(params: any, url: string, httpHeaders?: HttpHeaders): any {
        return {
            headers: this.getHttpHeaders(httpHeaders, url),
            params: this.getHttpParams(params),
        };
    }

    private getRequestOptionsBlob(params: any, url: string, httpHeaders?: HttpHeaders): any {
        return {
            responseType: 'blob',
            headers: this.getHttpHeaders(httpHeaders, url),
            params: this.getHttpParams(params)
        };
    }

    private getHttpHeaders(httpHeaders: HttpHeaders, url: string): HttpHeaders {
        httpHeaders = httpHeaders || new HttpHeaders();
        httpHeaders = httpHeaders.set('Accept-Language', this.translateService.currentLang || '');

        if (!ApiUrl.isWhiteListed(url)) {
            const securityToken = this.securityService.getSecurityToken();
            if (securityToken) {
                httpHeaders = httpHeaders.set('Authorization', `Bearer ${securityToken}`);
            }
        }
        return httpHeaders;
    }

    private getHttpParams(params: any): HttpParams {
        let httpParams = new HttpParams();

        if (params) {

            Object.entries(params).forEach(([key, value]) => {
                if (value !== undefined && value !== null) {
                    if (value instanceof Date) {
                        httpParams = httpParams.set(key, DateService.getIsoStringDatePart(value));
                    } else {
                        httpParams = httpParams.set(key, value as string);
                    }
                }
            });

        }

        return httpParams;
    }

}
