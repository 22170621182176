import {Component, Inject, OnInit, ViewChild, ViewEncapsulation, OnDestroy} from '@angular/core';
import {DialogService} from '../../services/dialog.service';
import {ImageCroppedEvent, ImageCropperComponent} from 'ngx-image-cropper';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AppUtils} from '@quiox/global/helpers/app.utils';

@Component({
    selector: 'app-image-cropper',
    templateUrl: './f-image-cropper.component.html',
    styleUrls: ['./f-image-cropper.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FImageCropperComponent implements OnInit, OnDestroy {
    private readonly MAX_FILE_SIZE = 10 * 1024 * 1024;
    private readonly MAX_FILE_SIZE_LABEL = '10MB';
    imageChangedEvent: any = '';
    imageLoaded = false;
    ratio: any;
    roundCropper: boolean;
    maintainAspectRatio: boolean;

    @ViewChild(ImageCropperComponent, {static: true})
    cropperComponent: ImageCropperComponent;

    constructor(public dialogRef: MatDialogRef<any>, private dialogService: DialogService,
                @Inject(MAT_DIALOG_DATA) private _data: any) {
        this.imageChangedEvent = _data.fileEvent;
        this.ratio = _data.ratio || '1';
        this.roundCropper = _data.roundCropper || false;
        this.maintainAspectRatio = _data.maintainAspectRatio !== false;
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.imageChangedEvent.target.value = '';
    }

    async save(): Promise<any> {
        this.cropperComponent.crop(/*'both'*/);

    }

    cancel(): void {
        this.dialogRef.close();
        this.imageChangedEvent.target.value = '';
    }

    delete(): void {
    }

    // ------------------------------------

    onImageCropped(event: ImageCroppedEvent): void {
        const temp: any = event;
        const fileName = this.imageChangedEvent.target.files[0].name;
        temp.file = this.convertToFile(event.base64, fileName);
        const maxFileExceeded = temp.file.size > this.MAX_FILE_SIZE;
        this.dialogRef.close(maxFileExceeded ? null : temp);
        if (maxFileExceeded) {
            this.dialogService.showErrorDialog('No se pueden subir recursos de más de ' + this.MAX_FILE_SIZE_LABEL, 'Tamaño excedido');
        }
        this.imageChangedEvent.target.value = '';
    }

    onImageLoaded(): void {
        this.imageLoaded = true;
    }

    onCropperReady(): void {
        // cropper ready
    }

    onLoadImageFailed(): void {
        // show message
    }

    private convertToFile(base64Url: string, fileName: string): File {
        const base64UrlTrimmed = base64Url.split(',')[1];
        const blob = AppUtils.dataURItoBlob(base64UrlTrimmed);
        return new File([blob], fileName, {type: 'image/jpeg'});
    }

}
