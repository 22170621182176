<h2 mat-dialog-title>Existen Errores de Validación</h2>
<mat-dialog-content class="mat-typography">
    <ng-container *ngFor="let error of errors | keyvalue">
        <ng-container [ngTemplateOutlet]="error.key === '.' ? flatList : nestedList" [ngTemplateOutletContext]="{'e': error}"></ng-container>
    </ng-container>

</mat-dialog-content>
<mat-dialog-actions align="end">
<!--  <button mat-button mat-dialog-close>Cancel</button>-->
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
</mat-dialog-actions>


<ng-template #flatList let-e="e">
    <li *ngFor="let message of e.value">{{ message }}</li>
</ng-template>

<ng-template #nestedList let-e="e">
    <h3>{{e.key | titlecase}}</h3>
    <ul>
        <li *ngFor="let message of e.value">{{ message }}</li>
    </ul>
</ng-template>