import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BlockTextType} from './block-text-type.enum';
import {ContentBlockText} from '../../../block-view/models/content-block-text';
import {MatSelectChange} from '@angular/material/select';
import {ContentBlockTypeBase} from '../block-type-base';
import {AppValidators} from '@quiox/global/helpers/app.validators';

@Component({
    selector: 'app-content-block-text-edit',
    templateUrl: './content-block-text-edit.component.html',
})
export class ContentBlockTextEditComponent implements OnInit, ContentBlockTypeBase {
    @Input() form: FormGroup;
    @Input() model: ContentBlockText;
    @Input() disabledBlock = false;

    hideText: boolean;

    constructor(private fb: FormBuilder) {
        this.hideText = false;
    }

    ngOnInit(): void {
        this.form.addControl('text', this.fb.control('', [Validators.required, AppValidators.image]));
        this.form.addControl('textType', this.fb.control('', Validators.required));
        this.form.patchValue(this.model);

        if (this.model.textType === BlockTextType.TEXT) {
            this.hideText = true;
        }
        
        if (this.model.newBlock) {
            this.disabledBlock = false;
            this.changeToDisable(false);
        }
        else {
            this.changeToDisable(true);
        }
    }

    changeToDisable(disable: boolean): void {
        if (!this.hideText) {
            if (disable) {
                this.form.controls['text'].disable();
                this.form.controls['textType'].disable();
            } else {
                this.form.controls['text'].enable();
                this.form.controls['textType'].enable();
            }
        } else {
            this.disabledBlock = true;
        }
    }

    get textTypes(): string[] {
        return Object.keys(BlockTextType);
        // return options.slice(options.length / 2);
    }

    changeText(event: MatSelectChange): void {
        this.hideText = event.value === BlockTextType.TEXT;
        this.model.text = this.model.text.replace(/(<([^>]+)>)/ig, '');
        this.form.get('text').setValue(this.model.text);
    }

}
