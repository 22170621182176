export class MarketingPermissions {

    static readonly SLIDER_VIEW = 'ROLE_SLIDER_VIEW';
    static readonly BLOCK_VIDEO_INFO_VIEW = 'ROLE_BLOCK_VIDEO_INFO_VIEW';
    static readonly LEGAL_DOCUMENT_VIEW = 'ROLE_LEGAL_DOCUMENT_VIEW';
    static readonly NEUUNI_COURSE_INFO_VIEW = 'ROLE_NEUUNI_COURSE_INFO_VIEW';
    static readonly GENERAL_INFO_VIEW = 'ROLE_GENERAL_INFO_VIEW';
    static readonly SECTIONS_VIEW = 'SECTIONS_VIEW';
}

