<div id="profile-image-circular">
    <div class="mb-8 wrap" fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let teacher of teachers">

        <div class="cursor-pointer" fxLayout="row" [routerLink]="['/courses/profile', teacher.id]">

            <div class="user" fxLayout="row" fxLayoutAlign="center center">
                <img class="avatar big" [src]="teacher?.logo?.url || 'assets/images/avatars/profile.jpg'">
            </div>

            <div fxLayout="column" fxLayoutAlign="center start">
                <div class="" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}" fxLayout="row"
                     fxLayoutAlign="center center">
                    <span
                        class="font-size-18">{{ teacher.firstName ? teacher.firstName + ' ' + teacher.lastName : '@' + teacher.nick.split('@')[0] }}</span>
                </div>
                <div *ngIf="teacher.evaluation" class="labels" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}"
                     fxLayout="row" fxLayoutAlign="center center">
                    <div class="star-icon" *ngIf="teacher.evaluation">
                        <app-rating [eval]="teacher.evaluation"></app-rating>
                    </div>
                </div>
            </div>

        </div>


        <div fxLayout="row">
            <button mat-icon-button color="warn" aria-label="Seguir"
                    [fxHide]="teacher.id === currentUser.id || !canFollowUser"
                    [disabled]="disableBtnFollowing[teacher.id]"
                    [matTooltip]="isFollowing[teacher.id] ? 'Dejar de seguir' : 'Seguir'"
                    [ngClass]="{'unfollow-btn': !isFollowing[teacher.id]}"
                    (click)="followAction(teacher, !isFollowing[teacher.id])">
                <mat-icon>favorite</mat-icon>
            </button>
            <mat-radio-button *ngIf="canSelectPrincipal" matTooltip="Seleccionar como profesor principal"
                              value="true" class="py-8" [checked]="teacher.principal"
                              (click)="setAsPrincipal(teacher.id)">
            </mat-radio-button>

            <div *ngIf="canViewReport">
                <button mat-icon-button aria-label="Ver reporte" matTooltip="Ver reporte" (click)="goTo(teacher.id)">
                    <mat-icon class="secondary-text">assignment_ind</mat-icon>
                </button>

            </div>
            <div [fxHide]="teacher.id === currentUser.id || !canRemoveTeacher">
                <button mat-icon-button aria-label="Opciones" matTooltip="Opciones" [matMenuTriggerFor]="listMenu">
                    <mat-icon class="secondary-text">more_vert</mat-icon>
                </button>

                <mat-menu #listMenu="matMenu">
                    <button mat-menu-item (click)="removeMember(teacher.id)">
                        <span>Eliminar</span>
                    </button>
                </mat-menu>
            </div>
        </div>


    </div>

</div>