import {Component, EventEmitter, Injector, Input, OnInit, Optional, Output} from '@angular/core';
import {ControlContainer, FormGroup, FormGroupDirective} from '@angular/forms';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {TitleConfig} from '../../models/title-config';

@Component({
    selector: 'app-form-layout',
    templateUrl: './form-layout.component.html',
    styleUrls: ['./form-layout.component.scss'],
    animations   : fuseAnimations,
})
export class AppFormLayoutComponent implements OnInit {
    form: FormGroup;
    @Input() titleConfig: TitleConfig;
    @Input() submitting = false;
    @Output() back: EventEmitter<any>;
    @Output() save: EventEmitter<any>;

    constructor(@Optional() private controlContainer: ControlContainer) {
        this.back = new EventEmitter();
        this.save = new EventEmitter();
    }

    ngOnInit(): void {
        if (this.controlContainer) {
            this.form = (this.controlContainer as FormGroupDirective).form;
        } else {
            console.error('Can\'t find parent FormGroup directive');
        }
    }

    onBack(): void {
        this.back.emit();
    }

    onSave(): void {
        this.save.emit();
    }

}

