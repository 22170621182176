import {Pipe, PipeTransform} from '@angular/core';
import {FileType} from '../../course/components/common/course-preview/unit-project/components/project-resource/file-type';

@Pipe({
    name: 'appAuthType',
})
export class AuthTypePipe implements PipeTransform {
    private types = {
        LOCAL: 'Local',
        GOOGLE: 'Google',
        FACEBOOK: 'Facebook',
    };

    constructor() {
    }

    public transform(type): string {
        return this.types[type];
    }

}
