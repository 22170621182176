<div id="fuse-shortcuts" #shortcuts>

    <!--<div class="shortcuts-mobile-toggle" *ngIf="!mobileShortcutsPanelActive" fxLayout="row" fxLayoutAlign="start center"
         fxHide fxShow.lt-md>
        <button mat-icon-button (click)="showMobileShortcutsPanel()">
            <mat-icon class="amber-600-fg">star</mat-icon>
        </button>
    </div>-->

    <div class="shortcuts" fxLayout="row" fxShow.gt-sm>

        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="0 1 auto">

            <div fxHide fxShow.gt-sm fxLayout="row" fxLayoutAlign="start center">

                <div class="w-40 h-40 p-4" fxLayout="row" fxLayoutAlign="center center"
                     *ngFor="let shortcutItem of shortcutItems">
                    <a mat-icon-button matTooltip="{{ shortcutItem.title }}" [routerLink]="shortcutItem.url">
                        <mat-icon class="secondary-text"
                                  *ngIf="shortcutItem.icon && (!shortcutItem.badge || shortcutItem.badge <= 0)">
                            {{ shortcutItem.icon }}</mat-icon>
                        <mat-icon class="secondary-text" *ngIf="shortcutItem.badge > 0" [matBadge]="shortcutItem.badge"
                                  matBadgeColor="warn">{{ shortcutItem.icon }}</mat-icon>
                        <span *ngIf="!shortcutItem.icon" class="h2 secondary-text text-bold">
                            {{ shortcutItem.title.substr(0, 1).toUpperCase() }}
                        </span>
                    </a>
                </div>


                <!--<button mat-icon-button [matMenuTriggerFor]="addMenu" matTooltip="Click to add/remove shortcut"
                        (menuOpened)="onMenuOpen()">
                    <mat-icon class="amber-600-fg">star</mat-icon>
                </button>-->

                <button mat-icon-button [matMenuTriggerFor]="addMenu2" matTooltip="Notificaciones"
                        (click)="loadNotifications()">
                    <mat-icon class="secondary-text" [matBadge]="notificationCount"
                              [matBadgeHidden]="!notificationCount" matBadgeColor="warn">notifications
                    </mat-icon>
                </button>

<!--                style="display: flex;align-items: center; cursor:pointer;"-->
<!--                <button mat-icon-button  matTooltip="Buscar usuarios"-->
<!--                        (click)="loadNotifications()" style="padding-left: 5px">-->
<!--&lt;!&ndash;                      <p style="display: flex;align-items: center; cursor:pointer;">&ndash;&gt;-->
<!--                          <mat-icon class="secondary-text">search-->
<!--                          </mat-icon> Buscar usuarios-->

<!--&lt;!&ndash;                      </p>&ndash;&gt;-->

<!--                </button>-->


<!--               <Comentado por Ronnay, Cambio de compra por gedux>-->
<!--                <button mat-icon-button matTooltip="Carrito" (click)="showShoppingCartUniversity()"-->
<!--                        *ngIf="shoppinCart.universityCount">-->
<!--                    <mat-icon class="secondary-text"-->
<!--                              [matBadge]="shoppinCart.universityCount "-->
<!--                              [matBadgeHidden]="!shoppinCart.universityCount"-->
<!--                              matBadgeColor="warn">-->
<!--                        shopping_cart-->
<!--                    </mat-icon>-->
<!--                </button>-->

<!--               </Comentado por Ronnay, Cambio de compra por gedux>-->

                <!--                <button mat-icon-button matTooltip="Carrito de universidad" (click)="showShoppingCartUniversity()"-->
                <!--                        *ngIf="shoppinCart.universityCount">-->
                <!--                    <mat-icon class="secondary-text" [matBadge]="shoppinCart.universityCount"-->
                <!--                              [matBadgeHidden]="!shoppinCart.universityCount" matBadgeColor="warn">shopping_cart-->
                <!--                    </mat-icon>-->
                <!--                </button>-->

                <button mat-icon-button [matMenuTriggerFor]="addMenu3" matTooltip="Webinars" (click)="loadWebinars()"
                        *ngIf="showWebinars">
                    <mat-icon class="secondary-text" [matBadge]="webinarCount" [matBadgeHidden]="!webinarCount"
                              matBadgeColor="warn">event
                    </mat-icon>
                </button>

                <button class="search-button"  matTooltip="Buscar usuarios" (click)="loadSearchUser()">
                    <mat-icon class="secondary-text">search </mat-icon> Buscar usuarios

                </button>


                <button mat-icon-button matTooltip="Perfil" (click)="changeProfile()" *ngIf="showLayoutBtn">
                    <mat-icon class="secondary-text" matBadgeColor="warn">account_box</mat-icon>
                </button>
            </div>

            <!--MORE-OPTION ONLY-MOBILE-->
            <div fxShow fxHide.gt-sm fxLayout="row" fxLayoutAlign="start center" >

                <button mat-icon-button [matMenuTriggerFor]="options" class="help-button">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <span class="">
                            <mat-icon>more_vert</mat-icon>
                        </span>
                    </div>
                </button>

                <mat-menu #options="matMenu" [overlapTrigger]="false">
                    <div class="ml-8 w-40 h-40 p-4" fxLayout="row" fxLayoutAlign="center center"
                         *ngFor="let shortcutItem of shortcutItems">
                        <a mat-icon-button matTooltip="{{ shortcutItem.title }}" [routerLink]="shortcutItem.url">
                            <mat-icon class="secondary-text"
                                      *ngIf="shortcutItem.icon && (!shortcutItem.badge || shortcutItem.badge <= 0)">
                                {{ shortcutItem.icon }}</mat-icon>
                            <mat-icon class="secondary-text" *ngIf="shortcutItem.badge > 0"
                                      [matBadge]="shortcutItem.badge"
                                      matBadgeColor="warn">{{ shortcutItem.icon }}</mat-icon>
                            <span *ngIf="!shortcutItem.icon" class="h2 secondary-text text-bold">
                                    {{ shortcutItem.title.substr(0, 1).toUpperCase() }}
                                </span>
                        </a>
                    </div>
                    <button mat-menu-item [matMenuTriggerFor]="addMenu2" matTooltip="Notificaciones"
                            (click)="loadNotifications()">
                        <mat-icon class="secondary-text" [matBadge]="notificationCount"
                                  [matBadgeHidden]="!notificationCount" matBadgeColor="warn">notifications
                        </mat-icon>
                    </button>

<!--                    <button mat-menu-item matTooltip="Carrito" (click)="showShoppingCartUniversity()"-->
<!--                            *ngIf="shoppinCart.universityCount">-->
<!--                        <mat-icon class="secondary-text" [matBadge]="shoppinCart.universityCount"-->
<!--                                  [matBadgeHidden]="!shoppinCart.universityCount" matBadgeColor="warn">shopping_cart-->
<!--                        </mat-icon>-->
<!--                    </button>-->

                    <button mat-menu-item [matMenuTriggerFor]="addMenu3" matTooltip="Webinars" (click)="loadWebinars()"
                            *ngIf="showWebinars">
                        <mat-icon class="secondary-text" [matBadge]="webinarCount" [matBadgeHidden]="!webinarCount"
                                  matBadgeColor="warn">event
                        </mat-icon>
                    </button>

                    <button class="search-button"  matTooltip="Buscar usuarios" (click)="loadSearchUser()">
                        <mat-icon class="secondary-text">search </mat-icon>

                    </button>

                    <button mat-menu-item matTooltip="Perfil" (click)="changeProfile()" *ngIf="showLayoutBtn">
                        <mat-icon class="secondary-text" matBadgeColor="warn">account_box</mat-icon>
                    </button>

                </mat-menu>

            </div>

            <!--<div class="shortcuts-mobile-close" fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.lt-md>
                <button mat-icon-button (click)="hideMobileShortcutsPanel()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>-->

        </div>

        <!-- Buscar -->
<!--
        <mat-menu #addMenu="matMenu" class="w-240">

            <mat-form-field class="px-16 w-100-p" (click)="$event.stopPropagation()" floatLabel="never">
                <input #searchInput matInput placeholder="Search for an app or a page" (input)="search($event)">
            </mat-form-field>

            <mat-divider></mat-divider>

            <mat-nav-list *ngIf="!searching" style="max-height: 312px; overflow: auto" fusePerfectScrollbar>

                <mat-list-item *ngFor="let shortcutItem of shortcutItems"
                               (click)="toggleShortcut($event, shortcutItem)">

                    <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">

                        <mat-icon mat-list-icon class="mr-8 secondary-text" *ngIf="shortcutItem.icon">
                            {{shortcutItem.icon}}
                        </mat-icon>

                        <span class="h2 w-32 h-32 p-4 mr-8 secondary-text text-bold" fxLayout="row"
                              fxLayoutAlign="center center" *ngIf="!shortcutItem.icon">
                            {{shortcutItem.title.substr(0, 1).toUpperCase()}}
                        </span>

                        <p matLine fxFlex>{{shortcutItem.title}}</p>

                        <mat-icon class="ml-8 amber-fg">star</mat-icon>

                    </div>

                </mat-list-item>

                <mat-list-item *ngIf="shortcutItems.length === 0">
                    <p>
                        <small>No shortcuts yet!</small>
                    </p>
                </mat-list-item>

            </mat-nav-list>

            <mat-nav-list *ngIf="searching" style="max-height: 312px; overflow: auto" fusePerfectScrollbar>

                <mat-list-item *ngFor="let navigationItem of filteredNavigationItems"
                               (click)="toggleShortcut($event, navigationItem)">

                    <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">

                        <mat-icon mat-list-icon class="mr-8 secondary-text" *ngIf="navigationItem.icon">
                            {{navigationItem.icon}}
                        </mat-icon>

                        <span class="h2 w-32 h-32 p-4 mr-8 secondary-text text-bold" fxLayout="row"
                              fxLayoutAlign="center center" *ngIf="!navigationItem.icon">
                            {{navigationItem.title.substr(0, 1).toUpperCase()}}
                        </span>

                        <p matLine fxFlex>{{navigationItem.title}}</p>

                        <mat-icon class="ml-8 amber-fg" *ngIf="isInShortcuts(navigationItem)">star</mat-icon>

                    </div>

                </mat-list-item>

            </mat-nav-list>

        </mat-menu>
        -->

        <mat-menu #addMenu2="matMenu" class="w-365">
            <mat-list *ngIf="!searching" style="max-height: 70vh; overflow: auto" fusePerfectScrollbar>
                <h3 matSubheader>{{ notifications.header }}</h3>
                <infinite-scroll (scrolled)="onNotificationScroll()">
                    <mat-list-item *ngFor="let notification of notifications.list" [ngClass]="{'no-read': !notification.read, 'notification': true}" (click)="notificationClick(notification)">
                        <div matListAvatar>
                            <img class="avatar" src="{{ notification.avatar }}">
                            <button mat-icon-button class="category" color="primary">
                                <mat-icon>{{ notification.icon }}</mat-icon>
                            </button>
                        </div>
                        <h4 matLine class="notification-text" style="white-space: normal; line-height: 1.1em; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical;">
                            {{ notification.text }}
                        </h4>
                        <p style="text-transform:capitalize;" matLine>{{ notification.date }}</p>
                        <span class="indicator"></span>
                    </mat-list-item>
                </infinite-scroll>
            </mat-list>

            <mat-nav-list *ngIf="searching" style="max-height: 312px; overflow: auto" fusePerfectScrollbar>

                <mat-list-item *ngFor="let navigationItem of filteredNavigationItems"
                               (click)="toggleShortcut($event, navigationItem)">

                    <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">

                        <mat-icon mat-list-icon class="mr-8 secondary-text" *ngIf="navigationItem.icon">
                            {{ navigationItem.icon }}
                        </mat-icon>

                        <span class="h2 w-32 h-32 p-4 mr-8 secondary-text text-bold" fxLayout="row"
                              fxLayoutAlign="center center" *ngIf="!navigationItem.icon">
                            {{ navigationItem.title.substr(0, 1).toUpperCase() }}
                        </span>

                        <p matLine fxFlex>{{navigationItem.title}}</p>

                        <mat-icon class="ml-8 amber-fg" *ngIf="isInShortcuts(navigationItem)">star</mat-icon>

                    </div>

                </mat-list-item>

            </mat-nav-list>

        </mat-menu>

        <mat-menu #addMenu3="matMenu" class="w-365">
            <mat-list *ngIf="!searching" style="max-height: calc(90vh - 20px); overflow: auto" fusePerfectScrollbar>
                <h3 matSubheader *ngIf="webinars.list.length>0">Webinars</h3>
                <h3 matSubheader *ngIf="webinars.list.length===0">No hay Webinars disponibles en este momento.</h3>
                <infinite-scroll (scrolled)="onWebinarsScroll()">
                    <mat-list-item class="cursor-pointer" *ngFor="let item of webinars.list" (click)="webinarClick(item)">
                        <div matListAvatar>
                            <img class="avatar" [src]="item?.image?.url || 'assets/images/avatars/profile.jpg'">
                        </div>
                        <h4 matLine class="notification-text" style="white-space: normal; line-height: 1.1em; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical;">
                            {{ item.name }}
                        </h4>
                        <p class="text-capitalize" matLine>{{ item.date }}</p>
                    </mat-list-item>
                </infinite-scroll>
            </mat-list>

        </mat-menu>
    </div>

</div>
