const configTeacherCourse = {
    id: 'course',
    name: 'Curso',
    fieldI18Key: 'NAV.COURSE',
    hidden: true,
    menu: [
        {
            name: 'Enable Edition',
            fieldI18Key: 'NAV.ENABLE_EDITION',
            url: '/courses/student',
        },
        {
            name: 'Settings',
            fieldI18Key: 'NAV.SETTINGS',
            url: '/courses/message',
        },
    ]

};

export const menuTeacher = {
    projectName: '',
    projectLogo: 'assets/img/neuuni_logotipo_blanco.png',
    modules: [
        {
            id: 'home',
            name: 'Inicio',
            fieldI18Key: 'NAV.HOME',
            menu: [
                {
                    name: 'MyCourses',
                    fieldI18Key: 'NAV.MYCOURSES',
                    url: '/courses/teacher',
                    icon: 'school',
                },
                {
                    id: 'myGeneralMessages',
                    name: 'Messages',
                    fieldI18Key: 'NAV.MESSAGES',
                    url: '/courses/message',
                    icon: 'chat',
                },
            ]

        },
        configTeacherCourse,
    ]
};
