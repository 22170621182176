import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {RestClientService} from '../../core/services/rest-client.service';
import {PagedResult} from '../../course/models/paged-result';

@Injectable({
    providedIn: 'root'
})
export class DeanInfoRemoteService {

    private URL = '/api/dean-info';

    constructor(private restClientService: RestClientService) {
    }

    get(): Observable<any> {
        return this.restClientService.get(`${this.URL}`);
    }
    save(item: any): Observable<any> {
     return this.restClientService.put(`${this.URL}`, item);
        }

}
