<div class="mt-16" style="display: flex; flex-wrap: wrap;">
    <div class="h3 mb-16" *ngIf="label">{{ label }}</div>
    <!-- IMAGES -->
    <div class="fuse-card" *ngFor="let file of filesPreview">
        <div class="img">
            <img [src]="file.url">
        </div>
        <div class="px-16 py-4" fxLayout="row" fxLayoutAlign="center center">
            <button *ngIf="!disabled" mat-icon-button aria-label="bookmark" (click)="onFileRemove(file)">
                <mat-icon color="accent">delete</mat-icon>
            </button>
            <button *ngIf="disabled" mat-icon-button aria-label="bookmark" [disabled]="true" >
                <mat-icon style="color:grey;" >delete</mat-icon>
            </button>
        </div>
    </div>
    <!-- IMAGES -->

    <!-- DRAG DROP IMAGES -->
    <div class="drop-zone" *ngIf="filesPreview.length < limit">
        <label *ngIf="!disabled" [for]="'drop-resource-' + identifier">
<!--        <div class="px-16 py-4 drop-zone-center" appDragDropFile (files)="filesDropped($event)" style="justify-content: space-around; cursor: pointer;     text-align: center;">-->
        <div class="px-16 py-4 drop-zone-center" style="justify-content: space-around; cursor: pointer;     text-align: center;">
            <div style="order: 1">
                <div class="h4">Haga Click para agregar la Imagen</div>
            </div>
            <div class="image-upload" style="order: 2">

                <mat-icon color="accent" style="width: 100%; font-size: 44px; margin-top: 20px; margin-bottom: 40px;">add_circle</mat-icon>

                <input [id]="'drop-resource-' + identifier" type="file" accept="image/x-png,image/jpeg" (change)="onFileChange($event)" style="display: none;"/>
            </div>
            <ng-container *ngIf="maxSize > 0 else dontShowSizeLimit">
                <div style="order: 3; display: flex; flex-direction: column; align-items: center;">
                    <div class="h4">El Archivo debe pesar menos de </div>
                    <strong>{{maxSize}} MB</strong>
                    <!--<div class="h4">Allowed file types <b>TXT</b></div>-->
                </div>
            </ng-container>
            <ng-template #dontShowSizeLimit>
                <div style="order: 3; display: flex; flex-direction: column; align-items: center;">

                </div>
            </ng-template>
        </div>
        </label>
        <label *ngIf="disabled">
            <!--        <div class="px-16 py-4 drop-zone-center" appDragDropFile (files)="filesDropped($event)" style="justify-content: space-around; cursor: pointer;     text-align: center;">-->
            <div class="px-16 py-4 drop-zone-center" style="justify-content: space-around; text-align: center;">
                <div style="order: 1">
                    <div class="h4">Haga Click para agregar la Imagen</div>
                </div>
                <div class="image-upload" style="order: 2">
    
                    <mat-icon style="color:grey; width: 100%; font-size: 44px; margin-top: 20px; margin-bottom: 40px; cursor: default;">add_circle</mat-icon>    
                    
                </div>
                <ng-container *ngIf="maxSize > 0 else dontShowSizeLimit">
                    <div style="order: 3; display: flex; flex-direction: column; align-items: center;">
                        <div class="h4">El Archivo debe pesar menos de </div>
                        <strong>{{maxSize}} MB</strong>
                        <!--<div class="h4">Allowed file types <b>TXT</b></div>-->
                    </div>
                </ng-container>
                <ng-template #dontShowSizeLimit>
                    <div style="order: 3; display: flex; flex-direction: column; align-items: center;">
    
                    </div>
                </ng-template>
            </div>
        </label>
    </div>
    <!-- DRAG DROP IMAGES -->
</div>
