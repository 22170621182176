import {courseMenu} from '../course/config/course.menu';
import {generalMenu} from '../general/config/general.menu';
import {paymentMenu} from '../payment/config/payment.menu';
import {seoMenu} from '../course/config/seo.menu';
import {marketingMenu} from './menu-marketing';
import {humanResoursesMenu} from './menu-human-resourses';
import {generalConfigMenu} from './menu-general-config';
import {universityMenu} from './menu-university';
import {courseSalesMenu} from '../sales/config/sales.menu';
import {userReportMenu} from '../payment/config/user-report.menu';
import {procedureMenu} from './menu-procedure';
import {incomeMenu} from './menu-income';
import {reportMenu} from './menu-reports';
import {coffeeShopModeratorMenu} from './menu-coffee-shop-moderator';
import {courseSupervisorMenu} from '../course/config/course-supervisor.menu';
import {contentConfigMenu} from './menu-content';
import {neuuniWatchModeratorMenu} from './menu-neuuni-watch-moderator';
import {indicatorMenu} from './menu-indocatorkd';
import {wharsappMenu} from "./menu-whatsapp";
import {mediaCreatorMenu, mediaMenu} from "./menu-media-creator";
import {migrarMenu} from "./menu-migrar";
import {resultadoExportarMenu} from "./menu-resultado-exportar";
import {menuCoordinator} from './menu-coordinator';

export const configCustom = {
    projectName: '',
    projectLogo: 'assets/img/neuuni_logotipo_blanco.png',
    modules: [
        // adminMenu,
        courseMenu,
        courseSupervisorMenu,
        // reportMenu,
        seoMenu,
        marketingMenu,
        generalMenu,
        paymentMenu,
        userReportMenu,
        humanResoursesMenu,
        generalConfigMenu,
        universityMenu,
        procedureMenu,
        // incomeMenu,
        indicatorMenu,
        migrarMenu,
        resultadoExportarMenu,
        // wharsappMenu,
        coffeeShopModeratorMenu,
        neuuniWatchModeratorMenu,
        contentConfigMenu,
        generalConfigMenu,
        courseSalesMenu,
        // mediaMenu,
        // testMenu,
    ]
};
