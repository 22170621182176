import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';

@Component({
    selector: 'app-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss'],
    animations: fuseAnimations
})
export class ImageComponent implements OnInit {
    @Input() title: string;
    @Input() iconType: string;
    @Output() changeImage: EventEmitter<File>;

    @ViewChild('fileInput', {static: true})
    private fileInputComponent: ElementRef;

    constructor() {
        this.changeImage = new EventEmitter();
    }

    ngOnInit(): void {
    }

    onFileChange($event): void {
        this.changeImage.emit($event);
    }

    clear(): void {
        this.fileInputComponent.nativeElement.value = null;
    }

}
