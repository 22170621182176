import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {fuseAnimations} from '@fuse/animations';
import {ProfileRemoteService} from '../../../course/components/common/profile/services/remote/profile.remote.service';
import {NotificationService} from '../../../core/services/notification.service';
import {SocialRemoteService} from '../../../course/services/remote-services/social-remote.service';
import {CourseRemoteService} from '../../../course/services/remote-services/course.remote.service';
import {MatDialog} from '@angular/material/dialog';
import {AuthorizationService} from '../../../core/services/authorization.service';
import {SecurityService} from '../../../core/services/security.service';
import {CoursePermissions} from '../../../course/config/course-permissions';


@Component({
    selector: 'app-followed-like',
    templateUrl: './followed-like.component.html',
    styleUrls: ['./followed-like.component.scss'],
    animations: fuseAnimations
})
export class FollowedLikeComponent implements OnInit {
    private loggedUser: any;
    userId: string;
    isFollowing = false;
    disableFollowBtn = false;
    ownProfile: boolean;
    canSendMessage = false;
    canFollow: boolean;

    @Input() profile: any;
    @Input() showButtons: boolean;
    @Output() changed: EventEmitter<boolean>;

    constructor(route: ActivatedRoute, private router: Router, protected fb: FormBuilder,
                private profileRemoteService: ProfileRemoteService,
                private notificationService: NotificationService,
                private socialRemoteService: SocialRemoteService, private courseRemoteService: CourseRemoteService,
                private _matDialog: MatDialog, authorizationService: AuthorizationService,
    ) {
        // this.profile = new Profile();
        this.loggedUser = SecurityService.getUser();
        this.changed = new EventEmitter();
        this.canFollow = authorizationService.hasPermission(CoursePermissions.FOLLOW_USER);
    }

    ngOnInit(): void {
        if (!this.showButtons) {
            this.initialize(this.profile);
        }
    }

    initialize(profile): void {
        this.userId = profile.id;
        this.profile = profile;
        this.ownProfile = this.loggedUser.id === this.userId;
        this.loadData();
    }

    loadData(): void {
        if (!this.ownProfile) {

            if (this.canFollow) {
                this.loadFollowingData();
            }
        }
    }


    saveFollowing(): void {
        // const message = (this.isFollowing ? 'Ha dejado de seguir' : 'Siguiendo') + ` a ${this.profile.fullName}`;
        const endpoint = this.isFollowing ? this.socialRemoteService.delete(this.userId) : this.socialRemoteService.create(this.userId);

        this.disableFollowBtn = true;
        endpoint.subscribe(response => {
            if (response.success) {
                this.isFollowing = !this.isFollowing;
                // this.notificationService.showMessage(message);
                // this.loadData();
                // this.changed.emit('follow');
            }
            this.disableFollowBtn = false;
        });
    }

    private loadFollowingData(): void {
        this.socialRemoteService.getFollowedInUsers([this.userId]).subscribe(users => {
            this.isFollowing = users.includes(this.userId);
        });
    }

    goProfile(): void {
        this.router.navigateByUrl(`/courses/profile/${this.userId}`);
        this.changed.emit(true);
    }


}
