
<div *ngIf="!ready" class="poster-img" >

    <div class="button-container">
        <img [src]="poster" class="video-image" />
        <button  *ngIf="poster" mat-fab (click)="startPlayback()">
            <mat-icon>play_circle_filled_white</mat-icon>
        </button>
    </div>
</div>

<video *ngIf="ready" [id]="elementId" class="video-js" controls muted playsinline preload="none"></video>