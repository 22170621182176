import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import * as _ from 'lodash';
import {LangCurrencyService} from '../../services/lang-currency.service';

@Component({
    selector: 'app-country-change',
    templateUrl: './country-change.component.html',
    styleUrls: ['./country-change.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CountryChangeComponent implements OnInit {
    countries: any;
    selectedCountry: any;

    constructor(private langCurrencyService: LangCurrencyService) {
        this.countries = [
            // {
            //     id   : 'en',
            //     title: 'English',
            //     flag : 'us'
            // },
            {
                id: 'mx',
                // title: 'México (ESP) - MXN',
                title: 'MXN',
                flag: 'mx',
                lang: 'es',
                currency: 'MXN'
            },
            // {
            //     id: 'other',
            //     // title: 'Resto del Mundo (ESP) - USD',
            //     title: 'USD',
            //     flag: 'other',
            //     lang: 'es',
            //     currency: 'USD'
            // },
            // {
            //     id   : 'tr',
            //     title: 'Turkish',
            //     flag : 'tr'
            // }
        ];
    }

    ngOnInit(): void {
        this.selectedCountry = _.find(this.countries, {id: this.langCurrencyService.getCurrent().id});
    }

    setLanguage(lang): void {
        const url = new URL(window.location.href);
        url.searchParams.set('locale', lang.id);
        window.location.href = url.href;

        // this.selectedLanguage = lang;

        // // Set the selected language for the toolbar
        // this.selectedLanguage = lang;
        //
        // // Use the selected language for translations
        // this._translateService.use(lang.id);
    }

}
