import {NgModule} from '@angular/core';
import {UserNotificationHandlerInitService} from './user-notification-handler-init.service';

@NgModule({
    imports: [
    ],
    declarations: [
    ],
    exports: [
    ],
    providers: [
        UserNotificationHandlerInitService
    ],
})
export class UserNotificationModule {
    constructor() {
    }
}

