import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {RestClientService} from '../../core/services/rest-client.service';

@Injectable({
    providedIn: 'root'
})
export class WelcomeContentRemoteService {
    private generalInfos = new BehaviorSubject(null);
    private generalInfosLoaded = false;
    private wsapp = new BehaviorSubject(null);
    private wsappLoaded = false;
    private sectionOurCourses = new BehaviorSubject(null);
    private sectionOurCoursesLoaded = false;
    private infoBlocks = new BehaviorSubject(null);
    private infoBlocksLoaded = false;

    private URL = '/welcome-api';

    constructor(private restClientService: RestClientService) {
    }

    getGeneralInfo(): Observable<any> {
        if (!this.generalInfosLoaded) {
            this.restClientService.get(`${this.URL}/general-info/public/footer`).subscribe(result => {
                this.generalInfos.next(result);
            });
            this.generalInfosLoaded = true;
        }
        return this.generalInfos;
    }

    getWhatsappNumber(): Observable<any> {
        if (!this.wsappLoaded) {
            this.restClientService.get(`${this.URL}/general-info/public/whatsapp`).subscribe(result => {
                this.wsapp.next(result);
            });
            this.wsappLoaded = true;
        }
        return this.wsapp;
    }

    private getSectionByType(type): Observable<any> {
        return this.restClientService.get(`${this.URL}/section/public/${type}`);
    }

    getCourseInfoBlock(): Observable<any> {
        if (!this.infoBlocksLoaded) {
            this.restClientService.get(`${this.URL}/neuuni-course-info/public`).subscribe(result => {
                this.infoBlocks.next(result);
            });
            this.infoBlocksLoaded = true;
        }
        return this.infoBlocks;
    }

    getSectionOurCourses(): Observable<any> {
        if (!this.sectionOurCoursesLoaded) {
            this.getSectionByType('COURSE_INFO').subscribe(response => {
                this.sectionOurCourses.next(response);
            });
            this.sectionOurCoursesLoaded = true;
        }
        return this.sectionOurCourses;
    }

}
