import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';

import videojs from 'video.js';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';

@Component({
    selector: 'app-vjs-player-test',
    template: `{{player.paused()}}{{player.currentTime() }}
        <video #target class="video-js" controls muted playsinline preload="none"></video>
    `,
    styleUrls: [
        './f-video-js-player-test.component.scss'
    ],
    encapsulation: ViewEncapsulation.None,
})
export class VjsPlayerTestComponent implements OnInit, OnDestroy {
    @ViewChild('target', {static: true})
    target: ElementRef;

    // see options: https://github.com/videojs/video.js/blob/maintutorial-options.html
    @Input() options: {
        fluid: boolean,
        aspectRatio: string,
        autoplay: boolean,
        sources: {
            src: string,
            type: string,
        }[],
    };

    player: videojs.Player;

    constructor(
        private elementRef: ElementRef,
    ) {
    }

    ngOnInit(): void {
        // instantiate Video.js
        const ops: any = this.options;
        ops.html5 = {
            vhs: {
                overrideNative: true
            }
        };

        this.player = videojs(this.target.nativeElement, ops, () => {
            this.player.hlsQualitySelector({
                displayCurrentQuality: true
            });

        });
        this.player.on('pause',  args => {
        });
    }

    ngOnDestroy(): void {
        // destroy player
        if (this.player) {
            this.player.dispose();
        }
    }

}
