import {Injectable} from '@angular/core';
import {DialogService} from './dialog.service';
import {SecurityService} from './security.service';

@Injectable({
    providedIn: 'root'
})
export class BroadcastChannelService {
    private initialized = false;

    constructor(private dialogService: DialogService) {
    }

    messageBroadcast(message): void {
        const uid = Date.now().toString() + Math.random().toString().substr(2);
        localStorage.setItem('message', JSON.stringify({...message, msgId: uid}));
    }

    async messageReceive(event): Promise<void> {
        if (event.key === 'message') {
            const message = JSON.parse(event.newValue);

            if (SecurityService.getCurrentContextFromRole() === message.profile_changed) {
                return;
            }

            const modalMessage = `Su perfil cambió a ${message.profile_changed === 'teacher' ? 'Profesor' : 'Estudiante'}`;
            await this.dialogService.showErrorDialog(modalMessage, 'Recargar', {
                btnLabel: 'Recargar',
                matDialogConfig: {disableClose: true, hasBackdrop: true}
            });
            this.changeProfile(message.profile_changed);
        }
    }

    init(): void {
        if (!this.initialized) {
            window.addEventListener('storage', this.messageReceive.bind(this));
            this.initialized = true;
        }
    }

    private changeProfile(profile): void {
        console.log('Parametro de cambio de perfil');
        window.location.href = '/auth/success?code=0&profile=' + profile;
    }

}
