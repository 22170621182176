import {Institution} from '../../../../models/institution';
import {CourseCategory} from '../../../../models/course-category';

export class Profile {
    id?: string;
    username: string;
    avatar: string;
    cover: string;
    name: string;
    lastName: string;
    location: string;
    birthDate: string;
    summary: string;
    profession: string;
    professionalLocation: string;
    freelance: boolean;
    contract: boolean;
    interesting: boolean;
    website: string;
    phone: string;
    fullName: string;
    anyware: boolean;
    email: string;
    institutions?: Institution[];
    courseCategory?: CourseCategory[];
    following?: any[];
    totalFollowing?: number;
    follower?: any[];
    totalFollower?: number;
    isStudent?: boolean;
    isTeacher?: boolean;
    teacherEval?: number;
    causeNoApproveInstitution?: any[];
    emailPublic?: boolean;
    contactPublic?: boolean;

    constructor(item ?) {
        if (item !== undefined) {
            this.id = item.id || '';
            this.username = item.username || '';
            this.avatar = item.avatarImage ? item.avatarImage.url : null;
            this.cover = item.coverImage ? item.coverImage.url : null;
            this.name = item.firstName || '';
            this.lastName = item.lastName || '';
            this.location = item.localization || '';
            this.birthDate = item.birthDate || '';
            this.summary = item.summary || '';
            this.profession = item.profession || '';
            this.professionalLocation = item.localizationProfessional || '';
            this.freelance = item.freelance || false;
            this.contract = item.contract || false;
            this.interesting = item.interesting || false;
            this.website = item.website || '';
            this.phone = item.phone || '';
            this.fullName = item.fullName || '';
            this.email = item.email || '';
            this.anyware = item.anywhere || false;
            this.isStudent = item.student || false;
            this.isTeacher = item.teacher || false;
            this.teacherEval = item.teacherEval;

            this.emailPublic = item.emailPublic;
            this.contactPublic = item.contactPublic;

            if (this.anyware) {
                this.professionalLocation = 'En cualquier lugar';
            }

            this.institutions = [];
            if (item.institutions && item.institutions.length > 0) {
                item.institutions.forEach(institution => {
                    this.institutions.push(new Institution(institution));
                });
            }
            this.courseCategory = item.categories ? item.categories : [];

        }
    }

}
