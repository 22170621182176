import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-course-detail-box',
    templateUrl: './course-detail-box.component.html',
    styleUrls: ['./course-detail-box.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CourseDetailBoxComponent implements OnInit {
    @Input() model: any;
    @Input() showCourseState = false;

    constructor() {
    }

    ngOnInit(): void {
    }

}
// advance
