export const locale = {
    lang: 'es',
    data: {
        core: {
            required: 'Requerido',
            accessDenied: 'Access Denegado',
            enabled: 'Activo',
            disabled: 'Deshabilitado',
            code: 'Código',
            name: 'Nombre',
            lastName: 'Apellidos',
            firstName: 'Nombre',
            new: 'Nuevo',
            edit: 'Editar',
            detail: 'Detalles',
            save: 'Guardar',
            nameRequired: 'Nombre es requerido!',
            maxCharacters: 'Máx {{count}} caracteres',
            hours: 'Horas',
            state: 'Estado',
            description: 'Descripción',
            date: 'Fecha',
            file: 'Archivo',
            files: 'Archivos',
            requiredField: 'Campo requerido',
            errorCourseDelete: 'El curso no se puede eliminar porque está siendo utilizado',
            successCourseDelete: 'Curso eliminado',
            text: 'Texto',
            image: 'Imagen',
            video: 'Video',
            cancel: 'Cancelar',
            urlNotValid: 'Url no válida!',
            close: 'Cerrar',
            type: 'Tipo',
            percent: 'Porciento de calificación final',
            attempts: 'Intentos',
            logout: 'Salir',
            changePassword: 'Cambiar Contraseña',
            public: 'Público',
            authType: 'Tipo de autenticación',
            ads: 'Avisos',
            addAd: 'Adicionar Aviso',
            countUses: 'Cantidad de usos'
        }
    }
};
