import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ProjectResourceComponent} from '../../../../course/components/common/course-preview/unit-project/components/project-resource/project-resource.component';
import {ContentBlockResource} from '../../../block-view/models/content-block-resource';
import {ContentBlockTypeBase} from '../block-type-base';

@Component({
    selector: 'app-content-block-resource-edit',
    templateUrl: './content-block-resource-edit.component.html',
    styleUrls: ['./content-block-resource-edit.component.scss'],
})
export class ContentBlockResourceEditComponent implements OnInit, ContentBlockTypeBase {
    @Input() form: FormGroup;
    @Input() model: ContentBlockResource;
    @Output() resourceAction: EventEmitter<any>;
    @Input() disabledBlock = false;

    @ViewChild('resources', {read: ProjectResourceComponent, static: true})
    resources: ProjectResourceComponent;

    constructor() {
        this.resourceAction = new EventEmitter();
    }

    ngOnInit(): void {
        this.form.patchValue(this.model);
        this.resources.setInitData(this.model.resources);
        this.resources.resourceAdded = this.onFileResourceAddHandler.bind(this);
        this.resources.resourceDeleted = this.onFileResourceDeleteHandler.bind(this);
    }

    changeToDisable(disable: boolean): void {
        this.resources.disabled = disable;
    }

    updateData(data): void {
        this.resources.setInitData(data.resources);
    }

    private onFileResourceAddHandler(data): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.resourceAction.emit({data: data, resolve: resolve, reject: reject});
            // this.changeToDisable(true);
        });
    }

    private onFileResourceDeleteHandler(data): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.resourceAction.emit({data: data, resolve: resolve, reject: reject, isDelete: true});
            // this.changeToDisable(true);
        });
    }

}
