import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClientService} from '../../../core/services/rest-client.service';
import {PagedResult} from '../../models/paged-result';
import {User} from '../../../core/models/user';

@Injectable({
    providedIn: 'root'
})
export class CourseMemberRemoteService {
    private URL = '/api/course-member';

    constructor(private restClientService: RestClientService) {
    }

    searchCandidates(courseId: any, criteria?: any): Observable<PagedResult<User>> {
        return this.restClientService.get<PagedResult<User>>(`${this.URL}/${courseId}/candidates`, criteria);
    }

    searchClassmates(criteria): Observable<any> {
        return this.restClientService.get(`${this.URL}/classmates`, {
            page: criteria.page,
            size: 1000,
            q: criteria.q
        });
    }

}
