<mat-card class="mb-16">
    <mat-card-content>
        <div fxLayout="column">
            <div class="content-wrapper" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" [formGroup]="form">

                <div class="mb-24" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="h2">{{ title | translate }}</div>
                    <div fxLayout="row">
                        <ng-container *ngIf="(disabledBlock.value && (managedFlag || !busyFlag)) else disableOptions">
                            <button mat-icon-button matTooltip="Up" *ngIf="!isFirst" (click)="moveUpInOrder()" [disabled]="!managedFlag || busyFlag">
                                <mat-icon>arrow_upward</mat-icon>
                            </button>
                            <button mat-icon-button matTooltip="Down" *ngIf="!isLast" (click)="moveDownInOrder()" [disabled]="!managedFlag || busyFlag">
                                <mat-icon>arrow_downward</mat-icon>
                            </button>
                            <button class="ml-16" mat-icon-button matTooltip="Delete" (click)="delete()" [disabled]="busyFlag">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button mat-icon-button matTooltip="Guardar" (click)="save()" [disabled]="!formValid || busyFlag">
                                <mat-icon>save</mat-icon>
                            </button>
                        </ng-container>
                        <ng-template #disableOptions>
                            <button mat-icon-button matTooltip="Up" *ngIf="!isFirst" [disabled]="true">
                                <mat-icon>arrow_upward</mat-icon>
                            </button>
                            <button mat-icon-button matTooltip="Down" *ngIf="!isLast" [disabled]="true">
                                <mat-icon>arrow_downward</mat-icon>
                            </button>
                            <button class="ml-16" mat-icon-button matTooltip="Delete" [disabled]="true">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button mat-icon-button matTooltip="Guardar" [disabled]="true">
                                <mat-icon>save</mat-icon>
                            </button>
                        </ng-template>
                        <mat-slide-toggle class="mt-8" 
                            matTooltip="{{disabledBlock.value ? 'Modo Escritura' : 'Modo Lectura'}}"
                            [formControl]="disabledBlock"
                            [checked]="disabledBlock.value"
                            (click)="toggleReadWrite()">
                            <!-- <label>{{disabledBlock.value ? 'Escritura' : 'Lectura'}}</label> -->
                        </mat-slide-toggle>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>{{ 'course.topMargin' | translate }}</mat-label>
                        <input matInput formControlName="topMargin" required>
                        <mat-error>{{ 'core.requiredField' | translate }}!</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                        <mat-label>{{ 'course.bottomMargin' | translate }}</mat-label>
                        <input matInput formControlName="bottomMargin" required>
                        <mat-error>{{ 'core.requiredField' | translate }}!</mat-error>
                    </mat-form-field>

                </div>

                <!-- CUSTOM BLOCKS -->
                <ng-container [ngSwitch]="blockType">

                    <app-content-block-text-edit *ngSwitchCase="'text'" #childBlock [disabledBlock]="!disabledBlock.value" [form]="form" [model]="model"></app-content-block-text-edit>

                    <app-content-block-image-edit *ngSwitchCase="'image'" #childBlock [form]="form" [model]="model"></app-content-block-image-edit>

                    <app-content-block-video-edit *ngSwitchCase="'video'" #childBlock [form]="form" [model]="model"></app-content-block-video-edit>

                    <app-content-block-video-app-edit *ngSwitchCase="'videoApp'" #childBlock [form]="form" [model]="model"></app-content-block-video-app-edit>

                    <app-content-block-resource-edit *ngSwitchCase="'resource'" #childBlock [form]="form" [model]="model" (resourceAction)="onResourceAction($event)"></app-content-block-resource-edit>

                    <app-content-block-label-edit *ngSwitchCase="'label'" #childBlock [form]="form" [model]="model"></app-content-block-label-edit>

                    <app-content-block-audio-app-edit *ngSwitchCase="'audioApp'" #childBlock [form]="form" [model]="model"></app-content-block-audio-app-edit>

                    <app-content-block-h5p-edit *ngSwitchCase="'h5p'" #childBlock [form]="form" [model]="model"></app-content-block-h5p-edit>

                </ng-container>
                <!-- / CUSTOM BLOCKS -->

            </div>
        </div>
    </mat-card-content>
</mat-card>