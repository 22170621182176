import {Injectable} from '@angular/core';
import {SecurityService} from '../services/security.service';
import {Router} from '@angular/router';
import {UserNotificationHandlerInitService} from './user-notification-handler-init.service';
import * as moment from 'moment';
import {UserNotificationRemoteService} from './user-notification.remote.service';
import {UserNotificationCountService} from './user-notification-count.service';
import {UnitCount} from './model/UnitCount';
import {AppUtils} from '@quiox/global/helpers/app.utils';

export interface ProcessNotification {
    processNotification(notification, data, roleContext): void;
}

export interface OnMarkAsRead {
    onMarkAsRead(notification, countInfo: UnitCount[]): void;
}


@Injectable({
    providedIn: 'root',
})
export class UserNotificationService {
    handlers: any = {};
    private readonly NOTIFICATIONS_LIMIT = 50;
    private currentPage: any = {page: -1};
    private totalPages: any;
    private _searching = false;

    constructor(private userNotificationRemoteService: UserNotificationRemoteService, private router: Router,
                initService: UserNotificationHandlerInitService,
                private userNotificationCountService: UserNotificationCountService) {
        initService.initHandlers(this.handlers);

    }

    reset(): void {
        this.currentPage = {
            page: -1,
            elements: []
        };
    }

    loadMore(): void {
        if (this.list.length >= this.NOTIFICATIONS_LIMIT) {
            return;
        }

        this._searching = true;
        const page = this.currentPage.page + 1;
        if (page < this.totalPages || page === 0) {
            const profile = SecurityService.getCurrentContextFromRole();
            const group5 = (profile === 'student' || profile === 'teacher') ? AppUtils.capitalizeString(profile) : null;

            this.userNotificationRemoteService.search({page: page, group5: group5}).subscribe(res => {
                this.processResult(res);
            });
        }

    }

    private processResult(res): void {
        const content = this.currentPage.elements || [];
        res.elements.forEach(item => content.push(this.processNotification(item)));
        res.elements = content;
        this.currentPage = res;
        this.totalPages = res.totalPages;
        this._searching = false;
    }

    private processNotification(notification): any {
        const data = JSON.parse(notification.data);
        const roleContext = SecurityService.getCurrentContextFromRole();

        notification.readStrategy = 'standard';
        this.processDate(notification);
        notification.avatar = data?.userLogo || 'assets/images/avatars/profile.jpg';

        if (this.handlers[notification.type]) {
            const handler: ProcessNotification = this.handlers[notification.type];
            handler.processNotification(notification, data, roleContext);
            return notification;
        }

        return null;
    }

    async click(notification): Promise<void> {
        if (!notification.read && notification.readStrategy === 'standard') {
            await this.userNotificationRemoteService.setAsRead({ids: [notification.id]}).toPromise();
            const currCount = this.userNotificationCountService.$courseCount.value;
            if (this.handlers[notification.type]) {
                const handler: OnMarkAsRead = this.handlers[notification.type];
                if (handler.onMarkAsRead) {
                    handler.onMarkAsRead(notification, currCount.course);
                }
            }
            currCount.count -= 1;
            this.userNotificationCountService.$courseCount.next(currCount);
        }else if (!notification.read && notification.readStrategy === 'other') {
            await this.userNotificationRemoteService.setAsRead({ids: [notification.id]}).toPromise();
            const currCount = this.userNotificationCountService.$courseCount.value;

            currCount.count -= 1;
            this.userNotificationCountService.$courseCount.next(currCount);
        }
        this.read(notification);
    }

    private read(notification): void {
        notification.read = true;
        if (notification.url) {
            this.router.navigateByUrl(notification.url);
        }
    }

    private processDate(notification): void {
        const today = moment();
        const yesterday = moment().subtract(1, 'd');
        const creationDate = moment(notification.creationDate);

        if (today.format('DDMMYYYY') === creationDate.format('DDMMYYYY')) {
            if (today.hour() === creationDate.hour()) {
                notification.date = 'Ahora';
            } else {
                notification.date = 'Hoy: ' + creationDate.format('hh:mm a');
            }
        } else if (yesterday.format('DDMMYYYY') === creationDate.format('DDMMYYYY')) {
            notification.date = 'Ayer: ' + creationDate.format('hh:mm a');
        } else {
            notification.date = creationDate.locale('es_MX').format('dddd D, MMMM: hh:mm a');
        }
    }

    get list(): any {
        return this.currentPage.elements || [];

    }

    get searching(): boolean {
        return this._searching;
    }

    get header(): string {
        if (this.searching) {
            return 'Buscando ...';
        }

        return this.list.length ? 'Notificaciones' : 'Sin Notificaciones';
    }

}
