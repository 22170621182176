import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {WebinarRemoteService} from '../../course/services/remote-services/webinar.remote.service';


@Injectable({
    providedIn: 'root',
})
export class WebinarGlobalService {
    private readonly LIMIT = 5;
    private currentPage: any = {page: -1};
    private _searching: boolean = false;

    constructor(private webinarRemoteService: WebinarRemoteService, private router: Router) {
    }

    reset(): void {
        this.currentPage = {
            page: -1,
            elements: []
        };
    }

    loadMore(): void {
        if (this.list.length >= this.LIMIT) {
            return;
        }

        this._searching = true;

        const page = this.currentPage.page + 1;
        this.webinarRemoteService.allMine({page: page}).subscribe(res => {
            this.processResult(res);
        });
    }

    getCount(): Promise<any> {
        return this.webinarRemoteService.count().toPromise();
    }

    private processResult(res): void {
        const content = this.currentPage.elements || [];
        res.elements.forEach(item => {
            this.processDate(item);
            content.push(item);
        });
        res.elements = content;
        this.currentPage = res;
        this._searching = false;
    }

    async click(item): Promise<void> {
        const courseAnnouncementId = item.courseAnnouncementId ? '/course-announcement/' + item.courseAnnouncementId + '/' : '/';
        this.router.navigateByUrl(`/courses${courseAnnouncementId}course/${item.courseId}/webinar/${item.id}`);
    }

    private processDate(item): void {
        const today = moment();
        const yesterday = moment().subtract(1, 'd');
        const creationDate = moment(item.date);

        if (today.format('DDMMYYYY') === creationDate.format('DDMMYYYY')) {
            if (today.hour() === creationDate.hour()) {
                item.date = 'Ahora';
            } else {
                item.date = 'Hoy: ' + creationDate.format('hh:mm a');
            }
        } else if (yesterday.format('DDMMYYYY') === creationDate.format('DDMMYYYY')) {
            item.date = 'Ayer: ' + creationDate.format('hh:mm a');
        } else {
            item.date = creationDate.locale('es_MX').format('dddd D, MMMM: hh:mm a');
        }
    }

    get list(): any {
        return this.currentPage.elements || [];
    }

    get searching(): boolean {
        return this._searching;
    }

    get header(): string {
        if (this.searching) {
            return 'Buscando ...';
        }

        return this.list.length ? 'Webinars' : 'Sin Webinars';
    }

}
