<button [matMenuTriggerFor]="helpMenu" class="help-button" mat-button matTooltip="Escríbenos por WhatsApp">
    <div fxLayout="row" fxLayoutAlign="center center">
                        <span>
                            <mat-icon>live_help</mat-icon>
                        </span>
        <mat-icon>keyboard_arrow_down</mat-icon>
    </div>
</button>

<mat-menu #helpMenu="matMenu" [overlapTrigger]="false">
    <button mat-menu-item>
        <a [href]="whatsappLink" target="_blank" style="text-decoration: none; color: #144364;">
            <mat-icon>perm_phone_msg</mat-icon>
            <span style="color: grey;">WhatsApp</span>
        </a>
    </button>
</mat-menu>