export abstract class ContentBlock {
    id: string;
    order: number;
    topMargin: number;
    bottomMargin: number;
    newBlock = false;

    protected constructor(contentBlock) {
        this.id = contentBlock.id || null;
        this.order = contentBlock.order || '';
        this.topMargin = contentBlock.topMargin || 0;
        this.bottomMargin = contentBlock.bottomMargin || 0;
    }

    updateData(data): void {
        this.id = data.id;
        this.order = data.order;
        this.topMargin = data.topMargin;
        this.bottomMargin = data.bottomMargin;
    }

    abstract getTitle(): string;
    abstract getTypeAsString(): string;

}
