import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FileHandle} from '../../directives/drag-drop-file.directive';
import {NotificationService} from '../../services/notification.service';
import {FImageCropperComponent} from '../f-image-cropper/f-image-cropper.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../../services/dialog.service';

@Component({
    selector: 'app-f-drop-zone-image-field',
    templateUrl: './f-drop-zone-image-field.component.html',
    styleUrls: ['./f-drop-zone-image-field.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FDropZoneImageFieldComponent implements OnInit {
    files: File[] = [];
    filesPreview: any[] = [];
    onAddHandler: (newFiles: File[]) => boolean;

    @Input() identifier = 'test';
    @Input() label: string;
    @Input() limit: number;
    @Input() maxSize: number;
    @Input() maintainAspectRatio: boolean;
    @Input() disabled: boolean = false;

    @Output() fileRemoved: EventEmitter<any>;
    @Output() fileChanged: EventEmitter<any>;

    private cropperDialogRef: MatDialogRef<FImageCropperComponent>;

    constructor(private notificationService: NotificationService,
                private _matDialog: MatDialog,
                private dialogService: DialogService
    ) {
        this.fileRemoved = new EventEmitter();
        this.fileChanged = new EventEmitter();
    }

    ngOnInit(): void {
        this.limit = this.limit || 100;
        this.maxSize = this.maxSize || 0;
    }

    // onSelectResources(event: NgxDropzoneChangeEvent): void {
    //     if (!this.onAddHandler || this.onAddHandler(event.addedFiles)) {
    //         this.files = this.files.concat(event.addedFiles);
    //     }
    // }

    onRemove(idx: number): void {
        const file: any = this.files.splice(idx, 1);
        this.fileRemoved.emit({index: idx, file: file[0]});
    }

    addFile(fileObject: any): void {
        if (fileObject.resourceName || fileObject.url) {
            const file: any = new File([], fileObject.resourceName);
            file.metaId = fileObject.id;
            this.addFilePreview(file, fileObject.url);
        } else {
            if (fileObject.size > (this.maxSize * 1024 * 1024) && this.maxSize > 0) {
                this.dialogService.showErrorDialog(`No se pueden subir recursos de más de ${this.maxSize} MB`, 'Tamaño excedido');
            } else {
                this.files = this.files.concat(fileObject);
                this.addFilePreview(fileObject, null);
                this.fileChanged.emit(this.files.length);
            }
        }

    }

    addFilePreview(file: File, url: any): void {
        if (!url) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (_event) => {
                this.filesPreview.push({url: reader.result, file: file});
            };
        } else {
            this.filesPreview.push({url: url, file: file});
        }

    }

    // New Methods //

    filesDropped(files: FileHandle[]): void {
        if (files.length <= this.availableSpace()) {
            files.forEach(f => {
                this.addFile(f.file);
            });
        } else {
            this.notificationService.showMessage(`No puede agregar más de ${this.limit} elementos`);
        }
    }

    availableSpace(): number {
        return this.limit - this.files.length;
    }

    onFileChange(event): void {
        this.cropperDialogRef = this._matDialog.open(FImageCropperComponent, {
            panelClass: 'contact-form-dialog',
            data: {
                fileEvent: event,
                ratio: 16 / 9,
                maintainAspectRatio: this.maintainAspectRatio,
            },
            restoreFocus: false,
            autoFocus: false
        });

        this.cropperDialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.addFile(res.file);
            }
        });
    }

    onFileRemove(file: any): void {
        this.files.splice(file.file, 1);
        this.fileRemoved.emit(file);
        this.filesPreview = this.filesPreview.filter(fp => fp !== file);
    }

    clearData(): void {
        this.files = [];
        this.filesPreview = [];
    }

}
