import {ModuleConfig} from '@quiox/global/config/config';
import {CoursePermissions} from '../../course/config/course-permissions';


export const courseSalesMenu: ModuleConfig = {
    id: 'sales',
    name: 'Sales',
    fieldI18Key: 'NAV.SALES',
    menu: [
        {
            name: 'Courses',
            fieldI18Key: 'NAV.COUPONS',
            url: '/coupon',
            exactMatch: false,
            roles: [CoursePermissions.COUPON_VIEW],
        },
        {
            name: 'Users',
            fieldI18Key: 'NAV.USERS',
            url: '/users',
            exactMatch: false,
            roles: [CoursePermissions.COUPON_VIEW],
        }
    ]

};
export const salesMenu = {
    projectName: '',
    projectLogo: 'assets/img/neuuni_logotipo_blanco.png',
    modules: [
        courseSalesMenu
    ]
};
