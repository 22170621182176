export class UniversityPermissions {

    static readonly MANAGE_CAREER = 'ROLE_MANAGE_CAREER';
    static readonly MANAGE_LEVEL = 'ROLE_MANAGE_LEVEL';
    static readonly MANAGE_STUDY_PLAN = 'ROLE_MANAGE_STUDY_PLAN';
    static readonly MANAGE_STAGE = 'ROLE_MANAGE_STAGE';
    static readonly MANAGE_SUBJECT = 'ROLE_MANAGE_SUBJECT';
    static readonly MANAGE_DISCIPLINE = 'ROLE_MANAGE_DISCIPLINE';
    static readonly MANAGE_INCOME = 'ROLE_UNIVERSITY_PAY_MANUAL';


}
