import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
  selector: 'app-go-cart-dialog',
  templateUrl: './go-cart-dialog.component.html',
  styleUrls: ['./go-cart-dialog.component.scss']
})
export class GoCartDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<any>,  private router: Router) { }

  ngOnInit(): void {
  }
  goCart(): void{
      this.dialogRef.close();
      this.router.navigateByUrl('/payment/cart');

  }


}
