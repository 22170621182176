import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FDropZoneImageFieldConcurrencyComponent} from '../f-drop-zone-image-concurrency/f-drop-zone-image-field-concurrency.component';

@Component({
    selector: 'app-f-video-app-edit',
    templateUrl: './f-video-app-edit.component.html',
})
export class FVideoAppEditComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() model: any;

    @ViewChild('image', {read: FDropZoneImageFieldConcurrencyComponent, static: true})
    imageComponent: FDropZoneImageFieldConcurrencyComponent;
    imagesToDelete: any;

    constructor(private fb: FormBuilder) {
    }

    ngOnInit(): void {
        this.form.patchValue(this.model);
        this.setImage(this.model.image);
    }

    updateData(data): void {
        this.setImage(data.image);
    }

    onImageChange(event): void {
        const images = this.imageComponent.files.filter((f: any) => !f.metaFromServer);
        const mm = images.map(file => ({file: file}));
        this.form.get('image').setValue(mm[0]);
    }

    onFileRemoved(fileData: any): void {
        this.form.get('image').setValue(null);
        this.imagesToDelete = fileData.file.metaId;

    }

    private setImage(image: any): void {
        this.form.get('image').setValue(null);
        this.imageComponent.files = [];
        this.imageComponent.filesPreview = [];
        if (image) {
            this.imageComponent.addFile(image);
        }
    }

}
