import {ContentBlock} from './content-block';
import {Image} from '../../../core/models/image';
import {Observable} from 'rxjs';

export class ContentBlockAudioApp extends ContentBlock{
    audioUrl: string;
    expiration: number;
    image: Image;
    readyHandler: Observable<any>;

    constructor(lessonBlock) {
        super(lessonBlock);
        this.audioUrl = lessonBlock.audioUrl || '';
        this.expiration = lessonBlock.expiration || 0;
        this.image = lessonBlock.image;
    }

    updateData(data): void {
        super.updateData(data);
        this.audioUrl = data.audioUrl;
        this.expiration = data.expiration;
        this.image = data.image;
    }

    getTitle(): string {
        return 'course.appAudioBlock';
    }

    getTypeAsString(): string {
        return 'audioApp';
    }

}
