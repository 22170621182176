import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'durationTime'
})
export class DurationTimePipe implements PipeTransform {
    transform(value: string): string {
        if (value) {
            const splitDuration = value.split(':');

            const hours = parseInt(splitDuration[0]);
            const minutes = parseInt(splitDuration[1]);
            const seconds = parseInt(splitDuration[2]);

            if (minutes < 1 && hours < 1) {
                return seconds + ' secs';
            } else if (hours < 2) {
                return (hours * 60) + minutes + ' mins';
            } else {
                return hours + ' hrs ' + minutes + ' mins';
            }
        }
    }
}
