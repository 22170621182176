import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {WebSocketService} from './web-socket.service';
import {map} from 'rxjs/operators';
import {ChatHeader} from '../models/chat-header';


@Injectable({
    providedIn: 'root'
})
export class MessageSocketRemoteService {
    private headersAddress: string = '/user/queue/headers';
    private dialogAddress: string = '/user/queue/dialog';
    private userInfoAddress: string = '/user/queue/user-info';
    private messagesAddress: string = '/user/queue/messages';
    private messageCreatedAddress: string = '/user/queue/message-created';
    private unreadMessageCountAddress: string = '/user/queue/unread-message-count';

    constructor(private webSocketService: WebSocketService) {
    }

    connect(): Promise<void> {
        return this.webSocketService.connect();
    }

    disconnect(): void {
        this.webSocketService.disconnect();
    }

    getHeaders(includeUserInfo?): void {
        this.webSocketService.send('/app/get-headers');
    }

    subscribeToHeaders(): Observable<ChatHeader[]> {
        return this.webSocketService.subscribe(this.headersAddress).pipe(map(data => data.map(d => ChatHeader.fromData(d))));
    }

    unsubscribeToHeaders(): void {
        this.webSocketService.unsubscribe(this.headersAddress);
    }

    getDialog(userId, size: number): void {
        this.webSocketService.send(`/app/get-dialog/${userId}/${size}`);
    }

    subscribeToDialog(): Observable<any> {
        return this.webSocketService.subscribe(this.dialogAddress);
    }

    unsubscribeToDialog(): void {
        this.webSocketService.unsubscribe(this.dialogAddress);
    }

    getUnreadMessageCount(): void {
        this.webSocketService.send('/app/get-unread-message-count');
    }

    subscribeToUnreadMessageCount(): Observable<any> {
        return this.webSocketService.subscribe(this.unreadMessageCountAddress);
    }

    subscribeToMessages(): Observable<any> {
        return this.webSocketService.subscribe(this.messagesAddress);
    }

    createMessage(message: any): void {
        this.webSocketService.send(`/app/send-message`, message);
    }

    subscribeToMessageCreated(): Observable<any> {
        return this.webSocketService.subscribe(this.messageCreatedAddress);
    }

    unsubscribeToMessageCreated(): void {
        this.webSocketService.unsubscribe(this.messageCreatedAddress);
    }

    setMessagesAsRead(messages): void {
        this.webSocketService.send(`/app/set-messages-as-read`, messages);
    }

    getUserInfo(id: string): void {
        this.webSocketService.send(`/app/user-info/` + id);
    }

    subscribeToUserInfo(): Observable<any> {
        return this.webSocketService.subscribe(this.userInfoAddress);
    }

    unsubscribeToUserInfo(): void {
        this.webSocketService.unsubscribe(this.userInfoAddress);
    }

}
