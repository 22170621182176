import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LikeSearchCriteria} from "../models/like-search-criteria";
import {CourseTeacherMemberRemoteService} from "../../../course/services/remote-services/course-teacher-member.remote.service";
import {CourseStudentMemberRemoteService} from "../../../course/services/remote-services/course-student-member.remote.service";
import {AuthorizationService} from "../../../core/services/authorization.service";
import {CourseMemberRemoteService} from "../../../course/services/remote-services/course-member.remote.service";
import {SecurityService} from "../../../core/services/security.service";
import {CoursePermissions} from "../../../course/config/course-permissions";
import {ChatSearchCriteria} from "../../../chat/models/chat-search-criteria";


@Component({
    selector: 'app-search-user-list-dialog',
    templateUrl: './search-user-list-dialog.component.html',
    styleUrls: ['./search-user-list-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SearchUserListDialogComponent implements OnInit, OnDestroy {
    private readonly criteria: LikeSearchCriteria;
    private _unsubscribeAll: Subject<any>;
    users: any[];
    total: number;
    size: number;
    page: number;
    totalPages: number;
    filter: FormControl;
    dataChanged = false;
    loading: boolean;
    userSelectedId: any;
    isTeacher: boolean;
    isBothRoles: boolean;
    isStudent: boolean;

    canFollow: boolean;
    disableFollowBtn = false;
    isFollowing = false;
    ownProfile: boolean;
    private loggedUser: any;

    constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) private _data: any,
                private teacherMemberRemoteService: CourseTeacherMemberRemoteService,
                private studentMemberRemoteService: CourseStudentMemberRemoteService,
                private courseMemberRemoteService: CourseMemberRemoteService, authorizationService: AuthorizationService
    ) {
        this.isBothRoles = SecurityService.getUser().roles.includes('Student') && SecurityService.getUser().roles.includes('Teacher');
        const contextRole = SecurityService.getCurrentContextFromRole();
        this.isTeacher = contextRole === 'teacher';
        this.isStudent = contextRole === 'student';
        this.filter = new FormControl('');
        this._unsubscribeAll = new Subject();
        // console.log("Resultado roles de isBothRoles", this.isBothRoles);
        // this.criteria = new ChatSearchCriteria(1000);
        this.criteria = new LikeSearchCriteria(1000);
        this.canFollow = authorizationService.hasPermission(CoursePermissions.FOLLOW_USER);
        this.disableFollowBtn = false;
        this.loggedUser = SecurityService.getUser();

    }

    ngOnInit(): void {
        this.filter.valueChanges.pipe(
            takeUntil(this._unsubscribeAll),
            distinctUntilChanged(),
            debounceTime(500))
            .subscribe(value => {
                this.loadInitData(value);
            });
        this.loadInitData();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    private loadInitData(filter?): void {
        this.users = [];
        this.criteria.page = 0;
        if (filter) {
            this.criteria.q = filter;
        } else {
            delete this.criteria.q;
        }
        this.loading = true;
        this.loadMoreData().then(() => {
            this.loading = false;
        });
    }

    private async loadMoreData(): Promise<void> {
        this.users = this._data?.user;
        this.total = this.users?.length;
        console.log('Datos 1111111111111: ',  this.users);
        this.ownProfile = true;



            if (this.isBothRoles) {
            const endpointMates = await this.courseMemberRemoteService.searchClassmates(this.criteria);
            const {elements, totalElements, page, size, totalPages} = await endpointMates.toPromise();
            this.total = totalElements;
            this.users = elements;
            this.page = page;
            this.size = size;
            this.totalPages = totalPages;
        } else if (this.isTeacher) {
            const endpointStd = await this.studentMemberRemoteService.searchMyStudents(this.criteria);
            const {elements, totalElements, page, size, totalPages} = await endpointStd.toPromise();
            this.total = totalElements;
            this.users = elements;
            this.page = page;
            this.size = size;
            this.totalPages = totalPages;
        } else {
            const endpointMates1 = await this.courseMemberRemoteService.searchClassmates(this.criteria);
            const {elements, totalElements, page, size, totalPages} = await endpointMates1.toPromise();
            this.total = totalElements;
            this.users = elements;
            this.page = page;
            this.size = size;
            this.totalPages = totalPages;

        }
        }

    viewMore(): void {
        this.criteria.page += 1;
        this.loadMoreData();
    }

    closeDialog(event): void {

        if (event){
            this.dialogRef.close();
        }

    }

    // private setDummyUserData(): void {
    //     const data = [];
    //     for (const i of [1, 2, 3, 4, 5, 6, 7]) {
    //         data.push({
    //             id: '' + i,
    //             firstName: 'Nombre ' + i,
    //             lastName: 'Apellido ' + i,
    //         });
    //     }
    //     this.total += 7;
    //     while (this.users.length < this.criteria.size) {
    //         this.users.push(data.shift());
    //     }
    // }

    startConversation(userId): void {
        // this.userSelectedId = userId;
        // this.dialogRef.close();
    }

    saveFollowing(): void {
        // this.disableFollowBtn = true;
        //
        // const message = (this.isFollowing ? 'Ha dejado de seguir' : 'Siguiendo') + ` a ${this.users.fullName}`;
        // const endpoint = this.isFollowing ? this.socialRemoteService.delete(this.userId) : this.socialRemoteService.create(this.userId);
        //
        // this.disableFollowBtn = true;
        // endpoint.subscribe(response => {
        //     if (response.success) {
        //         this.isFollowing = !this.isFollowing;
        //         this.notificationService.showMessage(message);
        //         // this.loadData();
        //         // this.changed.emit('follow');
        //     }
        //     this.disableFollowBtn = false;
        // });
    }

    // private loadFollowingData(): void {
    //     this.socialRemoteService.getFollowedInUsers([this.userId]).subscribe(users => {
    //         this.isFollowing = users.includes(this.userId);
    //     });
    // }
}
