<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">

            <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="mr-8">shopping_cart</mat-icon>
                <span class="title dialog-title">
                   Carrito de compra
                </span>
            </div>

            <button mat-icon-button (click)="dialogRef.close()" aria-label="Close">
                <mat-icon>close</mat-icon>
            </button>

        </div>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar style="position: inherit;">

        <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
            Este curso ya ha sido adicionado anteriormente al carrito de compra.
        </div>

    </div>

    <div mat-dialog-actions class="m-0 pl-24 pr-24 pb-24" fxLayout="row" fxLayoutAlign="end center">

        <button mat-raised-button class="save-button"  color="accent" (click)="goCart()">
            Ir al carrito
        </button>

    </div>
</div>
