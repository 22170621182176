import {ControlValueAccessor} from '@angular/forms';

export abstract class BaseControlValueAccessor<T> implements ControlValueAccessor {
    protected _value: T;

    public get value(): T {
        return this._value;
    }

    public set value(value: T) {
        this._value = value;
        this.onChange(value);
    }

    public writeValue(value: T): void {
        this._value = value;
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public onChange = (_: any) => {};
    public onTouched = () => {};
}
