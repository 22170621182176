<div class="cropper">
    <div class="dialog-content-wrapper">
        <div mat-dialog-content class="p-0 m-0" fusePerfectScrollbar>
            <image-cropper [imageChangedEvent]="imageChangedEvent"
                           [maintainAspectRatio]="maintainAspectRatio"
                           [aspectRatio]="ratio"
                           format="jpeg"
                           [autoCrop]="false"
                           [roundCropper]="roundCropper"
                           [resizeToWidth]="1920"
                           onlyScaleDown="true"
                           (imageCropped)="onImageCropped($event)"
                           (imageLoaded)="onImageLoaded()" (cropperReady)="onCropperReady()"
                           (loadImageFailed)="onLoadImageFailed()">
            </image-cropper>
            <div *ngIf="!imageLoaded" class="loading-text secondary-text mat-headline text-center mb-0 pb-24" fxLayoutAlign="center center">
                Cargando Imagen ...
            </div>
        </div>

        <div *ngIf="imageLoaded" mat-dialog-actions class="m-0 px-8" fxLayout="row" fxLayoutAlign="end center">
            <button mat-button class="save-button" (click)="save()" aria-label="GUARDAR">
                {{ 'core.save' | translate }}
            </button>
            <button mat-button class="save-button" (click)="cancel()" aria-label="CANCELAR">
                {{ 'core.cancel' | translate }}
            </button>
        </div>
    </div>
</div>
