import {Injectable} from '@angular/core';
import {FuseNavigationService} from '../../../../@fuse/components/navigation/navigation.service';
import {Course} from '../models/course';
import {currentCourseMenu} from '../../menuConfig/menu-student';
import {UnitStructure} from '../models/unit-structure';
import {UserCourseState} from '../models/user-course-state';
import {CourseRole} from '../models/course-role';
import {CourseMenuNotificationService} from './course-menu-notification.service';
import {CourseInfo} from '../models/course-info';
import {universityManagerMenu} from '../../menuConfig/menu-university';
import {SecurityService} from '../../core/services/security.service';
import {CourseStudentRemoteService} from "./remote-services/course-student-remote.service";

@Injectable()
export class CourseMenuService {
    isSupervisorTeacher: false;
    isStudent: boolean;

    constructor(private _fuseNavigationService: FuseNavigationService,
                private _courseMenuNotificationService: CourseMenuNotificationService,
                private courseStudentRemoteService: CourseStudentRemoteService) {
    }

    setMenu(courseInfo: CourseInfo, contextRole, examShow, updateNotifications = true): void {
        const courseAnnouncement = courseInfo.courseAnnouncementId ? 'course-announcement/' + courseInfo.courseAnnouncementId : '';
        this.isSupervisorTeacher = SecurityService.getUser().roles.includes(SecurityService.ROLE_SUPERVISOR_TEACHER);
        this.isStudent = contextRole === 'student';
        console.log('Exam prueba show examShowexamShow:', examShow);
        const course = courseInfo.course;
        const unitsItemsTitleExam = courseInfo.unitsInfo.find(u => u.titleExam === true);
        console.log('Valores titleExam = true:' , unitsItemsTitleExam);
        let unitsItems;
        const unitsInfoNotExam =  courseInfo.unitsInfo.filter(u => u.titleExam === false);
        console.log('Valores titleExam = false:' , unitsInfoNotExam);
        // Asegúrate de que las variables no sean undefined antes de concatenar
        const unitWithTitleExam = [].concat(unitsItemsTitleExam || [], unitsInfoNotExam || []);

        console.log('Valores ordenados:' , unitWithTitleExam);

            if ((!this.isStudent && examShow) || (this.isStudent && examShow == true) || (SecurityService.getUser().roles.includes('Coordinator'))) {
                unitsItems = unitWithTitleExam.map((u, idx) => {
                    if (unitsItemsTitleExam != null && examShow) {
                        return unitsItemsTitleExam != null && u.titleExam == true && examShow ? this.getUnitMenuItemTitle(u, idx, course.id, courseInfo.courseRole, contextRole, courseInfo.courseAnnouncementId, unitsItemsTitleExam) : this.getUnitMenuItem(u, idx, course.id, courseInfo.courseRole, contextRole, courseInfo.courseAnnouncementId);
                    } else {
                        return this.getUnitMenuItemIsNullTitle(u, idx, course.id, courseInfo.courseRole, contextRole, courseInfo.courseAnnouncementId);
                    }
                });
            } else if (this.isStudent && examShow == false) {
                if (unitsItemsTitleExam != null || unitsItemsTitleExam === undefined) {
                    const unitsInfoExamTitle = courseInfo.unitsInfo.filter(unit => unit.titleExam === false);
                    unitsItems = unitsInfoExamTitle.map((u, idx) => {
                        return this.getUnitMenuItemIsNullTitle(u, idx, course.id, courseInfo.courseRole, contextRole, courseInfo.courseAnnouncementId);
                    });
                }

            }
            const courseMenu = Object.assign({}, currentCourseMenu);
            let itemChildren = [courseMenu.children[5]];

            courseMenu.children[5].url = `courses/${courseAnnouncement}/course/${course.id}/ads`;

            courseMenu.children[0].url = `courses/${courseAnnouncement}/course/${course.id}/preview/${contextRole}/0/detail`;
            itemChildren.push(courseMenu.children[0]);

            if (courseInfo.course.hideContent === false) {
                itemChildren = itemChildren.concat(unitsItems);
                courseMenu.children[1].url = `courses/${courseAnnouncement}/course/${course.id}/preview/${contextRole}/${itemChildren.length - 1}/detail`;
                itemChildren.push(courseMenu.children[1]);

                courseMenu.children[2].url = `courses/${courseAnnouncement}/course/${course.id}/bibliography`;
                itemChildren.push(courseMenu.children[2]);
            }

            if (courseInfo.courseRole === 'Teacher') {
                courseMenu.children[6].url = `courses/${courseAnnouncement}/course/${course.id}/grades`;
                itemChildren.push(courseMenu.children[6]);
            }
            if (this.isSupervisorTeacher) {
                courseMenu.children[6].url = `courses/${courseAnnouncement}/course/${course.id}/grades`;
                itemChildren.push(courseMenu.children[6]);
            }
            if (SecurityService.getUser().roles.includes('Manager')) {
                courseMenu.children[6].url = `courses/${courseAnnouncement}/course/${course.id}/grades`;
                itemChildren.push(courseMenu.children[6]);
            } else if (SecurityService.getUser().roles.includes('Supervisor')) {
                courseMenu.children[6].url = `courses/${courseAnnouncement}/course/${course.id}/grades`;
                itemChildren.push(courseMenu.children[6]);
            } else if (SecurityService.getUser().roles.includes('Coordinator')) {
                courseMenu.children[6].url = `courses/${courseAnnouncement}/course/${course.id}/grades`;
                itemChildren.push(courseMenu.children[6]);
            }


            this.addEvaluationMenu(course, itemChildren, courseMenu.children, courseInfo.courseAnnouncementId);

            courseMenu.children = itemChildren;
            this._fuseNavigationService.addNavigationItem(courseMenu, 'end');

            if (courseInfo.courseRole) {
                this._courseMenuNotificationService.setUnitsNotifications(course, updateNotifications, courseInfo.courseAnnouncementId);
            }
    }

    updateEvaluationUrl(): void {
        this._fuseNavigationService.updateNavigationItem('currCourseEvaluation', {
            hidden: true
        });
        this._fuseNavigationService.updateNavigationItem('currCourseCertificate', {
            hidden: false
        });
    }

    destroy(): void {
        this._courseMenuNotificationService.destroy();
    }

    private getUnitMenuItem(unitStructure: UnitStructure, order, courseId, courseRole, contextRole, courseAnnouncementId): any {
        const courseAnnouncement = courseAnnouncementId ? 'course-announcement/' + courseAnnouncementId : '' ;
        const caId = courseAnnouncementId ? courseAnnouncementId : '' ;

        // const unitInfo = this.unitsStructure.find(ui => ui.unitId === unitId);
        const unitItem = {
            id: unitStructure.unitId + caId,
            title: `Tema ${order + 0}`,
            type: 'collapsable',
            // icon:   'border_all',
            url: `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/detail`,
            children: [],
        };
        unitItem.children = unitStructure.lessons.map((lessonId, idx) => ({
            id: lessonId,
            title: `Página ${idx + 1}`,
            type: 'item',
            url: `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/${unitStructure.unitId}/${lessonId}/lesson`
        }));

        const forumItem = {
            id: unitStructure.unitId + caId + '-forum',
            title: 'Foro',
            type: 'item',
            url: `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/${unitStructure.unitId}/forum`
        };
        const activitiesItem = {
            id: unitStructure.unitId + caId + '-activity-group',
            title: `Actividades`,
            type: 'collapsable',
            children: [],
        };

        activitiesItem.children = unitStructure.activities.map((activityId, idx) => ({
            id: unitStructure.unitId + caId + '-activity-' + activityId,
            title: `Actividad ${idx + 1}`,
            type: 'item',
            url: this.getUrlForActivity(courseId, order, unitStructure.unitId, activityId, courseRole, contextRole, courseAnnouncementId)
        }));
        const syncClassItem = {
            id: unitStructure.unitId + '-sync-class',
            title: 'Clase sincrónica',
            type: 'item',
            url: `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/${unitStructure.unitId}/56/lesson`
        };
        const materialItem = {
            id: unitStructure.unitId + '-material',
            title: 'Material de apoyo',
            type: 'item',
            url: `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/${unitStructure.unitId}/55/lesson`
        };
        unitItem.children.push(syncClassItem);
        unitItem.children.push(materialItem);
        unitItem.children.push(forumItem);
        if (activitiesItem.children.length > 0) {
            unitItem.children.push(activitiesItem);
        }
        return unitItem;
    }

    private getUnitMenuItemIsNullTitle(unitStructure: UnitStructure, order, courseId, courseRole, contextRole, courseAnnouncementId): any {
        const courseAnnouncement = courseAnnouncementId ? 'course-announcement/' + courseAnnouncementId : '' ;
        const caId = courseAnnouncementId ? courseAnnouncementId : '' ;

        // const unitInfo = this.unitsStructure.find(ui => ui.unitId === unitId);
        const unitItem = {
            id: unitStructure.unitId + caId,
            title: `Tema ${order + 1}`,
            type: 'collapsable',
            // icon:   'border_all',
            url: `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/detail`,
            children: [],
        };
        unitItem.children = unitStructure.lessons.map((lessonId, idx) => ({
            id: lessonId,
            title: `Página ${idx + 1}`,
            type: 'item',
            url: `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/${unitStructure.unitId}/${lessonId}/lesson`
        }));

        const forumItem = {
            id: unitStructure.unitId + caId + '-forum',
            title: 'Foro',
            type: 'item',
            url: `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/${unitStructure.unitId}/forum`
        };
        const activitiesItem = {
            id: unitStructure.unitId + caId + '-activity-group',
            title: `Actividades`,
            type: 'collapsable',
            children: [],
        };

        activitiesItem.children = unitStructure.activities.map((activityId, idx) => ({
            id: unitStructure.unitId + caId + '-activity-' + activityId,
            title: `Actividad ${idx + 1}`,
            type: 'item',
            url: this.getUrlForActivity(courseId, order, unitStructure.unitId, activityId, courseRole, contextRole, courseAnnouncementId)
        }));
        const syncClassItem = {
            id: unitStructure.unitId + '-sync-class',
            title: 'Clase sincrónica',
            type: 'item',
            url: `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/${unitStructure.unitId}/56/lesson`
        };
        const materialItem = {
            id: unitStructure.unitId + '-material',
            title: 'Material de apoyo',
            type: 'item',
            url: `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/${unitStructure.unitId}/55/lesson`
        };
        unitItem.children.push(syncClassItem);
        unitItem.children.push(materialItem);
        unitItem.children.push(forumItem);
        if (activitiesItem.children.length > 0) {
            unitItem.children.push(activitiesItem);
        }
        return unitItem;
    }

    private getUnitMenuItemTitle(unitStructure: UnitStructure, order, courseId, courseRole, contextRole, courseAnnouncementId, unitsItemsTitleExam): any {
        const courseAnnouncement = courseAnnouncementId ? 'course-announcement/' + courseAnnouncementId : '';
        const caId = courseAnnouncementId ? courseAnnouncementId : '';

        // const unitInfo = this.unitsStructure.find(ui => ui.unitId === unitId);
        let unitItem;
        if (unitStructure.titleExam == true && unitsItemsTitleExam != null) {
            unitItem = {

                id: unitStructure.unitId + caId,
                title: `Exámen a título`,
                type: 'collapsable',
                // icon:   'border_all',
                url: `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/detail`,
                children: [],
            };

            unitItem.children = unitStructure.lessons.map((lessonId, idx) => ({
                id: lessonId,
                title: `Página ${idx + 1}`,
                type: 'item',
                url: `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/${unitStructure.unitId}/${lessonId}/lesson`
            }));

            const forumItem = {
                id: unitStructure.unitId + caId + '-forum',
                title: 'Foro',
                type: 'item',
                url: `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/${unitStructure.unitId}/forum`
            };
            const activitiesItem = {
                id: unitStructure.unitId + caId + '-activity-group',
                title: `Actividades`,
                type: 'collapsable',
                children: [],
            };

            activitiesItem.children = unitStructure.activities.map((activityId, idx) => ({
                id: unitStructure.unitId + caId + '-activity-' + activityId,
                title: `Actividad ${idx + 1}`,
                type: 'item',
                url: this.getUrlForActivity(courseId, order, unitStructure.unitId, activityId, courseRole, contextRole, courseAnnouncementId)
            }));
            const syncClassItem = {
                id: unitStructure.unitId + '-sync-class',
                title: 'Clase sincrónica',
                type: 'item',
                url: `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/${unitStructure.unitId}/56/lesson`
            };
            const materialItem = {
                id: unitStructure.unitId + '-material',
                title: 'Material de apoyo',
                type: 'item',
                url: `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/${unitStructure.unitId}/55/lesson`
            };
            unitItem.children.push(syncClassItem);
            unitItem.children.push(materialItem);
            unitItem.children.push(forumItem);
            if (activitiesItem.children.length > 0) {
                unitItem.children.push(activitiesItem);
            }

            return unitItem;

        // } else if (unitStructure.titleExam == false && unitsItemsTitleExam != null) {
        //     const unitItem1 = {
        //         id: unitStructure.unitId + caId,
        //         title: `Tema ${order}`,
        //         type: 'collapsable',
        //         // icon:   'border_all',
        //         url: `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/detail`,
        //         children: [],
        //     };
        //     unitItem1.children = unitStructure.lessons.map((lessonId, idx) => ({
        //         id: lessonId,
        //         title: `Página ${idx + 1}`,
        //         type: 'item',
        //         url: `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/${unitStructure.unitId}/${lessonId}/lesson`
        //     }));
        //
        //     const forumItem = {
        //         id: unitStructure.unitId + caId + '-forum',
        //         title: 'Foro',
        //         type: 'item',
        //         url: `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/${unitStructure.unitId}/forum`
        //     };
        //     const activitiesItem = {
        //         id: unitStructure.unitId + caId + '-activity-group',
        //         title: `Actividades`,
        //         type: 'collapsable',
        //         children: [],
        //     };
        //
        //     activitiesItem.children = unitStructure.activities.map((activityId, idx) => ({
        //         id: unitStructure.unitId + caId + '-activity-' + activityId,
        //         title: `Actividad ${idx + 1}`,
        //         type: 'item',
        //         url: this.getUrlForActivity(courseId, order, unitStructure.unitId, activityId, courseRole, contextRole, courseAnnouncementId)
        //     }));
        //     const syncClassItem = {
        //         id: unitStructure.unitId + '-sync-class',
        //         title: 'Clase sincrónica',
        //         type: 'item',
        //         url: `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/${unitStructure.unitId}/56/lesson`
        //     };
        //     const materialItem = {
        //         id: unitStructure.unitId + '-material',
        //         title: 'Material de apoyo',
        //         type: 'item',
        //         url: `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/${unitStructure.unitId}/55/lesson`
        //     };
        //     unitItem1.children.push(syncClassItem);
        //     unitItem1.children.push(materialItem);
        //     unitItem1.children.push(forumItem);
        //     if (activitiesItem.children.length > 0) {
        //         unitItem1.children.push(activitiesItem);
        //     }
        //     return unitItem1;
        }
    }



    private addEvaluationMenu(course: Course, itemChildren: any[], menuTemplate: any[], courseAnnouncementId):
        void {
        // if (!course.userCourseState || course.userCourseState === UserCourseState.STARTED) { return; }
        const courseAnnouncement = courseAnnouncementId ? 'course-announcement/' + courseAnnouncementId : '';

        menuTemplate[3].url = `/courses/${courseAnnouncement}/course/${course.id}/evaluate`;
        menuTemplate[3].hidden = course.studentCourseState !== UserCourseState.TO_EVALUATE;
        itemChildren.push(menuTemplate[3]);
        menuTemplate[4].url = `/courses/${courseAnnouncement}/course/${course.id}/certificate`;
        menuTemplate[4].hidden = course.studentCourseState !== UserCourseState.EVALUATED;
        itemChildren.push(menuTemplate[4]);

    }

    private getUrlForActivity(courseId, order, unitId, activityId, courseRole: CourseRole, contextRole, courseAnnouncementId):
        string {
        const courseAnnouncement = courseAnnouncementId ? 'course-announcement/' + courseAnnouncementId : '';

        const vCourseRole = courseRole === CourseRole.STUDENT ? 'student' : 'teacher';
        return `courses/${courseAnnouncement}/course/${courseId}/preview/${contextRole}/${order + 1}/${unitId}/project/${activityId}/${vCourseRole}`;
    }

    async hideTitleExam(courseId: string, courseAnnouncementId: string ): Promise<any> {

        if (courseId != null) {
            if (this.isStudent) {
                let courseannouncement = courseAnnouncementId;
                if (courseAnnouncementId === undefined){
                    courseannouncement = 'Undefined';
                }
                const courseShowGenral = await this.courseStudentRemoteService.hideTitleExamOwnedCourses(courseId, courseannouncement).toPromise();
                return  courseShowGenral.titleExamHideForStudent;

            } else if (!this.isStudent) {
                // this.courseShowGenral = await this.unitRemoteService.getByCourseIdContainsOneTitleExamOne(this.courseId).toPromise();
                // if (this.courseShowGenral != null){
                //     this.titleExamHideForStudent = this.courseShowGenral.id;
                // }

                return true;
            }
        }

        return false;
    }


}
