import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Profile} from '../../models/profile';
import {RestClientService} from '../../../../../../core/services/rest-client.service';
import {Deleteable, Retrieveable} from '../../../../../../core/services/remote-services-interfaces';
import {Institution} from '../../../../../models/institution';
import {CourseCategory} from '../../../../../models/course-category';


@Injectable({
    providedIn: 'root'
})
export class ProfileRemoteService implements Retrieveable<any, string>, Deleteable<any, string> {
    private URL = '/api/profile';

    constructor(private restClientService: RestClientService) {
    }

    updateAvatar(imageId: string): Observable<any> {
        return this.restClientService.put(`${this.URL}/avatar`, imageId);
    }

    updateCover(imageId: string): Observable<any> {
        return this.restClientService.put(`${this.URL}/cover`, imageId);
    }

    updateAboutYou(data: any): Observable<any> {
        return this.restClientService.put(`${this.URL}/about-you`, data);
    }

    updateCategories(categoryIds: any): Observable<any> {
        return this.restClientService.put(`${this.URL}/categories`, categoryIds);
    }

    updateProfessional(data: any): Observable<any> {
        return this.restClientService.put(`${this.URL}/professional`, data);
    }

    updateInstitution(data: any): Observable<any> {
        return this.restClientService.put(`${this.URL}/institution`, data);
    }

    updateContact(data: any): Observable<any> {
        return this.restClientService.put(`${this.URL}/contact`, data);
    }

    get(id: string): Observable<Profile> {
        return this.restClientService.get(`${this.URL}/${id}`);
    }

    getGeneral(id: string): Observable<Profile> {
        return this.restClientService.get(`${this.URL}/${id}/general`);
    }

    getGeneralWhithCategs(id: string): Observable<Profile> {
        return this.restClientService.get(`${this.URL}/${id}`);
    }

    getInstitutions(id: string): Observable<Institution[]> {
        return this.restClientService.get(`${this.URL}/${id}/institutions`);
    }

    delete(id: string): Observable<CourseCategory> {
        return this.restClientService.delete(`${this.URL}/${id}`);
    }

    deleteInstitution(institutionId: string): Observable<any> {
        return this.restClientService.delete(`${this.URL}/${institutionId}`);
    }

    teacherComments(id: any, criteria: any): Observable<any> {
        return this.restClientService.get(`${this.URL}/teacher-comments/${id}`, criteria);
    }

    setVisited(): Observable<any> {
        return this.restClientService.put(`${this.URL}/visited`);
    }

    checkVerification(): Observable<any> {
        return this.restClientService.get(`${this.URL}/check-email-confirmation`);
    }
}
