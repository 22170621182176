<button mat-button fxShow.gt-xs class="language-button" [matMenuTriggerFor]="languageMenu">
    <div fxLayout="row" fxLayoutAlign="center center">
        <img [src]="'assets/icons/flags/' + selectedCountry.flag + '.png'" class="flag mr-8 other">
        <span class="iso" fxHide fxShow.gt-sm>{{ selectedCountry.title }}</span>
        <mat-icon>keyboard_arrow_down</mat-icon>
    </div>
</button>

<mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

    <button mat-menu-item *ngFor="let country of countries" (click)="setLanguage(country)">
        <span fxLayout="row" fxLayoutAlign="start center">
            <img class="flag mr-16 other" [src]="'assets/icons/flags/' + country.flag + '.png'">
            <span class="iso">{{ country.title }}</span>

        </span>
    </button>

</mat-menu>