import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ContentBlockVideoApp} from '../../../block-view/models/content-block-video-app';
import {FDropZoneImageFieldComponent} from '../../../../core/components/f-drop-zone-image/f-drop-zone-image-field.component';
import {ContentBlockTypeBase} from '../block-type-base';

@Component({
    selector: 'app-content-block-audio-app-edit',
    templateUrl: './content-block-audio-app-edit.component.html',
})
export class ContentBlockAudioAppEditComponent implements OnInit, ContentBlockTypeBase {
    @Input() form: FormGroup;
    @Input() model: ContentBlockVideoApp;
    @Input() disabledBlock = false;

    @ViewChild('images', {read: FDropZoneImageFieldComponent, static: true})
    imageComponent: FDropZoneImageFieldComponent;

    constructor(private fb: FormBuilder) {
    }

    ngOnInit(): void {
        this.form.addControl('audioUrl', this.fb.control('', [Validators.required]));
        this.form.addControl('expiration', this.fb.control(0));
        this.form.addControl('image', this.fb.control(null));

        this.form.controls['audioUrl'].disable();
        this.form.patchValue(this.model);
        this.setImage(this.model.image);
    }

    changeToDisable(disable: boolean): void {
        if (disable) {
            this.form.controls['audioUrl'].disable();
            this.imageComponent.disabled = true;
        } else {
            this.form.controls['audioUrl'].enable();
            this.imageComponent.disabled = false;
        }
    }

    updateData(data): void {
        this.setImage(data.image);
    }

    onImageChange(event): void {
        const images = this.imageComponent.files.filter((f: any) => !f.metaFromServer);
        const mm = images.map(file => ({file: file}));
        this.form.get('image').setValue(mm[0]);
    }

    onFileRemoved(fileData: any): void {
        this.form.get('image').setValue(null);
    }

    private setImage(image: any): void {
        this.form.get('image').setValue(null);
        this.imageComponent.files = [];
        this.imageComponent.filesPreview = [];
        if (image) {
            this.imageComponent.addFile(image);
        }
    }

}
