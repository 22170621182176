<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">

            <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                <ng-container>
                    <mat-icon>arrow_right</mat-icon>
                    <ng-container>
                        <span *ngIf="!loading && total > 1">{{ total }} Personas</span>
                        <span *ngIf="!loading && total == 1">{{ total }} Persona</span>
                        <span *ngIf="!loading && total == 0"> No hay resultados</span>
                    </ng-container>
<!--                    <ng-container *ngIf="isTeacher && !isBothRoles">-->
<!--                        <span *ngIf="!loading && total > 1">{{ total }} Estudiantes</span>-->
<!--                        <span *ngIf="!loading && total == 1">{{ total }} Estudiante</span>-->
<!--                        <span *ngIf="!loading && total == 0"> No hay resultados</span>-->
<!--                    </ng-container>-->
<!--                    <ng-container *ngIf="!isTeacher && !isBothRoles">-->
<!--                        <span *ngIf="!loading && total > 1">{{ total }} Usuarios</span>-->
<!--                        <span *ngIf="!loading && total == 1">{{ total }} Usuarios</span>-->
<!--                        <span *ngIf="!loading && total == 0"> No hay resultados</span>-->
<!--                    </ng-container>-->
                </ng-container>
            </div>

            <button mat-icon-button (click)="dialogRef.close()" aria-label="Close">
                <mat-icon>close</mat-icon>
            </button>

        </div>
    </mat-toolbar>

    <div mat-dialog-content class="p-0 m-0">

        <div fxLayout="column">
            <div *ngIf="isTeacher || isBothRoles || isStudent" class="search-wrapper" fxFlex fxLayout="row" fxLayoutAlign="start center">
                <div class="search" [ngClass]="{disabled: loading}" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon class="s-16 secondary-text">search</mat-icon>
                    <input [formControl]="filter" type="text" placeholder="Buscar Miembros" fxFlex>
                </div>
            </div>

            <div *ngIf="!loading; else spinnerTemplate" class="testing" fxLayout="column" fusePerfectScrollbar>
                <ng-container *ngIf="users">
                    <div fxLayout="column" *ngFor="let user of users">

<!--                        componenete followed-like-->
                        <app-followed-like *ngIf="user"
                                           [profile]="user"
                                           [showButtons]='false'
                                            (changed)="closeDialog($event)">
                        </app-followed-like>


                    </div>
                </ng-container>
                <div *ngIf="(total - (size * (page+1)) ) >= users?.length && users?.length!=0" class="my-16"
                     fxLayout="column">
                    <button mat-raised-button color="accent" (click)="viewMore()">Ver más</button>
                </div>
            </div>
        </div>

    </div>

</div>

<ng-template #spinnerTemplate>
    <div class="p-16">
        <div class="text-center" style="margin-top: 2em">
            <div style="display: inline-block">
                <mat-progress-spinner [diameter]="50" color="accent" mode="indeterminate"></mat-progress-spinner>
            </div>
        </div>
    </div>
</ng-template>
