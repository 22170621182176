<mat-toolbar color="primary">
    <mat-toolbar-row>
        <span>{{ title }}</span>        
        <mat-icon class="dialog-icon" svgIcon="close" (click)="dialogRef.close()"></mat-icon>
    </mat-toolbar-row>
</mat-toolbar>

<div class="content-center ">
    <div class="container-details col-xs-12 scrollable">
        <form [formGroup]="form" class="padding-top-10">
            <mat-form-field class="input-container col-sm-12">
                <input autofocus matInput [matAutocomplete]="filterListAuto" placeholder="{{name}}" formControlName="value" (keypress)="keyPress($event)">
                <mat-autocomplete #filterListAuto="matAutocomplete"  [displayWith]="displayFn" (optionSelected)="selectedOption($event)">
                    <mat-option *ngFor="let item of itemsReactives | async" [value]="item">
                        <span>{{ item[keyValue] }}</span>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </form>
    </div>
</div>

<div class="bottom-context" >
    <div class="pull-right">
        <button mat-raised-button color="warn" class="text-uppercase margin-right-10" (click)="dialogRef.close()">
            Cancelar
        </button>
        <button mat-raised-button color="accent" class="text-uppercase" (click)="acepted()" [disabled]="!form.valid">
            Aceptar
        </button>
    </div>
</div>