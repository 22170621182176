import {OnMarkAsRead, ProcessNotification} from '../user-notification.service';
import {UnitCount} from '../model/UnitCount';

export class UnitProjectEvaluationHandler implements ProcessNotification {

    processNotification(notification, data, roleContext): void {
        const courseId = notification.group1;
        const unitId = notification.group2;
        const activityId = notification.group3;
        const courseAnnouncementUrl = (notification.group6) ? `course-announcement/${notification.group6}/` : '';

        notification.text = `Proyecto evaluado. Curso ${data.courseName}`;

        notification.url = `/courses/${courseAnnouncementUrl}course/${courseId}/preview/${roleContext}/${data.unitOrder}/${unitId}` +
            `/project/${activityId}/student?r=preview&projectId=${data.projectId}`;

        notification.icon = 'assignment';
        notification.readStrategy = 'other';
    }
    //
    // onMarkAsRead(notification, countInfo: UnitCount[]): void {
    //     const unitId = notification.group2;
    //     const unitCount = countInfo.find(i => i.id === unitId);
    //     const activityCount = unitCount.activities.find(a => a.id === notification.group3);
    //     activityCount.projectCount = 0;
    //     activityCount.updateTotalCount();
    //     unitCount.updateTotalCount();
    // }

}
