<div class="dialog-content-wrapper">
    <mat-toolbar style="  justify-content: center;" matDialogTitle class="mat-accent m-0">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">

            <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                <span class="title dialog-title">
                    {{ this.componentInstance.editFlag ?
                        (this.componentInstance.titleConfig?.title)
                        : (this.componentInstance.titleConfig?.title | translate) }}
                </span>

                <div class="ml-16" fxFlex="0 1 auto" *ngIf="this.componentInstance.editFlag">
                    <button mat-icon-button aria-label="View" *ngIf="!readOnly" (click)="toggleEditMode()">
                        <mat-icon>remove_red_eye</mat-icon>
                    </button><button mat-icon-button aria-label="Edit" *ngIf="readOnly" (click)="toggleEditMode()">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button aria-label="Delete" (click)="delete()">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>

            <button mat-icon-button (click)="dialogRef.close()" aria-label="Close">
                <mat-icon>close</mat-icon>
            </button>

        </div>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
        <ng-template #childComponent></ng-template>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button mat-button class="save-button" (click)="save()" [disabled]="!validForm" aria-label="SAVE">
            {{ 'core.save' | translate }}
        </button>

    </div>
</div>
