import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {CompoundResource} from '../../../../../../models/compound-resource';
import {DialogService} from '../../../../../../../core/services/dialog.service';
import {CompoundResourceMetadataDialogComponent} from './metadata-dialog/compound-resource-metadata-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-project-resource',
    templateUrl: './project-resource.component.html',
    styleUrls: ['./project-resource.component.scss'],
})
export class ProjectResourceComponent implements OnInit {
    private readonly MAX_FILE_SIZE = 10 * 1024 * 1024;
    private readonly MAX_FILE_SIZE_LABEL = '10MB';
    private metadataDialog: MatDialogRef<CompoundResourceMetadataDialogComponent>;
    initialResources: CompoundResource[];
    fileResources: any[];
    linkResources: any[];
    resourceAdded: (data: any) => Promise<any>;
    resourceDeleted: (data: any) => Promise<any>;
    @Input() title;
    @Input() maxFiles = 0;
    @Input() minFiles = 0;
    @Input() showType = true;
    @Input() disabled = false;


    constructor(protected fb: FormBuilder, private dialogService: DialogService, private _matDialog: MatDialog) {
    }

    ngOnInit(): void {
    }

    setInitData(initialResources: CompoundResource[]): void {
        this.initialResources = initialResources;
        this.fileResources = [];
        this.linkResources = [];

        if (this.initialResources) {
            this.fileResources = this.initialResources.filter(i => !i.link);
            this.linkResources = this.initialResources.filter(i => i.link);
        }
    }

    addFileResource(files: FileList, event: Event): void {
        if (files.length === 0) {
            // No file selected, return early
            return;
        }
        if (files[0].size > this.MAX_FILE_SIZE) {
            this.dialogService.showErrorDialog('No se pueden subir recursos de más de ' + this.MAX_FILE_SIZE_LABEL, 'Tamaño excedido');
            return;
        }

        if (this.checkForMaxFiles(this.linkResources.length + this.fileResources.length + 1)) {
            return;
        }

        this.showMetadataDialog({file: files[0], name: files[0].name}, 'file');

        // if (!this.checkForMaxFiles(this.linkResources.length + this.fileResources.length + 1)) {
        //     this.showMetadataDialog({file: files[0], name: files[0].name}, 'file');
        // }
    }

    addLinkResource(): void {
        if (!this.checkForMaxFiles(this.linkResources.length + this.fileResources.length + 1)) {
            this.showMetadataDialog({url: null, name: null}, 'link');
        }
    }

    async deleteResource(res, from: 'file' | 'link', idx: number): Promise<void> {
        if (!this.checkForMinFiles(this.linkResources.length + this.fileResources.length - 1)) {
            this.dialogService.showConfirmDialog(`¿Estás seguro que deseas eliminar el ${from === 'link' ? 'enlace' : 'archivo'}?`, () => {
                this.resourceDeleted(res).then(() => {
                    const list = from === 'file' ? this.fileResources : this.linkResources;
                    list.splice(idx, 1);
                });
            });
        }
    }

    showMetadataDialog(data, from: 'file' | 'link', idx?: number): void {
        this.metadataDialog = this._matDialog.open(CompoundResourceMetadataDialogComponent, {
            panelClass: 'lesson-add-dialog',
            restoreFocus: false,
            disableClose: true,
            // width: '400px',
            autoFocus: false,
            data: {data: data, handler: this.resourceAdded, showType: this.showType},
        });

        this.metadataDialog.afterClosed().subscribe(res => {
            if (res) {
                this.addOrUpdateToList(idx, res, from === 'file' ? this.fileResources : this.linkResources);
            }
        });
    }

    getAllResourcesCount(): number {
        return this.linkResources.length + this.fileResources.length;
    }

    private addOrUpdateToList(idx: number, res, list: any[]): void {
        if (idx !== undefined) {
            list.splice(idx, 1, res);
        } else {
            list.push(res);
        }
    }

    private checkForMaxFiles(count: number): boolean {
        const result = this.maxFiles !== 0 && count > this.maxFiles;
        if (result) {
            this.dialogService.showErrorDialog(`Sólo puede subir hasta ${this.maxFiles} archivos`, 'Máximo de archivos excedido');
        }
        return result;
    }

    private checkForMinFiles(count: number): boolean {
        const result = this.minFiles !== 0 && count < this.minFiles;
        if (result) {
            this.dialogService.showErrorDialog(`Debe tener al menos ${this.minFiles} archivos`, 'Mínimo de archivos requerido');
        }
        return result;
    }

}
