import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CodeVerifierGeneratorService {
    private readonly CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';

    constructor() {
    }

    getCode(): string {
        const length = this.randomIntFromInterval(43, 128);
        let value = '';
        for (let i = 0; i < length; i++) {
            value += this.CHARACTERS.charAt(Math.floor(Math.random() * this.CHARACTERS.length));
        }
        return value;
    }

    private randomIntFromInterval(min, max): number {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

}
