import {InscriptionCourseHandler} from './handlers/inscription-course.handlers';
import {ApprovedPaymentOrderHandler} from './handlers/approved-payment-order.handler';
import {UnitForumMessageHandler} from './handlers/unit-forum-message.handler';
import {SuggestCourseHandler} from './handlers/suggest-course.handler';
import {UnitProjectFeedbackHandler} from './handlers/unit-project-feedback.handler';
import {UnitProjectEvaluationHandler} from './handlers/unit-project-evaluation.handler';
import {UnitProjectCreateHandler} from './handlers/unit-project-create.handler';
import {InscriptionCourseToTeacherHandlers} from './handlers/inscription-course-to-teacher.handlers';
import {NotificationAdHandlers} from './handlers/notification-ad.handlers';
import {NotificationCourseAnnouncementHandlers} from './handlers/notification-course-announcement.handlers';
import {NotificationNewPostHandlers} from "./handlers/notification-new-post-space.handlers";

export class UserNotificationHandlerInitService {

    initHandlers(handlers): void {
        handlers[NotificationTypes.UNIT_FORUM_NEW_MESSAGE]              = new UnitForumMessageHandler();
        handlers[NotificationTypes.UNIT_PROJECT_FORUM_NEW_MESSAGE]      = new UnitProjectFeedbackHandler();
        handlers[NotificationTypes.UNIT_PROJECT_CREATE]                 = new UnitProjectCreateHandler();
        handlers[NotificationTypes.UNIT_PROJECT_EVALUATION]             = new UnitProjectEvaluationHandler();
        handlers[NotificationTypes.SUGGEST_COURSE]                      = new SuggestCourseHandler();
        handlers[NotificationTypes.APPROVED_PAYMENT_ORDER]              = new ApprovedPaymentOrderHandler();
        handlers[NotificationTypes.INSCRIPTION_COURSE]                  = new InscriptionCourseHandler();
        handlers[NotificationTypes.INSCRIPTION_COURSE_NOTIFICATION_TEACHER]                  = new InscriptionCourseToTeacherHandlers();
        handlers[NotificationTypes.NEW_ADS_TO_STUDENTS_NOTIFICATION]                 = new NotificationAdHandlers();
        handlers[NotificationTypes.NEW_COURSE_ANNOUNCEMENT_TO_STUDENTS_NOTIFICATION]                 = new NotificationCourseAnnouncementHandlers();
        handlers[NotificationTypes.NEW_POST_TO_STUDENTS_NOTIFICATION]                 = new NotificationNewPostHandlers();
        handlers[NotificationTypes.NEW_POST_TO_MODELATOR_NOTIFICATION]                 = new NotificationNewPostHandlers();
    }

}

export class NotificationTypes {
    static readonly UNIT_FORUM_NEW_MESSAGE              = 'UNIT_FORUM_NEW_MESSAGE';
    static readonly UNIT_PROJECT_FORUM_NEW_MESSAGE      = 'UNIT_PROJECT_FORUM_NEW_MESSAGE';
    static readonly UNIT_PROJECT_CREATE                 = 'UNIT_PROJECT_CREATE';
    static readonly UNIT_PROJECT_EVALUATION             = 'UNIT_PROJECT_EVALUATION';
    static readonly SUGGEST_COURSE                      = 'SUGGEST_COURSE';
    static readonly APPROVED_PAYMENT_ORDER              = 'APROVED_PAYMENT_ORDER';
    static readonly INSCRIPTION_COURSE                  = 'INSCRIPTION_COURSE';
    static readonly INSCRIPTION_COURSE_NOTIFICATION_TEACHER           = 'INSCRIPTION_COURSE_NOTIFICATION_TEACHER';
    static readonly NEW_ADS_TO_STUDENTS_NOTIFICATION                  = 'NEW_ADS_TO_STUDENTS_NOTIFICATION';
    static readonly NEW_COURSE_ANNOUNCEMENT_TO_STUDENTS_NOTIFICATION  = 'NEW_COURSE_ANNOUNCEMENT_TO_STUDENTS_NOTIFICATION';
    static readonly NEW_POST_TO_STUDENTS_NOTIFICATION  = 'NEW_POST_TO_STUDENTS_NOTIFICATION';
    static readonly NEW_POST_TO_MODELATOR_NOTIFICATION  = 'NEW_POST_TO_MODELATOR_NOTIFICATION';
}
