import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {LangCurrencyService} from '../../core-common/services/lang-currency.service';

@Pipe({
    name: 'appCurrency',
})
export class AppCurrencyPipe implements PipeTransform {

    constructor(private currencyPipe: CurrencyPipe, private langCurrencyService: LangCurrencyService) {
    }

    public transform(value: any, currencyCode?: string): any {
        const currCode = currencyCode || this.langCurrencyService.getCurrent().currency;
        return this.currencyPipe.transform(value, currCode, 'symbol-narrow', '1.0-0');
    }

}
