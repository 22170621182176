import {ProcessNotification} from '../user-notification.service';

export class SuggestCourseHandler implements ProcessNotification {

    processNotification(notification, data, roleContext): void {

        notification.text = `${data.userFirstName} ${data.userLastName} te recomienda el curso ${data.courseName}`;
        notification.url = `/courses/course/${data.courseId}/preview/${roleContext}/0/detail`;
        notification.icon = 'school';

    }
}
