import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FileType} from '../file-type';
import {ImageRemoteService} from '../../../../../../../../core/services/image.remoteservice';
import {AppValidators} from '@quiox/global/helpers/app.validators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-compound-resource-metadata-dialog',
    templateUrl: './compound-resource-metadata-dialog.component.html',
    styleUrls: ['./compound-resource-metadata-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CompoundResourceMetadataDialogComponent {
    form: FormGroup;
    file: File;
    uploading = false;
    isLink = false;
    showType: boolean;

    buttonClicked = false;

    constructor(public dialogRef: MatDialogRef<any>, private fb: FormBuilder,
                private imageRemoteService: ImageRemoteService,
                @Inject(MAT_DIALOG_DATA) private _data: any) {

        this.form = this.fb.group({
            id: [null],
            name: [null, [Validators.required]],
            type: ['other'],
        });

        this.showType = _data.showType !== undefined ? _data.showType : true;

        if (_data.data.url !== undefined) {
            this.form.addControl('url', this.fb.control(null, [AppValidators.url]));
            this.isLink = true;
        }

        if (_data.data) {
            if (!this.isLink && !_data.data.id) {
                _data.data.name = _data.data.name.replace(/\.[^/.]+$/, '');
            }

            this.form.patchValue(_data.data);
        }

        this.file = _data.data.file;
    }

    get typeInfo(): any {
        return FileType.types[this.form.get('type').value];
    }

    get fileTypes(): any {
        return FileType.types;
    }

    async save(): Promise<void> {
        this.uploading = true;
        this.form.disable();

        const dataToSend = {...this.form.value, link: this.isLink};
        if (this.file) {
            const response = await this.imageRemoteService.save(this.file).toPromise();
            dataToSend.file = {id: response.data};
        }

        let data = dataToSend;
        if (this._data.handler) {
            try {
                data = await this._data.handler(dataToSend);
            } catch (reason) {
            }
        }

        this.dialogRef.close(data);
        this.uploading = false;
        this.form.enable();
    }
}
