<div class="image-preview">
    <div *ngIf="!imagePreview && !initialData" class="image-preview-default w-500" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>photo_size_select_actual</mat-icon>
        <div class="image-preview-default-text">Puede arrastrar imagen</div>
    </div>
    <div *ngIf="!imagePreview && initialData" class="image-container">
        <img [src]="initialData" alt="Imagen">
    </div>
    <div *ngIf="imagePreview" class="image-container image-light" (click)="removeImage()">
        <img [src]="imagePreview" alt="Imagen">
        <div class="close-container">
            <mat-icon>close</mat-icon>
        </div>
    </div>
</div>
<div class="mt-8">
    <input hidden type="file" #fileInput accept="image/*" (change)="onFileChange(fileInput.files, $event)"/>
    <button mat-raised-button aria-label="Seleccionar Imagen" (click)="fileInput.click()">
        <mat-icon class="mr-8">photo</mat-icon>
        <span>Seleccionar Imagen</span>
    </button>
</div>