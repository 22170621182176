import {Component} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {DialogFilterListService} from './dialog-filter-list.service';
import {AppValidators} from '@quiox/global/helpers/app.validators';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'dialog-filter-list',
    templateUrl: './dialog-filter-list.component.html',
    styleUrls: ['./dialog-filter-list.component.scss'],

})
export class DialogFilterListComponent {
    title = 'Seleccione un elemento';
    items: any[] = [];
    itemsReactives: any;
    name: any;
    keyId: any;
    keyValue: any;
    selected: any;
    acept = false;
    url: any;
    form: FormGroup;

    constructor(public dialogRef: MatDialogRef<any>, public filterlistService: DialogFilterListService, fb: FormBuilder) {
        this.form = fb.group({
            value: [null, [Validators.required, AppValidators.isAnObject]]
        });

        this.itemsReactives = this.filterStates('');
        this.form.get('value').valueChanges.subscribe(res => {
            if (res !== null && typeof res === 'object') {
                this.itemsReactives = this.filterStates(res);
            }
        });

        setTimeout(_ => this.form.get('value').setValue(''));
    }

    async filterStates(val: string) {
        if (!this.url) {
            return val ? this._filter(this.items, val) : this.items;
        } else {
            val = val || '';
            const vialidades = await this.filterlistService.get(this.url, val);
            return vialidades;
        }
    }

    displayFn(value: any): string {
        return value && typeof value === 'object' ? (value.nombreCompleto || value.name) : value;
    }

    private _filter(states, val: string) {
        const filterValue = val.toLowerCase();
        return states.filter(state => state[this.keyValue].toLowerCase().indexOf(filterValue) >= 0);
    }

    selectedOption(item) {
        this.selected = item.option.value;
    }

    keyPress(event) {
        if (event.key === 'Enter' && this.form.valid) {
            this.acepted();
        }
    }

    acepted() {
        this.acept = true;
        this.dialogRef.close();
    }

}
