import {Component, Inject, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import {FNomenclatorFormComponent} from '../f-nomenclator-form.component';
import {DialogService} from '../../services/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-f-dialog-nomenclator',
    templateUrl: './f-dialog-nomenclator.component.html',
    styleUrls: ['./f-dialog-nomenclator.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FDialogNomenclatorComponent implements OnInit {
    validForm = false;
    readOnly = false;
    componentInstance: FNomenclatorFormComponent;

    @ViewChild('childComponent', {read: ViewContainerRef, static: true})
    childComponent: ViewContainerRef;

    constructor(public dialogRef: MatDialogRef<any>, private dialogService: DialogService,
                @Inject(MAT_DIALOG_DATA) private _data: any, private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.componentLoader();
    }

    private componentLoader(): void {
        if (this._data.factory) {
            this.childComponent.clear();
            const componentRef = this.childComponent.createComponent<FNomenclatorFormComponent>(this._data.factory);
            this.componentInstance = componentRef.instance;

            this.componentInstance.form.valueChanges.subscribe(res => {
                this.validForm = this.componentInstance.form.valid;
            });

            if (this._data.model) {
                this.componentInstance.editFlag = true;
                this.componentInstance.form.patchValue(this._data.model);
                this.componentInstance.onModelLoaded(this._data.model);
                this.toggleEditMode();
            }

            this.componentInstance.closeBehavior.subscribe(res => {
                if (res) {
                    // this.saved = true;
                    this.dialogRef.close();
                }
            });
        }
    }

    async save(): Promise<any> {
        const data = await this.componentInstance.save();
        if (data.success) {
            // this.saved = true;
            if (!this.componentInstance.keepOpen) {
                this.dialogRef.close();
            }
        }
    }

    delete(): void {
        this.dialogService.showConfirmDialog('', () => this.componentInstance.removeConfirm());
        // this.saved = true;
        // this.dialogRef.close();
    }

    toggleEditMode(): void {
        this.readOnly = !this.readOnly;
        const titleConfig = this.componentInstance.getEditTitleConfig();
        if (this.readOnly) {
            this.componentInstance.form.disable();
            titleConfig.title = this.translateService.instant('core.detail') + ' ' + this.translateService.instant(titleConfig.title);
        } else {
            this.componentInstance.form.enable();
            this.componentInstance.form.get('code').disable();
            titleConfig.title = this.translateService.instant('core.edit') + ' ' + this.translateService.instant(titleConfig.title);
        }
        this.componentInstance.titleConfig = titleConfig;
    }
}
