import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClientService} from '../../../core/services/rest-client.service';

@Injectable({
    providedIn: 'root'
})
export class CourseTeacherMemberRemoteService {
    private URL = '/api/course-teacher-member';

    constructor(private restClientService: RestClientService) {
    }

    create(courseId: string, userIds: string[], courseAnnouncementId): Observable<any> {
        return this.restClientService.post(`${this.URL}/${courseId}`, {userIdList: userIds}, {courseAnnouncementId: courseAnnouncementId}, {handleErr: true});
    }

    delete(courseId: string, userId: string, courseAnnouncementId?): Observable<any> {
        return this.restClientService.delete(`${this.URL}/${courseId}/${userId}`, {courseAnnouncementId: courseAnnouncementId});
    }

    searchMyStudents(): Observable<any[]> {
        return this.restClientService.get(`${this.URL}/my-teachers`);
    }

    setAsPrincipal(courseId, data): Observable<any> {
        return this.restClientService.put(`${this.URL}/${courseId}/set-principal`, data, );
    }

}
