import {Message} from './message';

export class ChatHeader {
    id: string;
    contactId: string;
    name: string;
    nick: string;
    unreadCount: number = 0;
    lastMessage: Message;
    image: string;
    loaded: boolean;
    visible: boolean = true;

    constructor() {
    }

    static fromData(data): ChatHeader {
        const obj = new this();
        obj.id = data.conversationId;
        obj.contactId = data.user.id;
        obj.name = data.user.firstName + ' ' + data.user.lastName;
        // obj.nick = data.user.nick;
        obj.nick = data.user.username;
        obj.unreadCount = data.unreadCount || 0;
        if (data.lastMessage) {
            obj.lastMessage = Message.fromAttr(data.lastMessage.id, data.lastMessage.who, data.lastMessage.message,
                data.lastMessage.created, data.lastMessage.read);
        }
        obj.image = data.user.image || {url: 'assets/images/avatars/profile.jpg'};
        obj.loaded = false;
        return obj;
    }

    static fromId(id): ChatHeader {
        const obj = new this();
        obj.id = id;
        return obj;
    }

    updateMessage(id, text, date, who, read): ChatHeader {
        this.lastMessage = Message.fromAttr(id, text, date, who, read);
        return this;
    }

    updateMessageFromMsg(message: Message): ChatHeader {
        this.lastMessage = message ? Message.fromAttr(message.id, message.who, message.message, message.time, message.read) : undefined;
        return this;
    }

    updateContactInfo(id, name, image): void {
        this.contactId = id;
        this.name = name;
        this.image = image;
    }
}
