import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
    transform(value: string): string {
        const lines = value.split('\n').map((line) => line.trim());
        const truncatedText = lines.slice(0, 3).join('\n'); // Show up to three lines
        return truncatedText;
    }
}
