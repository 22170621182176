<div fxLayout="column" [formGroup]="form">


    <mat-form-field appearance="outline" fxFlex="100">
        <mat-label>Título del recurso</mat-label>
        <textarea matInput formControlName="title" required></textarea>
        <mat-error>El título es requirido!</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="100">
        <mat-label>Src del recurso</mat-label>
        <input matInput formControlName="src" required>
        <mat-error>El src del recurso es requirido!</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="100">
        <mat-label>Tamaño del recurso</mat-label>
        <input matInput formControlName="height" required>
        <mat-error>El tamaño es requirido</mat-error>
    </mat-form-field>



<!--    <div fxLayout="column" [fxHide]="!hideText">-->
<!--        <app-f-text-editor-field [disabled]="disabledBlock" formControlName="text" label="Texto"></app-f-text-editor-field>-->
<!--    </div>-->

</div>