import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
    @Input() eval: any;
    @Input() color = '#f44336';
    @Input() size: any;
    @Input() defaultIcons: boolean;

    heartIcons = {
        empty: 'assets/icons/course-evaluation/emptyheart.svg',
        half: 'assets/icons/course-evaluation/half-heart.svg',
        full: 'assets/icons/course-evaluation/like.svg',
    };

    constructor() {
    }

    ngOnInit(): void {
    }

}
