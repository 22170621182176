import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatInputModule} from '@angular/material/input';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatRadioModule} from '@angular/material/radio';
import {MatChipsModule} from '@angular/material/chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatMenuModule} from '@angular/material/menu';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTabsModule} from '@angular/material/tabs';

@NgModule({
    imports: [
        MatButtonModule,
        MatMenuModule,
        MatPaginatorModule,
        MatIconModule,
        MatCardModule,
        MatChipsModule,
        // MatSliderModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatInputModule,
        // MatGridListModule,
        MatSnackBarModule,
        // MatProgressSpinnerModule,
        MatTooltipModule,
        MatListModule,
        MatDialogModule,
        MatSidenavModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatRadioModule,
        MatTableModule,
        MatTabsModule,
        // MatDialogModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        // MatNativeDateModule,
        MatSelectModule
    ],
    exports: [
        MatButtonModule,
        MatMenuModule,
        MatPaginatorModule,
        MatIconModule,
        MatChipsModule,
        MatCardModule,
        MatSliderModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatInputModule,
        // MatGridListModule,
        MatSnackBarModule,
        // MatProgressSpinnerModule,
        MatTooltipModule,
        MatListModule,
        MatDialogModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatRadioModule,
        MatTableModule,
        MatTabsModule,
        // MatDialogModule,
        MatDatepickerModule,
        // MatNativeDateModule,
        MatSelectModule
    ],
})

export class MaterialModule {
}
