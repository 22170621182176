export const locale = {
    lang: 'en',
    data: {
        core: {
            required: 'Required',
            accessDenied: 'Access Denied',
            enabled: 'Enabled',
            disabled: 'Disabled',
            code: 'Code',
            name: 'Name',
            firstName: 'First name',
            lastName: 'Last name',
            new: 'New',
            edit: 'Edit',
            detail: 'Detail',
            save: 'Save',
            nameRequired: 'Name is required!',
            maxCharacters: 'Max {{count}} characters',
            hours: 'Hours',
            state: 'State',
            description: 'Description',
            date: 'Date',
            file: 'File',
            files: 'Files',
            requiredField: 'Required field',
            text: 'Text',
            image: 'Image',
            video: 'Video',
            cancel: 'Cancel',
            urlNotValid: 'Url not valid!',
            close: 'Close',
            type: 'Type',
            percent: 'Final grade percent',
            attempts: 'Attempts',
            logout: 'Logout',
            changePassword: 'Change Password',
            public: 'Public',
            authType: 'Authentication type',
            ads: 'Ads',
            addAd: 'Add Ad',
            countUses: 'Count uses'
        }
    }
};
