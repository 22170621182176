import {ActivityCount} from './ActivityCount';

export class UnitCount {
    id: string;
    courseAnnouncementId: string;
    forumCount = 0;
    activities: ActivityCount[];
    totalActivitiesCount = 0;
    totalCount = 0;

    constructor(id, courseAnnouncementId) {
        this.id = id;
        this.courseAnnouncementId = courseAnnouncementId;
    }

    updateTotalCount(): void {
        this.totalActivitiesCount = this.activities.map(a => a.totalCount).reduce((prev, curr) => {
            return prev + curr;
        }, 0);

        this.totalCount = this.forumCount + this.totalActivitiesCount;
    }

}
