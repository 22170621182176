import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AppUtils } from '../helpers/app.utils';
import {APP_CONFIG} from "../../app.config";
import {AppConfigService} from "../../main/core/services/app-config.service";





@Injectable({
    providedIn: 'root'
  })
export class DefaultOAuthInterceptor implements HttpInterceptor {


    constructor(private router: Router) {


    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        let url = request.url;
        const config = APP_CONFIG[APP_CONFIG.active_profile];
        const prefix = config.url_gateway;
        const aws = config.url_aws_s3;
        // const api_curso = "https://cursosapi.release.unineuuni.edu.mx";
        // const url_acceso_videoapp = "api/video/access/course";
        const resourceApi = config.api_recurso;
        const chatServerUrl = config.chatUrl;
        const credentialPublic = config.credentialPublic;


        if (url.includes(credentialPublic)) {

            request = request.clone({url: prefix + url});

            return of(true)
                .pipe(
                    switchMap(secure => secure === true ? from(this.getCurrentSession()) : of(false)),
                    map((auth: any) => this.checkAccess(request, url, credentialPublic)), // switchMap() is used instead of map().
                    switchMap(req => next.handle(req)),
                    catchError(err => {
                        // Verificar para incluir
                        // this.snackBarService.verification(err);
                        return throwError(err);
                    })
                );
        }else if (!url.includes(chatServerUrl)){

            if (!url.startsWith(aws)) {


                if (!url.startsWith("/")) {
                    url = '/' + url;
                }


                if (url.includes('assets')) {

                } else if (!url.includes(prefix)) {

                    request = request.clone({url: prefix + url});
                }
            }  else{

                return of(true)
                    .pipe(
                        switchMap(secure => secure === true ? from(this.getCurrentSession()) : of(false)),
                        map((auth: any) => this.checkAws(request, url, aws)), // switchMap() is used instead of map().
                        switchMap(req => next.handle(req)),
                        catchError(err => {
                            // Verificar para incluir
                            // this.snackBarService.verification(err);
                            return throwError(err);
                        })
                    );
            }
        }else if (url.includes(chatServerUrl)) {

            request = request.clone({url:  url});

            return of(true)
                .pipe(
                    switchMap(secure => secure === true ? from(this.getCurrentSession()) : of(false)),
                    map((auth: any) => this.checkOAuthToken(auth, request, url, chatServerUrl)), // switchMap() is used instead of map().
                    switchMap(req => next.handle(req)),
                    catchError(err => {
                        // Verificar para incluir
                        // this.snackBarService.verification(err);
                        return throwError(err);
                    })
                );
        }


        return of(true)
            .pipe(
                switchMap(secure => secure === true ? from(this.getCurrentSession()) : of(false)),
                map((auth: any) => this.checkOAuthToken(auth, request, url, aws)), // switchMap() is used instead of map().
                switchMap(req => next.handle(req)),
                catchError(err => {
                    // Verificar para incluir
                    // this.snackBarService.verification(err);
                    return throwError(err);
                })
            );

    }


    private replaceURL(originalURL: string, subURL: string, replacementURL: string): string {
        if (originalURL.includes(subURL)) {
            return originalURL.replace(originalURL, replacementURL);
        }
        return originalURL;
    }


    // private getCurrentSession(): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         console.log("access_token", AppUtils.load("access_token"))
    //         console.log("user access_token", AppUtils.load("currenUser"))
    //         resolve(AppUtils.load("access_token"));
    //     });
    // }

    private async getCurrentSession(): Promise<any> {
        try {
            const accessToken = AppUtils.load("access_token");


            const currentUser = await AppUtils.load("currentUser");


            // Si solo necesitas resolver con el accessToken, puedes hacerlo aquí
            return accessToken;

            // Si necesitas resolver con más información, puedes hacer algo como esto:
            // return { accessToken, currentUser };
        } catch (error) {
            console.error("Error al cargar la sesión actual:", error);
            throw error; // Puedes manejar el error según tus necesidades
        }
    }

    private checkOAuthToken(auth, request, url, aws): HttpRequest<any> {
        // if (!url.startsWith(aws)){
        //     console.log('Entroooo a check url AWS');
        //     return request;
        // }
        if (auth !== false) {
            const jwt = auth;
            const with_auth_request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${jwt}`
                }
            });
            return with_auth_request;
        }
        return request;
    }


    private checkAws(request: HttpRequest<any>, url: string, aws: string): HttpRequest<any> {
        if (!url.startsWith(aws)) {
            return request;
        }
        return request;

    }

    private checkAccess(request: HttpRequest<any>, url: string, urlFinal: string): HttpRequest<any> {
        return request;
    }


}


