import {Image} from '../../core/models/image';

export class Institution {
    id: string;
    name: string;
    logo?: Image;
    state?: string;
    url?: string;
    begin?: string;
    end?: string;
    location?: string;

    constructor(item) {
        this.id = item.id || '';
        this.name = item.name || '';
        this.location = item.location || '';
        this.logo = item.logo && item.logo.name ? item.logo : null;
        this.state = item.state === true ? '' : 'En espera de aprobación' || null;
        // this.begin = item.begin == DateService.getDateFromIsoString(new Date()).getFullYear() ? 'Actualmente' : item.begin || null;
        // this.end = item.end == DateService.getDateFromIsoString(new Date()).getFullYear() ? 'Actualmente' : item.end || null;
        this.begin = item.begin || null;
        this.end =  item.end || null;
        this.url =  item.url || null;
    }
}
