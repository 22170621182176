import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FuseConfigService} from '../../../../../@fuse/services/config.service';
import {NgxGalleryAnimation, NgxGalleryComponent, NgxGalleryImage, NgxGalleryOptions} from 'ngx-gallery-9';

@Component({
    selector: 'app-f-ngx-gallery',
    templateUrl: './f-ngx-gallery.component.html',
    styleUrls: ['./f-ngx-gallery.component.scss']
})
export class FNgxGalleryComponent implements OnInit {
    @Input() images: any;
    @Input() from: any;
    @Output() previewChanged: EventEmitter<any>;

    @ViewChild(NgxGalleryComponent)
    ngxGalleryComponent: NgxGalleryComponent;

    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[] = [];

    constructor(private _fuseConfigService: FuseConfigService) {
        this.previewChanged = new EventEmitter();
    }

    ngOnInit(): void {
        this.ngxGallery();
    }

    open(): void {
        this.ngxGalleryComponent.openPreview(0);
    }

    ngxGallery(): void {

        this.ngxGalleryImage();

        if (this.galleryImages.length === 1) {
            this.ngxGalleryOptionsOne();
        } else {
            this.ngxGalleryOptions();
        }
    }

    ngxGalleryOptions(): void {
        this.galleryOptions = [
            {
                width: '100%',
                height: '400px',
                imageAnimation: NgxGalleryAnimation.Slide,
                imageSwipe: true,

                // arrowPrevIcon: 'fa fa-arrow-left',
                // arrowNextIcon: 'fa fa-arrow-right',
                imageArrows: false,

                thumbnails: false,

                previewCloseOnEsc: true,
                previewKeyboardNavigation: true,
                previewCloseOnClick: true,
                previewZoom: true,
                previewRotate: true,
                downloadIcon: 'fa fa-download',
                rotateRightIcon: 'fa fa-undo fa-flip-horizontal',
                previewBullets: true
            },
            // max-width 1370
            {
                breakpoint: 1370,
                width: '100%',
                height: '320px',
                imagePercent: 80
            },
            // max-width 1030
            {
                breakpoint: 1030,
                width: '100%',
                height: '250px',
                imagePercent: 80
            },
            // max-width 700
            {
                breakpoint: 700,
                width: '100%',
                height: '180px',
                imagePercent: 10,
                previewArrows: false,
                previewSwipe: true
            }
        ];
    }

    ngxGalleryOptionsOne(): void {
        this.galleryOptions = [
            {
                width: '100%',
                height: '400px',
                imageAnimation: NgxGalleryAnimation.Slide,
                imageSwipe: true,

                imageArrows: false,
                previewArrows: false,

                // arrowPrevIcon: 'fa fa-arrow-left',
                // arrowNextIcon: 'fa fa-arrow-right',

                thumbnails: false,

                previewCloseOnEsc: true,
                previewKeyboardNavigation: true,
                previewCloseOnClick: true,
                previewZoom: true,
                previewRotate: true,
                downloadIcon: 'fa fa-download',
                rotateRightIcon: 'fa fa-undo fa-flip-horizontal',
                previewBullets: true
            },
            // max-width 1370
            {
                breakpoint: 1370,
                width: '100%',
                height: '320px',
                imagePercent: 80
            },
            // max-width 1030
            {
                breakpoint: 1030,
                width: '100%',
                height: '250px',
                imagePercent: 80
            },
            // max-width 700
            {
                breakpoint: 700,
                width: '100%',
                height: '180px',
                imagePercent: 10,
                previewArrows: false,
                previewSwipe: true
            }
        ];
    }

    ngxGalleryImage(): void {
        this.images.forEach((data: any) => {
            this.galleryImages.push(
                {
                    small: data.url,
                    medium: data.url,
                    big: data.url
                }
            );
        });
    }

    previewOpen(): void {
        this.previewChanged.emit('open');
        if (this.from === 'INSIDE') {
            this._fuseConfigService.config = {
                layout: {
                    navbar: {
                        hidden: true
                    },
                    toolbar: {
                        hidden: true
                    },
                    sidepanel: {
                        hidden: true
                    }
                }
            };
        }
    }

    previewClose(): void {
        this.previewChanged.emit('close');
        if (this.from === 'INSIDE') {
            this._fuseConfigService.config = {
                layout: {
                    navbar: {
                        hidden: false
                    },
                    toolbar: {
                        hidden: false
                    },
                    sidepanel: {
                        hidden: false
                    }
                }
            };
        }
    }

}
