import {ProcessNotification} from '../user-notification.service';

export class NotificationNewPostHandlers implements ProcessNotification {

    processNotification(notification, data, roleContext): void {
        notification.text = `Hay un nuevo post en Neuuni Space por ${data.userName}`;
        notification.url = `/general-forum/topic/${data.topicId}/subtopic/${data.subTopicId}/post`;
        notification.icon = 'school';
        notification.avatar = '/assets/images/logos/neuuni_isotipo_250px.png';
    }

}
