<!--<qx-filter [filters]="filters" (change)="search($event)"></qx-filter>-->
<!--<mat-form-field>-->
<!--    <input matInput (keyup.enter)="applyFilter($event.target.value)" placeholder="Filter">-->
<!--</mat-form-field>-->

<!--<div class="mat-elevation-z8">-->
    <table mat-table [dataSource]="data" matSort>
        <ng-container [matColumnDef]="c.field" *ngFor="let c of configuration.columns">
<!--            <ng-container [ngTemplateOutlet]="c.sortable ? sortableHeader : noSortableHeader" [ngTemplateOutletContext]="{'c': c}"></ng-container>-->
            <ng-container *ngIf="c.sortable">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ c.label | translate }}</th>
            </ng-container>
            <ng-container *ngIf="!c.sortable && c.background">
                <th mat-cell class="background-header" *matHeaderCellDef>{{ c.label | translate }}</th>
            </ng-container>
            <ng-container *ngIf="!c.sortable">
                <th mat-header-cell *matHeaderCellDef>{{ c.label | translate }}</th>
            </ng-container>
            <ng-container *ngIf="!c.date && !c.dateShort && c.background">
                <td mat-cell class="background-cell" [style.background-color]=getValueBackground(c,row) *matCellDef="let row"> {{ getValue(row, c) }} </td>
            </ng-container>
            <ng-container *ngIf="!c.date && !c.dateShort">
                <td mat-cell *matCellDef="let row"> {{ getValue(row, c) }} </td>
            </ng-container>
            <ng-container *ngIf="c.date">
                <td mat-cell *matCellDef="let row"> {{ getValue(row, c) | date }} </td>
            </ng-container>
            <ng-container *ngIf="c.dateShort">
                <td mat-cell *matCellDef="let row"> {{ getValue(row, c) | date:'short' }} </td>
            </ng-container>

        </ng-container>

        <ng-container matColumnDef="actions" *ngIf="configuration.rowActionButtons?.length && !configuration.compactActionButton">
            <th mat-header-cell *matHeaderCellDef class="action-column" [ngStyle]="configuration.rowActionButtonsStyle"></th>
            <td mat-cell *matCellDef="let row">
                <button *ngFor="let b of configuration.rowActionButtons" mat-icon-button
                        [matTooltip]="b.textI18nKey"
                        class="secondary-text" (click)="b.onClick(row)">
                    <mat-icon>{{ b.icon }}</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions" *ngIf="configuration.rowActionButtons?.length && configuration.compactActionButton">
            <th mat-header-cell *matHeaderCellDef class="action-column" [ngStyle]="configuration.rowActionButtonsStyle"></th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button class="list-header-option-button" [matMenuTriggerFor]="listMenu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #listMenu="matMenu">
                    <button mat-menu-item *ngFor="let b of configuration.rowActionButtons" (click)="b.onClick(row)">
                        <mat-icon class="mr-8">{{ b.icon }}</mat-icon>
                        <span>{{ b.textI18nKey }}</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns()"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns();"
            (click)="configuration.rowClickHandler ? onRowClick(row) : false"
            [ngStyle]="{cursor: configuration.rowClickHandler ? 'pointer' : false}"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="configuration.pageSizeSelector"
                   [length]="resultLength"
                   [pageSize]="configuration.pageSize">
    </mat-paginator>

<!--<ng-template #sortableHeader let-c="c">-->
<!--    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ c.label | translate }}</th>-->
<!--</ng-template>-->

<!--<ng-template #noSortableHeader let-c="c">-->
<!--    <th mat-header-cell *matHeaderCellDef>{{ c.label | translate }}</th>-->
<!--</ng-template>-->
<!--</div>-->

<!--<div id="loading">Loading...</div>-->
<!--    <div id="table-data">-->
<!--    <qx-filter [filters]="filters" (change)="search($event)"></qx-filter>-->
<!--    <div class="table-responsive">-->
<!--        <table class="table table-hover" mat-table [dataSource]="list_items">-->
<!--            <ng-container [matColumnDef]="key" *ngFor="let key of keys; let index = index">-->
<!--                <th mat-header-cell *matHeaderCellDef>{{ labels[index] | translate }}</th>-->
<!--                <td mat-cell *matCellDef="let row" [class]="rowClass(row)">{{ getValue(row, key) }}</td>-->
<!--              </ng-container>            -->
<!--            -->
<!--            <tr mat-header-row *matHeaderRowDef="keys" color="primary"></tr>-->
<!--            <tr mat-row *matRowDef="let row; columns: keys;" (click)="detail(row)"></tr>-->
<!--        </table>-->
<!--        <qx-pagination [currentitems]="currentItems"-->
<!--                       [(itemperpage)]="itemperpage"-->
<!--                       [(currentpage)]="currentpage"-->
<!--                       [totalitems]="totalItems"-->
<!--                       [totalpage]="totalPage" (search)="search($event)"></qx-pagination>-->
<!--    </div>-->
<!--</div>-->
