import {ContentBlock} from './content-block';
import {BlockTextType} from '../../edit-container/blocks/text/block-text-type.enum';

export class ContentBlockH5P extends ContentBlock{
    src: string;
    title: string;
    height: string;


    constructor(lessonBlock) {
        super(lessonBlock);
        console.log('Valor de Src: ', lessonBlock.src);
        this.src = lessonBlock.src;
        this.title = lessonBlock.title || '';
        this.height = lessonBlock.height || '550';
    }

    updateData(data): void {
        super.updateData(data);
        this.src = data.src;
        this.title = data.text;
        this.height = data.height;

    }

    getTitle(): string {
        return 'course.h5pBlock';
    }

    getTypeAsString(): string {
        return 'h5p';
    }


}
