import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClientService} from '../../../core/services/rest-client.service';

@Injectable({
    providedIn: 'root'
})
export class UserAccessRemoteService {
    private URL = '/api/user-access';

    constructor(private restClientService: RestClientService) {
    }

    updateLastAccess(): Observable<any> {
        return this.restClientService.post(this.URL);
    }

    updateCourse(courseId, courseAnnouncementId): Observable<any> {
        return this.restClientService.post(`${this.URL}/course/${courseId}`,
            {},
            {courseAnnouncementId: courseAnnouncementId});
    }

    updateActivity(activityId, courseAnnouncementId): Observable<any> {
        return this.restClientService.post(`${this.URL}/activity/${activityId}`,
            {},
            {courseAnnouncementId: courseAnnouncementId});
    }

}
