import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {FuseUtils} from '../../../../../@fuse/utils';

declare const THEOplayer;

@Component({
    selector: 'app-aws-video-player',
    styleUrls: ['./f-aws-video-player.component.scss'],
    templateUrl: './f-aws-video-player.component.html',
})
export class FAwsVideoPlayerComponent implements OnInit, OnDestroy {
    private readonly videoType: 'application/dash+xml' | 'application/x-mpegURL';
    player: any;
    ready: boolean;
    elementId: string;
    @Input() src: string;
    @Input() poster: string;
    @Input() readyHandler: Observable<any>;

    constructor() {
        this.player = false;
        this.videoType = 'application/x-mpegURL';
        this.elementId = FuseUtils.generateGUID();
    }

    ngOnInit(): void {
        if (!this.readyHandler) {
            this.ready = true;
            setTimeout(() => this.initPlayer());
        }
    }

    startPlayback(): void {
        this.readyHandler.subscribe(result => {
            this.src = result.data;
            // console.log(this.src);
            this.ready = true;
            setTimeout(() => {
                this.initPlayer();
                this.player.play();
                // console.log('this.player', this.player);
            });
        });
    }

    initPlayer(): void {
        // const element = document.querySelector('.theoplayer-container');
        const element = document.getElementById(this.elementId);
        this.player = new THEOplayer.Player(element, {
            libraryLocation: 'https://cdn.myth.theoplayer.com/93907cd9-512b-42e4-99b7-8a4d1e5668e8'
        });

        this.player.source = {
            sources: [{
                src: this.src,
                type: this.videoType,
                crossOrigin: 'use-credentials',
            }],
            poster: this.poster
        };

        // player.preload = 'auto';

        this.player.addEventListener('destroy', (e) => {
            // console.log('Event fired: Destroy' + ' -> ' + this.elementId);
        });

        this.player.addEventListener('playing', (e) => {
        });
    }

    pausePlayback(): void {
        this.player.pause();
    }

    stopPlayback(): void {
        this.player.stop();
    }

    destroy(): void {
        this.player.destroy();
    }

    ngOnDestroy(): void {
        if (this.player) {
            this.player.destroy();
        }
    }

}
