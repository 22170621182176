import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {Deleteable, Retrieveable, Saveable, Searchable} from '../../core/services/remote-services-interfaces';
import {RestClientService} from '../../core/services/rest-client.service';
import {PagedResult} from '../../course/models/paged-result';
import {TranslationUtils} from '../../core/services/translation.utils';

@Injectable({
    providedIn: 'root'
})
export class ReportsRemoteService implements Searchable<any> {
    private URL = 'api/teacher-access-history';

    constructor(private restClientService: RestClientService, private translateService: TranslateService) {
    }

    saveTeacherAccessToCourse(item: any): Observable<any> {
        return this.restClientService.post(this.URL, item);
    }

    search(criteria?: any): Observable<PagedResult<any>> {
        return this.restClientService.get(this.URL + '/all-to-course', criteria);
    }

    get(courseId, teacherId): Observable<any> {
        return this.restClientService.get(`${this.URL}/totals-info/${courseId}/${teacherId}`);
    }
    getInWeek(courseId, teacherId): Observable<any> {
        return this.restClientService.get(`${this.URL}/review-info/${courseId}/${teacherId}`);
    }

    searchForum(criteria?: any): Observable<PagedResult<any>> {
        return this.restClientService.get(this.URL + '/all-to-unit', criteria);
    }

    searchEvaluation(criteria?: any): Observable<PagedResult<any>> {
        return this.restClientService.get(this.URL + '/all-to-evaluations', criteria);
    }
    searchFeedback(criteria?: any): Observable<PagedResult<any>> {
        return this.restClientService.get(this.URL + '/all-to-feedback', criteria);
    }
}