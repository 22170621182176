import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {RestClientService} from '../../../core/services/rest-client.service';
import {Retrieveable, Saveable, Searchable} from '../../../core/services/remote-services-interfaces';
import {Unit} from '../../models/unit';
import {Observable} from 'rxjs';
import {PagedResult} from '../../models/paged-result';
import {tap} from 'rxjs/operators';
import {TranslationUtils} from '../../../core/services/translation.utils';
import {ChangeUnitOrderRequest} from '../../models/change-unit-order-request';
import {UnitActivity} from "../../models/unit-activity";


@Injectable({
    providedIn: 'root'
})
export class UnitRemoteService implements Searchable<Unit>,
    Retrieveable<Unit, string>,
    Saveable<Unit> {
    private URL = '/api/units';

    constructor(private restClientService: RestClientService, private translateService: TranslateService) {
    }

    search(criteria?: any): Observable<PagedResult<Unit>> {
        return this.restClientService.get(this.URL, criteria);
    }

    searchSelect(criteria?: any): Observable<PagedResult<Unit>> {
        return this.restClientService.get(`${this.URL}/select`, criteria);
    }

    get(id: string, includeUserData?: boolean, courseAnnouncementId: string = null): Observable<Unit> {
        const params: any = {};
        if (includeUserData) {
            params.includeUserData = includeUserData;
        }
        if (courseAnnouncementId) {
            params.courseAnnouncementId = courseAnnouncementId;
        }

        return this.restClientService.get(`${this.URL}/${id}`, params).pipe(
            tap((unit: Unit) => unit.virtualLesson.lessons.map((lesson, idx) => lesson.order = idx)));
    }

    save(item: Unit): Observable<Unit> {
        TranslationUtils.processTranslation(item, this.getTranslatableFields(), this.translateService);
        if (item.id) {
            return this.restClientService.put(`${this.URL}/${item.id}`, item);
        }
        return this.restClientService.post(this.URL, item);
    }

    updateOrder(unitId, request: ChangeUnitOrderRequest): Observable<any> {
        return this.restClientService.put(`${this.URL}/${unitId}/order`, request);
    }

    getAllByCourse(courseId): Observable<any> {
        return this.restClientService.get(`${this.URL}/by-course/${courseId}`);
    }

    delete(unitId): Observable<Unit> {
        return this.restClientService.delete(`${this.URL}/${unitId}`);
    }

    getUnitsStructureByCourse(courseId: string, courseAnnouncementId: string): Observable<any[]> {
        return this.restClientService.get(`${this.URL}/structure-by-course/${courseId}`,
            {courseAnnouncementId: courseAnnouncementId});
    }

    getNotificationsByCourse(courseId: string, roleTeacher: boolean): Observable<any[]> {
        return this.restClientService.get(`${this.URL}/by-course-with-notifications/${courseId}`, {teacher: roleTeacher});
    }

    getForumsByCourse(courseId: string): Observable<any[]> {
        return this.restClientService.get(`${this.URL}/${courseId}/units-simple-info`);
    }


    private getTranslatableFields(): string[] {
        return ['name', 'description'];
    }

    getUnitsByCourseId(courseId: string): Observable<any[]> {
        return this.restClientService.get(`${this.URL}/select-by-course/${courseId}`);
    }

    getUnitsByCourseAnnouncementId(courseId: string): Observable<any[]> {
        return this.restClientService.get(`${this.URL}/select-by-course-announcement/${courseId}`);
    }

    getByCourseIdContainsOneTitleExam(courseId: string): Observable<boolean> {
        return this.restClientService.get(`${this.URL}/by-unit/${courseId}/title-exam`);
    }

    getByCourseIdContainsOneTitleExamOne(courseId: string): Observable<any> {
        return this.restClientService.get(`${this.URL}/by-unit/${courseId}/title-exam-one`);
    }

}
