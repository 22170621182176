export class NeuuniWatchPermissions {
    static readonly VIEW_CATEGORY_VIDEO = 'ROLE_VIEW_CATEGORY_VIDEO';
    static readonly MANAGE_CATEGORY_VIDEO = 'ROLE_MANAGE_CATEGORY_VIDEO';
    static readonly VIEW_SEASON = 'ROLE_VIEW_SEASON';
    static readonly MANAGE_SEASON = 'ROLE_MANAGE_SEASON';
    static readonly VIEW_SERIES = 'ROLE_VIEW_SERIES';
    static readonly MANAGE_SERIES = 'ROLE_MANAGE_SERIES';
    static readonly VIEW_TRENDING = 'ROLE_VIEW_TRENDING';
    static readonly MANAGE_TRENDING = 'ROLE_MANAGE_TRENDING';
    static readonly VIEW_VIDEO = 'ROLE_VIEW_VIDEO';
    static readonly MANAGE_VIDEO = 'ROLE_MANAGE_VIDEO';

}
