import {NgxStarsModule} from 'ngx-stars';
import {FileTypePipe} from '../core/pipe/file-type.pipe';
import {NgModule} from '@angular/core';
import {SafeStylePipe} from '../core/pipe/safe-style.pipe';
import {RatingComponent} from '../core/components/rating/rating.component';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {CountryChangeComponent} from './components/country-change/country-change.component';
import {MatMenuModule} from '@angular/material/menu';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AppCurrencyPipe} from '../core/pipe/app-currency.pipe';
import {HelpChangeComponent} from './components/help-change/help-change.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BtnDisableAfterClickDirective} from '../core/directives/btn-disable-after-click.directive';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ExpandableTextComponent} from '../core/components/expandable-text/expandable-text.component';
import {TruncatePipe} from '../core/components/expandable-text/truncate-pipe';

@NgModule({
    imports: [
        CommonModule,
        NgxStarsModule,
        MatMenuModule,
        FlexLayoutModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
    ],
    declarations: [
        RatingComponent,
        ExpandableTextComponent,
        TruncatePipe,
        FileTypePipe,
        SafeStylePipe,
        AppCurrencyPipe,
        CountryChangeComponent,
        HelpChangeComponent,
        BtnDisableAfterClickDirective,
    ],
    exports: [
        CommonModule,
        RatingComponent,
        ExpandableTextComponent,
        TruncatePipe,
        FileTypePipe,
        SafeStylePipe,
        AppCurrencyPipe,
        CountryChangeComponent,
        HelpChangeComponent,
        BtnDisableAfterClickDirective,
    ],
    providers: [
        CurrencyPipe
    ],
    entryComponents: []
})
export class CoreCommonModule {

}

