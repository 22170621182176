import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {MatSelectionList} from '@angular/material/list';
import {AppUtils} from "@quiox/global/helpers/app.utils";



@Component({
    selector: 'qx-select-list',
    templateUrl: './qx-select-list.component.html',
    styleUrls: ['./qx-select-list.component.scss']
})
export class QxSelectListComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    @Input() compId;
    items: any[] = [];
    currentItems: any[] = [];
    filterControl: FormControl;
    atr_name: any="name";
    atr_value: any="name";
    

    @ViewChild(MatSelectionList, {static: true})
    selectListComponent: MatSelectionList;

    constructor() {
        this.filterControl = new FormControl('');
        this._unsubscribeAll = new Subject<any>();
    }

    ngOnInit(): void {
        this.initSearchFilter();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    setAtrName(item: any): void {
        this.atr_name = item;
    }

    setAtrValue(item: any): void {
        this.atr_value = item;
    }

    getName(item) {
		if (!AppUtils.isNullOrUndefined(item) && AppUtils.isObject(item)) {			
				return item[this.atr_name]			
		}
		else {
			return item;
		}	
	}


    setItems(items: any[]): void {
        this.items = items;
        this.currentItems = this.items;
    }

    addItems(items: any[]): void {
        this.items = this.items.concat(items);
        this.currentItems= this.items;
    }

    removeItems(items: any[]): void {
        this.items = this.items.filter(u => !items.some(u1 => u1.id === u.id));
        this.currentItems = this.items;
    }

    getSelection(): any[] {
        var a =this.selectListComponent.selectedOptions.selected.map(i => i.value);
        return  a;
    }

    selectionAll(): void {
        this.selectListComponent.selectAll();
    }

    clearFilter(): void {
        this.filterControl.setValue('');
    }

    private initSearchFilter(): void {
        this.filterControl.valueChanges.pipe(
            takeUntil(this._unsubscribeAll),
            debounceTime(200),
            distinctUntilChanged(),
        ).subscribe((searchText: string) => {
            this.currentItems = this.items.filter(user =>
                (user.name.toLowerCase().includes(searchText.toLowerCase().trim())));
        });
    }

}
