import {APP_CONFIG} from "../../app.config";
import {SecurityService} from "../../main/core/services/security.service";
import {StorageService} from "../../main/core/services/storage.service";

export class AppUtils {

    static clone(data: any): any {
        const str = JSON.stringify(data);
        return JSON.parse(str);
    }

    static back(): void {
        window.history.back();
    }

    static dataURItoBlob(dataURI): Blob {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        return new Blob([int8Array], {type: 'image/jpeg'});
    }

    static capitalizeString(str): string {
        if (!str) {
            return str;
        }
        return str[0].toUpperCase() + str.substr(1).toLocaleLowerCase();
    }


    public static save(key, val) {
        localStorage.setItem(btoa(key), btoa(JSON.stringify(val)));
        let appKeys: Array<any> = AppUtils.load('appKeys') || [];
        if (AppUtils.isNullOrUndefined(appKeys)) {
            appKeys = [];
        }
        appKeys.push(key);
        localStorage.setItem(btoa('appKeys'), btoa(JSON.stringify(appKeys)));

    }

    public static load(key) {

        try {
            return JSON.parse(atob(localStorage.getItem(btoa(key))));
        } catch (e) {
            return null;
        }
    }

    public static del(key) {
        localStorage.removeItem(btoa(key));
    }

    public static currentUser(): any {
        return AppUtils.load('currentUser');
    }

    public static currenAccesToken(): any {
        return AppUtils.load('securityToken');
    }

  

    public static initHostedUi(){
        const config = APP_CONFIG[APP_CONFIG.active_profile];
        const url_hosted_ui = config.url_hosted_ui;
         window.location.href = url_hosted_ui;
    }

    public static logout() {
        // this.spin.spinLoading();
        const storageService = new StorageService();
        const securityService = new SecurityService(storageService);
        this.deleteFromStorageAll(securityService);
        AppUtils.del('currentUser');
        AppUtils.del('securityToken');
        AppUtils.del('userHome');
        const config = APP_CONFIG[APP_CONFIG.active_profile];
        const url_logout_hosted_ui = config.url_logout_hosted_ui;
        window.location.href = url_logout_hosted_ui;
    }

    public static isArray(object: any): boolean{
        return Array.isArray(object);
    }

    public static isBoolean(object: any): boolean{
        return typeof object === 'boolean';
    }

    public static isFunction(object: any): boolean{
        return typeof object === 'function';
    }

    public static isNull(object: any): boolean{
        return object === null;
    }

    public static isNullOrUndefined(object: any): boolean{
        return object === null || object === undefined;
    }

    public static isUndefined(object: any): boolean{
        return object === undefined;
    }

    public static isNumber(object: any): boolean{
        return typeof object === 'number';
    }

    public static isObject(object: any): boolean{
        return object !== null && typeof object === 'object';
    }

    public static isString(object: any): object is string{
        return typeof object === 'string';
    }

    public static verifyPerm(rol) {
        const user = SecurityService.getUser();
        if(!AppUtils.isNullOrUndefined(user)){
            return user.authorities.filter(a => a == rol).length > 0;
        }
        return false;
    }

    public static deleteFromStorageAll(securityService: SecurityService) {
        securityService.deleteFromStorage();
    }
}

