<div class="profile-box info-box" fxLayout="column">
    <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="title">Detalles del curso</div>
        <app-rating *ngIf="model.evaluation" [eval]="model.evaluation" [color]="'#f44336'"></app-rating>
    </header>

    <div class="content detail">

        <div class="info-line">
            <div class="info" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="s-16 ml-4">local_library</mat-icon>
                <span>{{ model.unitsCount }} tema(s)</span>
            </div>
        </div>

        <div class="info-line">
            <div class="info" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="s-16 ml-4">perm_identity</mat-icon>
                <span>{{ model.studentCount }} estudiante(s)</span>
            </div>
        </div>

        <div class="info-line">
            <div class="info" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="s-16 ml-4">language</mat-icon>
                <span>Español</span>
            </div>
        </div>
        <div class="info-line">
            <div class="info" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="s-16 ml-4">local_library</mat-icon>
                <span>{{model.additionalResources}}  Recurso(s) Adicionale(s) </span>
            </div>
        </div>
        <div class="info-line">
            <div class="info" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="s-16 ml-4">grade</mat-icon>
                <span>{{ ('course.' + model.level) | translate }}</span>
            </div>
        </div>
        <div class="info-line">
            <div class="info" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="s-16 ml-4">vpn_key</mat-icon>
                <span>Acceso Ilimitado</span>
            </div>
        </div>
        <div class="info-line">
            <div class="info" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="s-16 ml-4">perm_identity</mat-icon>
                <span>Tutor Personal</span>
            </div>
        </div>

        <div class="info-line" *ngIf="showCourseState">
            <div class="info ml-4">
                <div class="title">Estado</div>
                <div>{{ 'course.' + model.courseState | lowercase | translate }}</div>
            </div>
        </div>

        <div class="info-line">
            <div class="info ml-4">
                <div class="title">Resumen</div>
                <div>{{ model.summary }}</div>
            </div>
        </div>

        <!--        <div class="info-line ml-4"  fxLayout="row">-->
        <!--&lt;!&ndash;            <div class="title">Categoría</div>&ndash;&gt;-->
        <!--            <mat-icon>assignment_turned_in</mat-icon>-->
        <!--            <div class="info time">{{ model.categoryName }}</div>-->
        <!--        </div>-->
        <div class="info-line">
            <div class="info" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="s-16 ml-4">local_offer</mat-icon>
                <span>{{ model?.categoryName || 'Sin categoría' }}</span>
            </div>
        </div>

    </div>
</div>