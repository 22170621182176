<div class="dialog-content-wrapper">

    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">

            <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                <span class="title dialog-title">{{ data.headerText }}</span>
            </div>

            <button mat-icon-button (click)="dialogRef.close()" aria-label="Close">
                <mat-icon>close</mat-icon>
            </button>

        </div>
    </mat-toolbar>

    <app-vjs-player
            [src]="data.videoData.videoUrl"
            [poster]="data.videoData.image.url"
            [readyHandler]="data.videoData.readyHandler">
    </app-vjs-player>

</div>
