import {ContentBlock} from './content-block';
import {BlockTextType} from '../../edit-container/blocks/text/block-text-type.enum';

export class ContentBlockText extends ContentBlock{
    text: string;
    textType: BlockTextType;

    constructor(lessonBlock) {
        super(lessonBlock);
        this.text = lessonBlock.text || '';
        this.textType = lessonBlock.textType || BlockTextType.TITLE;
    }

    updateData(data): void {
        super.updateData(data);
        this.text = data.text;
        this.textType = data.textType;
    }

    getTitle(): string {
        return 'course.textBlock';
    }

    getTypeAsString(): string {
        return 'text';
    }

}
