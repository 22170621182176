import {Component, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'app-upload-data-file',
    templateUrl: './upload-data-file.component.html'
})
export class UploadDataFileComponent {
    @Output() fileUpload: EventEmitter<File>;

    constructor() {
        this.fileUpload = new EventEmitter();
    }

    onFileChange(event): void {
        if (event.target.files && event.target.files.length > 0) {
            const file: File = event.target.files[0];
            this.fileUpload.emit(file);
        }
    }

}
