export class Pageable {
    public page?: number;
    public size?: number;
    public sortColumn?: string;
    public order?: number;

    public setAll?(): Pageable {
        this.size = 10000000;
        return this;
    }

    constructor(size?: number) {
        this.size = size;
        this.page = 0;
    }
}
