<div fxLayout="column" [formGroup]="form">

    <mat-form-field appearance="outline">
        <mat-select formControlName="textType" [disabled]="disabledBlock" placeholder="Tipo de Texto" (selectionChange)="changeText($event)">
            <mat-option *ngFor="let textType of textTypes" [value]="textType">{{ textType }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="100" [fxHide]="hideText">
        <mat-label>Texto</mat-label>
        <textarea matInput formControlName="text" required></textarea>
        <mat-error>El texto es requirido!</mat-error>
    </mat-form-field>

    <div fxLayout="column" [fxHide]="!hideText">
        <app-f-text-editor-field [disabled]="disabledBlock" formControlName="text" label="Texto"></app-f-text-editor-field>
    </div>

</div>