import {Component, Input, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ContentBlockImage} from '../../../block-view/models/content-block-image';
import {FDropZoneImageFieldComponent} from '../../../../core/components/f-drop-zone-image/f-drop-zone-image-field.component';
import {ContentBlockTypeBase} from '../block-type-base';

@Component({
    selector: 'app-content-block-image-edit',
    templateUrl: './content-block-image-edit.component.html',
    styleUrls: ['./content-block-image-edit.component.scss'],
})
export class ContentBlockImageEditComponent implements OnInit, ContentBlockTypeBase {
    // files: any[] = [];
    imagesToDelete: any[] = [];

    @Input() form: FormGroup;
    @Input() model: ContentBlockImage;
    @Input() disabledBlock: boolean = false;

    @ViewChild('images', {read: FDropZoneImageFieldComponent, static: true})
    imageComponent: FDropZoneImageFieldComponent;

    constructor(private fb: FormBuilder) {
    }

    ngOnInit(): void {
        this.form.addControl('images', this.fb.control([]));
        this.form.addControl('imagesToDelete', this.fb.control([]));

        this.form.patchValue(this.model);
        this.setImages(this.model.images);
    } 
    
    changeToDisable(disable: boolean): void {
        if(disable) {
            this.imageComponent.disabled = true;
        } else {
            this.imageComponent.disabled = false;
        }
    }

    updateData(data): void {
        this.setImages(data.images);
    }

    // onFileChange(file: File): void {
    //     this.files.push({file: file});
    //     this.form.get('images').setValue(this.files);
    // }

    onImageChange(event): void {
        const images = this.imageComponent.files.filter((f: any) => !f.metaFromServer);
        const mm = images.map(file => ({file: file}));
        this.form.get('images').setValue(mm);
    }

    // downloadFile(downloadLink: string): void {
    //     this.http.get(downloadLink, {responseType: 'blob'}).subscribe(response => {
    //         const blob = new Blob([response], { type: 'image/jpg' });
    //         const url = window.URL.createObjectURL(blob);
    //         window.open(url);
    //
    //         // rest of browsers
    //         // const url = window.URL.createObjectURL(response);
    //         // const a = document.createElement('a');
    //         // document.body.appendChild(a);
    //         // a.setAttribute('style', 'display: none');
    //         // a.href = url;
    //         // a.download = 'prueba.png';
    //         // a.click();
    //         // setTimeout(() => {
    //         //     document.body.removeChild(a);
    //         //     window.URL.revokeObjectURL(url);
    //         // }, 100);
    //     });
    // }

    // downloadFile1(downloadLink: string): void {
    //     this.http.get(downloadLink).pipe(
    //         map((response: any) => {
    //             const blob = new Blob([response], { type: 'image/jpg' });
    //             const url = window.URL.createObjectURL(blob);
    //             window.open(url);
    //         })).subscribe(() => {});
    // }

    private setImages(images: any[]): void {
        this.form.get('images').setValue([]);
        this.imageComponent.files = [];
        this.imageComponent.filesPreview = [];
        if (images) {
            images.forEach(i => {
                const file: any = new File([], i.resourceName);
                file.metaId = i.id;
                file.metaFromServer = true;
                // this.imagesResources.addFile(file);
                this.imageComponent.addFile(i);
            });
        }
    }

    onFileRemoved(fileData: any): void {
        this.imagesToDelete.push(fileData.file.metaId);
        this.form.get('imagesToDelete').setValue(this.imagesToDelete);
    }

}
