import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AppUtils} from '@quiox/global/helpers/app.utils';
import {FNavigationService} from "../../core/services/f-navigation.service";
import {AuthService} from "../../core/auth/service/auth.service";
import {FuseSplashScreenService} from "../../../../@fuse/services/splash-screen.service";
import {UserAccessRemoteService} from 'app/main/course/services/remote-services/user-access.remote.service';
import {BroadcastChannelService} from 'app/main/core/services/broadcast-channel.service';
import {Observable} from 'rxjs';
import {SecurityService} from "../../core/services/security.service";
import {HttpClient} from "@angular/common/http";


@Injectable({providedIn: 'root'})
export class CallbackGuard implements CanActivate {
    public coreConfig: any;
    baseUrl: any;

    /**
     * Constructor
     *
     * @param {CoreConfigService} _coreConfigService
     */
    constructor(private router: Router,
                private authService: AuthService,
                private navigationService: FNavigationService,
                private userAccessRemoteService: UserAccessRemoteService,
                private broadcastChannelService: BroadcastChannelService,
                private securityService: SecurityService,
                private _http: HttpClient) {

    }

    // canActivate
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        var url = state.url;
        console.log(`route ${route.url}`); // the url you are going to
        console.log(`state ${state.url}`); // the url you are going to
        console.log(`router ${this.router.url}`); // the url you are coming from
        var cognitoParam = {};

        console.log("Url data", url);

        var dataOauth: String = url.split("#")[1];
        this.baseUrl = url.split("/")[0];
        var params: any[] = dataOauth.split("&");
        params.forEach(param => {
            var paranName = param.split("=")[0];
            var paramValue = param.split("=")[1];
            cognitoParam[paranName] = paramValue;
        })

        console.log("Cognito param", cognitoParam);
        this.securityService.setSecurityToken(cognitoParam);
        return this.process((cognitoParam as any).access_token);
    }

    private async process(token): Promise<UrlTree> {
        try {

            console.log("access_token callback", token);

            AppUtils.save("access_token", token);

            const result: boolean = await this.authService.retrieveTokenProcess();
            if (result) {
                this.broadcastChannelService.init();
                // try {
                //     console.log('Entro a verificar external');
                //     const externalUidUser: any = await this._http.get('api/users/externalUid').toPromise();
                // } catch (error) {
                //     console.error('Usuario no puede ser procesado, error de autenticación.', error);
                //     alert('Usuario no puede ser procesado, error de autenticación');
                // }
                var url = this.navigationService.determineUserHome();
                console.log("url menuuuuuuu", url);
                return this.router.createUrlTree([url]);

            }

        } catch (e) {
            console.error(e);
            alert('Ha ocurrido un error iniciando sesión');
            // AppUtils.logout();
            throw new Error(e);
        }
    }

    /* private successLogin(data?: any) : Promise<UrlTree> {

        return new Promise((resolve, reject) => {

            this.auth.loadSession().subscribe((res: any) => {
                if (res != null) {
                    console.log("currentUser", res);
                    AppUtils.save('currentUser', res);
                    this.securityService.setUser(res);
                  //  this.buildMenuAndGetFirstUrl();
                    resolve(true);

                } else {
                  AppUtils.logout();
                }

            },err=>{
                AppUtils.logout();
            });
        });
    }

    buildMenuAndGetFirstUrl() {

        const firstMenu = this.navigation.determineUserHome();
        console.log("firstMenu", firstMenu)
        console.log("baseUrl", window.location.href)
    
       this.router.navigateByUrl(firstMenu).then(_ => {
            this._fuseSplashScreenService.hide();
        });
    } */

}
