<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Información del {{ isLink ? 'Enlace' : 'Archivo' }}</span>
            <button mat-icon-button (click)="dialogRef.close()" aria-label="Close" [disabled]="uploading">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0" fxLayout="column" fusePerfectScrollbar>

        <div fxLayout="column" [formGroup]="form">
            <div fxLayout="row" fxLayoutAlign="start baseline" fxFlex="100" style="flex-flow: wrap" class="wrap">

                <mat-form-field class="pr-4" appearance="outline" fxFlex="100"
                                [hintLabel]="'core.maxCharacters' | translate:{count: 100}">
                    <mat-label>{{ 'core.name' | translate }}</mat-label>
                    <input #nameInput matInput type="text" formControlName="name" maxlength="100" required>
                    <mat-hint align="end">{{nameInput.value?.length || 0}}/{{nameInput.maxLength}}</mat-hint>
                    <mat-error>{{ 'core.requiredField' | translate }}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="70" class="select-icon-field" *ngIf="showType">
                    <mat-label>Icono</mat-label>
                    <mat-select formControlName="type" placeholder="Icono">
                        <mat-select-trigger>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-icon>{{ typeInfo.icon }}</mat-icon>
                                <span class="ml-16">{{ typeInfo.text }}</span>
                            </div>
                        </mat-select-trigger>
                        <mat-option *ngFor="let fileType of fileTypes | keyvalue" [value]="fileType.key">
                            <mat-icon>{{ fileType.value.icon }}</mat-icon>
                            <span> {{ fileType.value.text }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <mat-form-field *ngIf="isLink" appearance="outline" fxFlex="60" [hintLabel]="'core.maxCharacters' | translate:{count: 255}">
                <input #urlInput matInput type="text" maxlength="255" formControlName="url" placeholder="http://example.com *" required>
                <mat-hint align="end">{{urlInput.value?.length || 0}}/{{urlInput.maxLength}}</mat-hint>
                <mat-error *ngIf="form.get('url').hasError('required')">{{ 'core.requiredField' | translate }}</mat-error>
                <mat-error *ngIf="form.get('url').hasError('url')">{{ 'core.urlNotValid' | translate }}</mat-error>
            </mat-form-field>

        </div>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button mat-button class="save-button" [disabled]="form.invalid || uploading || buttonClicked" (click)="save(); buttonClicked = true;"  aria-label="SAVE">
            <span class="mr-8" style="display: inline-block;" *ngIf="uploading">
                <mat-progress-spinner [diameter]="20" value="30" color="accent" mode="indeterminate"></mat-progress-spinner>
            </span>
            <span>GUARDAR</span>
        </button>

    </div>
</div>
