<div class="mt-24" fxLayout="column">
    <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">

        <div>
            <div class="h3 mb-16">Archivos</div>

            <mat-nav-list class="articles-list">

                <ng-container *ngFor="let res of fileResources; let idx = index">

                    <a *ngIf="!disabled" mat-list-item (click)="showMetadataDialog({id: res.id, name: res.name, type: res.type}, 'file', idx)">
                        <mat-icon class="hint-text mr-8">{{ res.type | appIcon }}</mat-icon>
                        <div>{{ res.name }}</div>
                        <mat-icon class="delete-icon hint-text ml-16" (click)="$event.stopPropagation(); deleteResource(res, 'file', idx)">delete</mat-icon>
                    </a>
                    <a *ngIf="disabled" mat-list-item style="color: grey; cursor: default;" >
                        <mat-icon  class="hint-text mr-8">{{ res.type | appIcon }}</mat-icon >
                        <div>{{ res.name }}</div>                        
                    </a>

                </ng-container>

            </mat-nav-list>

            <div class="my-16">
                <input hidden type="file" #fileInput accept="*" (change)="addFileResource(fileInput.files, $event)" (click)="fileInput.value = null"/>
                <button mat-raised-button (click)="fileInput.click()" [disabled]="disabled">
                    <span>Adicionar Archivo</span>
                </button>
            </div>
        </div>

        <div class="link-resources mt-32" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
            <div class="h3 mb-16">Enlaces</div>

            <mat-nav-list class="articles-list">

                <ng-container *ngFor="let res of linkResources; let idx = index">

                    <a *ngIf="!disabled" mat-list-item (click)="showMetadataDialog({id: res.id, name: res.name, type: res.type, url: res.url}, 'link', idx)">
                        <mat-icon class="hint-text mr-8">{{ res.type | appIcon }}</mat-icon>
                        <div>{{ res.name }}</div>
                        <mat-icon class="delete-icon hint-text ml-16" (click)="$event.stopPropagation(); deleteResource(res, 'link', idx)">delete</mat-icon>
                    </a>
                    <a *ngIf="disabled" mat-list-item style="color: grey; cursor: default;" >
                        <mat-icon class="hint-text mr-8">{{ res.type | appIcon }}</mat-icon>
                        <div>{{ res.name }}</div>
                    </a>

                </ng-container>

            </mat-nav-list>

            <div class="my-16">
                <button mat-raised-button (click)="addLinkResource()" [disabled]="disabled">
                    <span>Adicionar Enlace</span>
                </button>
            </div>
        </div>

    </div>
</div>

