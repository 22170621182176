import {CoursePermissions} from '../course/config/course-permissions';
import {NeuuniWatchPermissions} from '../neuuni-watch-moderator/config/neuuni-watch-permissions';


export const neuuniWatchMenu = {
    id: 'neuuniWatch',
    name: 'neuuniWatch',
    fieldI18Key: 'NAV.neuuniWatch',
    menu: [
        {
            name: 'neuuniWatch',
            fieldI18Key: 'NAV.neuuniWatch',
            url: '/neuuni-watch/',
            icon: 'camera',
            roles: [CoursePermissions.CATEGORY_VIEW]
        }
    ]
};

export const neuuniWatchManagerMenu = {
    projectName: '',
    projectLogo: 'assets/img/neuuni_logotipo_blanco.png',
    modules: [
        neuuniWatchMenu
    ]
};
