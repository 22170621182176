import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ContentBlockLabel} from '../../../block-view/models/content-block-label';
import {ContentBlockTypeBase} from '../block-type-base';

@Component({
    selector: 'app-content-block-label-edit',
    templateUrl: './content-block-label-edit.component.html',
})
export class ContentBlockLabelEditComponent implements OnInit, ContentBlockTypeBase {
    @Input() form: FormGroup;
    @Input() model: ContentBlockLabel;
    @Input() disabledBlock = true;

    constructor(private fb: FormBuilder) {
    }

    ngOnInit(): void {
        this.form.addControl('title', this.fb.control(''));
        this.form.addControl('subtitle', this.fb.control(''));
        this.form.addControl('bgColor', this.fb.control(''));
        this.form.patchValue(this.model);
        this.changeToDisable(true);
    }

    changeToDisable(disable: boolean): void {
        if (disable) {
            this.form.controls['title'].disable();
            this.form.controls['subtitle'].disable();
            this.form.controls['bgColor'].disable();
            this.disabledBlock = true;
        } else {
            this.form.controls['title'].enable();
            this.form.controls['subtitle'].enable();
            this.form.controls['bgColor'].enable();
            this.disabledBlock = false;
        }
    }

    colors: any[] = [
        {
            name: 'AZUL',
            color: '#0047BA'
        },
        {
            name: 'VERDE',
            color: '#3AE9D6'
        },
        {
            name: 'ROJO',
            color: '#FF4A5C'
        },
        {
            name: 'AMARILLO',
            color: '#CFE734'
        },
        {
            name: 'NARANJA',
            color: '#FFB229'
        },
        {
            name: 'MORADO',
            color: '#7748AF'
        }
    ];

}
