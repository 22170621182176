import {ModuleConfig} from '@quiox/global/config/config';
import {CoursePermissions} from './course-permissions';

export const courseMenu: ModuleConfig = {
    id: 'courses',
    name: 'Course',
    fieldI18Key: 'NAV.COURSE',
    menu: [
        {
            name: 'Courses',
            fieldI18Key: 'NAV.COURSES',
            url: '/courses/course',
            exactMatch: true,
            roles: [CoursePermissions.COURSE_VIEW_MANAGER],
        },
        {
            name: 'Categories',
            fieldI18Key: 'NAV.CATEGORIES',
            url: '/courses/course-category',
            roles: [CoursePermissions.CATEGORY_UPDATE],
            // childrens: [
            //     {name: 'Categorías', url: '/categories', roles: ['ROLE_OWNER']}
            // ]
        },
        // {
        //     name: 'Institution',
        //     fieldI18Key: 'NAV.INSTITUTION',
        //     url: '/courses/institution',
        //     roles: [CoursePermissions.INSTITUTION_VIEW],
        //     // childrens: [
        //     //     {name: 'Categorías', url: '/categories', roles: ['ROLE_OWNER']}
        //     // ]
        // },
    ]

};
