import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClientService} from '../../../core/services/rest-client.service';
import {Pageable} from '../../models/pageable';
import {PagedResult} from '../../models/paged-result';
import {Course} from '../../models/course';
import {LangCurrencyService} from '../../../core-common/services/lang-currency.service';
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class CourseStudentRemoteService {
    private URL = '/api/course-student';

    constructor(private restClientService: RestClientService, private langCurrencyService: LangCurrencyService,  private httpClient: HttpClient) {
    }

    evaluate(courseId: string, evaluation: any, courseAnnouncementId): Observable<Course> {
        return this.restClientService.post(`${this.URL}/${courseId}/evaluate`, evaluation, {courseAnnouncementId:courseAnnouncementId});
    }

    searchOwnedCourses(page, size): Observable<PagedResult<any>> {
        return this.restClientService.get(`${this.URL}/owned`, {page: page, size});
    }

    searchCompletedOwnedCourses(criteria?: Pageable): Observable<PagedResult<any>> {
        return this.restClientService.get(`${this.URL}/completed`, criteria);
    }

    getStudentCourse(courseId: any): Observable<PagedResult<any>> {
        return this.restClientService.get(`${this.URL}/completed/${courseId}`);
    }

    getCourseAnnouncementsIdsBySubject(coursesIds: any, generationId): Observable<any> {
        return this.restClientService.get(`${this.URL}/subject-courses-by-ids/`, {
            courseAnnouncementIds: coursesIds,
            currency: this.langCurrencyService.getCurrent().currency,
            generationId: generationId
        });
    }

    getProgressCoursesIdsBySubject(coursesIds: any, generationId): Observable<any> {
        return this.restClientService.get(`${this.URL}/progress-courses-by-ids/`, {
            coursesIds: coursesIds,
            currency: this.langCurrencyService.getCurrent().currency,
            generationId: generationId
        });
    }

    getRecommended(): Observable<any> {
        return this.restClientService.get(`${this.URL}/recommended`, this.getCurrencyParam());
    }

    getCertificate(courseId, courseAnnouncementId): Observable<any> {
        return this.restClientService.get(`${this.URL}/${courseId}/certificate`,
            {courseAnnouncementId: courseAnnouncementId});
    }

    getCurrencyParam(): any {
        return {currency: this.langCurrencyService.getCurrent().currency};
    }

    getCourseProgress(courseId, courseAnnouncementId): Observable<any> {
        return this.restClientService.get(`${this.URL}/${courseId}/last-activity`,
            {courseAnnouncementId: courseAnnouncementId});
    }


    ejecutarGeneretedEvaluationFromStudentProfile(): Observable<any>{
        return this.httpClient.get('api/course-student/generar-evaluation', {});
    }

    hideTitleExamOwnedCourses(courseId: string, courseAnnouncementId: string): Observable<any> {
        return this.restClientService.get(`${this.URL}/${courseId}/hideTitleExam/${courseAnnouncementId}`);
    }

}
