import {Injectable} from '@angular/core';
import {Course} from '../models/course';
import {UnitCount} from '../../core/user-notification/model/UnitCount';
import {FuseNavigationService} from '../../../../@fuse/components/navigation/navigation.service';
import {UserNotificationCountService} from '../../core/user-notification/user-notification-count.service';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

@Injectable()
export class CourseMenuNotificationService {
    private _unsubscribeAll: Subject<any>;

    constructor(private _fuseNavigationService: FuseNavigationService,
                private userNotificationCountService: UserNotificationCountService) {
        this._unsubscribeAll = new Subject();
    }

    setUnitsNotifications(course: Course, updateNotifications = true, courseAnnouncementId: string): void {
        if (updateNotifications) {
            this.userNotificationCountService.getCourseCount(course.id, courseAnnouncementId);

            this.userNotificationCountService.$courseCount.pipe(
                takeUntil(this._unsubscribeAll),
                filter(v => !!v)
            ).subscribe(count => {
                this.updateItemsFromData(count.course);
            });
        } else {
            this.updateItemsFromData(this.userNotificationCountService.$courseCount.value.course);
        }

    }

    destroy(): void {
        this._unsubscribeAll.next();
    }

    private updateItemsFromData(units: UnitCount[]): void {
        units.forEach(unit => {
            const caId = (unit.courseAnnouncementId) ? unit.courseAnnouncementId : '';
            this.updateNotificationItem(unit.id + caId, unit.totalCount);
            this.updateNotificationItem(unit.id + caId + '-forum', unit.forumCount);

            this.updateNotificationItem(unit.id + caId + '-activity-group', unit.totalActivitiesCount);
            unit.activities.forEach(a => {
                this.updateNotificationItem(unit.id + caId + '-activity-' + a.id, a.totalCount);
            });
        });
    }

    private updateNotificationItem(itemId, value): void {
        const data = {
            title: value,
            // bg: 'rgb(5,198,39)',
            bg: 'rgb(82, 94, 138)',
            fg: 'rgb(255, 255, 255)'
        };
        this._fuseNavigationService.updateNavigationItem(itemId, {
            badge: value > 0 ? data : null
        });
    }

    private activateNotificationItemAlert(itemId): void {
        this._fuseNavigationService.updateNavigationItem(itemId, {
            classes: 'notification',
            badge: {
                bg: 'rgb(5,198,39)',
            }
        });
    }

}
