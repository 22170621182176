import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClientService} from '../services/rest-client.service';
import {PagedResult} from '../../course/models/paged-result';

@Injectable({
    providedIn: 'root'
})
export class UserNotificationRemoteService {
    private readonly URL = RestClientService.NOTIFICATION_SERVER_URL_PREFIX + 'user';

    constructor(private restClientService: RestClientService) {
    }

    count(params): Observable<any> {
        return this.restClientService.get(this.URL + '/count', params);
    }

    search(criteria?: any): Observable<PagedResult<any>> {
        return this.restClientService.get(this.URL, criteria);
    }

    setAsRead(data): Observable<any> {
        return this.restClientService.put(this.URL, data);
    }

}
