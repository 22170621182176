import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';

import {SecurityService} from '../../../../../core/services/security.service';
import {SocialRemoteService} from '../../../../services/remote-services/social-remote.service';
import {NotificationService} from '../../../../../core/services/notification.service';
import {CoursePermissions} from '../../../../config/course-permissions';
import {AuthorizationService} from '../../../../../core/services/authorization.service';
import {DialogService} from '../../../../../core/services/dialog.service';
import {CourseTeacherMemberRemoteService} from '../../../../services/remote-services/course-teacher-member.remote.service';
import {CourseResolverService} from '../../../../services/resolver/course-resolver.service';
import {Subject} from 'rxjs';
import {CourseRemoteService} from '../../../../services/remote-services/course.remote.service';
import {Profile} from '../../profile/models/profile';
import {ProfileRemoteService} from '../../profile/services/remote/profile.remote.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-teacher-box',
    templateUrl: './teacher-box.component.html',
    styleUrls: ['./teacher-box.component.scss'],
    animations: fuseAnimations
})
export class TeacherBoxComponent implements OnInit, OnDestroy {
    private unsubscribeAll: Subject<any>;
    isFollowing: { string?: boolean } = {};
    disableBtnFollowing: { string?: boolean } = {};
    canFollowUser: boolean;
    profile: Profile;
    currentUser: any;
    avatar: string;
    canRemoveTeacher: boolean;
    canSelectPrincipal: boolean;
    canViewReport: boolean;

    @Input() teachers = [];
    @Input() courseId: string;

    constructor(private profileRemoteService: ProfileRemoteService, private socialRemoteService: SocialRemoteService,
                private notificationService: NotificationService, private authorizationService: AuthorizationService,
                private dialogService: DialogService, private courseTeacherMemberRemoteService: CourseTeacherMemberRemoteService,
                private courseResolverService: CourseResolverService,
                private courseRemoteService: CourseRemoteService, private route: ActivatedRoute, private router: Router,
    ) {
        this.unsubscribeAll = new Subject();
        this.profile = new Profile();
        this.currentUser = SecurityService.getUser();
        this.canFollowUser = this.authorizationService.hasPermission(CoursePermissions.FOLLOW_USER);
        this.canRemoveTeacher = this.authorizationService.hasPermission(CoursePermissions.COURSE_MEMBERS_DELETE);
        this.canSelectPrincipal = this.authorizationService.hasPermission(CoursePermissions.TEACHER_PRINCIPAL_UPDATE);
        this.canViewReport = this.authorizationService.hasPermission(CoursePermissions.REPORT_ACCESS_REVIEW_BY_TEACHER);
    }

    ngOnInit(): void {
        this.loadDataAboutFollowing();
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    loadDataAboutFollowing(): void {
        if (this.canFollowUser && this.teachers) {
            this.socialRemoteService.getFollowedInUsers(this.teachers.map(t => t.id)).subscribe(users => {
                this.teachers.forEach(t => {
                    this.isFollowing[t.id] = users.includes(t.id);
                });
            });
        }
    }

    followAction(teacher: any, isFollowAction: boolean): void {
        const message = (isFollowAction ? 'Siguiendo' : 'Ha dejado de seguir') + ` a ${teacher.firstName} ${teacher.lastName}`;
        const endpoint = isFollowAction ? this.socialRemoteService.create(teacher.id) : this.socialRemoteService.delete(teacher.id);

        this.disableBtnFollowing[teacher.id] = true;

        endpoint.subscribe(response => {
            if (response.success) {
                this.notificationService.showMessage(message);
                this.isFollowing[teacher.id] = !this.isFollowing[teacher.id];
            }
            this.disableBtnFollowing[teacher.id] = false;
        });
    }

    removeMember(userId: string): void {
        this.dialogService.showConfirmDialog('¿Está seguro que desea eliminar el mentor del curso?', () => {
            this.courseTeacherMemberRemoteService.delete(this.courseId, userId).subscribe(() => {
                this.courseResolverService.fetchTeachers();
            });
        });
    }

    setAsPrincipal(id): void {
        this.dialogService.showConfirmDialog('¿Está seguro que desea seleccionar el mentor como principal en el curso?', () => {
            const dataToSend = {
                userId: id,
                principal: true
            };
            this.courseTeacherMemberRemoteService.setAsPrincipal(this.courseId, dataToSend).subscribe(() => {
                this.notificationService.showMessage('Profesor seleccionado como mentor principal satisfactoriamente');
                this.courseResolverService.fetchTeachers();
            });
        });
    }
    goTo(teacherId): void{
        this.router.navigate(['../../../../report/course-access', teacherId, 0, 'dashboard'], {relativeTo: this.route});

    }

}
