import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {AppUtils} from '../../helpers/app.utils';
import {DialogFilterDate} from './dialog-filter-date/dialog-filter-date.component';
import * as moment from 'moment';
// import {DialogFilterListComponent} from '@quiox/global/components/qx-filter/dialog-filter-list/dialog-filter-list.component';
import {DialogFilterListComponent} from '../qx-filter/dialog-filter-list/dialog-filter-list.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

// declare const $;

@Component({
    selector: 'qx-filter',
    templateUrl: './qx-filter.component.html',
    styleUrls: ['./qx-filter.component.scss']
})
export class QxFilterComponent {
    chipSelect: any[] = [];
    filterCtrl: FormControl;
    addOnBlur = true;

    _filters;
    @Input()
    set filters(value) {
        this._filters = value;
        this._filters.forEach(item => {
            item['id'] = !item['id'] ? item['type'] : item['id'];
        });
    }

    get filters(): string {
        return this._filters;
    }

    @Output() change: EventEmitter<any>;
    dialogFilterList: MatDialogRef<DialogFilterListComponent>;
    dialogFilterDate: MatDialogRef<DialogFilterDate>;

    constructor(public dialog: MatDialog) {
        this.change = new EventEmitter<any>();
        this.change.subscribe(_ => this.add(null));

        this.filterCtrl = new FormControl();
        this.filterCtrl.valueChanges.subscribe(res => {
            if (!res) {
                this.filters[0]['value'] = res;
                this.filters[0]['text'] = res;
            }
        });
    }

    selectedOption(item) {
        this._filters.forEach(item => {
            item['id'] = !item['id'] ? item['type'] : item['id'];
        });
        if (item.option) {
            const chip = AppUtils.clone(item.option.value);
            const inChip = this.chipSelect.filter(x => x.id === chip.id);
            if (!inChip.length) {
                if (chip.type === 'list') {
                    this.dialogFilterList = this.dialog.open(DialogFilterListComponent, {
                        width: '45%',
                        height: '50%'
                    });
                    this.dialogFilterList.componentInstance.items = chip.options.list;
                    this.dialogFilterList.componentInstance.keyId = chip.options.keyId;
                    this.dialogFilterList.componentInstance.keyValue = chip.options.keyValue;
                    this.dialogFilterList.componentInstance.name = chip.options.name;
                    if (chip.options.url) {
                        const localidad = this.chipSelect.filter(x => x.type === 'list' && x.param['localidad']);
                        if (localidad.length) {
                            this.dialogFilterList.componentInstance.url = chip.options.url + '?localidad=' + localidad[0].param['localidad'];
                        } else {
                            this.dialogFilterList.componentInstance.url = chip.options.url;
                        }
                    }
                    this.dialogFilterList.afterClosed().subscribe(res => {
                        if (this.dialogFilterList.componentInstance.acept) {
                            const value = this.dialogFilterList.componentInstance.selected;
                            for (let i in chip.param) {
                                chip.param[i] = value[chip.options.keyId];
                            }
                            chip['value'] = value[chip.options.keyValue];
                            this.chipSelect.push(chip);
                            this.change.emit(this.chipSelect);
                        }
                    });
                } else if (chip.type === 'date') {
                    this.dialogFilterDate = this.dialog.open(DialogFilterDate, {
                        width: '45%',
                        height: '50%'
                    });
                    this.dialogFilterDate.afterClosed().subscribe(res => {
                        if (this.dialogFilterDate.componentInstance.acept) {
                            const startDate = moment(this.dialogFilterDate.componentInstance.form.value.minDate);
                            const endDate = moment(this.dialogFilterDate.componentInstance.form.value.maxDate);
                            chip.param['startDate'] = startDate.format();
                            chip.param['endDate'] = endDate.format();
                            chip['value'] = startDate.format('DD/MM/YYYY') + ' - ' + endDate.format('DD/MM/YYYY');
                            this.chipSelect.push(chip);
                            this.change.emit(this.chipSelect);
                        }
                    });
                } else {
                    this.chipSelect.push(chip);
                    this.change.emit(this.chipSelect);
                }
            } else {
                if (chip.type !== 'list' && chip.type !== 'date') {
                    const index = this.chipSelect.indexOf(inChip[0]);
                    this.chipSelect[index] = chip;
                    this.change.emit(this.chipSelect);
                } else {
                    if (chip.type === 'list') {
                        this.dialogFilterList = this.dialog.open(DialogFilterListComponent, {
                            width: '45%',
                            height: '50%'
                        });
                        this.dialogFilterList.componentInstance.items = chip.options.list;
                        this.dialogFilterList.componentInstance.keyId = chip.options.keyId;
                        this.dialogFilterList.componentInstance.keyValue = chip.options.keyValue;
                        this.dialogFilterList.componentInstance.name = chip.options.name;
                        if (chip.options.url) {
                            const localidad = this.chipSelect.filter(x => x.type === 'list' && x.param['localidad']);
                            if (localidad.length) {
                                this.dialogFilterList.componentInstance.url = chip.options.url + '?localidad=' + localidad[0].param['localidad'];
                            } else {
                                this.dialogFilterList.componentInstance.url = chip.options.url;
                            }
                        }
                        this.dialogFilterList.afterClosed().subscribe(res => {
                            if (this.dialogFilterList.componentInstance.acept) {
                                const value = this.dialogFilterList.componentInstance.selected;
                                for (let i in inChip[0].param) {
                                    inChip[0].param[i] = value[chip.options.keyId];
                                }
                                inChip[0]['value'] = value[inChip[0].options.keyValue];
                                // this.chipSelect.push(chip);
                                this.change.emit(this.chipSelect);
                            }
                        });
                    } else if (chip.type === 'date') {
                        this.dialogFilterDate = this.dialog.open(DialogFilterDate, {
                            width: '45%',
                            height: '50%'
                        });
                        this.dialogFilterDate.afterClosed().subscribe(res => {
                            if (this.dialogFilterDate.componentInstance.acept) {
                                const startDate = moment(this.dialogFilterDate.componentInstance.form.value.minDate);
                                const endDate = moment(this.dialogFilterDate.componentInstance.form.value.maxDate);
                                inChip[0].param['startDate'] = startDate.format();
                                inChip[0].param['endDate'] = endDate.format();
                                inChip[0]['value'] = startDate.format('DD/MM/YYYY') + ' - ' + endDate.format('DD/MM/YYYY');
                                //  this.chipSelect.push(chip);
                                this.change.emit(this.chipSelect);
                            }
                        });
                    }
                }
            }

        }
    }

    remove(item): void {
        const index = this.chipSelect.indexOf(item);

        if (index >= 0) {
            this.chipSelect.splice(index, 1);
            this.change.emit(this.chipSelect);
        }
    }

    add(event): void {
        // $('#filtro').val('');
        console.warn('usar aqui variante sin jquery');
    }
}
