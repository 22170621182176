import {Injectable} from '@angular/core';
import {ResolveEnd, Router} from '@angular/router';
import {AuthService} from 'app/main/core/auth/service/auth.service';
import * as _ from 'lodash';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {filter, take, takeUntil} from 'rxjs/operators';
import {FuseUtils} from '../../../../@fuse/utils';
import {AppConfigService} from '../../core/services/app-config.service';
import {SecurityService} from '../../core/services/security.service';
import {Chat} from '../models/chat';
import {ChatHeader} from '../models/chat-header';
import {Message} from '../models/message';
import {MessageSocketRemoteService} from './message-socket.remote.service';
import {MessageRemoteService} from './message.remote.service';

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    private readonly messagesCount: BehaviorSubject<any>;
    private currentUserId: string;
    private messagePageSize = 10;
    private unsubscribeAll: Subject<void>;
    private isChatOpened = false;
    private pendingChatDetails = false;
    private isConnected = false;
    private contactId: any;
    contacts: any[];
    chats: Chat[] = [];
    chatHeaders: ChatHeader[] = [];
    userSelected: string;
    chatItem: ChatHeader = null;
    onChatSelected = new BehaviorSubject(null);
    onContactSelected: BehaviorSubject<any>;
    onChatsUpdated = new BehaviorSubject([]);
    onUserUpdated: Subject<any>;
    onLeftSidenavViewChanged: Subject<any>;
    onRightSidenavViewChanged: Subject<any>;

    constructor(private messageSocketRemoteService: MessageSocketRemoteService,
                private messageRemoteService: MessageRemoteService,
                private appConfigService: AppConfigService,
                private router: Router,
                authService: AuthService,
    ) {
        this.onContactSelected = new BehaviorSubject(null);
        this.onUserUpdated = new Subject();
        this.onLeftSidenavViewChanged = new Subject();
        this.onRightSidenavViewChanged = new Subject();
        this.userSelected = '';
        this.messagesCount = new BehaviorSubject(0);

        // Esto era con el viejo método de autenticación
        // this.authenticationService.onLogout.subscribe(() => {
        //     this.messagesCount.next(0);
        //     this.disconnect();
        // });

        authService.registerLogoutHandler(() => {
            return this.disconnect();
        });

        this.unsubscribeAll = new Subject();

        this.initialize();
    }

    init(): void {
        const user = SecurityService.getUser();
        if (this.appConfigService.getAppConfig().disableChat || !user) {
            return;
        }

        this.currentUserId = user.id;
        this.chats = [];
        this.messageSocketRemoteService.connect().then(() => {
            this.subscribeToMessages();
            this.subscribeToUnreadMessageCount();
            this.messageSocketRemoteService.getUnreadMessageCount();
            this.isConnected = true;
            if (this.pendingChatDetails) {
                this.initChatDetails(this.contactId);
            }
            this.pendingChatDetails = false;
        });
    }

    initChatDetails(contactId?): void {
        if (this.isConnected) {
            this.chatHeaders = [];
            this.isChatOpened = true;
            this.subscribeToMessageCreated();
            this.subscribeToUserInfo();
            this.subscribeToHeaders(contactId);
            this.messageSocketRemoteService.getHeaders(true);
            this.subscribeToDialog();
        } else {
            this.pendingChatDetails = true;
            this.contactId = contactId;
        }
    }

    destroyChatDetails(): void {
        this.isChatOpened = false;
        this.messageSocketRemoteService.unsubscribeToMessageCreated();
        this.messageSocketRemoteService.unsubscribeToUserInfo();
        this.messageSocketRemoteService.unsubscribeToHeaders();
        this.messageSocketRemoteService.unsubscribeToDialog();
    }

    async disconnect(): Promise<void> {
        this.isChatOpened = false;
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
        this.messageSocketRemoteService.disconnect();
    }

    getChat(contactId): void {
        this.chatItem = this.chatHeaders.find(item => item.contactId === contactId);
        if (!this.chatItem || !this.chatItem.loaded) {
            this.messageSocketRemoteService.getDialog(contactId, this.messagePageSize);
        } else {
            const chat = this.chats.find(c => c.id === this.chatItem.id);
            this.loadConversation(chat);
        }
    }

    saveNewMessage(message): void {
        const responseToken = FuseUtils.generateGUID();
        this.messageSocketRemoteService.createMessage({...message, responseToken: responseToken});
        const chat = this.chats.find(c => c.id === this.chatItem.id);
        const msgData = Message.fromAttr(responseToken, this.currentUserId, message.message, message.created, true);
        chat.dialog.push(msgData);
        this.chatItem.updateMessageFromMsg(msgData);
        this.onChatSelected.next(this.getChatData(chat.id, chat.dialog));
    }

    getPreviewMessages(): void {
        const chat = this.getChatById(this.chatItem.id);
        const oldMessageDate = chat.dialog[0].time;
        this.messageRemoteService.getPreviousMessages(chat.id, oldMessageDate, this.messagePageSize).subscribe(messages => {
            messages.forEach(m => chat.dialog.splice(0, 0, m));
            const chatData = this.getChatData(chat.id, chat.dialog);
            this.onChatSelected.next({...chatData, allMessages: messages.length < this.messagePageSize, preview: true});
            const firstNotReadMessage = messages.find(m => !m.read && m.who !== this.currentUserId);
            if (firstNotReadMessage) {
                this.messageRemoteService.setPreviousMessagesAsRead(chat.id, firstNotReadMessage.created, this.messagePageSize)
                    .subscribe(() => {
                    });
            }
        });
    }

    get messageCount$(): Observable<any> {
        return this.messagesCount.asObservable();
    }

    private subscribeToUnreadMessageCount(): void {
        this.messageSocketRemoteService.subscribeToUnreadMessageCount().pipe(
            takeUntil(this.unsubscribeAll)
        ).subscribe(messageCount => {
            this.messagesCount.next(messageCount);
        });
    }

    private subscribeToHeaders(contactId): void {
        this.messageSocketRemoteService.subscribeToHeaders().pipe(
            takeUntil(this.unsubscribeAll)
        ).subscribe(headers => {
            headers.forEach(header => {
                this.updateChatWhenHeadersReceived(header.id, header);
                const chatHeader = this.chatHeaders.find(u => u.id === header.id);
                if (chatHeader && header.lastMessage.time > chatHeader.lastMessage.time) {
                    chatHeader.updateMessageFromMsg(header.lastMessage);
                } else {
                    this.chatHeaders.push(header);
                }
            });
            const promise = contactId ? this.createConversationIfNecessaryAndLoad(contactId) : Promise.resolve();
            promise.then(() => this.publishChats());
        });
    }

    private subscribeToDialog(): void {
        this.messageSocketRemoteService.subscribeToDialog().pipe(
            takeUntil(this.unsubscribeAll)
        ).subscribe(messages => {
            this.chatItem.loaded = true;
            const chat = this.getChatById(this.chatItem.id);
            if (messages.length > 0) {
                chat.dialog = chat.dialog.concat(messages.map(m => Message.fromData(m)));
                chat.dialog = _.uniqBy(chat.dialog, 'id');
                chat.dialog = _.sortBy(chat.dialog, 'time');
            }
            this.loadConversation(chat, messages.length);
        });
    }

    private subscribeToMessages(): void {
        this.messageSocketRemoteService.subscribeToMessages().pipe(
            takeUntil(this.unsubscribeAll)
        ).subscribe(message => {
            const chat = this.getChatById(message.conversationId);
            const newMsg = Message.fromData(message);
            if (!chat.dialog.some(c => c.id === newMsg.id)) {
                chat.dialog.push(newMsg);
            }

            if (this.isChatOpened) {
                this.updateChatHeaderFromChat(chat.id);

                if (this.chatItem && this.chatItem.id === message.conversationId) {
                    this.loadConversation(chat);
                }
            } else {
                this.messagesCount.next(this.messagesCount.getValue() + 1);
            }
        });
    }

    private subscribeToMessageCreated(): void {
        this.messageSocketRemoteService.subscribeToMessageCreated().pipe(
            takeUntil(this.unsubscribeAll)
        ).subscribe(message => {
            const currChat = this.chats.find(c => c.id === message.conversationId);
            const currMessage = currChat.dialog.find(m => m.id === message.responseToken);
            currMessage.id = message.id;
        });
    }

    private subscribeToUserInfo(): void {
        this.messageSocketRemoteService.subscribeToUserInfo().pipe(
            takeUntil(this.unsubscribeAll)
        ).subscribe(user => {
            if (user.id) {
                const chatHeader = this.chatHeaders.find(c => c.contactId === user.id);
                chatHeader.updateContactInfo(user.id, user.firstName + ' ' + user.lastName, user.image);
                chatHeader.visible = true;
                this.updateChatHeaderCount(chatHeader);
            }
        });
    }

    private loadConversation(chat: Chat, messagesCount?): void {
        const chatData = this.getChatData(this.chatItem.id, chat.dialog);
        if (messagesCount) {
            chatData.allMessages = messagesCount < this.messagePageSize;
        }
        this.onChatSelected.next(chatData);
        this.setNewMessagesAsRead(chat);
    }

    private setNewMessagesAsRead(chat: Chat): void {
        const notReadMessages = chat.dialog.filter(m => !m.read && m.who !== this.currentUserId).map(m => {
            m.read = true;
            return m.id;
        });
        if (notReadMessages.length > 0) {
            this.messageSocketRemoteService.setMessagesAsRead(notReadMessages);
            const chatHeader = this.chatHeaders.find(u => u.id === chat.id);
            const count = chatHeader.unreadCount - notReadMessages.length;
            chatHeader.unreadCount = count > 0 ? count : 0;

            const currValue = this.messagesCount.getValue() - notReadMessages.length;
            this.messagesCount.next(currValue > 0 ? currValue : 0);
        }
    }

    private getChatData(conversationId, dialog): any {
        return {
            chatId: conversationId,
            dialog: dialog,
            contact: {...this.chatItem, id: this.chatItem.contactId}
        };
    }

    private getChatById(id): Chat {
        let chat = this.chats.find(c => c.id === id);
        if (!chat) {
            chat = {id: id, dialog: []};
            this.chats.push(chat);
        }
        return chat;
    }

    private updateChatWhenHeadersReceived(id: string, header: ChatHeader): void {
        const chat = this.getChatById(id);
        if (header.lastMessage && !chat.dialog.some(m => m.id === header.lastMessage.id)) {
            const msgData = Message.fromAttr(header.lastMessage.id, header.lastMessage.who, header.lastMessage.message,
                header.lastMessage.time, header.lastMessage.read);
            chat.dialog.push(msgData);
        }
    }

    private updateChatHeaderFromChat(chatId: string): void {
        const chatHeader = this.chatHeaders.find(u => u.id === chatId);
        const chat = this.chats.find(c => c.id === chatId);
        if (chat.dialog.length > 0) {
            const message = chat.dialog[chat.dialog.length - 1];
            if (!chatHeader) {
                this.createChatHeader(chatId, message);
            } else if (chatHeader.lastMessage.time < message.time) {
                chatHeader.updateMessageFromMsg(message);
                this.updateChatHeaderCount(chatHeader);
            }
        }
    }

    private createChatHeader(chatId: string, message: Message): void {
        const chatHeader = ChatHeader.fromId(chatId).updateMessageFromMsg(message);
        chatHeader.contactId = message.who;
        chatHeader.visible = false;
        this.chatHeaders.push(chatHeader);
        this.messageSocketRemoteService.getUserInfo(message.who);
    }

    private updateChatHeaderCount(chatHeader: ChatHeader): void {
        if (!this.chatItem || this.chatItem.id !== chatHeader.id) {
            chatHeader.unreadCount += 1;
            this.messagesCount.next(this.messagesCount.getValue() + 1);
        }
        this.publishChats();
    }

    private publishChats(): void {
        this.onChatsUpdated.next(this.chatHeaders.filter(c => c.visible));
    }

    private async createConversationIfNecessaryAndLoad(contactId): Promise<void> {
        const chatToSelect = this.chatHeaders.find(c => c.contactId === contactId);
        if (!chatToSelect) {
            const conversation = await this.messageRemoteService.createConversation(contactId).toPromise();
            this.chatHeaders.push(ChatHeader.fromData(conversation));
        }
        this.getChat(contactId);
    }

    private initialize(): void {
        this.router.events.pipe(
            filter(event => event instanceof ResolveEnd),
            take(1)
        ).subscribe((event: ResolveEnd) => {
            this.init();
            // if (!event.urlAfterRedirects.startsWith('/welcome')) {
            //     this.init();
            // }
        });
    }

}
