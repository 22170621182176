import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { RestClientService } from './rest-client.service';
import { HttpClient } from '@angular/common/http';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import xml2js from 'xml2js';
import { DialogService } from './dialog.service';
import { AppConfigService } from './app-config.service';




@Injectable({
    providedIn: 'root'
})
export class ImageRemoteService {
    private URL = '/resource-api/request-data/';


    constructor(private restClientService: RestClientService, private http: HttpClient,
        private dialogService: DialogService, private appConfigService: AppConfigService) {
    }

    save(file: File): Observable<any> {
        // if (!!this.appConfigService.getAppConfig().localResourcesUpload) {
        //     return this.saveLocal(file);
        // }

        console.log('Entro al save');
        return this.restClientService.post(this.URL, { filename: file.name }).pipe(
            switchMap((data: any) => {
                console.log('Datos regresados: ', data);
                console.log('Datos file: ', file);
                const formData = ImageRemoteService.getFormData(file, data);
                console.log('Datos del formData: ', formData);
                const key = data.fields.key;
                return forkJoin([of(key), this.uploadFile1(data, formData)]);
            }),

            mergeMap((response: any) => {
                if (response[1]?.error) {
                    console.log('error subiendo a s3 descripcion:', response);
                    return throwError('error subiendo a s3');
                }
                return of({ data: response[0] });
            })
        );
    }

    private uploadFile(data, formData): Observable<any> {
        console.log('Llego al uploadFile');
        console.log('Data dentro del uploadFile', data);
        console.log('Data dentro del uploadFile', formData.get('key'));
        return this.http.post(data.url, formData, { observe: 'response' }).pipe(
            catchError((errResponse) => {
                return new Observable(subscriber => {

                    const parser = new xml2js.Parser({ trim: true, explicitArray: true });
                    parser.parseString(errResponse.error, (err, result) => {
                        if (result?.Error?.Code.includes('EntityTooLarge')) {
                            const maxSize = result?.Error.MaxSizeAllowed[0];
                            this.dialogService.showErrorDialog(`No se pueden subir recursos de más de ${maxSize / 1024} KB`, 'Tamaño excedido');
                        }
                        subscriber.next({ error: true });
                        subscriber.complete();
                    });
                });
            }),
            // map((errData: any) => {
            //     console.dir(errData);
            //     return errData;
            // })
        );
    }



    private static getFormData(file: any, data): FormData {
        const formData = new FormData();

        // let data = new Blob([JSON.stringify(dto)], {type: "application/json"});
        // formData.append("data", data);

        formData.append('Content-Type', file.type);
        Object.entries(data.fields).forEach(([k, v]) => {
            const value: any = v;
            console.log('Valores K, Value', k, value);
            formData.append(k, value);
        });
        formData.append('Content-Type', file.type);
        formData.append('file', file, file.name); // must be the last one

        return formData;
    }

    // ----------------  FOR DEV ---------------

    saveLocal(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.restClientService.post('/api/commons/resources', formData);
    }


    uploadFile1(data, formData: FormData): Observable<any> {
        // Reemplaza con la URL proporcionada por tu servidor para realizar la carga
        const s3UploadUrl = data.url;

        return this.http.post(s3UploadUrl, formData).pipe(
            catchError((error) => {
                console.error('Error al subir el archivo a S3:', error);
                return of({ error: true });
            })
        );
    }


}
