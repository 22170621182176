export class Message {
    id?: string;
    who?: string;
    message?: string;
    time?: Date;
    read?: boolean;

    constructor() {
    }

    static fromData(data): Message {
        const obj = Message.fromNewMsg(data.who, data.message, data.created);
        obj.id = data.id;
        obj.read = data.read;
        return obj;
    }

    static fromNewMsg(who, message, time): Message {
        const obj = new this();
        obj.who = who;
        obj.message = message;
        obj.time = time;
        return obj;
    }

    static fromAttr(id, who, message, time, read): Message {
        const obj = Message.fromNewMsg(who, message, time);
        obj.id = id;
        obj.read = read;
        return obj;
    }

}
