import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ContentBlockVideo} from '../../../block-view/models/content-block-video';
import {AppValidators} from '@quiox/global/helpers/app.validators';
import {ContentBlockTypeBase} from '../block-type-base';

@Component({
    selector: 'app-content-block-video-edit',
    templateUrl: './content-block-video-edit.component.html',
})
export class ContentBlockVideoEditComponent implements OnInit, ContentBlockTypeBase {
    @Input() form: FormGroup;
    @Input() model: ContentBlockVideo;
    @Input() disabledBlock = false;

    constructor(private fb: FormBuilder) {
    }

    ngOnInit(): void {
        this.form.addControl('videoUrl', this.fb.control('', [Validators.required, AppValidators.url]));
        this.form.patchValue(this.model);
        this.changeToDisable(true);
        
    }

    changeToDisable(disable: any): void {
        if(disable) {
            this.form.controls['videoUrl'].disable();
        } else {
            this.form.controls['videoUrl'].enable();
        }
    }
}
