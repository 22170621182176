import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LangCurrencyService} from 'app/main/core-common/services/lang-currency.service';
import {AuthService} from 'app/main/core/auth/service/auth.service';
import {DialogService} from 'app/main/core/services/dialog.service';
import {RestClientService} from 'app/main/core/services/rest-client.service';
import {SecurityService} from 'app/main/core/services/security.service';
import {StorageService} from 'app/main/core/services/storage.service';
import {environment} from 'environments/environment';
import {from, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {PaymentCartRemoteService} from './payment-cart.remote.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {GoCartDialogComponent} from '../components/go-cart-dialog/go-cart-dialog.component';
import {UnitEditDialogComponent} from '../../course/components/common/course-detail/unit/unit-edit-modal/unit-edit-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class ShoppingCartService {
    // private static USER_CART_KEY = 'CART_';
    readonly testMode: boolean = false; // !environment.production;
    private _orderId: string;
    private countDown = 10;
    private _courses: any[] = [];
    private _coursesUniversity: any[] = [];
    private _amount = 0;
    private _amountUniversity = 0;
    private _order: any = {};
    private _status = 'CREATED';
    private _link: any = {};
    private _currentErrorMsg: string;
    currentPaymentMethod: any;
    private goCartDialog: MatDialogRef<GoCartDialogComponent>;


    constructor(private restClient: RestClientService, private dialogService: DialogService,
                private langCurrencyService: LangCurrencyService, private httpClient: HttpClient,
                private storageService: StorageService, private authService: AuthService,
                private paymentCartRemoteService: PaymentCartRemoteService, private matDialog: MatDialog,) {

        SecurityService.userInfo$().subscribe(user => {
            if (user) {
                // this.load();
                // this.loadUniversityCart();
            }
        });
    }

    load(): any {
        this.paymentCartRemoteService.get().subscribe(courses => {
            this._courses = courses;
        });
    }

    loadUniversityCart(): any {
        this.paymentCartRemoteService.getUniversityCart().subscribe(courses => {
            this._coursesUniversity = courses;
        });
    }

    reset(): void {
        this._orderId = null;
        this._courses = [];
        this._coursesUniversity = [];
        this._order = {};
        this._status = null;
        this._link = {};
    }

    contains(courseId: any): boolean {
        return !!this._courses.find(item => item.courseId === courseId);
    }

    async push(course: any, generationId: string): Promise<any> {
        const user = SecurityService.getUser();
        if (!user) {
            localStorage.course = JSON.stringify(course);
            return 'user-invalid';
        }

        if (this.contains(course.courseId)) {
            return true;
        }

        const res = await this.restClient.get<any>('/api/payment/order/user-has/' + course.courseId).toPromise();
        if (res.success && !res.data) {
            if (generationId) {
                course.generationId = generationId;
                const response = await this.paymentCartRemoteService.addUniversityCart(course.courseId, generationId, course.courseAnnouncementId).toPromise();
                if (response.data.existInCart === false) {
                    this.coursesUniversity.push(course);
                }
                else{
                    // this.openDialog();
                    return  false;
                }

            } else {
                await this.paymentCartRemoteService.add(course.courseId, generationId).toPromise();
                this._courses.push(course);
            }

            // aqui agrego segun sea el tipo

            // this._coursesUniversity.push(course);
            // this.storageService.setObject(ShoppingCartService.USER_CART_KEY + user.id, this._courses);
        } else {
            if (res.message === 'has-order-proccesing') {
                this.dialogService.showErrorDialog('Usted tiene una compra en proceso.', 'Carrito de compra');
            } else {
                this.dialogService.showErrorDialog('Usted ya ha comprado el curso.', 'Carrito de compra');
            }
        }
        return !res.data;
    }

    async remove(course, courseAnnouncementId: string): Promise<void> {
        await this.paymentCartRemoteService.delete(course.courseId, courseAnnouncementId).toPromise();
        this._courses = this._courses.filter(item => item.courseId !== course.courseId);
        this._coursesUniversity = this._coursesUniversity.filter(item => item.courseId !== course.courseId);
        // this.storageService.setObject(ShoppingCartService.USER_CART_KEY + SecurityService.getUser().id, this._courses);
    }

    async buy(paymentMethod): Promise<any> {
        const courseIds = this._courses.map(course => course.courseId);

        // const courseIds = this._coursesUniversity.map(course => course.courseId);
        const reqData = {courseIds, paymentMethod};
        const params = {
            currency: this.currency,
            couponCode: localStorage.couponCode
        };
        const res = await this.restClient.post<any>('/api/payment/order', reqData, params).toPromise();

        if (res.success) {
            const data = res.data;
            this._link = data.link;
            this._orderId = data.orderId;
        } else {
            if (res.message === 'has-order-proccesing') {
                this.dialogService.showErrorDialog('Usted tiene una compra en proceso.', 'Carrito de compra');
            } else {
                if (res.message) {
                    this.dialogService.showErrorDialog(res.message, 'Carrito de compra');
                } else {
                    this.dialogService.showErrorDialog('No se ha podido conectar con el banco. Por favor, inténtelo mas tarde.', 'Carrito de compra');

                }
            }
        }

        return res;
    }

    async buyUniversity(paymentMethod): Promise<any> {
        const courseIds = this._coursesUniversity.map(course => course.courseId);
        const courseFromUniversity = [];
        this._coursesUniversity.map(course => {
                courseFromUniversity.push({
                    courseId: course.courseId,
                    courseAnnouncementId: course.courseAnnouncementId,
                    generationId: course.generationId,
                    ambitState: course.ambitState
                });
            }
        );

        // const courseIds = this._coursesUniversity.map(course => course.courseId);
        // const courseAnnouncementIds: any = [{
        //     courseId: '123',
        //     courseAnnouncementId: '1234',
        //     enrolmentAnnouncementId: '12345'
        // }];
        const reqData = {courseIds, courseFromUniversity, paymentMethod};
        const params = {currency: this.currency, couponCode: localStorage.couponCode};
        const res = await this.restClient.post<any>('/api/payment/order/university', reqData, params).toPromise();

        if (res.success) {
            const data = res.data;
            this._link = data.link;
            this._orderId = data.orderId;
        } else {
            if (res.message === 'has-order-proccesing') {
                this.dialogService.showErrorDialog('Usted tiene una compra en proceso.', 'Carrito de compra');
            } else {
                if (res.message) {
                    this.dialogService.showErrorDialog(res.message, 'Carrito de compra');
                } else {
                    this.dialogService.showErrorDialog('No se ha podido conectar con el banco. Por favor, inténtelo mas tarde.', 'Carrito de compra');
                }
            }
        }

        return res;
    }

    calculate(): Observable<any> {
        const coursesId = this._courses.map(course => course.courseId);
        return this.restClient.post<any>('/api/payment/order/amount', coursesId, {
            currency: this.currency,
            couponCode: localStorage.couponCode
        }).pipe(
            tap(res => this._amount = res.data)
        );
    }

    calculateUniversity(): Observable<any> {
        const courseFromUniversity = [];
        this._coursesUniversity.map(course => {
            courseFromUniversity.push({
                courseId: course.courseId,
                courseAnnouncementId: course.courseAnnouncementId,
                generationId: course.generationId,
                ambitState: course.ambitState
            });
        });
        // const coursesId = this._coursesUniversity.map(course => course.courseId);
        return this.restClient.post<any>('/api/payment/order/amount-university', courseFromUniversity, {
            currency: this.currency,
            couponCode: localStorage.couponCode
        }).pipe(
            tap(res => this._amountUniversity = res.data)
        );
    }

    details(id): Observable<any> {
        return this.restClient.get<any>('/api/payment/order/' + id);
    }

    invoice(id): Observable<any> {
        return this.restClient.get<any>(`/api/payment/order/${id}/invoice`);
    }

    invoiceFromList(id): Observable<any> {
        return this.restClient.get<any>(`/api/payment/order/${id}/invoice-by-payment`);
    }

    reload(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.restClient.get<any>('/api/payment/order/cart', {
                currency: this.currency,
                couponCode: localStorage.couponCode
            }).subscribe(res => {
                const order = res.data;
                if (order) {
                    this._courses = order.courses;
                    this._amount = order.amount;
                    this._status = order.status;
                    this._order = order;
                    this._orderId = order.id;
                    this._link = {
                        linkUrl: order.linkUrl
                    };
                }
                resolve(order);
            }, reject);
        });
    }

    checkPayment(): Promise<any> {
        this._orderId = localStorage.pendingPayment;
        this.countDown = 5;
        return new Promise((resolve, reject) => {
            this.checkPaymentRemote(resolve, reject);
        });
    }

    private checkPaymentRemote(resolve, reject): void {
        if (this.countDown <= 0) {
            reject('timeout');
            return;
        }

        this.countDown--;

        setTimeout(() => {
            this.restClient.get<any>('/api/qxpay/support/' + this.orderId + '/status').subscribe(
                res => {
                    const data: any = res.data;
                    this._status = data.status;
                    switch (this._status) {
                        case 'PAID':
                            resolve(data);
                            break;
                        case 'CANCELED':
                            reject(data.errorText);
                            break;
                        default:
                            this.checkPaymentRemote(resolve, reject);
                    }
                }, () => this.checkPaymentRemote(resolve, reject)
            );
        }, 5000);
    }

    reproccess(orderId): Observable<any> {
        return this.restClient.post<any>('/api/payment/reproccess/' + orderId, {});
    }

    testComplete(): void {
        this.httpClient.post<any>('http://localhost:8009/api/bank/complete/' + this.orderId, null)
            .subscribe(() => {
            }, err => console.log(JSON.stringify(err)));
    }

    testCancel(): void {
        this.httpClient.post<any>('http://localhost:8009/api/bank/cancel/' + this.orderId, null)
            .subscribe(() => {
            }, err => console.log(JSON.stringify(err)));
    }

    findAll(page, size): Observable<any> {
        return this.restClient.get<any>('/api/payment/order', {page, size});
    }

    coursesRecommended(courseId: string): Observable<any> {
        const coursesId = this._courses.map(course => course.courseId);
        return this.restClient.get<any>('/api/payment/courses/recommended', {
            currency: this.currency,
            ignoredCourses: coursesId,
            courseId: courseId,
            couponCode: localStorage.couponCode
        });
    }

    download(path, filename): void {
        this.restClient.getBlob(path, {responseType: 'blob'}).subscribe((res: any) => {
            const blob = new Blob([res]);
            this.saveAs(blob, filename);
        });
    }

    saveAs(blob: Blob, filename): void {
        const url = window.URL.createObjectURL(blob);
        const a: any = document.createElement('a');
        a.style = 'display: none';

        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
        }, 1000);
    }

    open(path, filename): void {

        this.restClient.getBlob(path, {responseType: 'blob'}).subscribe((res: any) => {
            const blob = new Blob([res], {type: 'application/pdf'});
            this.openAS(blob, filename);
        });
    }

    openAS(blob: Blob, filename): void {
        const objUrl = window.URL.createObjectURL(blob);
        const linkElement = document.createElement('a');
        linkElement.href = objUrl;
        //  linkElement.download = filename;
        linkElement.setAttribute('style', 'display: none');

        linkElement.setAttribute('target', '_blank');

        document.body.appendChild(linkElement);
        linkElement.click();
    }

    getMyOrders(page): Observable<any> {
        return this.restClient.get<any>('/api/payment/order/me', {page: page, size: 5});
    }

    getPaymentCourseById(courseId: any): Observable<any> {
        return this.restClient.get<any>(`/api/payment/course/${courseId}`, {
            currency: this.currency,
            couponCode: localStorage.couponCode
        });
    }

    get bankUrl(): any {
        if (this.testMode) {
            return 'http://localhost:8001/bank/' + this.orderId;
        }

        return this._link.linkUrl;
    }

    getCouponInfo(couponCode: any): Observable<any> {
        return this.restClient.get<any>(`/api/payment-cart/coupon-info`, {
            currency: this.currency,
            couponCode: couponCode
        });
    }

    restoreCouponUse(couponCode): Observable<any> {
        return this.restClient.put<any>('/api/payment/order/restore/' + couponCode);
    }

    get orderId(): any {
        return this._orderId;
    }

    get currency(): any {
        return this.langCurrencyService.getCurrent().currency;
    }

    get courses(): any[] {
        return this._courses;
    }

    get coursesUniversity(): any[] {
        return this._coursesUniversity;
    }

    get amount(): any {
        return this._amount;
    }

    set amount(value) {
        this._amount = value;
    }

    get amountUniversity(): any {
        return this._amountUniversity;
    }

    set amountUniversity(value) {
        this._amountUniversity = value;
    }

    get order(): any {
        return this._order;
    }

    get status(): any {
        return this._status;
    }

    get count(): any {
        return this._courses.length;
    }

    get universityCount(): any {
        return this._coursesUniversity.length;
    }

    get currentErrorMsg(): string {
        return this._currentErrorMsg;
    }

    set currentErrorMsg(msg: string) {
        this._currentErrorMsg = msg;
    }

    openDialog(): void {
        this.goCartDialog = this.matDialog.open(GoCartDialogComponent, {
            panelClass: 'contact-form-dialog',
            restoreFocus: false,
            autoFocus: false
        });
    }
}
