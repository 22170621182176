import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ContentBlock} from '../../block-view/models/content-block';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-content-block-edit',
    templateUrl: './content-block-edit.component.html',
})
export class ContentBlockEditComponent implements OnInit, OnDestroy, AfterViewInit {
    private unsubscribeAll: Subject<any>;
    title: string;
    formValid = false;
    busyFlag = false;
    managedFlag = false;

    disabledBlock: FormControl;

    @Input() form: FormGroup;
    @Input() model: ContentBlock;
    @Input() someBlockActivedFlag: boolean;

    @Output() deleteAction: EventEmitter<any>;
    @Output() moveUpAction: EventEmitter<number>;
    @Output() moveDownAction: EventEmitter<number>;
    @Output() saveAction: EventEmitter<any>;
    @Output() someBlockActived: EventEmitter<any>;

    @ViewChild('childBlock')
    childBlockComponent: any;

    constructor(private fb: FormBuilder, private fbD: FormBuilder) {
        this.unsubscribeAll = new Subject();
        this.deleteAction = new EventEmitter();
        this.moveUpAction = new EventEmitter();
        this.moveDownAction = new EventEmitter();
        this.saveAction = new EventEmitter();
        this.someBlockActived = new EventEmitter();
        this.disabledBlock = new FormControl(false);
    }

    ngOnInit(): void {
        this.title = this.model.getTitle();
        this.form.addControl('id', this.fb.control(null));
        this.form.addControl('topMargin', this.fb.control(null, [Validators.required]));
        this.form.addControl('bottomMargin', this.fb.control(null, [Validators.required]));
        this.form.addControl('order', this.fb.control(null));
        this.form.addControl('type', this.fb.control(this.model.getTypeAsString()));

        if (this.model.newBlock) {
            this.disabledBlock.setValue(true);
        } else {
            this.form.controls['topMargin'].disable();
            this.form.controls['bottomMargin'].disable();
        }

    }

    ngAfterViewInit(): void {
        this.form.statusChanges
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(status => {
                this.formValid = this.form.valid;
                this.busyFlag = this.form.disabled;
            });
        setTimeout(() => {
            this.formValid = this.form.valid;

            this.managedFlag = this.form.get('id').value !== null;
            if (this.model.newBlock) {
                this.childBlockComponent.changeToDisable(false);
            }
        });


    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    moveUpInOrder(): void {
        this.moveUpAction.emit(this.model.order);
        this.disabledBlock.setValue(false);
        this.someBlockActived.emit(false);
        this.childBlockComponent.changeToDisable(true);
    }

    moveDownInOrder(): void {
        this.moveDownAction.emit(this.model.order);
        this.disabledBlock.setValue(false);
        this.someBlockActived.emit(false);
        this.childBlockComponent.changeToDisable(true);
    }

    save(): void {
        this.saveAction.emit({
            formValue: this.form.value,
            reloadHandler: this.reloadHandler.bind(this),
            customData: this.getCustomData.bind(this)
        });

        this.disabledBlock.setValue(false);
        this.someBlockActived.emit(false);
        this.childBlockComponent.changeToDisable(true);
    }

    delete(): void {
        this.deleteAction.emit({
            formValue: this.form.value,
            order: this.model.order
        });
        this.someBlockActived.emit(false);
    }

    get blockType(): string {
        return this.model.getTypeAsString();
    }

    get isFirst(): boolean {
        return this.model.order === 1;
    }

    get isLast(): boolean {
        return this.model.order === (this.form.parent as FormArray).length;
    }

    reloadHandler(data): void {
        this.form.patchValue(data);
        this.managedFlag = this.form.get('id').value !== null;
        if ('updateData' in this.childBlockComponent) {
            this.childBlockComponent.updateData(data);
        }
    }

    getCustomData(): any {
        if ('getCustomData' in this.childBlockComponent) {
            return this.childBlockComponent.getCustomData();
        }
    }

    onResourceAction(data): void {
        this.saveAction.emit({
            formValue: this.form.value,
            reloadHandler: this.reloadHandler.bind(this),
            customData: this.getCustomData.bind(this),
            resourceData: data,
        });
        this.disabledBlock.setValue(false);
        this.someBlockActived.emit(false);
        this.childBlockComponent.changeToDisable(true);
    }

    toggleReadWrite(): void {
        this.form.enable();
        if (this.disabledBlock.value) {
            // when disable block
            this.someBlockActived.emit(false);
            this.form.controls['topMargin'].disable();
            this.form.controls['bottomMargin'].disable();

            this.childBlockComponent.changeToDisable(true);
        } else {
            // when enable block
            this.someBlockActived.emit(true);
            if (this.someBlockActivedFlag) {
                // when there is other block enable
                this.disabledBlock.setValue(true);
            } else {
                this.form.controls['topMargin'].enable();
                this.form.controls['bottomMargin'].enable();

                this.childBlockComponent.changeToDisable(false);
            }
        }
    }
}
