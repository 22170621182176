<div class="page-layout simple fullwidth">

    <!-- HEADER -->
    <div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="mr-0 mr-sm-16" (click)="onBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>

            <div fxLayout="column" fxLayoutAlign="start start" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                <div class="h2">
                    {{ titleConfig.title | translate }}
                </div>
                <div class="subtitle secondary-text">
                    <span>{{ titleConfig.subTitle | translate }}</span>
                </div>
            </div>
        </div>

        <button mat-raised-button class="save-product-button" [disabled]="form.invalid || submitting" (click)="onSave()">
            <mat-icon class="mr-8">save_alt</mat-icon>
            <span>{{ 'core.save' | translate }}</span>
        </button>
    </div>
    <!-- / HEADER -->

    <div class="p-16 p-md-24">

            <mat-card class="pt-28">
                <mat-card-content fxLayout="column">
                        <ng-content></ng-content>
                </mat-card-content>
            </mat-card>

    </div>

</div>
