<!--<div class="expandable-text">-->
<!--    <p *ngIf="!expanded; else fullText" class="preview-text" [innerHTML]="getTruncatedText()"></p>-->
<!--    <ng-template #fullText>-->
<!--        <p class="full-text" [innerHTML]="text"></p>-->
<!--    </ng-template>-->
<!--    <button (click)="toggleExpand()">-->
<!--        {{ expanded ? 'Ver menos' : 'Ver más' }}-->
<!--    </button>-->
<!--</div>-->


<div class="expandable-text">
    <p *ngIf="!expanded; else fullText" class="preview-text" [innerHTML]="truncatedText"></p>
    <ng-template #fullText>
        <p class="full-text" [innerHTML]="fullTextContent"></p>
    </ng-template>
    <a (click)="toggleExpand()" style="cursor: pointer" *ngIf="seeMoreText()">{{ expanded ? 'Ver menos...' : 'Ver más...' }}</a>
</div>