<div class="dual-list-container mb-16" fxLayout="row">

    <div style="width: 100%;" fxFlex>
        <div *ngIf="showTitles" class="h3 secondary-text" style="font-size: 18px;">{{titleAviable}}</div>
        <qx-select-list #select2></qx-select-list>
    </div>
    <div class="dual-list-actions" fxLayout="column" fxFlex fxLayoutAlign="center center">
        <button mat-icon-button class="mb-16" (click)="addAllItem()">
            <mat-icon matTooltip="Seleccionar Todos">last_page</mat-icon>
        </button>
        <button mat-icon-button class="mb-16" (click)="addItem()">
            <mat-icon matTooltip="Seleccionar">navigate_next</mat-icon>
        </button>
        <button mat-icon-button class="mb-16" (click)="removeItem()">
            <mat-icon matTooltip="Deshacer">navigate_before</mat-icon>
        </button>
        <button mat-icon-button class="mb-16" (click)="removeAllItem()">
            <mat-icon matTooltip="Deshacer Todo">first_page</mat-icon>
        </button>
    </div>
    <div style="width: 100%;" fxFlex>
        <div *ngIf="showTitles" class="h3 secondary-text" style="font-size: 18px;">{{titleSelected}}</div>
        <qx-select-list #select1></qx-select-list>
    </div>

</div>
