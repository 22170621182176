import {Component, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {WelcomeContentRemoteService} from '../../../general/services/welcome-content-remote.service';

@Component({
    selector: 'app-help-change',
    templateUrl: './help-change.component.html',
    styleUrls: ['./help-change.component.scss']
})
export class HelpChangeComponent implements OnInit {
    whatsappLink: string;
    private unsubscribeAll: Subject<void>;
    whatsappPhone: string;
    whatsappMsg = 'Hola, estoy interesado en recibir información de los cursos de NEUUNI';

    constructor(private welcomeContentRemoteService: WelcomeContentRemoteService) {
        this.unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        // this.loadWhatsappNumber();
    }

    setLinkWhatsApp(): void {
        this.whatsappLink = 'https://api.whatsapp.com/send?phone='
            + this.whatsappPhone
            + '&text='
            + encodeURIComponent(this.whatsappMsg);
    }

    loadWhatsappNumber(): void {
        this.welcomeContentRemoteService.getWhatsappNumber().pipe(
            takeUntil(this.unsubscribeAll)
        ).subscribe(value => {
            if (value !== null) {
                this.whatsappPhone = value.whatsapp;
            }
            this.setLinkWhatsApp();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }
}
