import {ModuleConfig} from '@quiox/global/config/config';
import {GeneralPermissions} from 'app/main/general/config/general-permissions';

export const userReportMenu: ModuleConfig = {
    id: 'payment',
    name: 'Reporte de usuarios',
    menu: [
        {
            name: 'Reporte de usuarios',
            // fieldI18Key: 'NAV.INVOICES',
            url: '/user-report',
            exactMatch: true,
            roles: [GeneralPermissions.COURSE_REPORT_VIEW]
        }
    ]
};
