import {Pipe, PipeTransform} from '@angular/core';
import {FileType} from '../../course/components/common/course-preview/unit-project/components/project-resource/file-type';

@Pipe({
    name: 'appIcon',
})
export class FileTypePipe implements PipeTransform {

    constructor() {
    }

    public transform(type): string {
        return FileType.types[type].icon;
    }

}
