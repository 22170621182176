import {Pageable} from '../../../course/models/pageable';


export class LikeSearchCriteria extends Pageable {
    public q: string;

    constructor(size: number) {
        super(size);
    }
}
