import {ProcessNotification} from '../user-notification.service';

export class InscriptionCourseToTeacherHandlers implements ProcessNotification {

    processNotification(notification, data, roleContext): void {
        notification.text = `Hay un nuevo estudiante en el curso: ${data.courseName}`;
        // notification.url = `/courses/course/${data.courseId}/preview/${roleContext}/0/detail`;
        notification.icon = 'school';
        notification.avatar = '/assets/images/logos/neuuni_isotipo_250px.png';
    }

}
