import {NeuuniWatchPermissions} from '../neuuni-watch-moderator/config/neuuni-watch-permissions';


export const neuuniWatchModeratorMenu = {
    id: 'neuuniWatch',
    name: 'neuuniWatch',
    fieldI18Key: 'NAV.neuuniWatch',
    menu: [
        {
            name: 'neuuniWatch',
            fieldI18Key: 'NAV.neuuniWatch',
            url: '/neuuni-watch',
            icon: 'camera',
            roles: [NeuuniWatchPermissions.VIEW_VIDEO]
        },
        {
            name: 'nwCategories',
            fieldI18Key: 'NAV.CATEGORIES',
            url: '/neuuni-watch-moderator/category',
            icon: 'camera',
            roles: [NeuuniWatchPermissions.MANAGE_CATEGORY_VIDEO]
        },
        {
            name: 'nwSeries',
            fieldI18Key: 'NAV.series',
            url: '/neuuni-watch-moderator/series',
            icon: 'camera',
            roles: [NeuuniWatchPermissions.MANAGE_SERIES]
        },
        {
            name: 'nwVideo',
            fieldI18Key: 'NAV.videos',
            url: '/neuuni-watch-moderator/videos',
            icon: 'camera',
            roles: [NeuuniWatchPermissions.MANAGE_VIDEO]
        },
        {
            name: 'nwTendencies',
            fieldI18Key: 'NAV.tendencies',
            url: '/neuuni-watch-moderator/tendency',
            icon: 'camera',
            roles: [NeuuniWatchPermissions.MANAGE_TRENDING]
        },
        {
            name: 'nwmoreViews',
            fieldI18Key: 'NAV.moreViews',
            url: '/neuuni-watch-moderator/more-views',
            icon: 'camera',
            roles: [NeuuniWatchPermissions.MANAGE_TRENDING]
        }
    ]
};

export const neuuniWatchManagerMenu = {
    projectName: '',
    projectLogo: 'assets/img/neuuni_logotipo_blanco.png',
    modules: [
        neuuniWatchModeratorMenu
    ]
};
