import {CoursePermissions} from '../course/config/course-permissions';
import {IndicatorPermissions} from '../measurement-indicator/config/indicator-permissions';

const configCoordinatorCourse = {
    id: 'course',
    name: 'Curso',
    fieldI18Key: 'NAV.COURSE',
    hidden: true,
    menu: [
        {
            name: 'Enable Edition',
            fieldI18Key: 'NAV.ENABLE_EDITION',
            url: '/courses/student',
        },
        {
            name: 'Settings',
            fieldI18Key: 'NAV.SETTINGS',
            url: '/courses/message',
        }/*,
        {
            name: 'Settings',
            fieldI18Key: 'NAV.SETTINGS',
            url: '/courses/course/:id',
        }*/
    ]

};

export const menuCoordinator = {
    projectName: '',
    projectLogo: 'assets/img/neuuni_logotipo_blanco.png',
    modules: [
        {
            id: 'home',
            name: 'Inicio',
            fieldI18Key: 'NAV.HOME',
            menu: [
                {
                    name: 'Courses',
                    fieldI18Key: 'NAV.COURSES',
                    // url: '/courses/course-supervisor',
                    url: '/courses/coordinator',
                    icon: 'school',
                    exactMatch: true,
                    roles: [CoursePermissions.COURSE_VIEW_COORDINATOR],
                },
                {
                    name: 'indicatorKDSurvey',
                    fieldI18Key: 'NAV.indicatorKDSurvey',
                    url: '/measurement-indicator/indicadorkd-survey',
                    icon: 'school',
                    roles: [IndicatorPermissions.INDICATOR_CADE_VIEW]
                }
                /*{
                    id: 'myGeneralMessages',
                    name: 'Messages',
                    fieldI18Key: 'NAV.MESSAGES',
                    url: '/courses/message',
                    icon: 'chat',
                },*/
            ]

        },
        // configSupervisorTeacherCourse,
    ]
};
