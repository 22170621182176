export const config = {
    modules: []
};

export interface ModuleConfig {
    id: string;
    name: string;
    title?: string;
    fieldI18Key?: string;
    hidden?: boolean;
    isEnrolled?: boolean;
    menu: MenuItem[];
}

export interface MenuItem {
    id?: string;
    name: string;
    fieldI18Key?: string;
    url?: string;
    icon?: string;
    exactMatch?: boolean;
    classes?: string;
    isEnrolled?: boolean;
    roles?: string[];
    childrens?: MenuItem[];
}
