import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {ContentBlockText} from '../../../block-view/models/content-block-text';
import {MatSelectChange} from '@angular/material/select';
import {ContentBlockTypeBase} from '../block-type-base';
import {AppValidators} from '@quiox/global/helpers/app.validators';
import {ContentBlockH5P} from '../../../block-view/models/content-block-h5p';

@Component({
    selector: 'app-content-block-h5p-edit',
    templateUrl: './content-block-h5p-edit.component.html',
})
export class ContentBlockH5pEditComponent implements OnInit, ContentBlockTypeBase {
    @Input() form: FormGroup;
    @Input() model: ContentBlockH5P;
    @Input() disabledBlock = false;

    // hideText: boolean;

    constructor(private fb: FormBuilder) {
    }

    ngOnInit(): void {
        this.form.addControl('src', this.fb.control('', [Validators.required, AppValidators.image]));
        this.form.addControl('title', this.fb.control('', [Validators.required, AppValidators.image]));
        this.form.addControl('height', this.fb.control('', [Validators.required, AppValidators.image]));
        this.form.patchValue(this.model);

        // if (this.model.textType === BlockTextType.TEXT) {
        //     this.hideText = true;
        // }
        
        if (this.model.newBlock) {
            this.disabledBlock = false;
            this.changeToDisable(false);
        }
        else {
            this.changeToDisable(true);
        }
    }

    changeToDisable(disable: boolean): void {
            if (disable) {
                this.form.controls['src'].disable();
                this.form.controls['title'].disable();
                this.form.controls['height'].disable();
                this.disabledBlock = true;

            } else {
                this.form.controls['src'].enable();
                this.form.controls['title'].enable();
                this.form.controls['height'].enable();
                this.disabledBlock = false;
            }
        }



}
