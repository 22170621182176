import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {fuseAnimations} from '@fuse/animations';
import {BlockInfo} from './blocks/block-info';
import {ContentBlockImage} from '../block-view/models/content-block-image';
import {ContentBlockVideo} from '../block-view/models/content-block-video';
import {ContentBlock} from '../block-view/models/content-block';
import {ContentBlockVideoApp} from '../block-view/models/content-block-video-app';
import {DialogService} from '../../core/services/dialog.service';
import {ContentBlockText} from '../block-view/models/content-block-text';
import {ContentBlockResource} from '../block-view/models/content-block-resource';
import {ContentBlockLabel} from '../block-view/models/content-block-label';
import {ContentBlockAudioApp} from '../block-view/models/content-block-audio-app';
import {ContentBlockH5P} from '../block-view/models/content-block-h5p';

@Component({
    selector: 'app-content-block-edit-container',
    templateUrl: './content-block-edit-container.component.html',
    animations: fuseAnimations
})
export class ContentBlockEditContainerComponent implements OnInit, OnDestroy {
    blocks: BlockInfo[];
    formArray: FormArray;
    unManagedBlocks = null;
    someBlockActivedFlag: boolean;
    ableText: boolean;
    ableImage: boolean;
    ableVideo: boolean;
    ableAppVideo: boolean;
    ableAppAudio: boolean;
    ableResource: boolean;
    ableLabel: boolean;
    ableH5P: boolean;
    @Input() contentBlocks: any [];
    @Input() blockVideoAppOnlyOne: boolean;
    @Input() countVideoApp: number;
    @Output() blockAction: EventEmitter<any>;
    @Output() closeAction: EventEmitter<void>;


    constructor(protected fb: FormBuilder, private dialogService: DialogService) {
        this.formArray = new FormArray([]);
        this.blocks = [];
        this.blockAction = new EventEmitter();
        this.closeAction = new EventEmitter();
    }

    ngOnInit(): void {
        this.setBlocks();
        this.setBlockOnlyVideoAws();

    }

    ngOnDestroy(): void {
    }

    setBlocks(): void {
        this.ableText = this.contentBlocks.includes('Text');
        this.ableImage = this.contentBlocks.includes('Image');
        this.ableVideo = this.contentBlocks.includes('Video');
        this.ableAppVideo = this.contentBlocks.includes('AppVideo');
        this.ableAppAudio = this.contentBlocks.includes('AppAudio');
        this.ableResource = this.contentBlocks.includes('Resource');
        this.ableLabel = this.contentBlocks.includes('Label');
        this.ableH5P = this.contentBlocks.includes('H5P');
    }

    setBlockOnlyVideoAws(): void {
        if ( this.blockVideoAppOnlyOne == true){
            this.blockVideoAppOnlyOne = true;
        }else {
            this.blockVideoAppOnlyOne = false;
        }

    }

    addBlockGenericAction(blocks: ContentBlock[]): void {
        blocks.forEach((block, idx) => {
            block.order = idx;
            this.addBlock(block, false);
        });
    }

    getData(): any[] {
        this.blocks.forEach(block => {
            block.form.patchValue({order: block.model.order});
        });
        return this.formArray.value;
    }

    canAddBlock(): boolean {
        if (this.someBlockActivedFlag === true) {
            this.dialogService.showErrorDialog('No puede agregar un bloque porque existe uno en modo escritura. Guarde los cambios y deshabilítelo el modo escritura del otro bloque o solo deshabilítelo', 'Bloque en modo escritura');
            return false;
        } else {
            this.someBlockActivedFlag = true;
        }

        return true;
    }

    addTextBlockAction(): void {
        if (this.canAddBlock()) {
            const cb = new ContentBlockText({});
            cb.newBlock = true;
            this.addBlock(cb);
        }
    }

    addImageBlockAction(): void {
        if (this.canAddBlock()) {
            const cb = new ContentBlockImage({});
            cb.newBlock = true;
            this.addBlock(cb);
        }
    }

    addVideoBlockAction(): void {
        if (this.canAddBlock()) {
            const cb = new ContentBlockVideo({});
            cb.newBlock = true;
            this.addBlock(cb);
        }
    }

    addAppVideoBlockAction(): void {
        if (this.canAddBlock()) {
            const cb = new ContentBlockVideoApp({expiration: 3600});
            cb.newBlock = true;
            this.addBlock(cb);
        }
    }

    addAppAudioBlockAction(): void {
        if (this.canAddBlock()) {
            const cb = new ContentBlockAudioApp({expiration: 3600});
            cb.newBlock = true;
            this.addBlock(cb);
        }
    }

    addResourceBlockAction(): void {
        if (this.canAddBlock()) {
            const cb = new ContentBlockResource({});
            cb.newBlock = true;
            this.addBlock(cb);
        }
    }

    addLabelBlockAction(): void {
        if (this.canAddBlock()) {
            const cb = new ContentBlockLabel({});
            cb.newBlock = true;
            this.addBlock(cb);
        }
    }

    addH5PBlockAction(): void {
        if (this.canAddBlock()) {
            const cb = new ContentBlockH5P({});
            cb.newBlock = true;
            this.addBlock(cb);
        }
    }

    onMoveDownOrder(order: number): void {
        if (this.checkIfThereAreUnmanagedBlocks(order)) {
            return null;
        }

        const idx = order - 1;
        const b1 = this.getOrderValuePair(this.blocks[idx]);
        b1.order += 1;
        const b2 = this.getOrderValuePair(this.blocks[idx + 1]);
        b2.order -= 1;

        this.emitOrderChange(b1, b2, order, 'down');
    }

    onMoveUpOrder(order: number): void {
        if (this.checkIfThereAreUnmanagedBlocks(order)) {
            return null;
        }

        const idx = order - 1;
        const b1 = this.getOrderValuePair(this.blocks[idx]);
        b1.order -= 1;
        const b2 = this.getOrderValuePair(this.blocks[idx - 1]);
        b2.order += 1;

        this.emitOrderChange(b1, b2, order, 'up');
    }

    onSave(event: any): void {
        const data = {...event};
        this.blockAction.emit({action: 'save', data: data});

        if (this.unManagedBlocks === data.formValue.order) {
            this.unManagedBlocks = null;
        }

    }

    onDelete(event): void {
        if (this.checkIfThereAreUnmanagedBlocks(event.order)) {
            return null;
        }

        this.dialogService.showConfirmDialog('', () => {
            const idx = event.order - 1;
            const block = this.blocks[idx];

            const data = {formValue: event.formValue, originalOrderBlock: event.order};
            this.blockAction.emit({action: 'delete', data: data});
        });
    }

    moveDownOrder(order: number): void {
        const idx = order - 1;
        const tmp = this.blocks[idx];
        this.blocks[idx] = this.blocks[idx + 1];
        this.blocks[idx + 1] = tmp;
        this.recalculateOrder();
    }

    moveUpOrder(order: number): void {
        const index = order - 1;
        const tmp = this.blocks[index];
        this.blocks[index] = this.blocks[index - 1];
        this.blocks[index - 1] = tmp;
        this.recalculateOrder();
    }

    delete(order): void {
        const idx = order - 1;
        const block = this.blocks[idx];

        this.formArray.removeAt(this.formArray.controls.indexOf(block.form));
        this.blocks.splice(idx, 1);
        this.recalculateOrder();
    }

    private addBlock(model: ContentBlock, userInput = true): void {
        if (model.id != null) {
            if (userInput && this.checkIfThereAreUnmanagedBlocks(model.order)) {
                return null;
            }
        }

        const formGroup = new FormGroup({});
        this.blocks.push({
            model: model,
            form: formGroup,
        });
        model.order = this.blocks.length;
        this.formArray.push(formGroup);

        if (userInput) {
            this.unManagedBlocks = model.order;
        }
    }

    private recalculateOrder(): void {
        this.blocks.forEach((item, index) => item.model.order = index + 1);
    }

    private emitOrderChange(b1, b2, order: number, direction): void {
        const data = {blocks: [b1, b2], originalOrderBlock: order, direction: direction};
        this.blockAction.emit({action: 'changeOrder', data: data});
    }

    private getOrderValuePair(block: BlockInfo): any {
        return {
            id: block.form.get('id').value,
            order: block.model.order,
        };
    }

    private checkIfThereAreUnmanagedBlocks(order): boolean {
        if (this.unManagedBlocks && this.unManagedBlocks !== order) {
            this.dialogService.showErrorDialog('Existen bloques sin guardar', 'Guardar cambios');
            return true;
        }

        return false;
    }

    someBlockActived(event: any): void {
        if (event === true && this.someBlockActivedFlag === true) {
            this.dialogService.showErrorDialog('Existe un bloque en modo escritura. Guarde los cambios y deshabilite el modo escritura del otro bloque o solo deshabilítelo', 'Bloque en modo escritura');
        } else {
            this.someBlockActivedFlag = event ? true : false;
        }
    }
}
