import {UniversityPermissions} from '../university/config/university-permissions';


export const procedureMenu = {
    id: 'university',
    name: 'university',
    fieldI18Key: 'NAV.school_control',
    menu: [

        {
            name: 'pre-enrolment',
            fieldI18Key: 'NAV.preenrolment',
            url: '/university/procedure/pre-enrolment',
            roles: [UniversityPermissions.MANAGE_STUDY_PLAN]
        },
        {
            name: 'enrolment',
            fieldI18Key: 'NAV.students',
            url: '/university/procedure/enrolment',
            roles: [UniversityPermissions.MANAGE_STUDY_PLAN]
        },
        {
            name: 'enrolmentFail',
            fieldI18Key: 'NAV.enrolmentFail',
            url: '/university/procedure/fail-enrolment-log',
            roles: [UniversityPermissions.MANAGE_STUDY_PLAN]
        },

        // {
        //     name: 'level',
        //     fieldI18Key: 'NAV.LEVEL',
        //     url: '/university/level',
        //     roles: [UniversityPermissions.MANAGE_CAREER]
        // }
        {
            name: 'campaign',
            fieldI18Key: 'NAV.CAMPAIGN',
            url: '/university/procedure/campaign',
            roles: [UniversityPermissions.MANAGE_CAREER]
        },
        {
            name: 'enrolment-announcement',
            fieldI18Key: 'NAV.GENERATIONS',
            url: '/university/procedure/enrolment-announcement',
            roles: [UniversityPermissions.MANAGE_CAREER]
        },
        {
            name: 'group',
            fieldI18Key: 'NAV.GROUP',
            url: '/university/procedure/group',
            roles: [UniversityPermissions.MANAGE_CAREER]
        },
        {
            name: 'course-announcement',
            fieldI18Key: 'NAV.COURSEANNOUNCEMENT',
            url: '/university/procedure/course-announcement',
            roles: [UniversityPermissions.MANAGE_CAREER]
        },
        {
            name: 'institution',
            fieldI18Key: 'NAV.INSTITUTION',
            url: '/university/procedure/institution',
            roles: [UniversityPermissions.MANAGE_CAREER]
        }
    ]
};

export const procedureManagerMenu = {
    projectName: '',
    projectLogo: 'assets/img/neuuni_logotipo_blanco.png',
    modules: [
        procedureMenu
    ]
};



