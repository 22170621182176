import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClientService} from '../../../core/services/rest-client.service';

@Injectable({
    providedIn: 'root'
})
export class VideoAccessRemoteService {
    private URL = '/api/video/access/course';

    constructor(private restClientService: RestClientService) {
    }

    getUrl(url): Observable<any> {
        return this.restClientService.post(this.URL, url);
    }

    getCookie(): Observable<any> {
        return this.restClientService.get(this.URL + '/cookie');
    }

}
