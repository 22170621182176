import {TranslateService} from '@ngx-translate/core';

export class TranslationUtils {

    static processTranslation(model: any, fields: string[], translateService: TranslateService): void {
        const lang = translateService.currentLang;
        model.localizations = {};
        const localizations = {};
        fields.forEach(field => localizations[field] = model[field]);
        model.localizations[lang] = localizations;
    }
}
