import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs';
import {FuseUtils} from '../../../../../@fuse/utils';

import videojs from 'video.js';

@Component({
    selector: 'app-aws-audio-player',
    styleUrls: ['./f-aws-audio-player.component.scss'],
    templateUrl: './f-aws-audio-player.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class FAwsAudioPlayerComponent implements OnInit, OnDestroy {
    player: any;
    ready: boolean;
    elementId: string;
    @Input() src: string;
    @Input() poster: string;
    @Input() readyHandler: Observable<any>;

    constructor() {
        this.player = false;
        this.elementId = FuseUtils.generateGUID();
    }

    ngOnInit(): void {
        if (!this.readyHandler) {
            this.ready = true;
            setTimeout(() => this.initPlayer());
        }
    }

    startPlayback(): void {
        this.readyHandler.subscribe(result => {
            this.src = result.data;
            this.ready = true;
            setTimeout(() => {
                this.initPlayer();
                this.player.play();
            });
        });
    }

    initPlayer(): void {
        const element = document.getElementById(this.elementId);
        const options = {
            autoplay: true,
            controls: true,
            sources: [
                {
                    src: this.src,
                    type: 'audio/mp3',
                    withCredentials: true
                }],
            poster: this.poster
        };
        this.player = videojs(element, options, () => {
            // console.log('onPlayerReady', this);
        });
    }

    ngOnDestroy(): void {
        if (this.player) {
            this.player.dispose();
        }
    }

}
