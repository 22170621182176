import {Component} from '@angular/core';

@Component({
    selector: 'app-f-error-dialog',
    templateUrl: 'f-error-dialog.component.html',
})
export class FErrorDialogComponent {
    title = 'Error';
    message: any;
    btnLabel = 'Cerrar';
}
