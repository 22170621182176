import {Component, Input, OnInit} from '@angular/core';
import {CourseCategoryRemoteService} from '../../../../services/remote-services/course-category.remote.service';
import {CourseSubcategoryRemoteService} from '../../../../services/remote-services/course-subcategory.remote.service';

@Component({
    selector: 'app-categories-field-edit',
    templateUrl: './categories-field-edit.component.html',
    styleUrls: ['./categories-field-edit.component.scss']
})
export class CategoriesFieldEditComponent implements OnInit {
    searchTerm: string;
    search: string;
    categoriesFilter: any[];
    categoriesNoSelect: any[];
    categoriesSelect: any[];
    allCategories: any[];

    searchTermSubCategory: string;
    searchSubcategory: string;
    subCategoriesFilter: any[];
    subCategoriesNoSelect: any[];
    subCategoriesSelect: any[];
    allSubCategories: any[];

    @Input() subCategoriesCourse: any[];

    constructor(private courseCategoryRemoteService: CourseCategoryRemoteService,
                private courseSubcategoryRemoteService: CourseSubcategoryRemoteService) {
        this.search = 'Buscar categoría';
        this.categoriesFilter = [];
        this.categoriesNoSelect = [];
        this.allCategories = [];
        this.categoriesSelect = [];

        this.searchSubcategory = 'Buscar subCategoría';
        this.subCategoriesFilter = [];
        this.subCategoriesNoSelect = [];
        this.allSubCategories = [];
        this.subCategoriesSelect = [];
    }

    ngOnInit(): void {
        this.allCategories = [];
        this.loadCategories();
    }

    loadCategories(): void {
        const categorySelectId = [];
        if (this.subCategoriesCourse) {
            this.subCategoriesCourse.forEach(subCategory => {
                this.subCategoriesSelect.push({
                    id: subCategory.id,
                    name: subCategory.name,
                    courseCategoryId: subCategory.courseCategoryId
                });

                categorySelectId.push(subCategory.courseCategoryId);
            });
        }

        this.courseCategoryRemoteService.search().subscribe(response => {
            response.elements.forEach(category => {
                this.allCategories[this.allCategories.length] = category;
                if (!categorySelectId.includes(category.id)) {
                    this.categoriesNoSelect.push(category);
                } else {
                    this.categoriesSelect.push(category);
                }
            });

            this.getCourseSubcategoryByCategories();
        });

        this.categoriesFilter = this.categoriesNoSelect;
    }

    filterCategoryByTerm(): void {
        const searchTerm = this.searchTerm.toLowerCase();

        if (searchTerm === '') {
            this.categoriesFilter = this.categoriesNoSelect;
        } else {
            this.categoriesFilter = this.categoriesNoSelect.filter((category) => {
                return category.name.toLowerCase().includes(searchTerm);
            });
        }
    }

    addCategory(id): void {
        let post = 0;
        let index = 0;
        this.categoriesNoSelect.forEach(cat => {
            if (cat.id === id) {
                // this.categoriesSelect.push(cat);
                if (this.categoriesSelect.length > 0){
                    this.categoriesNoSelect.push(this.categoriesSelect[0]);
                }
                this.categoriesSelect[0] = cat;
                index = post;
            }
            post++;
        });

        this.categoriesNoSelect.splice(index, 1);

        this.subCategoriesSelect = [];
        this.subCategoriesNoSelect = [];
        this.getCourseSubcategoryByCategories();
    }

    removeCategory(id): void {
        /*let post = 0;
        let index = 0;

        this.categoriesSelect.forEach(cat => {
            if (cat.id === id) {
                this.categoriesNoSelect.push(cat);
                index = post;
            }
            post++;
        });
        this.categoriesSelect.splice(index, 1);*/
        this.categoriesNoSelect.push(this.categoriesSelect[0]);
        this.categoriesSelect = [];

        const aux = [];
        this.subCategoriesNoSelect.forEach(sub => {
            if (sub.courseCategoryId !== id) {
                aux.push(sub);
            }
        });

        this.subCategoriesNoSelect = aux;
        this.allSubCategories = aux;
        this.subCategoriesFilter = aux;

        const auxSelect = [];
        this.subCategoriesSelect.forEach(sub => {
            if (sub.courseCategoryId !== id) {
                auxSelect.push(sub);
            }
        });

        this.subCategoriesSelect = auxSelect;
        this.subCategoriesCourse = auxSelect;
    }

    // For subcategories
    getCourseSubcategoryByCategories(): void {
        const catsIds = [];
        this.categoriesSelect.forEach(cat => {
            catsIds.push(cat.id);
        });
        if (catsIds.length === 0) {
            return;
        }

        this.courseSubcategoryRemoteService.getByCourseCategoryLisId(catsIds).subscribe(response => {
            const subCategorySelectId = [];
            this.subCategoriesFilter = [];
            this.allSubCategories = [];
            this.subCategoriesNoSelect = [];

            this.subCategoriesCourse.forEach(sub => subCategorySelectId.push(sub.id));

            response.forEach(subCategory => {
                this.allSubCategories[this.allSubCategories.length] = subCategory;
                if (subCategorySelectId.indexOf(subCategory.id) < 0 && subCategory.name) {
                    this.subCategoriesNoSelect.push(subCategory);
                }
            });

            this.subCategoriesFilter = this.subCategoriesNoSelect;
        });
    }

    filterSubCategoryByTerm(): void {
        const searchTerm = this.searchTermSubCategory.toLowerCase();

        if (searchTerm === '') {
            this.subCategoriesFilter = this.subCategoriesNoSelect;
        } else {
            this.subCategoriesFilter = this.subCategoriesNoSelect.filter((sub) => {
                return sub.name.toLowerCase().includes(searchTerm);
            });
        }
    }

    addSubCategory(id): void {
        let post = 0;
        let index = 0;
        this.subCategoriesNoSelect.forEach(subcategory => {
            if (subcategory.id === id) {
                this.subCategoriesSelect.push(subcategory);
                index = post;
            }
            post++;
        });

        this.subCategoriesNoSelect.splice(index, 1);
    }

    removeSubCategory(id): void {
        let post = 0;
        let index = 0;

        this.subCategoriesSelect.forEach(subCat => {
            if (subCat.id === id) {
                this.subCategoriesNoSelect.push(subCat);
                index = post;
            }
            post++;
        });

        this.subCategoriesSelect.splice(index, 1);
    }

}
