<div fxLayout="row wrap" fxLayoutAlign="start center">
    <div class="mb-16 block-image-content">
        <div class="mb-8">Imagen</div>
<!--        <app-f-image-preview-field [initialData]="initialImageUrl" (fileChange)="onFileChange($event)"></app-f-image-preview-field>-->
        <app-f-drop-zone-image-field #images
                                     (fileRemoved)="onFileRemoved($event)"
                                     (fileChanged)="onImageChange($event)"
                                     [limit]="5"
                                     [maxSize]="2"
                                     [identifier]="model.order"
                                     [maintainAspectRatio]="false"
                                     [disabled]="!disabledBlock"
        ></app-f-drop-zone-image-field>
    </div>
</div>