import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClientService} from '../../../core/services/rest-client.service';
import {PagedResult} from '../../models/paged-result';

@Injectable({
    providedIn: 'root'
})
export class CourseStudentMemberRemoteService {
    private URL = '/api/course-student-member';

    constructor(private restClientService: RestClientService) {
    }

    search(id: string, criteria?): Observable<PagedResult<any>> {
        return this.restClientService.get(`${this.URL}/${id}`, criteria);
    }

    create(courseId: string, userIds: string[]): Observable<any> {
        return this.restClientService.post(`${this.URL}/${courseId}`, {userIdList: userIds}, undefined, {handleErr: true});
    }

    finishCourse(courseId: string, courseAnnouncementId: string): Observable<any> {
        return this.restClientService.put(`${this.URL}/finish-course/${courseId}/owned`, {courseAnnouncementId: courseAnnouncementId});
    }

    finishCourseByTeacher(courseId: string, userId: string, courseAnnouncementId: string): Observable<any> {
        return this.restClientService.put(`${this.URL}/finish-course/${courseId}/${userId}/teacher`, {courseAnnouncementId: courseAnnouncementId});
    }

    delete(courseId: string, userId: string, courseAnnouncementId: string): Observable<any> {
        return this.restClientService.delete(`${this.URL}/${courseId}/${userId}`,
            {courseAnnouncementId: courseAnnouncementId});
    }

    searchMyStudents(criteria): Observable<any> {
        return this.restClientService.get(`${this.URL}/my-students`, {
            page: criteria.page,
            size: 1000,
            q: criteria.q
        });
    }

}
