import {ProcessNotification} from '../user-notification.service';

export class ApprovedPaymentOrderHandler implements ProcessNotification {

    processNotification(notification, data, roleContext): void {
        notification.text = 'Su pago ha sido efectuado satisfactoriamente.';
        notification.url = `/payment/invoice/${data.orderId}`;
        notification.icon = 'shopping_cart';
        notification.avatar = '/assets/images/logos/neuuni_isotipo_250px.png';
    }

}
