import {CourseRole} from '../../../course/models/course-role';
import {ProcessNotification} from '../user-notification.service';

export class UnitProjectFeedbackHandler implements ProcessNotification {

    processNotification(notification, data, roleContext): void {
        const courseId = notification.group1;
        const unitId = notification.group2;
        const activityId = notification.group3;
        const courseAnnouncementUrl = (notification.group6) ? `course-announcement/${notification.group6}/` : '';

        notification.text = `Nuevo mensaje de proyecto. Curso ${data.courseName}`;

        if (roleContext === CourseRole.TEACHER.toLowerCase()) {

            notification.url = `/courses/${courseAnnouncementUrl}course/${courseId}/preview/${roleContext}/${data.unitOrder}/${unitId}` +
                `/project/${activityId}/${roleContext}/eval/0?tab=1&studentId=${notification.group4}`;


        } else if (roleContext === CourseRole.STUDENT.toLowerCase()) {

            notification.url = `/courses/${courseAnnouncementUrl}course/${courseId}/preview/${roleContext}/${data.unitOrder}/${unitId}` +
                `/project/${activityId}/${roleContext}?tab=1`;

        }

        notification.icon = 'forum';
        notification.readStrategy = 'other';
    }

}
