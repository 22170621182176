<div fxLayout="column" [formGroup]="form">

    <mat-form-field appearance="outline" fxFlex="100" [hintLabel]="'core.maxCharacters' | translate:{count: 100}">
        <mat-label>{{ 'course.audioUrl' | translate }}</mat-label>
        <input #audioUrlInput matInput type="text" maxlength="150" formControlName="audioUrl">
        <mat-hint align="end">{{audioUrlInput.value?.length || 0}}/{{audioUrlInput.maxLength}}</mat-hint>
        <mat-error *ngIf="form.controls.audioUrl.hasError('url')">{{ 'core.urlNotValid' | translate }}</mat-error>
    </mat-form-field>

    <div class="mb-16 block-image-content">
        <div class="mb-8">Imagen</div>
        <app-f-drop-zone-image-field #images
                                     (fileChanged)="onImageChange($event)"
                                     (fileRemoved)="onFileRemoved($event)"
                                     [limit]="1"
                                     [maxSize]="2"
                                     [identifier]="model.order"
                                     [disabled]="!disabledBlock"
        ></app-f-drop-zone-image-field>
    </div>

</div>