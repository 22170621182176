import {AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {FuseConfigService} from '@fuse/services/config.service';
import {navigation} from 'app/navigation/navigation';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AuthService} from '../../../main/core/auth/service/auth.service';
import {SecurityService} from '../../../main/core/services/security.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ProfileRemoteService} from '../../../main/course/components/common/profile/services/remote/profile.remote.service';
import {NotificationService} from '../../../main/core/services/notification.service';
import {UserRemoteService} from '../../../main/general/services/user.remoteservice';
import {AccountService} from '../../../main/core/auth/service/account.service';
import { AppUtils } from '@quiox/global/helpers/app.utils';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    navigation: any;
    userStatusOptions: any[];
    currentUser: any;
    verificate: boolean;
    visitedProfile: boolean;
    mailtext = '';
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private authService: AuthService,
        private profileRemoteService: ProfileRemoteService,
        private notificationService: NotificationService,
        private securityService: SecurityService,
        private router: Router,
        private userRemoteService: UserRemoteService,
        private accountService: AccountService,
        private changeDetector: ChangeDetectorRef
    ) {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        //
        // route.url.subscribe(params => {
        //   console.log(route.url);
        // });

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        // this.selectedLanguage = _.find(this.languagesCurrencies, {id: this._translateService.currentLang});

        SecurityService.userInfo$().pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe(user => {
            console.log(user);
            this.currentUser = user;
            this.showVerifications();
            // console.log(user);
        });

        // ESTO ES PARA MOSTRAR COMPACTADO EL MENU LATERAL PARA ESTUDIANTE Y PROFESOR
        // if (!isNullOrUndefined(this.currentUser)) {
        //     setTimeout(() => {
        //         const folded = this.currentUser.roles.includes(SecurityService.TEACHER_ROLE)
        //             || this.currentUser.roles.includes(SecurityService.STUDENT_ROLE);
        //         const navBarComponent = this._fuseSidebarService.getSidebar('navbar');
        //         if (navBarComponent) {
        //             navBarComponent.folded = folded;
        //         }
        //     });
        // }

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    goProfile(): void {

        if (!this.visitedProfile) {
            this.router.navigateByUrl(`/courses/profile/${this.currentUser?.id}/wizard`);
        } else {
            this.router.navigateByUrl(`/courses/profile/${this.currentUser?.id}`);
        }

    }

    showVerifications(): void {
        if (this.currentUser) {
            if (this.currentUser.visitedProfile) {
                this.visitedProfile = true;
            }
            if (!this.currentUser.emailConfirmation) {
                this.verificate = true;
            }
            this.router.events.forEach(item => {
                if (item instanceof NavigationEnd) {
                    if (!this.currentUser.emailConfirmation) {
                        this.verificate = true;
                    }
                    if (item.url.indexOf('pre-enrolment/my-pre-enrolments/application/') > -1) {
                        this.verificate = false;
                    }

                    if (item.url.indexOf('wizard') > -1) {
                        this.visitedProfile = true;
                    }
                    // if ( {
                    //
                    //     this.verificate = false;
                    // }


                }
            });
        }

    }

    verificateAcount(): void {
        this.userRemoteService.checkVerification().subscribe(response => {
            if (response.data) {
                this.verificate = false;
                this.securityService.updateUser({emailConfirmation: true});

            } else {
                this.notificationService.showMessage('Lo sentimos, su cuenta aún no ha sido verificada');
            }
        });

    }

    async resendMail(): Promise<void> {
        const userId = this.currentUser.id;
        this.mailtext = 'enviando correo...';
        try {
            await this.accountService.resendMailVerification(userId).toPromise();
            this.mailtext = 'correo enviado';
            this.notificationService.showMessage('Correo enviado satisfactoriamente');
            setTimeout(() => {
                this.mailtext = '';
            }, 6000);
        } catch (e) {
            this.mailtext = 'Correo no enviado. Intente de nuevo';
            setTimeout(() => {
                this.mailtext = '';
            }, 6000);
        }


    }

    // [routerLink]="['/courses/profile', currentUser?.id]"
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(): void {
        // Do your search here...
        // console.log(value);
    }

    logout(): void {
    //    this.authService.logoutCompletely();
    AppUtils.logout();
    }

}
