import {CoursePermissions} from '../course/config/course-permissions';


export const contentConfigMenu = {
    id: 'sync-lesson',
    name: 'sync-lesson',
    fieldI18Key: 'NAV.content',
    menu: [
        {
            name: 'syncLesson',
            fieldI18Key: 'NAV.syncLesson',
            url: '/content-sync-lesson',
            icon: 'access_time',
            roles: [CoursePermissions.CONTENT_SYNC_LESSON_MANAGE]
        }
    ]
};

export const contentMenu = {
    projectName: '',
    projectLogo: 'assets/img/neuuni_logotipo_blanco.png',
    modules: [
        contentConfigMenu
    ]
};
