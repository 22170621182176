import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class StorageService {

    set(key: string, value: string): void {
        localStorage[key] = value;
    }

    get(key: string, defaultValue?: string): string {
        return localStorage[key] || defaultValue;
    }

    setObject(key: string, value: any): void {
        localStorage[key] = JSON.stringify(value);
    }

    getObject(key: string): any {
        return JSON.parse(localStorage[key] || null);
    }

    delete(key: string): any {
        delete localStorage[key];
    }

    clear(): any {
        localStorage.clear();
    }
}
