import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {TranslationUtils} from '../../../core/services/translation.utils';
import {Deleteable, Retrieveable, Saveable} from '../../../core/services/remote-services-interfaces';
import {RestClientService} from '../../../core/services/rest-client.service';
import {CourseSubCategory} from '../../models/course-subcategory';

@Injectable({
    providedIn: 'root'
})
export class CourseSubcategoryRemoteService implements Retrieveable<CourseSubCategory, string>,
    Saveable<CourseSubCategory>,
    Deleteable<CourseSubCategory, string> {

    private URL = '/api/course-subcategories';

    constructor(private restClientService: RestClientService, private translateService: TranslateService) {
    }

    get(id: string): Observable<CourseSubCategory> {
        return this.restClientService.get(`${this.URL}/${id}`);
    }

    save(item: CourseSubCategory): Observable<CourseSubCategory> {
        TranslationUtils.processTranslation(item, this.getTranslatableFields(), this.translateService);
        if (item.id) {
            return this.restClientService.put(`${this.URL}/${item.id}`, item);
        }
        return this.restClientService.post(this.URL, item);
    }

    getByCourseCategoryId(courseCategoryId: string): Observable<CourseSubCategory[]> {
        return this.restClientService.get(`${this.URL}/${courseCategoryId}/list`);
    }

    getByCourseCategoryLisId(courseCategoryId: any[]): Observable<CourseSubCategory[]> {
        return this.restClientService.get(`${this.URL}/list/categoryIds/${courseCategoryId}`);
    }

    delete(id: string): Observable<CourseSubCategory> {
        return this.restClientService.delete(`${this.URL}/${id}`);
    }

    getTranslatableFields(): string[] {
        return ['name', 'description'];
    }

}
