import {ContentBlock} from './content-block';
import {Image} from '../../../core/models/image';

export class ContentBlockImage extends ContentBlock {
    images: Image[];

    constructor(lessonBlock) {
        super(lessonBlock);
        this.images = lessonBlock.images;
    }

    updateData(data): void {
        super.updateData(data);
        this.images = data.images;
    }

    getTitle(): string {
        return 'course.imageBlock';
    }

    getTypeAsString(): string {
        return 'image';
    }

}
