import {UniversityPermissions} from '../university/config/university-permissions';


export const universityMenu = {
    id: 'university',
    name: 'university',
    fieldI18Key: 'NAV.university',
    menu: [
        {
            name: 'study-plan',
            fieldI18Key: 'NAV.STUDYPLAN',
            url: '/university/study-plan',
            roles: [UniversityPermissions.MANAGE_STUDY_PLAN]
        },
        {
            name: 'career',
            fieldI18Key: 'NAV.CAREER',
            url: '/university/career',
            roles: [UniversityPermissions.MANAGE_CAREER]
        },
        {
            name: 'discipline',
            fieldI18Key: 'NAV.DISCIPLINE',
            url: '/university/discipline',
            roles: [UniversityPermissions.MANAGE_DISCIPLINE]
        },
        {
            name: 'level',
            fieldI18Key: 'NAV.LEVEL',
            url: '/university/level',
            roles: [UniversityPermissions.MANAGE_LEVEL]
        }
        // {
        //     name: 'enrolment-announcement',
        //     fieldI18Key: 'NAV.ENROLMENTANNOUNCEMENT',
        //     url: '/university/enrolment-announcement',
        //     roles: [UniversityPermissions.MANAGE_CAREER]
        // }
    ]
};

export const universityManagerMenu = {
    projectName: '',
    projectLogo: 'assets/img/neuuni_logotipo_blanco.png',
    modules: [
        universityMenu
    ]
};



