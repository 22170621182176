import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'appSafeStyle',
})
export class SafeStylePipe implements PipeTransform {

    constructor(private domSanitizer: DomSanitizer) {
    }

    public transform(value: any, args?: any[]): any {
        return this.domSanitizer.bypassSecurityTrustStyle(value);
    }

}
