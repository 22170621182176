import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'dialog-filter-date',
    templateUrl: './dialog-filter-date.component.html',
    styleUrls: ['./dialog-filter-date.component.scss'],

})
export class DialogFilterDate {
    acept = false;
    form: FormGroup;

    constructor(public dialogRef: MatDialogRef<any>,
                public fb: FormBuilder) {
        this.form = this.fb.group({
            minDate: [null, [Validators.required]],
            maxDate: [null, [Validators.required]],
        });
    }

    acepted() {
        this.acept = true;
        this.dialogRef.close();
    }

}