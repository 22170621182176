<div *ngIf="label" class="my-16" [class.invalid]="invalidValue">
    <span *ngIf="invalidValue  && value.length == 0 "> ({{ 'core.requiredField' | translate }})</span>
</div>
<ckeditor #editorCk [editor]="classicEditor" [disabled]="isDisabled" [config]="editorConfig"
          [(ngModel)]="value"></ckeditor>
<div fxLayout="column">
    <mat-hint align="end">{{value.length || 0}}</mat-hint>
    <mat-error *ngIf="value.length == 0 && required">Campo requerido</mat-error>
    <mat-error *ngIf="value.length < minLength">Debe tener más de {{minLength}} caracteres</mat-error>
    <mat-error *ngIf="value.length > maxLength">No debe tener más de {{maxLength}} caracteres</mat-error>
    <mat-error *ngIf="value.includes('figure')===true && value.includes('img')===true">No debe contener imagen
    </mat-error>
</div>