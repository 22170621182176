import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../models/app-config';
import {AppConfigService} from './app-config.service';

@Injectable()
export class AppConfigLoaderService {
    constructor(private httpClient: HttpClient, private appConfigService: AppConfigService) {
    }

    public loadConfiguration(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.httpClient.get<AppConfig>('./assets/config/app-config.json')
                .subscribe((appConfig) => {
                    this.appConfigService.setAppConfig(appConfig);
                    resolve(true);
                });
        });
    }
}
