
import {UniversityPermissions} from '../university/config/university-permissions';


export const resultadoExportarMenu = {
    id: 'resultado-exportar',
    name: 'resultado-exportar',
    fieldI18Key: 'NAV.resultExport',
    menu: [
        {
            name: 'resultExport',
            fieldI18Key: 'NAV.resultExport',
            url: '/resultado-exportar',
            roles: [UniversityPermissions.MANAGE_CAREER]
        }
    ]
};

export const resultadoExportarManagerMenu = {
    projectName: '',
    projectLogo: 'assets/img/neuuni_logotipo_blanco.png',
    modules: [
        resultadoExportarMenu
    ]
};
