import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FDropZoneImageFieldComponent} from '../f-drop-zone-image/f-drop-zone-image-field.component';
import {FuseUtils} from '../../../../../@fuse/utils';


@Component({
    selector: 'app-aws-video-editor',
    templateUrl: './f-aws-video-editor.component.html',
})
export class FAwsVideoEditorComponent implements OnInit {
    id: string;
    form: FormGroup;

    @ViewChild('image', {read: FDropZoneImageFieldComponent})
    imageComponent: FDropZoneImageFieldComponent;

    constructor() {
        this.id = FuseUtils.generateGUID();
    }

    ngOnInit(): void {
    }

    initialize(form): void {
        this.form = form;
        setTimeout(() => {
            this.setImage(this.form.get('image').value);
        });
    }

    updateData(data): void {
        this.setImage(data.image);
    }

    onImageChange(event): void {
        const images = this.imageComponent.files.filter((f: any) => !f.metaFromServer);
        this.form.get('image').setValue(images[0]);
    }

    onFileRemoved(fileData: any): void {
        this.form.get('image').setValue(null);
    }

    setImage(image: any): void {
        this.form.get('image').setValue(null);
        this.imageComponent.files = [];
        this.imageComponent.filesPreview = [];
        if (image) {
            this.imageComponent.addFile(image);
        }
    }

}
