import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LangCurrencyService {
    private current: number;
    private readonly data: any[];

    constructor() {

        this.data = [
            {
                id: 'mx',
                lang: 'es_US',
                currency: 'MXN'
            },
            {
                id: 'other',
                lang: 'es_US',
                currency: 'USD'
            }
        ];
    }

    getCurrent(): any {
        return this.data[this.current];
    }

    setCurrent(id): void {
        const idx = this.data.findIndex(i => i.id === id);
        this.current = idx !== -1 ? idx : 0;
    }

}

