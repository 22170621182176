import {GeneralPermissions} from '../general/config/general-permissions';
import {MarketingPermissions} from '../general/config/marketing-permissions';

export const marketingMenu = {
    id: 'marketing',
    name: 'Marketing',
    fieldI18Key: 'NAV.WELCOMECONTENT',
    menu: [
        {
            name: 'General',
            fieldI18Key: 'NAV.GENERALINFO',
            url: '/welcome-management/general-info',
            roles: [MarketingPermissions.GENERAL_INFO_VIEW]
        },
        {
            name: 'Section',
            fieldI18Key: 'NAV.SECTIONS',
            url: '/welcome-management/section',
            roles: [MarketingPermissions.GENERAL_INFO_VIEW]
        },
        {
            name: 'Slider',
            fieldI18Key: 'NAV.SLIDERS',
            url: '/welcome-management/slider',
            roles: [MarketingPermissions.SLIDER_VIEW]
        },
        {
            name: 'Video',
            fieldI18Key: 'NAV.VIDEO',
            url: '/welcome-management/video',
            roles: [MarketingPermissions.BLOCK_VIDEO_INFO_VIEW]
        },
        {
            name: 'Legal',
            fieldI18Key: 'NAV.LEGALDOCUMENTS',
            url: '/welcome-management/legal-document',
            roles: [MarketingPermissions.LEGAL_DOCUMENT_VIEW]
        },

        {
            name: 'Info',
            fieldI18Key: 'NAV.OURCOURSES',
            url: '/welcome-management/course-info',
            roles: [MarketingPermissions.NEUUNI_COURSE_INFO_VIEW]
        }

    ]
};

export const marketingMangerMenu = {
    projectName: '',
    projectLogo: 'assets/img/neuuni_logotipo_blanco.png',
    modules: [
        marketingMenu
    ]
};



