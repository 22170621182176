import {AbstractControl, ValidationErrors, Validators} from '@angular/forms';

export class AppValidators {
    static isAnObject(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }

        if (!(typeof control.value === 'object')) {
            return {notObject: true};
        }

        return null;
    }

    public static url(formControl: AbstractControl): ValidationErrors | null {
        if (formControl.value && formControl.value !== '') {
            return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(formControl.value)
                ? null
                : {url: {valid: false}};
        }
        return null;
    }

    public static password(formControl: AbstractControl): ValidationErrors | null {
        if (formControl.value && formControl.value !== '') {
            return /[A-Za-z0-9]+/g.test(formControl.value)
                ? null
                : {password: {valid: false}};
        }
        return null;
    }

    public static passwordCognito(formControl: AbstractControl): ValidationErrors | null {
        if (formControl.value && formControl.value !== '') {
            return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{9,}$/g.test(formControl.value)
                ? null
                : {password: {valid: false}};
        }
        return null;
    }

    public static image(formControl: AbstractControl): ValidationErrors | null {
        if (formControl.value && formControl.value !== '') {
            return /(<figure>|img)/.test(formControl.value)
                ? {password: {valid: false}}
                : null;
        }
        return null;
    }

    static readonly phone = Validators.compose([
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('[0-9]*')]);

}
