import {ProcessNotification} from '../user-notification.service';

export class UnitForumMessageHandler implements ProcessNotification {

    processNotification(notification, data, roleContext): void {
        const courseId = notification.group1;
        const unitId = notification.group2;
        const courseAnnouncementUrl = (notification.group6) ? `course-announcement/${notification.group6}/` : '';

        notification.text = `Nuevo mensaje de foro. Curso ${data.courseName}`;
        notification.url = `/courses/${courseAnnouncementUrl}course/${courseId}/preview/${roleContext}/${data.unitOrder}/${unitId}/forum`;
        notification.icon = 'forum';
        notification.readStrategy = 'other';
    }
}
