import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CourseCategory} from '../../models/course-category';
import {TranslateService} from '@ngx-translate/core';
import {TranslationUtils} from '../../../core/services/translation.utils';
import {Deleteable, Retrieveable, Saveable, Searchable} from '../../../core/services/remote-services-interfaces';
import {RestClientService} from '../../../core/services/rest-client.service';
import {PagedResult} from '../../models/paged-result';


@Injectable({
    providedIn: 'root'
})
export class CourseCategoryRemoteService implements Searchable<CourseCategory>,
    Retrieveable<CourseCategory, string>,
    Saveable<CourseCategory>,
    Deleteable<CourseCategory, string> {

    private URL = '/api/course-categories';

    constructor(private restClientService: RestClientService, private translateService: TranslateService) {
    }

    search(criteria?: any): Observable<PagedResult<CourseCategory>> {
        return this.restClientService.get(this.URL, criteria);
    }

    get(id: string): Observable<CourseCategory> {
        return this.restClientService.get(`${this.URL}/${id}/with-subcategories`);
    }

    getSimple(id: string): Observable<CourseCategory> {
        return this.restClientService.get(`${this.URL}/${id}`);
    }

    getSEO(id: string): Observable<CourseCategory> {
        return this.restClientService.get(`${this.URL}/seo/${id}`);
    }

    getAllNotInIds(ids: string[]): Observable<CourseCategory[]> {
        return this.restClientService.post(`${this.URL}/get-all-not-in-ids`, ids);
    }

    save(item: CourseCategory): Observable<CourseCategory> {
        TranslationUtils.processTranslation(item, this.getTranslatableFields(), this.translateService);
        if (item.id) {
            return this.restClientService.put(`${this.URL}/${item.id}`, item);
        }
        return this.restClientService.post(this.URL, item);
    }

    saveUrlTitle(item: CourseCategory): Observable<CourseCategory> {
        TranslationUtils.processTranslation(item, this.getTranslatableFields(), this.translateService);
        return this.restClientService.put(`${this.URL}/seo/${item.id}`, item);

    }

    delete(id: string): Observable<CourseCategory> {
        return this.restClientService.delete(`${this.URL}/${id}`);
    }


    searchSimpleView(): Observable<CourseCategory[]> {
        return this.restClientService.get<CourseCategory[]>(`${this.URL}/nomenclator`);
    }

    getTranslatableFields(): string[] {
        return ['name', 'description', 'seoTitle'];
    }

}
