import {ProcessNotification} from '../user-notification.service';

export class InscriptionCourseHandler implements ProcessNotification {

    processNotification(notification, data, roleContext): void {
        const courseAnnouncementUrl = (notification.group6) ? `course-announcement/${notification.group6}/` : '';

        notification.text = `Ya eres estudiante del curso: ${data.courseName}`;
        notification.url = `/courses/${courseAnnouncementUrl}course/${data.courseId}/preview/${roleContext}/0/detail`;
        notification.icon = 'school';
        notification.avatar = '/assets/images/logos/neuuni_isotipo_250px.png';
    }

}
