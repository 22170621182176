import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClientService} from '../../core/services/rest-client.service';


@Injectable({
    providedIn: 'root'
})
export class MessageRemoteService {
    private URL = RestClientService.CHAT_SERVER_URL_PREFIX +  'api/conversation';

    constructor(private restClientService: RestClientService) {
    }

    getPreviousMessages(conversationId, date, size: number): Observable<any[]> {
        return this.restClientService.get<any[]>(`${this.URL}/${conversationId}/${date}`, {size: size});
    }

    createConversation(userId): Observable<any> {
        return this.restClientService.post(`${this.URL}/${userId}`);
    }

    setPreviousMessagesAsRead(conversationId: string, date, size): Observable<any> {
        return this.restClientService.post(`${this.URL}/${conversationId}/read-previous-messages/${date}`, {}, {size: size});
    }

    getNewMessageCount(): Observable<any> {
        return this.restClientService.get(`${this.URL}/new-message-count`);
    }

}
