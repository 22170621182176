<mat-form-field floatLabel="never">
  <mat-chip-list #chipList1>
    <mat-chip *ngFor="let chip of chipSelect"
               [removable]="true" [selectable]="true">
      {{chip.value}}      
      <mat-icon matChipRemove svgIcon="close" (click)="remove(chip)"></mat-icon>
    </mat-chip>
    <input id="filtro" matInput [matAutocomplete]="reactiveAuto" placeholder="Buscar..."
           [formControl]="filterCtrl"
           [matChipInputFor]="chipList1"
           (matChipInputTokenEnd)="add($event)">

    <mat-autocomplete #reactiveAuto="matAutocomplete" (optionSelected)="selectedOption($event)">
      <mat-option *ngFor="let filter of filters" [value]="filter">
        <span>{{ filter.name }}</span>
        <strong class="demo-secondary-text"> {{filter.text?filter.text:''}} </strong>
      </mat-option>
    </mat-autocomplete>
  </mat-chip-list>
</mat-form-field>