import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {CoursePermissions} from '../../course/config/course-permissions';
import {FuseNavigationService} from '../../../../@fuse/components/navigation/navigation.service';
import {menuStudent} from '../../menuConfig/menu-student';
import {marketingMenu} from '../../menuConfig/menu-marketing';

@Injectable({
    providedIn: 'root'
})
export class MyCareerService {
    private static careers$: BehaviorSubject<any>;
    private static preEnrollments$: BehaviorSubject<any>;

    constructor(private storageService: StorageService, private _fuseNavigationService: FuseNavigationService) {

        MyCareerService.careers$ = new BehaviorSubject(this.getCareers());
        MyCareerService.preEnrollments$ = new BehaviorSubject(this.getPreEnrollments());
    }

    static getCareers(): any {
        return MyCareerService.careers$.getValue();
    }

    static getPreEnrollments(): any {
        return MyCareerService.preEnrollments$.getValue();
    }

    static careerInfo$(): Observable<any> {
        return MyCareerService.careers$;
    }


    private getCareers(): any {
        return this.storageService.getObject('myCareers');
    }

    setCareer(career: any): void {
        MyCareerService.careers$.next(career);
        this.storageService.setObject('myCareers', career);
    }

    private getPreEnrollments(): any {
        return this.storageService.getObject('myPreEnrollments');
    }

    setPreEnrollments(preEnrollments: any): void {
        MyCareerService.preEnrollments$.next(preEnrollments);
        this.storageService.setObject('myPreEnrollments', preEnrollments);
    }

    buildCareerMenu(moment): any {
        console.log('Valor del moment', moment);
        if (moment === 'now') {
            menuStudent.modules = [menuStudent.modules[1]];
        }
        const universityStudentMyMenu = {
            projectName: '',
            projectLogo: 'assets/img/neuuni_logotipo_blanco.png',
            modules: [
                {
                    id: 'university-student',
                    name: 'university-student',
                    fieldI18Key: 'NAV.university',
                    menu: []
                }]
        };
        const universityItems = [];
        MyCareerService.getCareers().forEach(us => {
            const menu = {
                title: us.name,
                fieldI18Key: us.name,
                url: us.enrolled ? '/university/my-career/enrollment-announcement/' + us.studyPlanId + '/' + us.generationId : '/payment/cart',
                icon: 'book',
                type: 'item',
                order: '1',
                isEnrolled: us.enrolled,
                roles: [CoursePermissions.CATEGORY_VIEW]
            };
            universityStudentMyMenu.modules[0].menu.push(menu);
        });
        // Comentando ofertas academicas
        // const menuOffers = {
        //     name: 'offers',
        //     fieldI18Key: 'NAV.offers',
        //     url: '/university/procedure/offers',
        //     icon: 'school',
        //     roles: [CoursePermissions.UNIVERSITY_STUDENT_MENU_VIEW]
        // };
        // universityStudentMyMenu.modules[0].menu.push(menuOffers);
        MyCareerService.getPreEnrollments().forEach(us => {
            const menuPreEnrollmentsMenu = {
                title: us.enrollmentAnnouncementName,
                fieldI18Key: us.enrollmentAnnouncementName,
                url: '/university/procedure/pre-enrolment/my-pre-enrolments/application/' + us.enrollmentAnnouncementCode + '/' + us.id + '/personal/student',
                icon: 'book',
                type: 'item',
                roles: [CoursePermissions.CATEGORY_VIEW]
            };
            universityStudentMyMenu.modules[0].menu.push(menuPreEnrollmentsMenu);
        });
        // this._fuseNavigationService.addNavigationItem(universityStudentMyMenu, 'start');
        const studentMenu = menuStudent.modules[0];
        menuStudent.modules[0] = universityStudentMyMenu.modules[0];
        menuStudent.modules[1] = studentMenu;
    }

}
