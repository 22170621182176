<div fxLayout="column" [formGroup]="form">

    <mat-form-field appearance="outline" fxFlex="100" [hintLabel]="'core.maxCharacters' | translate:{count: 100}">
        <mat-label>{{ 'course.videoUrl' | translate }}</mat-label>
        <input #videoUrlInput matInput type="text" maxlength="150" formControlName="videoUrl">
        <mat-hint align="end">{{videoUrlInput.value?.length || 0}}/{{videoUrlInput.maxLength}}</mat-hint>
        <mat-error *ngIf="form.controls.videoUrl.hasError('url')">{{ 'core.urlNotValid' | translate }}</mat-error>
    </mat-form-field>

    <div class="mb-16 block-image-content">
        <div class="mb-8">Imagen del Video</div>
        <app-f-drop-zone-image-field-concurrency #image
                                     (fileChanged)="onImageChange($event)"
                                     (fileRemoved)="onFileRemoved($event)"
                                     [limit]="1"
                                     [maxSize]="2"
        ></app-f-drop-zone-image-field-concurrency>
    </div>

</div>