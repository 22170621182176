import {Injectable} from '@angular/core';
import {LangCurrencyService} from 'app/main/core-common/services/lang-currency.service';
import {RestClientService} from 'app/main/core/services/rest-client.service';
import {Observable} from 'rxjs';
import {StorageService} from '../../core/services/storage.service';
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class PaymentCartRemoteService {
    private URL = '/api/payment-cart';

    constructor(private restClientService: RestClientService, private langCurrencyService: LangCurrencyService, private storageService: StorageService, private _http: HttpClient) {
    }

    add(courseId: string, courseAnnouncementId: string): Observable<any> {
        return this.restClientService.post(`${this.URL}/${courseId}`, {courseAnnouncementId: courseAnnouncementId});
    }

    addUniversityCart(courseId: string, generationId: string, courseAnnouncementId: string): Observable<any> {
        return this.restClientService.post(`${this.URL}/add-one-university/${courseId}`,
            {
                courseAnnouncementId: courseAnnouncementId,
                generationId: generationId
            });
    }

    delete(courseId: string, courseAnnouncementId: string): Observable<any> {
        return this.restClientService.delete(`${this.URL}/${courseId}`, {courseAnnouncementId: courseAnnouncementId});
    }

    get(): Observable<any[]> {
        return this.restClientService.get(`${this.URL}`, {
            currency: this.langCurrencyService.getCurrent().currency,
            couponCode: localStorage.couponCode,
        });
    }

    getUniversityCart(): Observable<any[]> {
        return this.restClientService.get(`${this.URL}/university`, {
            currency: this.langCurrencyService.getCurrent().currency,
            couponCode: localStorage.couponCode,
        });
    }

    PayCoursesUniversity(item: any, inscription: boolean): Observable<any> {
        return this.restClientService.post(`api/payment/buy-manually/`, item, {
            currency: this.langCurrencyService.getCurrent().currency,
            newInscription: inscription
        });
    }


    getAmountByAnnouncement(id: any): Observable<any> {
        return this.restClientService.get(`api/payment/order/amount-every-course/${id}`, {currency: this.langCurrencyService.getCurrent().currency});
    }

    getPaymentsByUserInAnnouncement(userId: any, enrollmentAnnouncementId: any): Observable<any> {
        return this.restClientService.get(`api/payment/payments-by-enrollment/`, {
            userId: userId,
            enrollmentAnnouncementId: enrollmentAnnouncementId
        });
    }

    getPayment(paymentId): Observable<any> {
        return this.restClientService.get(`api/payment/payments-to-enrollment/${paymentId}`);
    }

    setPayment(paymentId, payment): Observable<any> {
        return this.restClientService.put(`api/payment/payments-to-enrollment/${paymentId}`, payment);
    }
}
