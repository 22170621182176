import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'dialog-filter-input',
    templateUrl: './dialog-filter-input.component.html',
    styleUrls: ['./dialog-filter-input.component.scss'],

})
export class DialogFilterInput {
    title = 'Filtrar';
    acept = false;
    form: FormGroup;    

    constructor(public dialogRef: MatDialogRef<any>,
                public fb: FormBuilder) {
        this.form = this.fb.group({
            value: [null, [Validators.required]]
        });
    }

    keyPress(event) {
        if (event.key === 'Enter' && this.form.valid) {
            this.acepted();
        }
    }

    acepted() {
        this.acept = true;
        this.dialogRef.close();
    }

}